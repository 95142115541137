import { ClientImage, ClientImageId, PageId } from '@mero/api-sdk';
import { ClientId } from '@mero/api-sdk/dist/clients';
import { ClientImageNotePreview } from '@mero/api-sdk/dist/pro/clientImages/clientImageNotePreview';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { FlatList, useWindowDimensions, Platform, Pressable, Text } from 'react-native';

import { ClientGalleryImage, Column, Row, Spacer, Title, Button } from '../../../../../components/shared';
import ClientEmptyListView from '../../components/ClientEmptyListView';

import { useMediaQueries } from '@/hooks/useMediaQueries';

import { colors } from '../../../../../styles';
import { ImageType } from '../../../../../utils/gallery';
import styles from './styles';

interface ClientGalleryListProps {
  readonly HeaderElement?: React.ReactElement;
  readonly clientImages: ClientImageNotePreview[];
  readonly pageId: PageId;
  readonly clientId: ClientId;
  readonly onImagePress: (imageId: ClientImageId) => void;
  readonly onAddNewImage: () => void;
  retryUpload: (image: ImageType, pageId: PageId, clientId: ClientId) => Promise<void>;
  removeImage: (imageId: string) => void;
  setImages: (images: (ClientImageNotePreview | ImageType)[]) => void;
  readonly images: (ClientImage | ImageType)[];
}

const ClientGalleryList: React.FC<ClientGalleryListProps> = ({
  clientImages,
  pageId,
  clientId,
  onImagePress,
  onAddNewImage,
  removeImage,
  retryUpload,
  setImages,
  images,
}) => {
  const { width: windowWidth } = useWindowDimensions();
  const width = Platform.select({ web: Math.min(windowWidth, 400), default: windowWidth });
  const { isPhone } = useMediaQueries();
  const columnGap = 8;
  const numColumns = 3;
  const paddingLeft = 16;
  const paddingRight = Platform.select({ web: 24, default: 16 });
  const horizontalPadding = paddingLeft + paddingRight;
  const imageSize = (width - horizontalPadding - columnGap * (numColumns - 1)) / numColumns;

  useEffect(() => {
    setImages(clientImages);
  }, [clientImages]);

  const renderItem = React.useCallback(
    ({ item, index }: { item: ClientImageNotePreview | ImageType | ClientImage; index: number }) => {
      const marginRight = (index + 1) % numColumns === 0 ? 0 : columnGap;

      return (
        <ClientGalleryImage
          onDeleteLocalImage={removeImage}
          onPress={onImagePress}
          key={item._id}
          image={item as any}
          onRetry={() => retryUpload(item as ImageType, pageId, clientId)}
          imageContainerStyle={{ width: imageSize, height: imageSize, marginRight }}
        />
      );
    },
    [width],
  );

  const listHeaderComponent = useCallback(() => {
    if (!clientImages.length) {
      return <Spacer size={16} />;
    }

    return (
      <Column>
        <Row justifyContent="space-between" alignItems="center">
          <Text style={styles.contentTitle}>Galerie foto</Text>
          <Pressable onPress={onAddNewImage}>
            <Title style={{ color: colors.DARK_BLUE }}>Adaugă foto</Title>
          </Pressable>
        </Row>
        <Spacer size={16} />
      </Column>
    );
  }, [clientImages.length]);

  const keyExtractor = (image: ClientImageNotePreview | ImageType | ClientImage): string => image._id;

  return (
    <>
      <FlatList
        scrollEnabled={!isPhone}
        numColumns={3}
        style={styles.container}
        contentContainerStyle={[styles.contentContainer, { paddingLeft, paddingRight }]}
        ListHeaderComponent={listHeaderComponent}
        data={images}
        keyboardShouldPersistTaps="handled"
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        removeClippedSubviews
        windowSize={11}
        ListEmptyComponent={
          <ClientEmptyListView title="Nu există imagini" subtitle="Imaginile clientului vor fi afisate aici">
            <Spacer size="32" />
            <Button
              text="Adaugă foto"
              onPress={onAddNewImage}
              size="medium"
              color={colors.DARK_BLUE}
              backgroundColor={colors.SKY_BLUE}
              containerStyle={styles.addNoteButton}
            />
          </ClientEmptyListView>
        }
      />
    </>
  );
};

export default ClientGalleryList;
