/**
 * Removes consequential duplicate characters from string
 */
export const removeConsequentialDuplicateCharacters = (string: string, caseSensitive: boolean): string => {
  return string.replace(caseSensitive ? /(.)\1+/g : /(.)\1+/gi, '$1');
};

/**
 * Removes all non alphabetical characters from string,
 * including special characters, digits and spaces
 */
export const removeNonAlphabeticalCharacters = (string: string): string => {
  return string.replace(/[^A-Za-z]/g, '');
};

/**
 * Check if string includes emojis
 */
export const includesEmojis = (string: string): boolean => {
  const emojiRegex = /\p{Extended_Pictographic}/u;

  return emojiRegex.test(string);
};
