import { parsePhoneNumber } from 'awesome-phonenumber';

/**
 * Replace diacritics from a string with their ASCII values.
 */
export function noDiacritics(str: string): string {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

/**
 * Make a url param from a string by replacing
 * whitespaces with dashes, removing diacritics
 * and making all letters lowercase.
 */
export function toUrlParam(str: string): string {
  return noDiacritics(str.toLowerCase())
    .replace(/\s/g, '-')
    .replace(/\//g, '') // remove forward slashes
    .replace(/\-+/g, '-'); // replace multiple dashes with a single dash
}

/**
 * Format phone number
 */
export const formatPhoneNumber = (phone: string, defaultValue?: string): string => {
  const phoneNumber = parsePhoneNumber(phone, { regionCode: 'RO' });
  if (phoneNumber && phoneNumber.valid) {
    return phoneNumber.number.national;
  }

  return defaultValue ?? phone ?? '';
};

/**
 * Capitalize first letter of a string.
 */
export function capitalize(s: string) {
  if (!s) return s;

  return s[0].toUpperCase() + s.slice(1);
}
