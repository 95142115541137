import { Dimensions, Platform, StyleSheet } from 'react-native';

import { colors } from '../../../styles';

const { width, height } = Dimensions.get('window');
const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingTop: (Platform.OS === 'ios' ? 28 : 16) + 16,
    paddingRight: 16,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10001,
    flexDirection: 'row',
    backgroundColor: '#000000',
  },
  imageContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#000000',
  },
  webImage: { width: '100%', height: '100%' },
  image: { borderWidth: 0, marginTop: -129, width, height },
  indexWrapper: { flex: 1, alignItems: 'center', marginLeft: 32 },
  index: {
    color: colors.WHITE,
  },
  previousButton: {
    position: 'absolute',
    left: 24,
    top: '50%',
    marginTop: -16,
  },
  nextButton: {
    position: 'absolute',
    right: 24,
    top: '50%',
    marginTop: -16,
  },
  buttonGallery: {
    ...(Platform.OS === 'web' ? { cursor: 'pointer' } : {}),
    backgroundColor: '#FAFAFA',
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
