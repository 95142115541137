import { colors, sizes } from '@/styles';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  card: {
    padding: sizes[16],
  },
  container: {
    flexDirection: 'row',
  },
  contentContainer: {
    flex: 1,
    paddingLeft: 16,
  },
  detailsContainer: {
    flex: 1,
    paddingRight: 16,
  },
  statusLabel: {
    fontFamily: 'open-sans-semibold',
  },
  finishedStatus: {
    color: colors.SHAMROCK,
  },
  deletedStatus: {
    color: colors.RADICAL_RED,
  },
  dateText: {
    color: colors.COMET,
  },
});
