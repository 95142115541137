import { colors } from '@/styles';
import { ClientImageId } from '@mero/api-sdk';
import { ClientImageNotePreview } from '@mero/api-sdk/dist/pro/clientImages/clientImageNotePreview';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Pressable, StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';

import { ImageType, isClientImage, Status } from '../../../utils/gallery';
import Icon from '../../Icon';
import Column from '../Layout/Column';
import Label from '../Text/Label';
import styles from './styles';

interface ClientGalleryImageProps {
  image: ImageType | ClientImageNotePreview;
  onRetry: (image: ImageType | ClientImageNotePreview) => void;
  onDeleteLocalImage: (imageId: string) => void;
  onPress?: (imageId: ClientImageId) => void;
  onDeleteClientImage?: (imageId: string) => void;
  imageContainerStyle?: StyleProp<ViewStyle>;
}

const ClientGalleryImage = ({
  image,
  onRetry,
  onDeleteLocalImage,
  onPress,
  onDeleteClientImage,
  imageContainerStyle,
}: ClientGalleryImageProps) => {
  const { t } = useTranslation('gallery');
  const uri = isClientImage(image) ? image.medium : image.uri;
  const hasAppointment = (image as ClientImageNotePreview).note?.appointment;
  const hasNote = (image as ClientImageNotePreview).note;

  return (
    <Pressable
      onPress={() => onPress && onPress(image._id as ClientImageId)}
      style={[styles.imageContainer, imageContainerStyle]}
    >
      <Image source={{ uri }} style={styles.image} />
      {onDeleteClientImage && (
        <Pressable
          onPress={() => onDeleteClientImage && onDeleteClientImage(image._id)}
          style={[styles.deleteOverlay, styles.iconContainer]}
        >
          <Icon name="Close" size={17} color={colors.WHITE} />
        </Pressable>
      )}
      {hasAppointment && (
        <View style={styles.appointmentOverlay}>
          <Icon name="CalendarFilled" size={24} color={colors.WHITE} />
        </View>
      )}
      {hasNote && (
        <View style={[styles.noteOverlay, { left: hasAppointment ? 28 : 4 }]}>
          <Icon name="Paper" size={24} color={colors.WHITE} />
        </View>
      )}
      {!isClientImage(image) && image.status !== Status.IDLE && (
        <Column style={styles.overlayContainer}>
          <Column style={styles.overlay}>
            {image.status === Status.LOADING && <Label style={styles.label}>{t('isLoading')}</Label>}
            {image.status === Status.FAILED && (
              <TouchableOpacity style={styles.iconContainer} onPress={() => onRetry(image)}>
                <Icon name="Retry" />
              </TouchableOpacity>
            )}
            {image.status === Status.TO_BIG && (
              <TouchableOpacity style={styles.iconContainer} onPress={() => onDeleteLocalImage(image._id)}>
                <Icon name="Close" />
              </TouchableOpacity>
            )}
          </Column>
          {image.status === Status.FAILED && (
            <TouchableOpacity style={styles.failedLabel} onPress={() => onRetry(image)}>
              <Label style={styles.label}>{t('error')}</Label>
            </TouchableOpacity>
          )}
          {image.status === Status.TO_BIG && (
            <TouchableOpacity style={styles.errorLabel} onPress={() => onDeleteLocalImage(image._id)}>
              <Label style={styles.label}>{t('toBig')}</Label>
            </TouchableOpacity>
          )}
        </Column>
      )}
    </Pressable>
  );
};

export default ClientGalleryImage;
