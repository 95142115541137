import React from 'react';
import { Svg, Path, G, Rect } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

import { colors } from '../../styles';

const Paper = ({ color, size = 24 }: IconProps) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24">
      <Path
        d="M6 9.5C6 8.83696 6.26339 8.20107 6.73223 7.73223C7.20107 7.26339 7.83696 7 8.5 7H15.5C16.163 7 16.7989 7.26339 17.2678 7.73223C17.7366 8.20107 18 8.83696 18 9.5V13H14.5C13.837 13 13.2011 13.2634 12.7322 13.7322C12.2634 14.2011 12 14.837 12 15.5V19H8.5C7.83696 19 7.20107 18.7366 6.73223 18.2678C6.26339 17.7989 6 17.163 6 16.5V9.5ZM13 18.859C13.3533 18.7348 13.6742 18.5328 13.939 18.268L17.267 14.94C17.5323 14.675 17.7347 14.3538 17.859 14H14.5C14.1022 14 13.7206 14.158 13.4393 14.4393C13.158 14.7206 13 15.1022 13 15.5V18.859Z"
        fill={color || colors.WHITE}
      />
    </Svg>
  );
};

export default Paper;
