import { ProductPrice, ScaledNumber } from '@mero/api-sdk';
import {
  colors,
  Spacer,
  Body,
  Row,
  styles,
  FormCard,
  InputWithLabel,
  TypeSafeTextInput,
  Select,
} from '@mero/components';
import * as E from 'fp-ts/lib/Either';
import { flow } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import { useCallback, useEffect } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';

import { SelectItem } from '@mero/components/lib/components/Select';

import { PositiveNumberFromString, numberValidator, roundToDecimals } from '../../../utils/number';
import { localeStringToNumber, scaledToString, stripLocalThousandsSeparators } from '../../../utils/scaled';

const MaxValueNumber = (limit: number) =>
  t.refinement(
    t.number,
    (input): input is t.Branded<number, { readonly MaxValueNumber: unique symbol }> => input <= limit,
    'MaxValueNumber',
  );

const vatRates: SelectItem<number>[] = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 5,
    label: '5%',
  },
  {
    value: 9,
    label: '9%',
  },
  {
    value: 19,
    label: '19%',
  },
];

type ValidatedInput = { input: string; decoded: t.Validation<any> };
type ValidatedInputSetter = (newValue: ValidatedInput) => void;

type Props = {
  finalPrice: ValidatedInput;
  setFinalPrice: ValidatedInputSetter;
  initialPrice: ValidatedInput;
  setInitialPrice: ValidatedInputSetter;
  showErrors: boolean;
  setShowErrors: (newValue: boolean) => void;
  showDiscountPrice: boolean;
  setShowDiscountPrice: (newValue: boolean) => void;
  discountPrice: ValidatedInput;
  setDiscountPrice: ValidatedInputSetter;
  vatRate: number;
  setVatRate: (newValue: number) => void;
  productPrice?: ProductPrice;
};
const ProductPriceScreen: React.FC<Props> = (props) => {
  const { t } = useTranslation('products');
  const {
    finalPrice,
    setFinalPrice,
    initialPrice,
    setInitialPrice,
    showErrors,
    showDiscountPrice,
    setShowDiscountPrice,
    discountPrice,
    setDiscountPrice,
    productPrice,
    vatRate,
    setVatRate,
  } = props;

  const isInitialPriceValid = E.isRight(initialPrice.decoded);
  const isDiscountPriceValid = E.isRight(discountPrice.decoded);
  const DynamicMaxValueNumber = useCallback(() => {
    if (E.isRight(finalPrice.decoded)) {
      return MaxValueNumber(finalPrice.decoded.right);
    }
    return PositiveNumberFromString;
  }, [finalPrice]);

  const isDiscountLessThanFinalPrice =
    E.isRight(discountPrice.decoded) &&
    E.isRight(finalPrice.decoded) &&
    discountPrice.decoded.right < finalPrice.decoded.right;

  const onInitialPriceChange = (payload: { input: string; decoded: t.Validation<any> }) => {
    setInitialPrice(payload);

    if (E.isLeft(payload.decoded)) {
      return payload;
    }

    const newFinalPrice = roundToDecimals(payload.decoded.right * ((100 + vatRate) / 100)).toLocaleString();
    setFinalPrice({
      input: newFinalPrice,
      decoded: PositiveNumberFromString.decode(localeStringToNumber(newFinalPrice).toString()),
    });

    return payload;
  };

  const onFinalPriceChange = (payload: { input: string; decoded: t.Validation<any> }) => {
    setFinalPrice(payload);

    if (E.isLeft(payload.decoded)) {
      return payload;
    }
    const newInitialPrice = roundToDecimals(payload.decoded.right / ((100 + vatRate) / 100)).toLocaleString();

    setInitialPrice({
      input: newInitialPrice,
      decoded: PositiveNumberFromString.decode(localeStringToNumber(newInitialPrice).toString()),
    });
    return payload;
  };
  useEffect(() => {
    if (initialPrice.input === '0' || initialPrice.input === '') {
      setFinalPrice({
        input: '0',
        decoded: PositiveNumberFromString.decode('0'),
      });
    }
  }, [initialPrice]);

  const onVatChange = (newVat: number) => {
    setVatRate(newVat);
    if (initialPrice.input === '') {
      return;
    }
    const newFinalPrice = (parseFloat(initialPrice.input) * ((100 + newVat) / 100)).toFixed(2);

    setFinalPrice({
      input: newFinalPrice,
      decoded: PositiveNumberFromString.decode(newFinalPrice),
    });
  };

  useEffect(() => {
    const init = async () => {
      if (!productPrice) {
        return;
      }

      const parsedVatRate = parseFloat(scaledToString(productPrice.vatRate));
      setVatRate(parsedVatRate);

      const strAmount = scaledToString(productPrice.retailPrice.amount);
      const decodedFinalPrice = PositiveNumberFromString.decode(strAmount);

      if (E.isRight(decodedFinalPrice)) {
        const initialPriceWithoutVat = roundToDecimals(
          decodedFinalPrice.right / ((100 + parsedVatRate) / 100),
        ).toLocaleString();

        setInitialPrice({
          input: initialPriceWithoutVat,
          decoded: PositiveNumberFromString.decode(localeStringToNumber(initialPriceWithoutVat).toString()),
        });

        setFinalPrice({
          input: strAmount,
          decoded: decodedFinalPrice,
        });
      }

      if (!ScaledNumber.equals(productPrice.discountedPrice.amount, productPrice.retailPrice.amount)) {
        const newDiscountPrice = scaledToString(productPrice.discountedPrice.amount);

        setDiscountPrice({
          input: newDiscountPrice,
          decoded: PositiveNumberFromString.decode(newDiscountPrice),
        });

        setShowDiscountPrice(true);
      }
    };

    init();
  }, [productPrice, setVatRate, setInitialPrice, setFinalPrice, setDiscountPrice, setShowDiscountPrice]);

  return (
    <FormCard dropShaddow rounded paddings="inputs">
      <InputWithLabel
        label={t('productPrice')}
        isError={showErrors && !isInitialPriceValid}
        errorText={t('priceError')}
      >
        <TypeSafeTextInput
          value={initialPrice.input}
          codec={PositiveNumberFromString}
          onChange={flow(numberValidator(initialPrice.input), onInitialPriceChange)}
          placeholder={roundToDecimals(0).toLocaleString()}
          keyboardType="numeric"
          editable={true}
          showError={showErrors && !isInitialPriceValid}
          onFocus={() => {
            setInitialPrice({
              input: stripLocalThousandsSeparators(initialPrice.input),
              decoded: PositiveNumberFromString.decode(localeStringToNumber(initialPrice.input).toString()),
            });
          }}
          onBlur={() => {
            setInitialPrice({
              input: initialPrice.input
                ? roundToDecimals(localeStringToNumber(initialPrice.input)).toLocaleString()
                : '',
              decoded: PositiveNumberFromString.decode(
                roundToDecimals(localeStringToNumber(initialPrice.input)).toString(),
              ),
            });
          }}
        />
      </InputWithLabel>

      <Spacer size={24} />
      <InputWithLabel label={t('vat')}>
        <Select
          placeholder={t('vatPlaceholder')}
          items={vatRates}
          value={vatRate}
          onChange={(value) => {
            onVatChange(value);
          }}
        />
      </InputWithLabel>

      <Spacer size={24} />
      <Row style={{ justifyContent: 'space-between' }}>
        <Body style={[styles.text.semibold]}>{t('productPriceVatIncluded')}</Body>
        <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => setShowDiscountPrice(!showDiscountPrice)}>
          <Body style={[styles.text.semibold, { color: colors.DARK_BLUE }]}>{t('discount')}</Body>
          <View
            style={{
              paddingLeft: 8,
              justifyContent: 'center',
            }}
          ></View>
        </TouchableOpacity>
      </Row>
      <Spacer size={8} />
      <TypeSafeTextInput
        value={finalPrice.input}
        codec={PositiveNumberFromString}
        editable={true}
        placeholder={roundToDecimals(0).toLocaleString()}
        onChange={flow(numberValidator(finalPrice.input), onFinalPriceChange)}
        onFocus={() => {
          setFinalPrice({
            input: stripLocalThousandsSeparators(finalPrice.input),
            decoded: PositiveNumberFromString.decode(localeStringToNumber(finalPrice.input).toString()),
          });
        }}
        onBlur={() => {
          setFinalPrice({
            input: finalPrice.input ? roundToDecimals(localeStringToNumber(finalPrice.input)).toLocaleString() : '',
            decoded: PositiveNumberFromString.decode(
              roundToDecimals(localeStringToNumber(finalPrice.input)).toString(),
            ),
          });
        }}
      />

      {showDiscountPrice && (
        <>
          <Spacer size={24} />
          <InputWithLabel
            label={t('discountPrice')}
            isError={showDiscountPrice && showErrors && (!isDiscountPriceValid || !isDiscountLessThanFinalPrice)}
            errorText={t(!isDiscountPriceValid ? 'priceError' : 'discountGreaterThanFinalPriceError')}
          >
            <TypeSafeTextInput
              value={discountPrice.input}
              codec={DynamicMaxValueNumber()}
              onChange={flow(numberValidator(discountPrice.input), setDiscountPrice)}
              placeholder={roundToDecimals(0).toLocaleString()}
              keyboardType="numeric"
              showError={showDiscountPrice && showErrors && (!isDiscountPriceValid || !isDiscountLessThanFinalPrice)}
              onFocus={() => {
                setDiscountPrice({
                  input: stripLocalThousandsSeparators(discountPrice.input),
                  decoded: PositiveNumberFromString.decode(
                    roundToDecimals(localeStringToNumber(discountPrice.input)).toString(),
                  ),
                });
              }}
              onBlur={() => {
                setDiscountPrice({
                  input: discountPrice.input
                    ? roundToDecimals(localeStringToNumber(discountPrice.input)).toLocaleString()
                    : '',
                  decoded: PositiveNumberFromString.decode(
                    roundToDecimals(localeStringToNumber(discountPrice.input)).toString(),
                  ),
                });
              }}
            />
          </InputWithLabel>
        </>
      )}
    </FormCard>
  );
};

export default ProductPriceScreen;
