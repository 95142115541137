import { OnlineSaleSettings } from './onlineSaleSettings';
import { ProOnlineSalesApi } from './proOnlineSalesApi';
import { HttpClient, unsafeRight, UnknownApiError } from '@mero/shared-sdk';
import * as t from 'io-ts';

/**
 * ProOnlineSalesApi HTTP client implementation
 */
export const proOnlineSalesHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): ProOnlineSalesApi => {
  const { apiBaseUrl, http } = env;

  const getMembershipOnlineSaleSettingsDecoder = http.decode.response(UnknownApiError, OnlineSaleSettings.JSON);
  const updateMembershipMarketplaceSaleSettingDecoder = http.decode.response(UnknownApiError, t.unknown);
  const updateMembershipExtraPromotionSettingDecoder = http.decode.response(UnknownApiError, t.unknown);

  return {
    getMembershipOnlineSaleSettings: async ({ pageId, membershipTemplateId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/membership-templates/${encodeURIComponent(
              membershipTemplateId,
            )}/online-sale-settings`,
          },
          getMembershipOnlineSaleSettingsDecoder,
        ),
      );
    },
    updateMembershipMarketplaceSaleSetting: async ({ pageId, membershipTemplateId, marketplaceSale }) => {
      const requestBody = {
        marketplaceSale: marketplaceSale,
      };
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/membership-templates/${encodeURIComponent(
              membershipTemplateId,
            )}/marketplace-sales`,
            data: requestBody,
          },
          updateMembershipMarketplaceSaleSettingDecoder,
        ),
      );
    },
    updateMembershipExtraPromotionSetting: async ({ pageId, membershipTemplateId, extraPromotion }) => {
      const requestBody = {
        extraPromotion: extraPromotion,
      };
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/membership-templates/${encodeURIComponent(
              membershipTemplateId,
            )}/extra-promotion`,
            data: requestBody,
          },
          updateMembershipExtraPromotionSettingDecoder,
        ),
      );
    },
  };
};
