import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

const WhatsAppIcon = () => (
  <Svg width={24} height={24} viewBox="0 0 24 24">
    <G>
      <G transform="translate(-176 -265) translate(-24007 596)">
        <Path
          d="M.057 17.7L1.3 13.154a8.77 8.77 0 113.406 3.325L.057 17.7zm4.865-2.807A7.272 7.272 0 102.9 12.925l-.737 2.69 2.76-.723zm8.4-4.029c-.055-.091-.2-.146-.42-.256s-1.3-.64-1.5-.713-.347-.11-.493.11-.566.713-.694.859-.256.164-.475.055a5.981 5.981 0 01-1.763-1.09A6.6 6.6 0 016.758 8.31c-.128-.219-.013-.338.1-.447s.219-.256.329-.384a1.437 1.437 0 00.213-.365.4.4 0 00-.018-.384c-.052-.109-.49-1.188-.672-1.63s-.359-.369-.493-.376L5.8 4.72a.8.8 0 00-.584.274 2.459 2.459 0 00-.767 1.828 4.265 4.265 0 00.895 2.267A9.771 9.771 0 009.083 12.4a12.68 12.68 0 001.249.462 3.012 3.012 0 001.381.087 2.257 2.257 0 001.479-1.049 1.826 1.826 0 00.128-1.043z"
          transform="translate(24186.135 -327.699)"
          fill="#2dce89"
        />
      </G>
    </G>
  </Svg>
);

export default WhatsAppIcon;
