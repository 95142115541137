import { colors } from '@mero/components';
import React from 'react';
import { Svg, Path, G } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

const Close = ({ color, size = 24 }: IconProps) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 22 22" fill="none">
      <Path
        d="M18 7.41L16.59 6L12 10.59L7.41 6L6 7.41L10.59 12L6 16.59L7.41 18L12 13.41L16.59 18L18 16.59L13.41 12L18 7.41Z"
        fill={color || colors.DARK_BLUE}
      />
    </Svg>
  );
};

export default Close;
