import { JSONable, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Delimiter = {
  readonly type: 'Delimiter';
  readonly title: string;
  readonly subtitle: Option<string>;
};

const JSON: t.Type<Delimiter, JSONable> = t.type(
  {
    type: t.literal('Delimiter'),
    title: t.string,
    subtitle: Option.json(t.string),
  },
  'Delimiter',
);

export const Delimiter = {
  JSON,
};
