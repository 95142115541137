import React from 'react';
import { Svg, Path, G } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

const BoostIcon = ({ color, size = 24 }: IconProps) => {
  if (size === 20) {
    return (
      <Svg data-name="Group 6844" width={20} height={20} viewBox="0 0 20 20">
        <Path data-name="Rectangle 55" fill="none" d="M0 0H20V20H0z" />
        <Path
          d="M4.164 8.673A3.539 3.539 0 01.974 10.7 3.566 3.566 0 013.02 7.506l.248.253a3.2 3.2 0 00-1.043 1.682 3.2 3.2 0 001.694-1.019zM3.612 3.16A4.206 4.206 0 00.809 4.43 4.472 4.472 0 000 5.528a2.114 2.114 0 012.5-.2A10.936 10.936 0 013.612 3.16zm2.53 8.507a4.443 4.443 0 001.1-.809 4.185 4.185 0 001.264-2.811 11.892 11.892 0 01-2.171 1.116 2.115 2.115 0 01-.193 2.504zM11.64.021Q11.328 0 11.027 0C6.488 0 4 3.61 3.2 6.324l2.153 2.152c2.81-.9 6.314-3.294 6.314-7.774V.673c0-.212-.009-.43-.026-.652zM8.75 3.889a.972.972 0 11.972-.972.972.972 0 01-.972.972z"
          transform="translate(4.167 4.775)"
          fill={color || '#7431f7'}
        />
      </Svg>
    );
  }

  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <G data-name="Group 6844" transform="translate(-206 -32)">
        <Path data-name="Rectangle 55" transform="translate(206 32)" fill="none" d="M0 0H24V24H0z" />
        <Path
          d="M5 10.408a4.246 4.246 0 01-3.828 2.427 4.279 4.279 0 012.452-3.828l.3.3a3.845 3.845 0 00-1.251 2.019A3.837 3.837 0 004.7 10.107zm-.666-6.616A5.047 5.047 0 00.971 5.316 5.367 5.367 0 000 6.634 2.536 2.536 0 013 6.4a13.123 13.123 0 011.334-2.608zM7.37 14a5.332 5.332 0 001.319-.971 5.023 5.023 0 001.519-3.373A14.27 14.27 0 017.6 11a2.538 2.538 0 01-.23 3zM13.969.026Q13.594 0 13.233 0C7.786 0 4.795 4.332 3.841 7.589l2.583 2.583C9.8 9.09 14 6.218 14 .842V.808c0-.255-.011-.516-.031-.782zM10.5 4.667A1.167 1.167 0 1111.667 3.5 1.166 1.166 0 0110.5 4.667z"
          transform="translate(211 37.729)"
          fill={color || '#7431f7'}
        />
      </G>
    </Svg>
  );
};

export default BoostIcon;
