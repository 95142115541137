import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Portal } from '@gorhom/portal';
import { ProductCategoryId, ProductId, ProductStatus } from '@mero/api-sdk';
import {
  Spacer,
  Row,
  Title,
  Column,
  H2s,
  Body,
  Button,
  colors,
  FormCard,
  Line,
  Icon,
  H1,
  H3s,
  ModalOverlay,
  SafeAreaView,
  styles as meroStyles,
  SmallBody,
} from '@mero/components';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, Pressable, ScrollView, View, TouchableOpacity } from 'react-native';

import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';

import { useClickOutsideWeb } from '../../../hooks/useClickOutsideWeb';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { DefaultCategory, ProductsContext, SelectedCategory } from '../../../contexts/ProductsContext';
import { SearchProductsContext } from '../../../contexts/ProductsSearchContext';
import ProductsTable from './ProductsTable';
import { styles } from './styles';

const getCategoryTitle = (t: TFunction, selectedCategory?: SelectedCategory, search?: string): ReactJSXElement => {
  if (!selectedCategory) {
    return <></>;
  }
  let title = '';
  switch (selectedCategory.name) {
    case 'active':
    case 'inactive': {
      title = t(selectedCategory.name);
      break;
    }
    case 'other': {
      title = t('otherCategory');
      break;
    }
    default: {
      title = selectedCategory.name;
      break;
    }
  }

  if (search) {
    return (
      <Row>
        <H1 style={{ fontFamily: 'open-sans-semibold', fontSize: 20 }}>Rezultate pentru</H1>
        <H1 style={{ fontFamily: 'open-sans-bold', fontSize: 20, fontStyle: 'italic' }}>{` “${search}“ `}</H1>
        <H1 style={{ fontFamily: 'open-sans-semibold', fontSize: 20 }}> în </H1>
        <H1 style={{ fontFamily: 'open-sans-bold', fontSize: 20 }}>{title}</H1>
      </Row>
    );
  }
  return <H1 style={{ fontFamily: 'open-sans-bold', fontSize: 20 }}>{title}</H1>;
};

type Props = {
  navigateSearchProduct: () => void;
  navigateProduct: (productId?: ProductId) => void;
  navigateDeleteCategory: (categoryId: ProductCategoryId) => void;
  navigateCategory: (categoryId?: ProductCategoryId) => void;
  navigateImportProducts: () => void;
  navigateCategoryProducts: (category: ProductCategoryId | 'noCategory' | 'inactive') => void;
};

const CategoriesContent: React.FC<Props> = ({
  navigateSearchProduct,
  navigateProduct,
  navigateDeleteCategory,
  navigateCategory,
  navigateImportProducts,
  navigateCategoryProducts,
}) => {
  const { t } = useTranslation('products');
  const { isPhone, isDesktop } = useMediaQueries();
  const optionsMenuRef = React.useRef<View>(null);

  const [state, { update }] = ProductsContext.useContext();
  const [searchState, { search }] = SearchProductsContext.useContext();
  const [showCategoryOptions, setShowCategoryOptions] = React.useState(false);
  const [showAddOptions, setShowAddOptions] = React.useState(false);

  React.useEffect(() => {
    update({ selectedCategory: DefaultCategory.active });
  }, []);

  React.useEffect(() => {
    if (!state.selectedCategory || searchState.type !== 'Loaded' || isPhone) {
      return;
    }
    switch (state.selectedCategory.name) {
      case 'active': {
        return search({
          query: {
            status: ProductStatus.Active.code,
            ...(searchState.query.search ? { search: searchState.query.search } : {}),
          },
        });
      }
      case 'inactive': {
        return search({
          query: {
            status: ProductStatus.Inactive.code,
            ...(searchState.query.search ? { search: searchState.query.search } : {}),
          },
        });
      }
      case 'other': {
        return search({
          query: {
            categoryId: 'none',
            status: ProductStatus.Active.code,
            ...(searchState.query.search ? { search: searchState.query.search } : {}),
          },
        });
      }
    }

    if (state.selectedCategory.type === 'custom') {
      return search({
        query: {
          categoryId: state.selectedCategory._id,
          status: ProductStatus.Active.code,
          ...(searchState.query.search ? { search: searchState.query.search } : {}),
        },
      });
    }
  }, [state.selectedCategory]);

  if (state.type !== 'Loaded') {
    return;
  }

  useClickOutsideWeb({
    ref: optionsMenuRef,
    isVisible: showCategoryOptions,
    onClickOutside() {
      setShowCategoryOptions(false);
    },
    condition: isDesktop && Platform.OS === 'web',
  });

  const optionsDropdown = (): ReactJSXElement => {
    return (
      <View style={{ position: 'relative' }} ref={optionsMenuRef}>
        <Column style={[styles.optionsMenu]}>
          <TouchableOpacity
            style={styles.optionsMenuItem}
            delayPressIn={0}
            onPress={() => {
              state.selectedCategory?.type === 'custom' && navigateCategory(state.selectedCategory._id);
            }}
          >
            <Body style={meroStyles.text.semibold}>{t('updateCategory')}</Body>
          </TouchableOpacity>
          <Line />

          <TouchableOpacity
            style={styles.optionsMenuItem}
            delayPressIn={0}
            onPress={() => {
              state.selectedCategory?.type === 'custom' && navigateDeleteCategory(state.selectedCategory._id);
            }}
          >
            <Body style={[meroStyles.text.semibold, { color: colors.RADICAL_RED }]}>{t('deleteCategory')}</Body>
          </TouchableOpacity>
        </Column>
      </View>
    );
  };

  return (
    <>
      <>
        <Spacer size={16} />
        {isDesktop && (searchState.counters.all > 0 || state.categories.length > 0) && (
          <FormCard dropShaddow paddings="none" style={{ flex: 1 }} rounded>
            <Row style={{ flex: 1 }}>
              <ScrollView style={{ maxWidth: 313, marginBottom: 24 }}>
                <FormCard
                  paddings="none"
                  rounded
                  dropShaddow
                  style={{
                    marginTop: 24,
                    marginHorizontal: 24,
                    shadowColor: 'rgba(136,152,170,0.15 )',
                    shadowOffset: {
                      width: 0,
                      height: 0,
                    },
                    shadowRadius: 16,
                    elevation: 16,
                  }}
                >
                  <TouchableOpacity onPress={() => update({ selectedCategory: DefaultCategory.active })}>
                    <Row
                      style={[
                        state.selectedCategory === DefaultCategory.active && {
                          backgroundColor: colors.ATHENS_GRAY,
                        },
                        { padding: 16, justifyContent: 'space-between' },
                      ]}
                    >
                      <SmallBody style={{ marginVertical: 2.5, width: '85%' }}>
                        {t('allProducts')} ({searchState.counters.active})
                      </SmallBody>
                      {state.selectedCategory === DefaultCategory.active && <Icon type="arrow-right" size={24}></Icon>}
                    </Row>
                  </TouchableOpacity>
                  <Line />

                  {state.categories.map((category, index) => (
                    <View key={category._id}>
                      <TouchableOpacity
                        onPress={() => {
                          update({
                            selectedCategory: {
                              type: 'custom',
                              _id: category._id as ProductCategoryId,
                              name: category.name,
                            },
                          });
                        }}
                      >
                        <Row
                          style={[
                            index === 0 && { borderTopRightRadius: 5, borderTopLeftRadius: 5 },
                            state.selectedCategory?.name === category.name && {
                              backgroundColor: colors.ATHENS_GRAY,
                            },
                            { padding: 16, justifyContent: 'space-between' },
                          ]}
                        >
                          <SmallBody style={{ marginVertical: 2.5, width: '85%' }}>
                            - {category.name} ({searchState.counters.categories[category._id] ?? 0})
                          </SmallBody>
                          {state.selectedCategory?.name === category.name && <Icon type="arrow-right" size={24}></Icon>}
                        </Row>
                      </TouchableOpacity>
                      <Line />
                    </View>
                  ))}

                  <TouchableOpacity onPress={() => update({ selectedCategory: DefaultCategory.other })}>
                    <Row
                      style={[
                        state.selectedCategory == DefaultCategory.other && {
                          backgroundColor: colors.ATHENS_GRAY,
                        },
                        { padding: 16, justifyContent: 'space-between' },
                      ]}
                    >
                      <SmallBody style={{ marginVertical: 2.5, width: '85%' }}>
                        - Fără categorie ({searchState.counters.noCategory})
                      </SmallBody>
                      {state.selectedCategory == DefaultCategory.other && <Icon type="arrow-right" size={24}></Icon>}
                    </Row>
                  </TouchableOpacity>

                  <Line />
                  <TouchableOpacity onPress={() => update({ selectedCategory: DefaultCategory.inactive })}>
                    <Row
                      style={[
                        state.selectedCategory === DefaultCategory.inactive && {
                          backgroundColor: colors.ATHENS_GRAY,
                        },
                        { padding: 16, justifyContent: 'space-between' },
                      ]}
                    >
                      <SmallBody>Arhivate ({searchState.counters.inactive})</SmallBody>
                      {state.selectedCategory === DefaultCategory.inactive && (
                        <Icon type="arrow-right" size={22}></Icon>
                      )}
                    </Row>
                  </TouchableOpacity>
                </FormCard>
              </ScrollView>

              <Column style={{ flex: 1, paddingVertical: 24, paddingRight: 24 }}>
                <Row style={{ justifyContent: 'space-between' }}>
                  {getCategoryTitle(t, state.selectedCategory, searchState.query.search)}
                  {state.selectedCategory?.type === 'custom' && !searchState.query.search && (
                    <TouchableOpacity onPress={() => setShowCategoryOptions((prev) => !prev)}>
                      <Icon type="options-dots" />
                    </TouchableOpacity>
                  )}
                </Row>
                <Column style={{ position: 'relative', zIndex: 1 }}>
                  {showCategoryOptions && state.selectedCategory?.type === 'custom' && optionsDropdown()}
                </Column>
                <ProductsTable navigateProduct={navigateProduct} />
              </Column>
            </Row>
          </FormCard>
        )}

        {isPhone && (searchState.counters.all > 0 || state.categories.length > 0) && (
          <>
            <Column style={{ marginHorizontal: 16 }}>
              <TouchableOpacity
                onPress={() => {
                  search({ query: { status: ProductStatus.Active.code } });
                  navigateSearchProduct();
                }}
              >
                <Row
                  style={{
                    borderWidth: 1,
                    borderRadius: 20,
                    borderColor: '#DEE2E6',
                    backgroundColor: colors.WHITE,
                    paddingHorizontal: 16,
                    paddingVertical: 8,
                  }}
                >
                  <Icon type="search" size={24} color={colors.COMET} />
                  <Body style={{ color: '#52577F', paddingHorizontal: 12 }}>{t('searchProductsShort')}</Body>
                </Row>
              </TouchableOpacity>

              <Spacer size={16} />
              <Row style={{ alignItems: 'center' }}>
                <Title style={{ color: '#32325D', fontSize: 17, flex: 1 }}>
                  {searchState.counters.all} {searchState.counters.all > 1 ? t('products') : t('product')}
                </Title>
                <TouchableOpacity style={styles.openOptionsMenuButton} onPress={() => setShowAddOptions(true)}>
                  <Title style={{ color: colors.DARK_BLUE, paddingLeft: 24, paddingRight: 16 }}>{t('add')}</Title>
                  <Column style={{ paddingRight: 12 }}>
                    <Icon type="dropdown" color={colors.DARK_BLUE} />
                  </Column>
                </TouchableOpacity>
              </Row>
              <Spacer size={24} />
            </Column>
            <ScrollView style={{ paddingHorizontal: 16 }}>
              <FormCard dropShaddow paddings="none" rounded>
                {state.categories.map((category) => (
                  <TouchableOpacity
                    key={category._id}
                    onPress={() => {
                      update({
                        selectedCategory: {
                          type: 'custom',
                          _id: category._id as ProductCategoryId,
                          name: category.name,
                        },
                      });
                      navigateCategoryProducts(category._id);
                    }}
                  >
                    <Column
                      style={{
                        paddingHorizontal: 16,
                        paddingTop: 24,
                      }}
                    >
                      <Row style={{ flex: 1, justifyContent: 'space-between' }}>
                        <H3s style={[meroStyles.text.semibold]}>
                          {category.name} ({searchState.counters.categories[category._id] ?? 0})
                        </H3s>
                        <Icon type="arrow-right" />
                      </Row>
                      <Spacer size={24} />
                      <Line />
                    </Column>
                  </TouchableOpacity>
                ))}

                <TouchableOpacity onPress={() => navigateCategoryProducts('noCategory')}>
                  <Column style={{ paddingHorizontal: 16, paddingTop: 24 }}>
                    <Row style={{ flex: 1, justifyContent: 'space-between' }}>
                      <H3s style={[meroStyles.text.semibold]}>
                        {t('otherCategory')} ({searchState.counters.noCategory ?? 0})
                      </H3s>
                      <Icon type="arrow-right" />
                    </Row>
                    <Spacer size={24} />
                    <Line />
                  </Column>
                </TouchableOpacity>

                <TouchableOpacity onPress={() => navigateCategoryProducts('inactive')}>
                  <Column style={{ paddingHorizontal: 16, paddingTop: 24 }}>
                    <Row style={{ flex: 1, justifyContent: 'space-between' }}>
                      <H3s style={[meroStyles.text.semibold]}>
                        {t('inactive')} ({searchState.counters.inactive ?? 0})
                      </H3s>
                      <Icon type="arrow-right" />
                    </Row>
                    <Spacer size={24} />
                  </Column>
                </TouchableOpacity>
              </FormCard>
              <Spacer size={163} />
            </ScrollView>
          </>
        )}
      </>

      {showAddOptions && isPhone && (
        <Portal>
          <ModalOverlay>
            <MobileWebModalWrapper position="bottom">
              <Pressable style={styles.optionsStretchContainer} onPress={() => setShowAddOptions(false)} />
              <Column style={[styles.optionsListContainer]}>
                <SafeAreaView edges={['bottom']}>
                  <Column>
                    <TouchableOpacity
                      style={{ paddingHorizontal: 24, paddingVertical: 16 }}
                      delayPressIn={0}
                      onPress={() => {
                        setShowAddOptions(false);
                        navigateImportProducts();
                      }}
                    >
                      <Title>{t('importProducts')}</Title>
                    </TouchableOpacity>
                    <Line />

                    <TouchableOpacity
                      style={{ paddingHorizontal: 24, paddingVertical: 16 }}
                      delayPressIn={0}
                      onPress={() => {
                        setShowAddOptions(false);
                        navigateProduct();
                      }}
                    >
                      <Title>{t('addProduct')}</Title>
                    </TouchableOpacity>
                    <Line />

                    <TouchableOpacity
                      style={{ paddingHorizontal: 24, paddingVertical: 16 }}
                      delayPressIn={0}
                      onPress={() => {
                        setShowAddOptions(false);
                        navigateCategory();
                      }}
                    >
                      <Title>{t('addCategory')}</Title>
                    </TouchableOpacity>
                  </Column>
                  <Line />
                </SafeAreaView>
              </Column>
            </MobileWebModalWrapper>
          </ModalOverlay>
        </Portal>
      )}

      {searchState.counters.all === 0 &&
        state.categories.length === 0 &&
        !searchState.query.search &&
        searchState.type === 'Loaded' && (
          <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <H2s>Niciun produs</H2s>
            <Body style={{ textAlign: 'center', paddingHorizontal: 40, paddingTop: 8 }}>
              Produsele adăugate vor apărea aici.
            </Body>
            <Column style={{ paddingTop: 24 }}>
              <Button
                backgroundColor={colors.DARK_BLUE}
                color={colors.ALABASTER}
                size="medium"
                withShadow
                expand={false}
                text={t('importProducts')}
                padding={36}
                onClick={() => navigateImportProducts()}
              />
              <Spacer size={16} />
              <Button
                backgroundColor={colors.SKY_BLUE}
                color={colors.DARK_BLUE}
                size="medium"
                withShadow
                expand={false}
                text={t('addProduct')}
                padding={36}
                onClick={() => navigateProduct()}
              />
            </Column>
          </Column>
        )}

      {searchState.counters.all === 0 &&
        state.categories.length === 0 &&
        searchState.type === 'Loaded' &&
        !!searchState.query.search && (
          <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <H2s>Niciun rezultat</H2s>
            <Body style={{ textAlign: 'center', paddingHorizontal: 40, paddingTop: 8 }}>
              Schimbă termenul de căutare
            </Body>
          </Column>
        )}
    </>
  );
};

export default CategoriesContent;
