import React from 'react';
import { Svg, Path, G } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

const ArrowFull = ({ size = 24 }: IconProps) => {
  return (
    <Svg width={10} height={5} viewBox="88 53 10 5">
      <G data-name="Group 5821">
        <G data-name="Icons/Dropdown">
          <Path d="M88 53h10l-5 5-5-5z" fill="#080de0" fillRule="evenodd" data-name="Rectangle" />
        </G>
      </G>
    </Svg>
  );
};

export default ArrowFull;
