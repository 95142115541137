import { Button, colors, Column, Label, Row, SmallBody, Spacer } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable } from 'react-native';

import { FormCard } from '../../../../../../components/shared';

import styles from './styles';

interface ClientBoostDetailsProps {
  isClaimPending: boolean;
  canClaim: boolean;
  onClaimDialog: () => void;
  onViewCommission: () => void;
  canAcceptCommission: boolean;
}

const ClientBoostDetails: React.FC<ClientBoostDetailsProps> = ({
  isClaimPending,
  onClaimDialog,
  canClaim,
  canAcceptCommission,
  onViewCommission,
}) => {
  const { t } = useTranslation('clients');

  return (
    <FormCard style={styles.card} paddings="none" borderRadius={6} dropShaddow>
      <SmallBody style={styles.boostDescription}>{t('boostDescription')}</SmallBody>
      <Spacer size={16} />
      {isClaimPending && (
        <>
          <Label style={styles.claimClientDescription}>{t('claimBoostClientProcessingPending')}</Label>
          <Spacer size={12} />
        </>
      )}
      <Row justifyContent="space-between" alignItems="center">
        {canAcceptCommission ? (
          <Pressable style={styles.commissionButton} onPress={onViewCommission}>
            <SmallBody style={styles.commissionText}>Vezi comision</SmallBody>
          </Pressable>
        ) : null}
        {canClaim && (
          <Pressable style={styles.claimButton} onPress={onClaimDialog}>
            <SmallBody style={styles.commissionText}>Revendică</SmallBody>
          </Pressable>
        )}
      </Row>
    </FormCard>
  );
};

export default ClientBoostDetails;
