import { WeekDaysSchedule } from '../weekDaysSchedule';
import { DateString, JSONable, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Weekly = {
  readonly type: 'Weekly';
  /**
   * Start date for working schedule recurrence
   */
  readonly start: DateString;
  /**
   * End date for working schedule recurrence
   */
  readonly end: Option<DateString>;
  /**
   * Schedule for each week of the schedule
   */
  readonly weekSchedules: WeekDaysSchedule[];
};

const JSON: t.Type<Weekly, JSONable> = t.type(
  {
    type: t.literal('Weekly'),
    start: DateString.JSON,
    end: Option.json(DateString.JSON),
    weekSchedules: t.array(WeekDaysSchedule.JSON),
  },
  'EditWeeklyWorkingSchedule',
);

const equals = (a: Weekly, b: Weekly): boolean => {
  return (
    a.start === b.start &&
    a.end === b.end &&
    a.weekSchedules.length === b.weekSchedules.length &&
    a.weekSchedules.every((weekSchedule, index) => WeekDaysSchedule.equals(weekSchedule, b.weekSchedules[index]))
  );
};

export const Weekly = {
  JSON,
  equals,
};
