import * as React from 'react';

import * as icons from '../../../assets/icons';
import Icon from '../../Icon';
import Row from '../Layout/Row';
import Label from '../Text/Label';
import styles from './styles';

interface StatusLabelProps {
  icon?: keyof typeof icons;
  text: string;
}
const BookingStatusLabel = ({ icon, text }: StatusLabelProps) => {
  return (
    <Row gap={2} style={styles.container}>
      <Icon name={icon} />
      <Label style={styles.text}>{text}</Label>
    </Row>
  );
};

export default BookingStatusLabel;
