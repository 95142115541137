import { NoteDetails, NoteId } from '@mero/api-sdk';
import { colors, Title } from '@mero/components';
import * as React from 'react';
import { Pressable, TouchableOpacity, View } from 'react-native';

import Line from '@mero/components/lib/components/Line';
import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import SimpleListItem from '@mero/components/lib/components/SimpleListItem';

import { styles } from './styles';

export type Props = {
  readonly note: NoteDetails;
  readonly onDismiss: () => void;
  readonly onEdit: (note: NoteDetails) => void;
  readonly isPinned: boolean;
  readonly onUpdatePinnedState: (pinnedState: boolean, noteId: NoteId) => void;
  readonly onDeleteNoteAndImages: (note: NoteDetails) => void;
  readonly onDeleteNoteAndKeepImages: (note: NoteDetails) => void;
};

/**
 * Options modal screen component for a client note options, where pros can:
 *  - Edit note
 *  - Delete note
 */
const ClientNoteOptionsModal: React.FC<Props> = ({
  note,
  onDismiss,
  onEdit,
  isPinned,
  onUpdatePinnedState,
  onDeleteNoteAndImages,
  onDeleteNoteAndKeepImages,
}: Props): React.ReactElement | null => {
  const onEditCallback = React.useCallback(() => {
    onEdit(note);
  }, [note, onEdit]);

  const handleUpdatePinnedState = React.useCallback(() => {
    onUpdatePinnedState(!isPinned, note._id);
  }, [isPinned, onUpdatePinnedState]);

  return (
    <ModalOverlay>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <View style={styles.optionsListContainer}>
        <SafeAreaView edges={['bottom']}>
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onEditCallback}>
            <SimpleListItem>
              <Title>Modifică notiță</Title>
            </SimpleListItem>
          </TouchableOpacity>
          <Line />
          {!isPinned && (
            <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={handleUpdatePinnedState}>
              <SimpleListItem>
                <Title>Fixează notița</Title>
              </SimpleListItem>
            </TouchableOpacity>
          )}
          {isPinned && (
            <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={handleUpdatePinnedState}>
              <SimpleListItem>
                <Title style={{ color: colors.RADICAL_RED }}>Anulează fixare notiță</Title>
              </SimpleListItem>
            </TouchableOpacity>
          )}
          <Line />
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={() => onDeleteNoteAndImages(note)}>
            <SimpleListItem>
              <Title style={{ color: colors.RADICAL_RED }}>Șterge notiță si imagini</Title>
            </SimpleListItem>
          </TouchableOpacity>
          <Line />
          <TouchableOpacity
            style={styles.optionsMenuItem}
            delayPressIn={0}
            onPress={() => onDeleteNoteAndKeepImages(note)}
          >
            <SimpleListItem>
              <Title style={{ color: colors.RADICAL_RED }}>Șterge notiță si pastrează imagini</Title>
            </SimpleListItem>
          </TouchableOpacity>
        </SafeAreaView>
      </View>
    </ModalOverlay>
  );
};

export default ClientNoteOptionsModal;
