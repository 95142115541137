import { ProductSalesSearchApi } from './productSalesApi';
import { ProductSaleArrayC } from './productSalesSearchJson';
import { HttpClient, Paged, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as tt from 'io-ts-types';

export const productSalesHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): ProductSalesSearchApi => {
  const { apiBaseUrl, http } = env;

  const getProductSalesDecoder = http.decode.response(UnknownApiError, Paged.json(ProductSaleArrayC));

  return {
    getProductSales: async (params) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(params.pageId)}/product-sales`,
            params: {
              ...(params.sold
                ? {
                    soldFrom: tt.DateFromISOString.encode(params.sold.from),
                    soldTo: tt.DateFromISOString.encode(params.sold.to),
                  }
                : {}),
              ...(params.search ? { search: params.search } : {}),
              ...(params.page ? { page: params.page } : {}),
              ...(params.clientId ? { clientId: params.clientId } : {}),
              limit: params.limit,
            },
          },
          getProductSalesDecoder,
        ),
      );
    },
  };
};
