import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import ClientDetailsScreen from '../screens/Authorized/ClientDetailsScreen';

import { createStackNavigator } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { ClientStackParamList } from '../types';
import { styles } from './styles';

const Stack = createStackNavigator<ClientStackParamList>();

function ClientDetailsStackNavigator(): React.ReactElement {
  const { t } = useTranslation('clients');

  const { isPhone, isDesktop } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCardPaddingLess
    : styles.tabletModalCardPaddingLess;

  return (
    <Stack.Navigator
      initialRouteName="DetailsScreen"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="DetailsScreen"
        component={ClientDetailsScreen}
        options={{
          title: '',
          cardStyle: isDesktop ? styles.desktopModalCardScreenLarge : modalStyle,
        }}
      />

      {/*TODO: check why this is no opening over the existing over the client profile - moved in the BookingStack for now */}
      {/*<Stack.Screen*/}
      {/*  name="MembershipDetailsScreen"*/}
      {/*  component={MembershipDetailsScreen}*/}
      {/*  options={{ title: t('membershipDetails'), cardStyle: modalStyle }}*/}
      {/*/>*/}
    </Stack.Navigator>
  );
}

export default pipe(ClientDetailsStackNavigator);
