import { AppointmentId } from '../calendar';
import { PageId } from '../pages';
import { ServiceId } from '../services';
import { CustomFieldResponse } from './customFieldResponse';
import { JSONable, optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type AppointmentCustomFields = {
  readonly pageId: PageId;
  readonly appointmentId: AppointmentId;
  readonly occurrenceIndex: number | undefined;
  readonly services: {
    readonly _id: ServiceId;
    readonly fields: CustomFieldResponse.Any[];
  }[];
};

const JSON: t.Type<AppointmentCustomFields, JSONable> = t.strict(
  {
    pageId: PageId,
    appointmentId: AppointmentId,
    occurrenceIndex: optionull(t.number),
    services: t.array(
      t.type(
        {
          _id: ServiceId,
          fields: t.array(CustomFieldResponse.Any.JSON),
        },
        'ServiceWithCustomFields',
      ),
      'ServicesWithCustomFields',
    ),
  },
  'AppointmentCustomFields',
);

export const AppointmentCustomFields = {
  JSON,
};
