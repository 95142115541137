import { AppointmentId } from '../../calendar/appointment-id';
import { WorkerId } from '../../workers/workerId';
import { JSONable, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

export type NoteAppointment = {
  readonly _id: AppointmentId;
  readonly occurrenceIndex: Option<number>;
  readonly start: Date;
  readonly worker: {
    readonly _id: WorkerId;
    readonly firstname: string;
    readonly lastname: string;
  };
};

const JSON: t.Type<NoteAppointment, JSONable> = t.type(
  {
    _id: AppointmentId,
    occurrenceIndex: Option.json(t.number),
    start: tt.DateFromISOString,
    worker: t.type({
      _id: WorkerId.JSON,
      firstname: t.string,
      lastname: t.string,
    }),
  },
  'NoteAppointment',
);

export const NoteAppointment = {
  JSON,
};
