import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, View } from 'react-native';

import FloatMenu, { Ref as FloatMenuRefu } from '../../../../../../components/FloatMenu';
import Icon from '../../../../../../components/Icon';
import { Row, Title, Line, SimpleListItem } from '../../../../../../components/shared';

import { colors } from '../../../../../../styles';
import styles from './styles';

interface ClientOptionsMenuProps {
  readonly isWarned: boolean;
  readonly isBlocked: boolean;
  readonly onEdit?: () => void;
  readonly onWarnClient: (isWarned: boolean) => void;
  readonly onBlockClient: (isBlocked: boolean) => void;
  readonly onDelete?: () => void;
  readonly floatMenuStartRef: React.RefObject<FloatMenuRefu>;
}

const ClientOptionsMenu: React.FC<ClientOptionsMenuProps> = ({
  isWarned,
  isBlocked,
  onEdit,
  onWarnClient,
  onBlockClient,
  onDelete,
  floatMenuStartRef,
}) => {
  const { t } = useTranslation('clients');

  return (
    <Row flex={1}>
      <FloatMenu
        touchableActiveOpacity={1}
        ref={floatMenuStartRef}
        button={
          <Row style={styles.button} gap={6}>
            <Title style={styles.optionsButton}>{t('optionsButton')}</Title>
            <Icon name="ArrowFull" />
          </Row>
        }
      >
        <View style={[styles.optionsListContainer, styles.modalBorderBottom]}>
          {onEdit && (
            <>
              <Pressable style={styles.optionsMenuItem} onPress={onEdit}>
                <SimpleListItem>
                  <Title>Modifică client</Title>
                </SimpleListItem>
              </Pressable>
              <Line />
            </>
          )}
          <Pressable style={styles.optionsMenuItem} onPress={() => onWarnClient(!isWarned)}>
            <SimpleListItem>
              {isWarned ? (
                <Title>Șterge avertizare</Title>
              ) : (
                <Title style={{ color: colors.OUTRAGEOUS_ORANGE }}>Adaugă avertizare</Title>
              )}
            </SimpleListItem>
          </Pressable>
          <Line />
          <Pressable style={styles.optionsMenuItem} onPress={() => onBlockClient(!isBlocked)}>
            <SimpleListItem>
              {isBlocked ? (
                <Title>Deblochează client</Title>
              ) : (
                <Title style={{ color: colors.RADICAL_RED }}>Blochează client</Title>
              )}
            </SimpleListItem>
          </Pressable>
          {onDelete && (
            <>
              <Line />
              <Pressable style={styles.optionsMenuItem} onPress={onDelete}>
                <SimpleListItem>
                  <Title style={{ color: colors.RADICAL_RED }}>Șterge client</Title>
                </SimpleListItem>
              </Pressable>
            </>
          )}
        </View>
      </FloatMenu>
    </Row>
  );
};

export default ClientOptionsMenu;
