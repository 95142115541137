import { BillingInfo } from './billing-info';
import { InvoiceId } from './invoice-id';
import { OrderId } from './order-id';
import { SubscriptionId } from './subscription-id';
import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

const InvoiceTracking = t.type(
  {
    series: t.string,
    subseries: t.string,
    number: t.number,
  },
  'InvoiceTracking',
);

export const Invoice = t.type(
  {
    _id: InvoiceId,
    createdAt: DateFromISOString,
    subscriptionId: SubscriptionId,
    orderId: OrderId,
    billing: BillingInfo,
    tracking: InvoiceTracking,
    downloadUrl: t.string,
  },
  'Invoice',
);

export type Invoice = t.TypeOf<typeof Invoice>;
