import React from 'react';
import { Svg, Path, G, Rect } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

import { colors } from '../../styles';

const Arrow = ({ color, size = 24 }: IconProps) => {
  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <Path
        d="M9.40697 17.743L8.02197 16.358L12.591 11.869L8.02197 7.384L9.40697 6L15.361 11.869L9.40697 17.743Z"
        fill={color || colors.DARK_BLUE}
      />
    </Svg>
  );
};

export default Arrow;
