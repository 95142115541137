import { NotePreview } from '../notes/notePreview';
import { ClientImage } from './clientImage';
import { JSONable } from '@mero/shared-sdk';
import { Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type ClientImageNotePreview = ClientImage & {
  readonly note: Option<NotePreview>;
};

const JSON: t.Type<ClientImageNotePreview, JSONable> = t.intersection(
  [
    ClientImage.JSON,
    t.type({
      note: Option.json(NotePreview.JSON),
    }),
  ],
  'ClientImageNotePreview',
);

export const ClientImageNotePreview = {
  JSON,
};
