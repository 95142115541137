import {
  ClientImageId,
  DateString,
  Email,
  MembershipPurchaseId,
  NoteDetails,
  NoteId,
  PageId,
  PhoneNumber,
  PublicFeedbackDetailsWithAppointment,
  PurchasedMembership,
  WorkerId,
} from '@mero/api-sdk';
import { UserAppointment } from '@mero/api-sdk/dist/calendar';
import { CheckoutTransactionId } from '@mero/api-sdk/dist/checkout/checkoutTransactionId';
import { ClientHistoryRecord, ClientId, ClientProfile, SavedClient } from '@mero/api-sdk/dist/clients';
import { PageClientStats } from '@mero/api-sdk/dist/clients/page-client-stats';
import { ClientImageNotePreview } from '@mero/api-sdk/dist/pro/clientImages/clientImageNotePreview';
import { ProductSale } from '@mero/api-sdk/dist/pro/productSales/productSale';
import { MeroUnits, Option } from '@mero/shared-sdk';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, ScrollView, Text, Animated } from 'react-native';

import ClaimDialog from '../../MenuScreen/screens/MarketingSettingsScreen/ClaimDialog';
import DeleteReplyDialog from '../../MenuScreen/screens/PageReviewsScreen/DeleteReplyDialog';
import ReplyDialog from '../../MenuScreen/screens/PageReviewsScreen/ReplyDialog';
import ReviewMenu from '../../MenuScreen/screens/PageReviewsScreen/ReviewMenu';
import SelectStarsFilterMenu from '../../MenuScreen/screens/PageReviewsScreen/SelectStarsFilterMenu';

import { Ref as FloatMenuRef } from '../../../../components/FloatMenu';
import { MeroHeader, Spacer, Button, Row, Column, ListMenu } from '../../../../components/shared';
import ClientConfirmBlockModal from '../components/ClientConfirmBlockModal';
import ClientConfirmDeleteModal from '../components/ClientConfirmDeleteModal';
import ClientConfirmNoteDeleteModal from '../components/ClientConfirmNoteDeleteModal';
import ClientGalleryList from '../components/ClientGalleryList';
import ClientReviewsList from '../components/ClientReviewsList';
import ClientTransactionsList, { FinishedTransaction } from '../components/ClientTransactionsList';
import ClientViewCommissionModal from '../components/ClientViewCommissionModal';
import ClientBookingsList from './components/ClientBookingsList';
import ClientBoostDetails from './components/ClientBoostDetails';
import ClientDetailsListItem from './components/ClientDetailsListItem';
import ClientHistoryList from './components/ClientHistoryList';
import ClientMembershipsList from './components/ClientMembershipsList';
import ClientNoteCard from './components/ClientNoteCard';
import ClientNotesList from './components/ClientNotesList';
import ClientOptionsMenu from './components/ClientOptionsMenu';
import ClientProductsList from './components/ClientProductsList';
import ClientProfileInfo from './components/ClientProfileInfo';
import ClientStats from './components/ClientStats';

import { useIsFocused } from '@react-navigation/native';

import { StarFilter, STARS_FILTERS, useReviews } from '../../../../hooks/useReviews';
import { useImageUpload } from '@/hooks/useImageUpload';

import * as icons from '../../../../assets/icons';
import { ClientDetailsContext } from '../../../../contexts/ClientDetailsContext';
import { sleep } from '../../../../utils/promise';
import { formattedBirthday } from '../../../../utils/text';
import { DEFAULT_TIMEZONE } from '../../../../utils/time';
import styles from './styles.web';

export enum MenuItemKey {
  BOOKINGS = 'bookings',
  SUBSCRIPTIONS = 'subscriptions',
  REVIEWS = 'reviews',
  GALLERY = 'gallery',
  JOURNAL = 'journal',
  HISTORY = 'history',
  PRODUCTS = 'products',
  TRANSACTIONS = 'transactions',
}

export interface MenuItem {
  icon: keyof typeof icons;
  value: string;
  key: MenuItemKey;
}

interface Props {
  readonly now: DateTime;
  readonly pageId: PageId;
  readonly clientId: ClientId;
  client: ClientProfile & { readonly pinnedNote: Option<NoteDetails> };
  readonly history: ClientHistoryRecord[];
  readonly totalAppointments: {
    readonly futureBookingsCount: number;
    readonly pastBookingsCount: number;
  };
  readonly notes: NoteDetails[];
  readonly clientImages: ClientImageNotePreview[];
  readonly reports: PageClientStats<'RON'>;
  readonly appointments: UserAppointment[];
  readonly productSales: ProductSale[];
  readonly memberships: PurchasedMembership<MeroUnits.Any>[];
  readonly onBack: () => void;
  readonly onViewSalesReport?: () => void;
  readonly onNewMembership: () => void;
  readonly onNewBooking?: () => void;
  readonly onEdit: () => void;
  readonly onCopyToClipboard: (text: PhoneNumber | Email) => void;
  readonly onSendWhatsappMessage: (phone: PhoneNumber) => void;
  readonly onShare: (text: string) => void;
  readonly onSendEmail: (email: Email) => void;
  readonly onDelete: (client: SavedClient) => void;
  readonly onHistoryReload: () => void;
  readonly handleAddNotePress: (note?: NoteDetails) => void;
  readonly onClientProductsSalesLoad: () => void;
  readonly onChangeIsBlocked: (client: SavedClient, isBlocked: boolean) => void;
  readonly onChangeIsWarned: (client: SavedClient, isWarned: boolean) => void;
  readonly onOpenClientAppointment: (appointment: UserAppointment) => void;
  readonly onDeleteNote: ({ noteId, deleteNoteImages }: { noteId: NoteId; deleteNoteImages: boolean }) => void;
  readonly onMembershipPress: (pageId: PageId, clientId: ClientId, membershipId: MembershipPurchaseId) => void;
  readonly handleProductSalePress: (transactionId: CheckoutTransactionId) => void;
  readonly onUpdatePinnedNote: ({ pinnedState, noteId }: { pinnedState: boolean; noteId: NoteId }) => void;
  readonly onClientImagePress: (imageId: ClientImageId) => void;
  readonly clientReviews: PublicFeedbackDetailsWithAppointment[];
  readonly canManageAllReviews: boolean;
  readonly workers: { label: string; value: any }[];
  readonly pageFeedbackScore: string;
  readonly clientTransactions: FinishedTransaction[];
  readonly onTransactionPress: (checkoutTransactionId: CheckoutTransactionId) => void;
}

const ClientDetailsLoadedScreenViewWeb: React.FC<Props> = ({
  now,
  client,
  workers,
  clientId,
  pageId,
  history,
  totalAppointments,
  appointments,
  memberships,
  onBack,
  onNewBooking,
  onNewMembership,
  onEdit,
  onCopyToClipboard,
  onSendWhatsappMessage,
  onDelete,
  onChangeIsBlocked,
  onChangeIsWarned,
  onOpenClientAppointment,
  onDeleteNote,
  onMembershipPress,
  onSendEmail,
  onHistoryReload,
  onClientProductsSalesLoad,
  handleProductSalePress,
  productSales,
  notes,
  reports,
  handleAddNotePress,
  onUpdatePinnedNote,
  clientImages,
  onClientImagePress,
  clientReviews,
  canManageAllReviews,
  pageFeedbackScore,
  clientTransactions,
  onTransactionPress,
}: Props) => {
  const { firstname, lastname } = client.user;
  const { t } = useTranslation('clients');
  const [activeMenuItem, setActiveMenuItem] = useState<MenuItemKey>(MenuItemKey.BOOKINGS);

  const {
    addReply,
    starFilter,
    showReviewMenu,
    selectedReview,
    showReplyDialog,
    showDeleteDialog,
    hideStarsFilter,
    hideReviewMenu,
    showStarsFilter,
    showMenu,
    changeReply,
    deleteReply,
    handleReplySuccess,
    handleDeleteSuccess,
    handleSelectStarsFilter,
    handleReplyCancel,
    toggleStarsFilter,
    handleDeleteCancel,
    updateSelectedWorker,
    selectedWorker,
    calculateAverage,
    scoreCounts,
  } = useReviews({
    pageId,
    onReviewsUpdate: (filter: StarFilter, workerId?: WorkerId) => {
      loadClientReviews(filter, workerId);
    },
  });

  const [
    ,
    {
      loadMoreAppointments,
      loadMoreMemberships,
      reloadClientProfile,
      loadMoreProductSales,
      loadClientNotes,
      loadClientImages,
      loadClientReviews,
      loadClientTransactions,
    },
  ] = ClientDetailsContext.useContext();
  const floatMenuStartRef = React.useRef<FloatMenuRef | null>(null);
  const fullName = React.useMemo(
    () => `${firstname ?? ''} ${lastname ?? ''}`.trim() || 'Fără nume',
    [firstname, lastname],
  );
  const isFocused = useIsFocused();
  const notesFloatMenuRef = React.useRef<FloatMenuRef | null>(null);

  const [showViewCommissionModal, setShowViewCommissionModal] = React.useState(false);
  const [showClientDeleteConfirmModal, setShowClientDeleteConfirmModal] = React.useState(false);
  const [showClientBlockConfirmModal, setShowClientBlockConfirmModal] = React.useState(false);
  const [showClientNoteDeleteConfirmModal, setShowClientNoteDeleteConfirmModal] = React.useState<
    | {
        noteId: NoteId;
        deleteNoteImages: boolean;
      }
    | undefined
  >(undefined);
  const [showClaimDialog, setShowClaimDialog] = React.useState(false);
  const isBoost = client.boostStatus.isBoost;
  const hideBoostDetails = client.boostStatus.isBoost && client.boostStatus.hideBoostDetails;
  const isClaimPending = isBoost && client.boostStatus.isClaimPending;
  const canClaim = isBoost && client.boostStatus.canClaim;
  const canAcceptCommission = isBoost && client.boostStatus.canAcceptCommission;

  const clientPhone = parsePhoneNumber(client.user.phone);

  const { removeImage, retryUpload, setImages, images, handleSelectImages } = useImageUpload();

  useEffect(() => {
    if (isFocused && activeMenuItem === MenuItemKey.JOURNAL) {
      loadClientNotes(pageId, clientId);
    }
  }, [isFocused, activeMenuItem]);

  const toggleShowCommissionModal = () => {
    setShowViewCommissionModal(!showViewCommissionModal);
  };

  const toggleClaimDialog = () => {
    setShowClaimDialog(!showClaimDialog);
  };

  const PROFILE_MENU_ITEMS: MenuItem[] = useMemo(() => {
    return [
      {
        key: MenuItemKey.BOOKINGS,
        value: t('bookings', { bookingsCount: appointments.length }),
        icon: 'Calendar',
      } as MenuItem,
      {
        key: MenuItemKey.SUBSCRIPTIONS,
        value: t('subscriptions', { subscriptionsCount: memberships.length }),
        icon: 'Identity',
      } as MenuItem,
      {
        key: MenuItemKey.PRODUCTS,
        // value: t('products_list', { productsCount: productSales ? productSales.length : 0 }),
        value: t('products'),
        icon: 'Products',
      } as MenuItem,
      { key: MenuItemKey.TRANSACTIONS, value: t('transactions'), icon: 'Receipt' } as MenuItem,
      { key: MenuItemKey.REVIEWS, value: t('reviews'), icon: 'Star' } as MenuItem,
      { key: MenuItemKey.GALLERY, value: t('gallery'), icon: 'Camera' } as MenuItem,
      { key: MenuItemKey.JOURNAL, value: t('journal'), icon: 'Journal' } as MenuItem,
      { key: MenuItemKey.HISTORY, value: t('history'), icon: 'History' } as MenuItem,
    ];
  }, [appointments.length, memberships.length, productSales.length]);

  const onMenuChange = (menuItemKey: MenuItemKey) => {
    switch (menuItemKey) {
      case MenuItemKey.HISTORY:
        onHistoryReload();
        break;
      case MenuItemKey.PRODUCTS:
        onClientProductsSalesLoad();
        break;
      case MenuItemKey.JOURNAL:
        loadClientNotes(pageId, clientId);
        break;
      case MenuItemKey.GALLERY:
        loadClientImages(pageId, clientId);
        break;
      case MenuItemKey.REVIEWS:
        loadClientReviews(starFilter);
        break;
      case MenuItemKey.TRANSACTIONS:
        loadClientTransactions(pageId, clientId);
        break;
      default:
        break;
    }
    setActiveMenuItem(menuItemKey);
  };

  const getContent = (tab: MenuItemKey) => {
    switch (tab) {
      case MenuItemKey.BOOKINGS: {
        return (
          <ClientBookingsList
            HeaderElement={
              appointments.length > 0 ? <Text style={styles.contentTitle}>{t('bookingsTitle')}</Text> : undefined
            }
            onEndReached={() => loadMoreAppointments(clientId)}
            totals={totalAppointments}
            appointments={appointments}
            boostAppointmentId={isBoost ? client.boostStatus.boostAppointmentId : undefined}
            now={now}
            onItemPress={onOpenClientAppointment}
          />
        );
      }
      case MenuItemKey.JOURNAL: {
        return (
          <ClientNotesList
            onUpdatePinnedState={handleUpdateNotePinnedState}
            notes={notes}
            onAddNote={() => handleAddNotePress(undefined)}
            onEditNote={handleAddNotePress}
            onDeleteNote={(note) => setShowClientNoteDeleteConfirmModal({ noteId: note._id, deleteNoteImages: true })}
          />
        );
      }
      case MenuItemKey.HISTORY: {
        return <ClientHistoryList history={history} now={now.toJSDate()} />;
      }
      case MenuItemKey.PRODUCTS: {
        return (
          <ClientProductsList
            onEndReached={() => loadMoreProductSales(pageId, clientId)}
            productSales={productSales}
            handleProductPress={handleProductSalePress}
          />
        );
      }

      case MenuItemKey.TRANSACTIONS: {
        return (
          <ClientTransactionsList onTransactionPress={onTransactionPress} clientTransactions={clientTransactions} />
        );
      }

      case MenuItemKey.SUBSCRIPTIONS: {
        return (
          <ClientMembershipsList
            pageId={pageId}
            onMembershipPress={onMembershipPress}
            onEndReached={() => loadMoreMemberships(clientId, pageId)}
            onNewMembership={onNewMembership}
            memberships={memberships}
            clientId={clientId}
          />
        );
      }

      case MenuItemKey.GALLERY: {
        return (
          <ClientGalleryList
            retryUpload={retryUpload}
            removeImage={removeImage}
            setImages={setImages}
            images={images}
            clientImages={clientImages}
            pageId={pageId}
            clientId={clientId}
            onImagePress={handleGalleryImagePress}
            onAddNewImage={() => handleSelectImages(pageId, clientId)}
          />
        );
      }

      case MenuItemKey.REVIEWS: {
        return (
          <ClientReviewsList
            HeaderElement={<Text style={styles.contentTitle}>{t('reviews')}</Text>}
            workers={workers}
            selectedWorker={selectedWorker}
            averageScore={calculateAverage}
            onReviewWorkerFilterChange={updateSelectedWorker}
            scoreCounts={scoreCounts}
            pageFeedbackScore={pageFeedbackScore}
            reviewsFilter={starFilter}
            onShowStarsFilters={toggleStarsFilter}
            onShowReviewMenu={showReviewMenu}
            onShowReplyDialog={addReply}
            clientReviews={clientReviews}
            canManageAllReviews={canManageAllReviews}
          />
        );
      }
    }
  };

  const handleGalleryImagePress = (imageId: ClientImageId) => {
    onClientImagePress(imageId);
  };

  const handleCloseClientOptions = () => {
    floatMenuStartRef.current?.onDismissMenu();
  };

  const handleBlockClient = (isBlocked: boolean) => {
    handleCloseClientOptions();
    if (isBlocked) {
      setShowClientBlockConfirmModal(true);
    } else {
      onChangeIsBlocked(client, false);
    }
  };

  const handleWarnClient = (isWarned: boolean) => {
    handleCloseClientOptions();
    onChangeIsWarned(client, isWarned);
  };

  const handleDeleteClient = () => {
    handleCloseClientOptions();
    setShowClientDeleteConfirmModal(true);
  };

  const handleOnEditClient = () => {
    handleCloseClientOptions();
    onEdit();
  };

  const handleBack = async () => {
    startAnimation();
    await sleep(250);
    onBack();
  };

  const translateX = useRef(new Animated.Value(0)).current;

  const startAnimation = () => {
    Animated.parallel([
      Animated.timing(translateX, {
        toValue: 960,
        duration: 250,
        useNativeDriver: true,
      }),
    ]).start();
  };

  const handleUpdateNotePinnedState = (pinnedState: boolean, noteId: NoteId) => {
    onUpdatePinnedNote({ pinnedState, noteId });
  };

  const handleUpdateProfileNoteState = (pinnedState: boolean, noteId: NoteId) => {
    notesFloatMenuRef.current?.onDismissMenu();
    onUpdatePinnedNote({ pinnedState, noteId });
  };

  return (
    <>
      <Animated.View
        style={[
          styles.container,
          {
            transform: [{ translateX }],
          },
        ]}
      >
        <Column style={styles.infoColumn}>
          <MeroHeader canGoBack onBack={handleBack} />
          <ScrollView>
            <View style={styles.contentContainer}>
              <ClientProfileInfo
                profilePicture={client.user.photo?.medium}
                firstName={client.user.firstname || ''}
                lastName={client.user.lastname || ''}
                fullName={fullName}
                phone={clientPhone.number?.international || ''}
                isBlocked={client.isBlocked}
                isWarned={client.isWarned}
                isBoost={isBoost}
                hideBoostDetails={hideBoostDetails}
              />
              <Row style={styles.buttonsWrapper} justifyContent="center" alignItems="center" flex={1}>
                {onNewBooking && (
                  <Button
                    containerStyle={styles.addBookingButton}
                    size="medium"
                    text={t('addBooking')}
                    onPress={onNewBooking}
                  />
                )}
                <ClientOptionsMenu
                  floatMenuStartRef={floatMenuStartRef}
                  isBlocked={client.isBlocked}
                  isWarned={client.isWarned}
                  onBlockClient={handleBlockClient}
                  onWarnClient={handleWarnClient}
                  onDelete={!isBoost ? handleDeleteClient : undefined}
                  onEdit={!hideBoostDetails ? handleOnEditClient : undefined}
                />
              </Row>
              {hideBoostDetails ? (
                <>
                  <ClientBoostDetails
                    isClaimPending={isClaimPending}
                    canClaim={canClaim}
                    onClaimDialog={toggleClaimDialog}
                    onViewCommission={toggleShowCommissionModal}
                    canAcceptCommission={canAcceptCommission}
                  />
                  <Spacer size={16} />
                </>
              ) : null}
              {client.pinnedNote && (
                <>
                  <ClientNoteCard
                    onUpdatePinnedState={handleUpdateProfileNoteState}
                    isProfilePinned
                    note={client.pinnedNote}
                    onEdit={handleAddNotePress}
                    onDelete={(note, deleteNoteImages) =>
                      setShowClientNoteDeleteConfirmModal({ noteId: note._id, deleteNoteImages })
                    }
                    notesFloatMenuRef={notesFloatMenuRef}
                  />
                  <Spacer size={16} />
                </>
              )}
              {reports ? (
                <ClientStats
                  completedBookingsCount={reports.completedBookings}
                  cancelledBookingsCount={reports.cancelledBookings}
                  lastCompletedBookingDate={reports.lastCompletedBookingTs}
                  noShowBookingsCount={reports.noShowBookings}
                  clientTotalCharged={reports.totalCheckoutCharge}
                  clientDebt={reports.debt}
                />
              ) : null}
            </View>

            <ClientDetailsListItem
              label={t('email')}
              value={client.user.email as Email | undefined}
              onCopyPress={client.user.email ? () => onCopyToClipboard(client.user.email as Email) : undefined}
              onSendPress={client.user.email ? () => onSendEmail(client.user.email as Email) : undefined}
            />

            {!hideBoostDetails && (
              <ClientDetailsListItem
                label={t('phone')}
                value={clientPhone.number?.international}
                onCopyPress={() => onCopyToClipboard(clientPhone.number?.international as PhoneNumber)}
                onWhatAppPress={() => onSendWhatsappMessage(clientPhone.number?.input as PhoneNumber)}
              />
            )}
            <ClientDetailsListItem
              label={t('birthday')}
              value={
                client.user.birthday
                  ? formattedBirthday(DateString.toDate(client.user.birthday, DEFAULT_TIMEZONE))
                  : undefined
              }
            />
          </ScrollView>
        </Column>
        <Column flex={1} style={styles.menuColumn}>
          <ListMenu activeItem={activeMenuItem} items={PROFILE_MENU_ITEMS} onItemPress={onMenuChange} />
        </Column>
        <Column flex={1} style={styles.contentColumn}>
          {getContent(activeMenuItem)}
        </Column>
      </Animated.View>

      {showViewCommissionModal && (
        <ClientViewCommissionModal
          client={client}
          onDismiss={toggleShowCommissionModal}
          onConfirm={() => {
            reloadClientProfile(client._id);
            toggleShowCommissionModal();
          }}
        />
      )}

      {showClientDeleteConfirmModal ? (
        <ClientConfirmDeleteModal
          deleteInProgress={false}
          onDismiss={() => {
            setShowClientDeleteConfirmModal(false);
          }}
          onConfirm={() => {
            setShowClientDeleteConfirmModal(false);
            onDelete(client);
          }}
        />
      ) : null}

      {showClientBlockConfirmModal ? (
        <ClientConfirmBlockModal
          phone={client.user.phone}
          onDismiss={() => {
            setShowClientBlockConfirmModal(false);
          }}
          onConfirm={() => {
            setShowClientBlockConfirmModal(false);
            // Client should be blocked
            onChangeIsBlocked(client, true);
          }}
        />
      ) : null}

      {showClientNoteDeleteConfirmModal !== undefined ? (
        <ClientConfirmNoteDeleteModal
          noteId={showClientNoteDeleteConfirmModal.noteId}
          deleteNoteImages={showClientNoteDeleteConfirmModal.deleteNoteImages}
          onDismiss={() => {
            setShowClientNoteDeleteConfirmModal(undefined);
          }}
          onConfirm={() => {
            setShowClientNoteDeleteConfirmModal(undefined);
            onDeleteNote(showClientNoteDeleteConfirmModal);
          }}
        />
      ) : null}

      {showClaimDialog && (
        <ClaimDialog
          onSuccess={() => reloadClientProfile(client._id)}
          onCancel={toggleClaimDialog}
          userId={client.user._id}
        />
      )}

      {selectedReview && showReplyDialog && (
        <ReplyDialog onSuccess={handleReplySuccess} onCancel={handleReplyCancel} review={selectedReview} />
      )}

      {selectedReview && showMenu && (
        <ReviewMenu onDeleteReply={deleteReply} onChangeReply={changeReply} onDismiss={hideReviewMenu} />
      )}

      {selectedReview && showDeleteDialog && (
        <DeleteReplyDialog
          onSuccess={handleDeleteSuccess}
          onCancel={handleDeleteCancel}
          feedbackId={selectedReview._id}
        />
      )}

      {showStarsFilter && (
        <SelectStarsFilterMenu
          list={[...STARS_FILTERS.filter((item) => item !== 'allStars'), 'allStars']}
          onSelect={handleSelectStarsFilter}
          onDismiss={hideStarsFilter}
        />
      )}
    </>
  );
};

export default ClientDetailsLoadedScreenViewWeb;
