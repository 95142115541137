import { colors } from '@mero/components';
import { StyleSheet } from 'react-native';

import { sizes } from '../../../../../../styles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  optionsListContainer: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    marginTop: 0,
    flexDirection: 'column',
    backgroundColor: colors.WHITE,
    borderTopLeftRadius: sizes[12],
    borderTopRightRadius: sizes[12],
    minHeight: sizes[32],
  },
  optionsMenuItem: {
    paddingLeft: sizes[24],
    paddingRight: sizes[24],
  },

  button: {
    width: 24,
    height: 24,
    alignSelf: 'baseline',
  },
});

export default styles;
