import { StyleSheet } from 'react-native';

import { colors } from '../../../styles';

const styles = StyleSheet.create({
  container: {
    height: 24,
    paddingLeft: 4,
    paddingRight: 10,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.ATHENS_GRAY,
  },
  text: {
    color: colors.COMET,
    fontFamily: 'open-sans-semibold',
  },
});

export default styles;
