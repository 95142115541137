import { ClientImage } from '@mero/api-sdk';
import { ClientId } from '@mero/api-sdk/dist/clients';
import { PageId } from '@mero/api-sdk/dist/pages';
import * as ImagePicker from 'expo-image-picker';
import { useState, useCallback } from 'react';
import { Platform } from 'react-native';

import { meroApi } from '../contexts/AuthContext';
import { getFileBlob, getLocalImageUrlForUpload } from '../utils/booking';
import {
  ImageType,
  Status,
  parseSelectedImages,
  multipleSelect,
  singleSelect,
  supportsMultiple,
  openCamera,
} from '../utils/gallery';
import log from '../utils/log';

interface UseImageUploadOptions {
  onError?: (error: unknown) => void;
  onUploadComplete?: (image: ClientImage) => void;
  skipUpload?: boolean;
}

interface UseImageUploadResult {
  images: (ClientImage | ImageType)[];
  isUploading: boolean;
  handleSelectImages: (pageId: PageId, clientId: ClientId) => Promise<void>;
  handleSelectImagesFromCamera: (pageId: PageId, clientId: ClientId) => Promise<void>;
  retryUpload: (image: ImageType, pageId: PageId, clientId: ClientId) => Promise<void>;
  removeImage: (imageId: string) => void;
  setImages: (images: (ClientImage | ImageType)[]) => void;
  uploadImages: (images: ImageType[], pageId: PageId, clientId: ClientId) => Promise<void>;
}

/**
 * Hook for managing image uploads for clients
 * Handles selecting, uploading, retrying and removing images
 */
export const useImageUpload = (options: UseImageUploadOptions = {}): UseImageUploadResult => {
  const [images, setImages] = useState<(ClientImage | ImageType)[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [cameraPermissionStatus, requestCameraPermission] = ImagePicker.useCameraPermissions();

  /**
   * Updates the status of an image in the images array
   */
  const updateImageStatus = useCallback((imageId: string, status: Status) => {
    setImages((prevImages) =>
      prevImages.map((img) => {
        if (img._id === imageId) {
          return {
            ...img,
            status,
          } as ImageType;
        }
        return img;
      }),
    );
  }, []);

  /**
   * Replaces a temporary image with the uploaded client image
   */
  const updateImages = useCallback((imageId: string, clientImage: ClientImage) => {
    setImages((prevImages) =>
      prevImages.map((img) => {
        if (img._id === imageId) {
          return clientImage;
        }
        return img;
      }),
    );
  }, []);

  /**
   * Uploads a single image to the server
   * Updates the image in state when complete
   */
  const uploadImage = useCallback(async (image: ImageType, pageId: PageId, clientId: ClientId) => {
    const photoUri = getLocalImageUrlForUpload(image.uri);
    const blob = await getFileBlob(photoUri);

    try {
      const newClientImage = await meroApi.pro.clientImages.uploadClientImage({
        clientId,
        pageId,
        image: { platform: Platform.OS, blob, uri: photoUri },
      });
      updateImages(image._id, newClientImage);
      options.onUploadComplete?.(newClientImage);
      return newClientImage;
    } catch (error) {
      log.debug('Failed to upload image', error);
      updateImageStatus(image._id, Status.FAILED);
      options.onError?.(error);
      throw error;
    }
  }, []);

  /**
   * Uploads multiple images sequentially
   * Only uploads images with Status.LOADING
   */
  const uploadImages = useCallback(async (newImages: ImageType[], pageId: PageId, clientId: ClientId) => {
    setIsUploading(true);
    const toUploadImages = newImages.filter((image) => {
      if (options.skipUpload) {
        return image.status === Status.IDLE;
      }
      return image.status === Status.LOADING;
    });

    try {
      for (const image of toUploadImages) {
        await uploadImage(image, pageId, clientId);
      }
    } finally {
      setIsUploading(false);
    }
  }, []);

  /**
   * Handles the image selection process
   * Supports both single and multiple image selection
   * Only uploads immediately if skipUpload is false
   */
  const handleSelectImages = useCallback(async (pageId: PageId, clientId: ClientId) => {
    try {
      const result = supportsMultiple() ? await multipleSelect() : await singleSelect();
      const selectedImages = parseSelectedImages(result, options.skipUpload ?? false);

      setImages((prevImages) => [...prevImages, ...selectedImages]);

      if (!options.skipUpload) {
        await uploadImages(selectedImages, pageId, clientId);
      }
    } catch (error) {
      log.error('Error while adding images', error);
      options.onError?.(error);
    }
  }, []);

  const handleSelectImagesFromCamera = useCallback(async (pageId: PageId, clientId: ClientId) => {
    if (cameraPermissionStatus === null) {
      await requestCameraPermission();
    }
    const result = await openCamera();
    const selectedImages = parseSelectedImages(result, options.skipUpload ?? false);
    setImages((prevImages) => [...prevImages, ...selectedImages]);

    if (!options.skipUpload) {
      await uploadImages(selectedImages, pageId, clientId);
    }
  }, []);

  /**
   * Retries uploading a failed image
   * Resets the image status to loading and attempts upload again
   */
  const retryUpload = useCallback(async (image: ImageType, pageId: PageId, clientId: ClientId) => {
    try {
      updateImageStatus(image._id, Status.LOADING);
      await uploadImage(
        {
          _id: image._id,
          uri: image.uri,
          status: Status.LOADING,
        },
        pageId,
        clientId,
      );
    } catch (error) {
      log.error('Error retrying to upload image', error);
      options.onError?.(error);
    }
  }, []);

  /**
   * Removes an image from the images array by ID
   */
  const removeImage = useCallback((imageId: string) => {
    setImages((prevImages) => prevImages.filter((img) => img._id !== imageId));
  }, []);

  return {
    images,
    isUploading,
    handleSelectImages,
    handleSelectImagesFromCamera,
    retryUpload,
    removeImage,
    setImages,
    uploadImages,
  };
};
