import { dateStringUtils, Email, HasOptionalLastname } from '@mero/api-sdk';
import { ClientId } from '@mero/api-sdk/dist/clients';
import { Firstname } from '@mero/api-sdk/dist/common';
import { createModelContext } from '@mero/components';
import { NonEmptyString } from 'io-ts-types';
import * as React from 'react';

import { meroApi } from '../../contexts/AuthContext';
import log, { logCatch } from '../../utils/log';

type ClientUpdateDetailsContextState =
  | {
      readonly type: 'Ready';
    }
  | {
      readonly type: 'Updating';
      readonly clientId: ClientId;
    }
  | {
      readonly type: 'Updated';
      readonly clientId: ClientId;
    }
  | {
      readonly type: 'Failed';
      readonly error?: unknown;
    };

const defaultState = (): ClientUpdateDetailsContextState => ({
  type: 'Ready',
});

export const ClientUpdateDetailsContext = createModelContext(
  defaultState(),
  {
    trySetUpdated: (_, payload: { clientId: ClientId }) => ({
      type: 'Updated',
      clientId: payload.clientId,
    }),
    trySetFailed: (_, error: unknown) => ({
      type: 'Failed',
      error: error,
    }),
    tryReset: (s) => {
      if (s.type === 'Updated' || s.type === 'Failed') {
        return {
          type: 'Ready',
        };
      }

      return s;
    },
    mutate: (s, fn: (s: ClientUpdateDetailsContextState) => ClientUpdateDetailsContextState) => {
      return fn(s);
    },
  },
  (dispatch) => {
    return {
      updateClientDetails: (
        payload: { clientId: ClientId } & HasOptionalLastname & {
            remark?: NonEmptyString;
            showRemarkOnCalendar: boolean;
            email?: Email;
            birthday?: Date;
            firstname: Firstname;
          },
      ) => {
        dispatch.mutate((state) => {
          if (state.type === 'Ready') {
            const updateClientDetails = async () => {
              try {
                await Promise.all([
                  meroApi.clients
                    .updateClient({
                      clientId: payload.clientId,
                      firstname: payload.firstname,
                      lastname: payload.lastname,
                      email: payload.email,
                      birthday: payload.birthday ? dateStringUtils.fromDate(payload.birthday) : undefined,
                    })
                    .catch(logCatch('updateClient')),
                ]);

                dispatch.trySetUpdated({ clientId: payload.clientId });
              } catch (error: unknown) {
                dispatch.trySetFailed(error);
                log.exception(error);
              }
            };

            updateClientDetails().catch(log.exception);

            return {
              type: 'Updating',
              clientId: payload.clientId,
            };
          }

          return state;
        });
      },
      tryReset: dispatch.tryReset,
    };
  },
);

export const ClientUpdateDetailsContextProvider: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  return <ClientUpdateDetailsContext.Provider>{children}</ClientUpdateDetailsContext.Provider>;
};

export const withClientUpdateDetailsContextProvider = <P extends object>(
  Content: React.ComponentType<P>,
): React.FC<P> => {
  return function WithClientUpdateDetailsContextProvider(props: P) {
    return (
      <ClientUpdateDetailsContextProvider>
        <Content {...props} />
      </ClientUpdateDetailsContextProvider>
    );
  };
};
