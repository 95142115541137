import { IsoWeekDay } from './isoWeekDay';
import { LocalDateObject } from './localDateObject';
import { isSome, Option } from './option';
import * as Ord from 'fp-ts/Ord';
import * as t from 'io-ts';

export class LocalDate implements LocalDateObject {
  /**
   * Year, ex: 2020
   */
  readonly year: number;
  /**
   * Month, [1..12]
   */
  readonly month: number;
  /**
   * Day, [1..31]
   */
  readonly day: number;

  private constructor(year: number, month: number, day: number) {
    this.year = year;
    this.month = month;
    this.day = day;
  }

  /**
   * Returns new Date build from year,month,day time in UTC
   * @private
   */
  private toUTCDate(): Date {
    return new Date(Date.UTC(this.year, this.month - 1, this.day));
  }

  private static fromUTCDate(date: Date): LocalDate {
    return new LocalDate(date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate());
  }

  plus(duration: Duration): LocalDate {
    const d = this.toUTCDate();
    let modified = false;

    if (isSome(duration.weeks)) {
      d.setUTCDate(d.getUTCDate() + duration.weeks * 7);
      modified = true;
    }

    if (isSome(duration.days)) {
      d.setUTCDate(d.getUTCDate() + duration.days);
      modified = true;
    }

    if (!modified) {
      return this;
    }

    return LocalDate.fromUTCDate(d);
  }

  minus(duration: Duration): LocalDate {
    const d = this.toUTCDate();
    let modified = false;

    if (isSome(duration.weeks)) {
      d.setUTCDate(d.getUTCDate() - duration.weeks * 7);
      modified = true;
    }

    if (isSome(duration.days)) {
      d.setUTCDate(d.getUTCDate() - duration.days);
      modified = true;
    }

    if (!modified) {
      return this;
    }

    return LocalDate.fromUTCDate(d);
  }

  getIsoWeekDay(): IsoWeekDay {
    const d = this.toUTCDate();
    const day = d.getUTCDay();

    if (day === 0) {
      return IsoWeekDay.SUNDAY;
    }

    return IsoWeekDay.unsafeFrom(day);
  }

  /**
   * https://www.mathsisfun.com/leap-years.html
   *
   * Leap Years are any year that can be exactly divided by 4 (such as 2020, 2024, 2028, etc)
   *  > except if it can be exactly divided by 100, then it isn't (such as 2100, 2200, etc)
   *    >	except if it can be exactly divided by 400, then it is (such as 2000, 2400)
   * > These leap year rules were introduced in 1582 by the Gregorian Calendar, named after Pope Gregory XIII.
   */
  isLeapYear(): boolean {
    return isLeapYear(this.year);
  }

  startOf(period: 'week' | 'month' | 'year'): LocalDate {
    switch (period) {
      case 'week': {
        const weekDay = this.getIsoWeekDay();
        if (weekDay === IsoWeekDay.MONDAY) {
          return this;
        }

        return this.minus({ days: weekDay - 1 });
      }
      case 'month': {
        if (this.day === 1) {
          return this;
        }

        return LocalDate.unsafeFrom(this.year, this.month, 1);
      }
      case 'year': {
        if (this.month === 1 && this.day === 1) {
          return this;
        }

        return LocalDate.unsafeFrom(this.year, 1, 1);
      }
    }
  }

  endOf(period: 'week' | 'month' | 'year'): LocalDate {
    switch (period) {
      case 'week': {
        const weekDay = this.getIsoWeekDay();
        if (weekDay === IsoWeekDay.SUNDAY) {
          return this;
        }

        return this.plus({ days: 7 - weekDay });
      }
      case 'month': {
        const lastDay = getLastDayOf(this.year, this.month);

        return LocalDate.unsafeFrom(this.year, this.month, lastDay);
      }
      case 'year': {
        if (this.month === 12 && this.day === 31) {
          return this;
        }

        return LocalDate.unsafeFrom(this.year, 12, 31);
      }
    }
  }

  equals(b: LocalDateObject): boolean {
    return LocalDateObject.equals(this, b);
  }

  compare(b: LocalDateObject): -1 | 0 | 1 {
    return LocalDateObject.compare(this, b);
  }

  lt(b: LocalDateObject): boolean {
    return LocalDateObject.lt(this, b);
  }

  lte(b: LocalDateObject): boolean {
    return LocalDateObject.lte(this, b);
  }

  gt(b: LocalDateObject): boolean {
    return LocalDateObject.gt(this, b);
  }

  gte(b: LocalDateObject): boolean {
    return LocalDateObject.gte(this, b);
  }

  toString(): string {
    let year: string = String(this.year);
    let month: string = String(this.month);
    let day: string = String(this.day);

    // Most of the date years are > 1900
    if (this.year < 1000) {
      if (this.year < 10) {
        year = '000' + year;
      } else if (this.year < 100) {
        year = '00' + year;
      } else {
        year = '0' + year;
      }
    }

    if (this.month < 10) {
      month = '0' + month;
    }

    if (this.day < 10) {
      day = '0' + day;
    }

    return year + '-' + month + '-' + day;
  }

  valueOf(): string {
    return this.toString();
  }

  static unsafeFrom(year: number, month: number, day: number): LocalDate {
    if (year < 0) {
      throw new Error(`Invalid year ${year}: must be >= 0`);
    }

    if (month < 1 || month > 12) {
      throw new Error(`Invalid month ${month}: must be between 1 and 12`);
    }

    if (!Number.isInteger(month)) {
      throw new Error(`Invalid month ${month}: must be an Int`);
    }

    if (day < 1 || day > 31) {
      throw new Error(`Invalid day ${day}: must be between 1 and 31`);
    }

    if (!Number.isInteger(day)) {
      throw new Error(`Invalid day ${day}: must be an Int`);
    }

    const lastDay = getLastDayOf(year, month);

    if (day > lastDay) {
      throw new Error(`Invalid day ${day}: max day for year: ${year}, month: ${month} is ${lastDay}`);
    }

    return new LocalDate(year, month, day);
  }

  static unsafeFromObject(date: { year: number; month: number; day: number }): LocalDate {
    return this.unsafeFrom(date.year, date.month, date.day);
  }

  /**
   * Local LocalDate
   */
  static local(): LocalDate {
    const d = new Date();

    return LocalDate.unsafeFrom(d.getFullYear(), d.getMonth() + 1, d.getDate());
  }

  static JSON: t.Type<LocalDate, LocalDateObject> = LocalDateObject.JSON.pipe(
    new t.Type<LocalDate, LocalDateObject, LocalDateObject>(
      'LocalDate',
      (value): value is LocalDate => {
        return value instanceof LocalDate;
      },
      (value: LocalDateObject, context) => {
        try {
          return t.success(LocalDate.unsafeFrom(value.year, value.month, value.day));
        } catch (error: unknown) {
          return t.failure(error, context);
        }
      },
      (value: LocalDate) => {
        return value;
      },
    ),
  );
}

type Duration = {
  /**
   * Adding years is tricky because of leap year, ex: 2024-02-29 + 1.year => 2025-02-28
   */
  // readonly years?: number;
  /**
   * Adding months is tricky, ex 2025-01-31 + 1.month => 2025-02-28
   */
  // readonly months?: number;
  readonly weeks?: number;
  readonly days?: number;
};

/**
 * https://www.mathsisfun.com/leap-years.html
 *
 * Leap Years are any year that can be exactly divided by 4 (such as 2020, 2024, 2028, etc)
 *  > except if it can be exactly divided by 100, then it isn't (such as 2100, 2200, etc)
 *    >	except if it can be exactly divided by 400, then it is (such as 2000, 2400)
 * > These leap year rules were introduced in 1582 by the Gregorian Calendar, named after Pope Gregory XIII.
 */
const isLeapYear = (year: number): boolean => {
  if (year % 4 !== 0) {
    return false;
  }

  if (year < 1582) {
    return true;
  }

  if (year % 100 !== 0) {
    return true;
  }

  if (year % 400 === 0) {
    return true;
  }

  return false;
};

const getLastDayOf = (year: number, month: number): number => {
  const isLeap = isLeapYear(year);

  if (isLeap && month === 2) {
    return 29;
  }

  const day = DAYS_IN_MONTH[month];

  if (!isSome(day)) {
    throw new Error(`Invalid month ${month}`);
  }

  return day;
};

/**
 * "Regular" days in a month, by month number
 */
const DAYS_IN_MONTH: { [Month in number]: Option<number> } = {
  1: 31,
  2: 28,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31,
};
