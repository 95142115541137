import { colors, Title } from '@mero/components';
import * as React from 'react';
import { Pressable, TouchableOpacity, View } from 'react-native';

import Line from '@mero/components/lib/components/Line';
import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import SimpleListItem from '@mero/components/lib/components/SimpleListItem';

import { useMediaQueries } from '../../../../hooks/useMediaQueries';

import { styles } from './styles';

export type Props = {
  readonly isWarned: boolean;
  readonly isBlocked: boolean;
  readonly onDismiss: () => void;
  readonly onEdit?: () => void;
  readonly onChangeIsWarned: (isWarned: boolean) => void;
  readonly onChangeIsBlocked: (isBlocked: boolean) => void;
  readonly onDelete?: () => void;
};

/**
 * Options modal screen component component for client details page, where user can choose to:
 *  - Edit client
 *  - Add/Remove client warning
 *  - Block/Unblock client
 *  - Delete client
 */
const ClientOptionsModal: React.FC<Props> = ({
  isWarned,
  isBlocked,
  onDismiss,
  onEdit,
  onChangeIsWarned,
  onChangeIsBlocked,
  onDelete,
}: Props): React.ReactElement | null => {
  const { isPhone } = useMediaQueries();

  return (
    <ModalOverlay>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <View style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom]}>
        <SafeAreaView edges={['bottom']}>
          {onEdit && (
            <>
              <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onEdit}>
                <SimpleListItem>
                  <Title>Modifică client</Title>
                </SimpleListItem>
              </TouchableOpacity>
              <Line />
            </>
          )}
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={() => onChangeIsWarned(!isWarned)}>
            <SimpleListItem>
              {isWarned ? (
                <Title>Șterge avertizare</Title>
              ) : (
                <Title style={{ color: colors.OUTRAGEOUS_ORANGE }}>Adaugă avertizare</Title>
              )}
            </SimpleListItem>
          </TouchableOpacity>
          <Line />
          <TouchableOpacity
            style={styles.optionsMenuItem}
            delayPressIn={0}
            onPress={() => onChangeIsBlocked(!isBlocked)}
          >
            <SimpleListItem>
              {isBlocked ? (
                <Title>Deblochează client</Title>
              ) : (
                <Title style={{ color: colors.RADICAL_RED }}>Blochează client</Title>
              )}
            </SimpleListItem>
          </TouchableOpacity>
          {onDelete && (
            <>
              <Line />
              <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onDelete}>
                <SimpleListItem>
                  <Title style={{ color: colors.RADICAL_RED }}>Șterge client</Title>
                </SimpleListItem>
              </TouchableOpacity>
            </>
          )}
        </SafeAreaView>
      </View>
    </ModalOverlay>
  );
};

export default ClientOptionsModal;
