import * as React from 'react';
import { Image } from 'react-native';

import { Column, SmallBody } from '../../../../../components/shared';

import styles from './styles';

interface NoteImageProps {
  url: string;
  showOverlay?: boolean;
  overlayImageCount?: number;
}

const NoteImage = ({ url, showOverlay }: NoteImageProps) => {
  const imageSize = 40;
  return (
    <Column
      style={[
        {
          width: imageSize,
          height: imageSize,
        },
        styles.image,
      ]}
    >
      <Image source={{ uri: url }} style={{ width: imageSize, height: imageSize }} />
      {showOverlay && (
        <Column alignItems="center" justifyContent="center" style={styles.closeIconContainer}>
          <SmallBody style={styles.count}>+2</SmallBody>
        </Column>
      )}
    </Column>
  );
};

export default NoteImage;
