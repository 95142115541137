import { PublicFeedbackDetailsWithAppointment, WorkerId } from '@mero/api-sdk';
import { Select, Spacer } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Pressable, View } from 'react-native';

import { StarIcon } from '../../../MenuScreen/screens/PageReviewsScreen/PageReviewsScreen';
import { AnonymousProfile } from '../../../MenuScreen/screens/PageReviewsScreen/Review';
import Review from '../../../MenuScreen/screens/PageReviewsScreen/Review';

import { Column, Icon, Row, SmallBody } from '../../../../../components/shared';
import ClientEmptyListView from '../../components/ClientEmptyListView';

import { StarFilter, StarsToScore } from '../../../../../hooks/useReviews';

import { colors } from '../../../../../styles';
import styles from './styles';

interface ClientReviewsListProps {
  readonly clientReviews: PublicFeedbackDetailsWithAppointment[];
  readonly canManageAllReviews: boolean;
  readonly onShowReplyDialog: (review: PublicFeedbackDetailsWithAppointment) => void;
  readonly onShowReviewMenu: (review: PublicFeedbackDetailsWithAppointment) => void;
  readonly reviewsFilter: StarFilter;
  readonly onShowStarsFilters: () => void;
  readonly HeaderElement?: React.ReactElement;
  readonly pageFeedbackScore?: string;
  readonly scoreCounts?: Record<number, number>;
  readonly workers?: { label: string; value: any }[];
  readonly selectedWorker?: WorkerId;
  readonly averageScore?: number;
  readonly onReviewWorkerFilterChange: (worker: WorkerId) => void;
}

const ClientReviewsList: React.FC<ClientReviewsListProps> = ({
  clientReviews,
  canManageAllReviews,
  onShowReplyDialog,
  onShowReviewMenu,
  reviewsFilter,
  onShowStarsFilters,
  HeaderElement,
  pageFeedbackScore,
  scoreCounts,
  selectedWorker,
  workers,
  averageScore,
  onReviewWorkerFilterChange,
}) => {
  const { t } = useTranslation('reviews');
  const filterReviews = scoreCounts?.[StarsToScore[reviewsFilter] ?? 0];

  const renderItem = ({ item }: { item: PublicFeedbackDetailsWithAppointment }) => {
    return (
      <Review
        id={item._id}
        isClientReview
        firstname={!item.isAnonymous && item.user.firstname ? item.user.firstname : 'Recenzie'}
        lastname={!item.isAnonymous && item.user.lastname ? item.user.lastname : 'Anonimizata'}
        image={item.isAnonymous ? AnonymousProfile.medium : item.user.profilePhoto?.medium}
        score={item.feedback.score}
        review={item.feedback.review}
        replies={item.replies}
        clientAppointment={item.isAnonymous ? undefined : item.clientAppointment}
        onSelect={() => onShowReviewMenu(item)}
        onAddReply={() => onShowReplyDialog(item)}
        canAddReply={canManageAllReviews}
      />
    );
  };

  const keyExtractor = (item: PublicFeedbackDetailsWithAppointment): string => item._id;

  const listHeader = () => {
    if (clientReviews.length === 0) {
      return null;
    }

    return (
      <>
        {HeaderElement}
        {workers && workers.length > 1 && (
          <>
            <Select value={selectedWorker} onChange={onReviewWorkerFilterChange} items={workers} />
            <Spacer size={24} />
          </>
        )}
        <Row alignItems="center">
          {/* TODO: this should show the average score and the total reviews for the selected client
            Implement this when we have the average score and the total reviews for the selected client
          */}
          {/* <Row style={{ alignItems: 'center', flex: 1 }}>
            {reviewsFilter !== 'allStars' ? (
              filterReviews && filterReviews >= 0 ? (
                <>
                  <StarIcon />
                  <Body style={{ fontFamily: 'open-sans-bold', paddingLeft: 8, paddingRight: 4 }}>
                    {filterReviews.toFixed(1)}
                  </Body>
                  <Body style={{ color: colors.BLACK }}>{t('totalReviews', { count: filterReviews })}</Body>
                </>
              ) : null
            ) : (
              <>
                <StarIcon />
                <Body style={{ fontFamily: 'open-sans-bold', paddingLeft: 8, paddingRight: 4 }}>
                  {selectedWorker ? averageScore : pageFeedbackScore}
                </Body>
                <Body style={{ color: colors.BLACK }}>
                  {t('totalReviews', { count: Object.values(scoreCounts ?? {}).reduce((a, b) => a + b, 0) })}
                </Body>
              </>
            )}
          </Row> */}
          <Row flex={1} />
          <Pressable onPress={onShowStarsFilters} style={styles.filterButton}>
            <SmallBody style={styles.filterButtonText}>{t(reviewsFilter)}</SmallBody>
            <Column style={styles.filterButtonIcon}>
              <Icon type="dropdown" color={colors.DARK_BLUE} />
            </Column>
          </Pressable>
        </Row>
      </>
    );
  };

  return (
    <FlatList
      scrollEnabled={false}
      style={styles.container}
      ListHeaderComponent={listHeader}
      contentContainerStyle={[styles.contentContainer]}
      data={clientReviews}
      keyboardShouldPersistTaps="handled"
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      removeClippedSubviews
      windowSize={11}
      ListEmptyComponent={
        <ClientEmptyListView title="Nu există recenzii" subtitle="Recenziile clientului vor fi afișate aici">
          <Spacer size="32" />
        </ClientEmptyListView>
      }
    />
  );
};

export default ClientReviewsList;
