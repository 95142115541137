import * as React from 'react';
import { Pressable } from 'react-native';

import Icon from '../../../../../components/Icon';
import { Body, Column, Row, SmallBody } from '../../../../../components/shared';

import { NoteBookingDetails } from '../../../../../contexts/NoteContext';
import { colors } from '../../../../../styles';
import { getAppointmentDateForNote } from '../../../../../utils/booking';
import styles from './styles';

type NoteBookingCardProps = {
  onPress?: () => void;
  onClearNoteBooking: () => void;
  booking?: NoteBookingDetails;
};

const NoteBookingCard = ({ onPress, booking, onClearNoteBooking }: NoteBookingCardProps) => {
  const date = getAppointmentDateForNote(booking);

  return (
    <Pressable onPress={onPress} style={styles.container}>
      <Row justifyContent="space-between">
        <Column>
          <Body style={styles.title}>Programare asociată</Body>
          {!booking?._id && <SmallBody style={styles.subtitle}>Alege programare</SmallBody>}
          {booking?._id ? (
            <Column>
              <SmallBody>{date}</SmallBody>
              {booking.worker && (
                <SmallBody style={styles.subtitle}>{`${booking.worker.firstname} ${
                  booking.worker.lastname ?? ''
                }`}</SmallBody>
              )}
            </Column>
          ) : null}
        </Column>
        <Pressable onPress={booking?._id ? onClearNoteBooking : onPress}>
          <Icon name={booking?._id ? 'Close' : 'Arrow'} color={colors.DARK_BLUE} />
        </Pressable>
      </Row>
    </Pressable>
  );
};

export default NoteBookingCard;
