import React from 'react';
import { Svg, Path } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

const Dots = ({ color, size = 24 }: IconProps) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        opacity="0.9"
        d="M6 13.838C7.10457 13.838 8 12.9788 8 11.919C8 10.8592 7.10457 10 6 10C4.89543 10 4 10.8592 4 11.919C4 12.9788 4.89543 13.838 6 13.838Z"
        fill={color || 'white'}
      />
      <Path
        opacity="0.9"
        d="M19 13.838C20.1046 13.838 21 12.9788 21 11.919C21 10.8592 20.1046 10 19 10C17.8954 10 17 10.8592 17 11.919C17 12.9788 17.8954 13.838 19 13.838Z"
        fill={color || 'white'}
      />
      <Path
        opacity="0.9"
        d="M12.5 13.838C13.6046 13.838 14.5 12.9788 14.5 11.919C14.5 10.8592 13.6046 10 12.5 10C11.3954 10 10.5 10.8592 10.5 11.919C10.5 12.9788 11.3954 13.838 12.5 13.838Z"
        fill={color || 'white'}
      />
    </Svg>
  );
};

export default Dots;
