import { Title } from '@mero/components';
import * as React from 'react';
import { Pressable, TouchableOpacity, View } from 'react-native';

import Line from '@mero/components/lib/components/Line';
import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import SimpleListItem from '@mero/components/lib/components/SimpleListItem';

import { styles } from './styles';

interface DeleteNoteOptionsProps {
  onDismiss: () => void;
  onDeleteNoteWithImages: () => void;
  onDeleteNoteWithoutImages: () => void;
}

const DeleteNoteOptions: React.FC<DeleteNoteOptionsProps> = ({
  onDismiss,
  onDeleteNoteWithImages,
  onDeleteNoteWithoutImages,
}) => {
  return (
    <ModalOverlay>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <View style={styles.optionsListContainer}>
        <SafeAreaView edges={['bottom']}>
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onDeleteNoteWithImages}>
            <SimpleListItem>
              <Title style={styles.deleteText}>Șterge notiță și imagini</Title>
            </SimpleListItem>
          </TouchableOpacity>
          <Line />
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onDeleteNoteWithoutImages}>
            <SimpleListItem>
              <Title style={styles.deleteText}>Șterge notiță și păstrează imagini</Title>
            </SimpleListItem>
          </TouchableOpacity>
        </SafeAreaView>
      </View>
    </ModalOverlay>
  );
};

export default DeleteNoteOptions;
