import { capitalize, DayTime, SavedWorker, Worker } from '@mero/api-sdk';
import { AppointmentId } from '@mero/api-sdk/dist/calendar';
import { DateWorkingSchedule } from '@mero/api-sdk/dist/pro/workingSchedule/dateWorkingSchedule';
import { WorkerId } from '@mero/api-sdk/dist/workers';
import {
  Avatar,
  Body,
  colors,
  Column,
  FormCard,
  H1,
  Header,
  Icon,
  Line,
  Row,
  SmallBody,
  Spacer,
  Title,
  useShowError,
} from '@mero/components';
import { DateString } from '@mero/shared-sdk';
import { pipe } from 'fp-ts/lib/function';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity, View } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { AppContext } from '../../../../../contexts/AppContext';
import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, ProsEditStackParamList } from '../../../../../types';
import {
  dayTimeSum,
  DEFAULT_TIMEZONE,
  intervalDiff,
  intervalToString,
  isDateInCurrentWeek,
} from '../../../../../utils/time';
import ActiveIntervalView, { getWeekIntervals, Interval } from '../PageReportsScreen/ActiveIntervalView';
import InvitedProWarning from './InvitedProWarning';
import { styles } from './ProScheduleScreen.styles';
import AddNew from './Schedule/AddNew';
import ScheduleButton from './Schedule/ScheduleButton';
import { DEFAULT_DAY_TIME, WorkerAndMember } from './Schedule/ScheduleMobile';

type ScheduleRecord = DateWorkingSchedule['records'][number];

type WorkerSchedule = {
  worker: CurrentBusinessProps['page']['workers'][number];
  schedule: Record<DateWorkingSchedule['date'], ScheduleRecord[]>;
};

type WorkerScheduleWithTotal = WorkerSchedule & { total: DayTime };

type Schedule = Extract<ScheduleRecord, { type: 'Schedule' }>;

export type Props = StackScreenProps<AuthorizedStackParamList & ProsEditStackParamList, 'ProSchedule'> &
  CurrentBusinessProps;

const ProScheduleScreen: React.FC<Props> = ({ navigation, page, route }) => {
  const goBack = useGoBack();
  const { t } = useTranslation('pros');
  const showError = useShowError();
  const isFocused = useIsFocused();

  const [appState] = AppContext.useContext();

  const changePastWorkingSchedule = React.useMemo(
    () => appState.type === 'Loaded' && appState.featureFlags.changePastWorkingSchedule?.enabled,
    [appState],
  );

  const { workerId } = route.params;

  const lastRequest = React.useRef<symbol>();

  const [pro, setPro] = React.useState<WorkerAndMember>();
  const [isLoading, setIsLoading] = React.useState(false);

  const now = React.useMemo(() => DateTime.now().setZone(DEFAULT_TIMEZONE).startOf('day'), []);

  const defaultInterval = React.useMemo(() => getWeekIntervals()[0], []);
  const [activeInterval, setActiveInterval] = React.useState<Interval>(defaultInterval);

  const [schedule, setSchedule] = React.useState<WorkerScheduleWithTotal[]>([]);

  const loadSchedule = async (worker?: SavedWorker) => {
    setIsLoading(true);
    try {
      const request = Symbol('');
      lastRequest.current = request;

      const day = activeInterval.value.start.startOf('week');
      const interval = {
        from: DateString.fromDate(day.toJSDate(), DEFAULT_TIMEZONE),
        to: DateString.fromDate(day.plus({ days: 6 }).toJSDate(), DEFAULT_TIMEZONE),
      };

      const workersSchedule = await meroApi.pro.workingSchedule.getWorkersSchedules({
        pageId: page.details._id,
        interval,
        workerIds: [workerId],
      });

      if (lastRequest.current !== request) {
        return;
      }

      const data = workersSchedule
        .filter((s) => s.workerId === workerId)
        .reduce((acc, workerSchedule) => {
          if (!worker) {
            return acc;
          }

          return [
            ...acc,
            {
              worker: worker,
              total: workerSchedule.days.reduce(
                (acc, day) =>
                  dayTimeSum(
                    acc,
                    day.records.reduce(
                      (acc, record) =>
                        record.type === 'Schedule' && record.schedule.active
                          ? dayTimeSum(
                              acc,
                              record.schedule.intervals.reduce(
                                (acc, interval) => dayTimeSum(acc, intervalDiff(interval)),
                                DEFAULT_DAY_TIME,
                              ),
                            )
                          : acc,
                      DEFAULT_DAY_TIME,
                    ),
                  ),
                DEFAULT_DAY_TIME,
              ),
              schedule: workerSchedule.days.reduce(
                (acc, day) => ({ ...acc, [day.date]: day.records }),
                {} as WorkerSchedule['schedule'],
              ),
            },
          ];
        }, [] as WorkerScheduleWithTotal[]);

      setSchedule(data);
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    const worker = page.workers.find((worker) => worker._id === workerId);
    const member = page.members.find((member) => member.user._id === worker?.user._id);

    if (worker && member) {
      setPro({
        worker,
        member,
      });
    }
  }, []);

  React.useEffect(() => {
    if (isFocused) {
      loadSchedule(pro?.worker);
    }
  }, [pro, activeInterval, isFocused]);

  const onAddNewInterval = (payload: { workerId: WorkerId; day: DateString }) => {
    navigation.navigate('Schedule', { screen: 'SetSchedule', params: { ...payload, type: 'new_interval' as const } });
  };

  const onEditInterval = (payload: { workerId: WorkerId; day: DateString }) => {
    navigation.navigate('Schedule', { screen: 'SetSchedule', params: { ...payload, type: 'edit_interval' as const } });
  };

  const onAddNewSchedule = (payload: { workerId: WorkerId; day: DateString }) => {
    navigation.navigate('Schedule', { screen: 'SetSchedule', params: { ...payload, type: 'new_schedule' as const } });
  };

  const onEditSchedule = (payload: { workerId: WorkerId; day: DateString }) => {
    navigation.navigate('Schedule', { screen: 'SetSchedule', params: { ...payload, type: 'edit_schedule' as const } });
  };

  const onAddBlockTime = (payload: { workerId: WorkerId; day: DateString }) => {
    navigation.navigate('Booking', {
      screen: 'BlockedTimeCreateScreen',
      params: { date: DateString.toDateTime(payload.day, DEFAULT_TIMEZONE).toISO(), workerId: payload.workerId },
    });
  };

  const onEditBlockTime = (payload: { day: DateString; appointmentId: AppointmentId; occurrenceIndex: number }) => {
    navigation.navigate('Booking', {
      screen: 'BlockedTimeEditScreen',
      params: {
        calendarId: undefined,
        calendarEntryId: payload.appointmentId,
        occurrenceIndex: `${payload.occurrenceIndex}`,
        start: DateString.toDateTime(payload.day, DEFAULT_TIMEZONE).toISO(),
      },
    });
  };

  const isEditable = React.useMemo(() => (pro ? !Worker.isInvited(pro.worker, page.details) : true), [pro]);

  const startOfWeek = React.useMemo(() => activeInterval.value.start.startOf('week'), [activeInterval]);
  const weekDays = React.useMemo(
    () => Array.from({ length: 7 }, (_, i) => startOfWeek.plus({ day: i })),
    [startOfWeek],
  );

  const weekDaysString = React.useMemo(
    () => weekDays.map((date) => DateString.fromDate(date.toJSDate(), DEFAULT_TIMEZONE)),
    [weekDays],
  );

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('proSchedule')}
        RightComponent={() => (
          <Column style={{ paddingRight: 24 }}>
            <Avatar
              size={32}
              source={pro?.worker.profilePhoto?.thumbnail}
              firstname={pro?.worker.user.firstname ?? ''}
              lastname={pro?.worker.user.lastname ?? ''}
            />
          </Column>
        )}
      />
      {pro && (
        <ScrollView>
          <Column style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1, paddingBottom: 92 }}>
            <H1 style={{ paddingHorizontal: 8 }}>{t('proSchedule')}</H1>
            {!isEditable && (
              <>
                <Spacer size={32} />
                <InvitedProWarning />
                <Spacer size={8} />
              </>
            )}
            <Body style={{ padding: 8 }}>
              <Trans
                ns={'pros'}
                t={t}
                i18nKey="proScheduleDescription"
                values={{ name: `${pro.worker.user.firstname} ${pro.worker.user.lastname}` }}
              >
                0<Title style={{ fontFamily: 'open-sans-semibold' }}>1</Title>2
              </Trans>
            </Body>
            <Spacer size={24} />
            <Row>
              <ActiveIntervalView
                withFullCurrentMonth
                activeInterval={activeInterval}
                setActiveInterval={setActiveInterval}
                showFutureDates={true}
                type="week"
                fullWidth
                headerTitle={t('selectPeriod')}
                title={t('selectPeriod')}
              />
            </Row>
            <Spacer size={16} />
            <FormCard rounded dropShaddow paddings="none" style={{ paddingVertical: 16 }}>
              {schedule.map(({ schedule, worker }) => (
                <React.Fragment key={worker._id}>
                  {weekDaysString.map((date, index) => {
                    const records = schedule[date] ?? [];
                    const hasSchedule = records.some(
                      (r) => (r.type === 'Schedule' && r.schedule.active) || r.type === 'BlockedTime',
                    );

                    const isPast = DateString.toDateTime(date, DEFAULT_TIMEZONE).startOf('day') < now;
                    const isCurrentWeek = isDateInCurrentWeek(date);

                    const total = records
                      .filter((r): r is Schedule => r.type === 'Schedule' && r.schedule.active)
                      .reduce((acc1, record) => {
                        if (record.schedule.active) {
                          return dayTimeSum(
                            acc1,
                            record.schedule.intervals.reduce((acc2, interval) => {
                              return dayTimeSum(acc2, intervalDiff(interval));
                            }, DEFAULT_DAY_TIME),
                          );
                        }
                        return acc1;
                      }, DEFAULT_DAY_TIME);

                    return (
                      <Column key={date}>
                        <Row style={{ paddingHorizontal: 16 }}>
                          <Column style={{ flex: 1 }}>
                            <Title>{capitalize(weekDays[index].toFormat('ccc dd MMM', { locale: 'ro' }))}</Title>
                            <SmallBody style={{ color: colors.COMET }}>
                              {hasSchedule
                                ? t(total.minute ? 'dayTimeWithMin' : 'dayTime', {
                                    hour: total.hour,
                                    minute: total.minute,
                                  })
                                : t('noSchedule')}
                            </SmallBody>
                          </Column>
                          <Column style={{ minWidth: 127 }}>
                            {records.map((record, recordIndex) => {
                              switch (record.type) {
                                case 'Schedule':
                                  return record.schedule.active
                                    ? record.schedule.intervals.map((interval, index) => (
                                        <React.Fragment key={`${interval.from.hour}_${index}`}>
                                          {record.schedule.active && index > 0 && <Spacer size={4} />}
                                          <ScheduleButton
                                            isCurrentWeek={isCurrentWeek}
                                            page={page.details}
                                            day={date}
                                            pro={pro}
                                            isPast={isPast}
                                            schedule={record}
                                            interval={interval}
                                            onEditInterval={() => {
                                              onEditInterval({ workerId: pro.worker._id, day: date });
                                            }}
                                            onEditSchedule={() =>
                                              onEditSchedule({
                                                workerId: pro.worker._id,
                                                day: date,
                                              })
                                            }
                                            style={{
                                              button: { fontSize: 14 },
                                            }}
                                            onDeleteSchedule={() => loadSchedule(pro.worker)}
                                          />
                                        </React.Fragment>
                                      ))
                                    : null;
                                case 'BlockedTime':
                                  return (
                                    <React.Fragment key={record.blockedTime._id}>
                                      {recordIndex > 0 && <Spacer size={4} />}
                                      <TouchableOpacity
                                        key={record.blockedTime._id}
                                        style={[styles.blockedTime, styles.blockedTimeButtonMobile]}
                                        onPress={() =>
                                          onEditBlockTime({
                                            day: date,
                                            appointmentId: record.blockedTime._id,
                                            occurrenceIndex: record.blockedTime.occurrenceIndex ?? 0,
                                          })
                                        }
                                        disabled={!isEditable}
                                      >
                                        <SmallBody style={[styles.blockedTimeText]}>
                                          {intervalToString(record.blockedTime.interval)}
                                        </SmallBody>
                                      </TouchableOpacity>
                                    </React.Fragment>
                                  );
                              }
                            })}
                            {hasSchedule && isEditable && <Spacer size={4} />}
                            {isEditable && (!isPast || changePastWorkingSchedule) ? (
                              <AddNew
                                isHovered
                                skipHover
                                isInvited={Worker.isInvited(worker, page.details)}
                                onAddNewInterval={() => onAddNewInterval({ workerId: worker._id, day: date })}
                                onAddNewSchedule={() => onAddNewSchedule({ workerId: worker._id, day: date })}
                                onBlockTime={() => onAddBlockTime({ workerId: worker._id, day: date })}
                              />
                            ) : null}
                          </Column>
                        </Row>
                        {index < weekDaysString.length - 1 && (
                          <>
                            <Spacer size={16} />
                            <Line />
                            <Spacer size={16} />
                          </>
                        )}
                      </Column>
                    );
                  })}
                </React.Fragment>
              ))}
            </FormCard>
          </Column>
          <Spacer size="16" />
        </ScrollView>
      )}
    </ModalScreenContainer>
  );
};

export default pipe(ProScheduleScreen, CurrentBusiness);
