import { AppEventsContext } from '@/contexts/AppEvents';
import { getBookingDateForNote } from '@/utils/booking';
import { DEFAULT_TIMEZONE } from '@/utils/time';
import { NoteId } from '@mero/api-sdk/dist/pro/notes/noteId';
import { NonEmptyString } from 'io-ts-types';
import * as React from 'react';
import { useCallback } from 'react';
import { Pressable, ScrollView, View } from 'react-native';

import ModalScreenContainer from '../../../../components/ModalScreenContainer';
import {
  Body,
  Button,
  Column,
  FormCard,
  H1,
  MeroHeader,
  Row,
  SafeAreaView,
  SelectableListItem,
  Title,
} from '../../../../components/shared';
import TextInput from '../../../../components/shared/TextInput';
import ClientConfirmNoteDeleteModal from '../../ClientDetailsScreen/components/ClientConfirmNoteDeleteModal';

import { CompositeNavigationProp, RouteProp } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';

import { useEscPressWeb } from '../../../../hooks/useEscPressWeb';
import useGoBack from '../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../hooks/useMediaQueries';

import { ClientImageDetailsContext } from '../../../../contexts/ClientImageDetailsContext';
import { ClientImageStackParamList, RootStackParamList } from '../../../../types';
import styles from './styles';

type ClientImageNoteScreenProps = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<RootStackParamList, 'Authorized'>,
    StackNavigationProp<ClientImageStackParamList, 'ClientImageNoteScreen'>
  >;
  route: RouteProp<ClientImageStackParamList, 'ClientImageNoteScreen'>;
};

const ClientImageNoteScreen: React.FC<ClientImageNoteScreenProps> = ({ route, navigation }) => {
  const [, { pushEvent }] = AppEventsContext.useContext();
  const [
    { client, selectedNote, selectedBooking, noteText },
    { createClientImageNote, removeClientNote, clearBooking, setNoteText, updateClientImageNote },
  ] = ClientImageDetailsContext.useContext();
  const [showClientNoteDeleteConfirmModal, setShowClientNoteDeleteConfirmModal] = React.useState<
    | {
        noteId: NoteId;
        deleteNoteImages: boolean;
      }
    | undefined
  >(undefined);
  const { isPhone } = useMediaQueries();

  const imageId = route.params.imageId;

  const goBack = useGoBack();
  useEscPressWeb({
    onPress: goBack,
  });

  const handleSaveNote = useCallback(() => {
    if (noteText && imageId) {
      createClientImageNote(noteText, imageId);
      pushEvent({
        type: 'PageClientNoteCreated',
      });
      goBack();
    }
  }, [noteText, imageId, createClientImageNote]);

  const handleUpdateNote = useCallback(() => {
    if (noteText && imageId) {
      updateClientImageNote(noteText, imageId);
      pushEvent({
        type: 'PageClientNoteUpdated',
      });
      goBack();
    }
  }, [noteText, imageId, updateClientImageNote]);

  const goToClientBookingsList = () => {
    if (selectedBooking?._id) {
      return;
    }

    if (client?._id) {
      navigation.navigate('ClientBookingsListScreen', {
        clientId: client._id,
      });
    }
  };

  const handleDeleteNote = async (withImages: boolean) => {
    setShowClientNoteDeleteConfirmModal(undefined);
    await removeClientNote(withImages);
    goBack();
  };

  return (
    <ModalScreenContainer style={styles.root}>
      <MeroHeader canGoBack onBack={goBack} title="Adaugă notiță" />
      <ScrollView style={styles.container}>
        <View style={styles.header}>
          <H1>{selectedNote ? 'Modifică notiță' : 'Adaugă notiță'}</H1>
          <Body>Notița va fi vizibilă doar intern, de către profesioniștii cu acces la fișa clientului</Body>
        </View>
        <Column style={styles.content}>
          <Column gap={8}>
            <Body style={styles.title}>Detalii</Body>
            <TextInput
              value={noteText}
              onChange={(value) => setNoteText(value as NonEmptyString)}
              placeholder="Adaugă detalii…"
              styles={{ textInput: styles.input }}
              multiline
              numberOfLines={7}
              maxLength={1000}
            />
          </Column>
          <SelectableListItem
            onPress={goToClientBookingsList}
            onClear={clearBooking}
            title="Programare asociată"
            value={selectedBooking ? getBookingDateForNote(selectedBooking?.start, DEFAULT_TIMEZONE) : undefined}
            valueSubtitle={
              selectedBooking ? `${selectedBooking.worker?.firstname} ${selectedBooking.worker?.lastname}` : undefined
            }
            subtitle="Alege o programare"
          />
        </Column>
        {selectedNote && (
          <Pressable
            style={styles.deleteButton}
            onPress={() =>
              setShowClientNoteDeleteConfirmModal({
                noteId: selectedNote._id,
                deleteNoteImages: false, // TODO: add this when we have images
              })
            }
          >
            <Row justifyContent="center">
              <Title style={styles.deleteText}>Șterge notiță</Title>
            </Row>
          </Pressable>
        )}
      </ScrollView>
      <FormCard dropShaddow paddings="button" style={[styles.footer, !isPhone && styles.modalBorderBottom]}>
        <SafeAreaView edges={['bottom']}>
          <Button text="Salvează modificări" onPress={selectedNote ? handleUpdateNote : handleSaveNote} />
        </SafeAreaView>
      </FormCard>
      {showClientNoteDeleteConfirmModal !== undefined ? (
        <ClientConfirmNoteDeleteModal
          noteId={showClientNoteDeleteConfirmModal.noteId}
          deleteNoteImages={showClientNoteDeleteConfirmModal.deleteNoteImages}
          onDismiss={() => {
            setShowClientNoteDeleteConfirmModal(undefined);
          }}
          onConfirm={() => {
            setShowClientNoteDeleteConfirmModal(undefined);
            handleDeleteNote(showClientNoteDeleteConfirmModal.deleteNoteImages);
          }}
        />
      ) : null}
    </ModalScreenContainer>
  );
};

export default ClientImageNoteScreen;
