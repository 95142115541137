import { NoteDetails, NoteId } from '@mero/api-sdk';
import { Button, colors, Spacer } from '@mero/components';
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Pressable, Text } from 'react-native';

import { Ref as FloatMenuRef } from '../../../../../../components/FloatMenu';
import { Column, Row, Title } from '../../../../../../components/shared';
import ClientEmptyListView from '../../../components/ClientEmptyListView';

import ClientNoteCard from '../ClientNoteCard';
import styles from './styles';

interface ClientNotesListProps {
  readonly notes: NoteDetails[];
  readonly onAddNote: () => void;
  readonly onEditNote: (note: NoteDetails) => void;
  readonly onDeleteNote: (note: NoteDetails, deleteNoteImages: boolean) => void;
  onUpdatePinnedState: (pinnedState: boolean, noteId: NoteId) => void;
  onEndReached?: () => void;
}

const ClientNotesList: React.FC<ClientNotesListProps> = ({
  notes,
  onAddNote,
  onEditNote,
  onDeleteNote,
  onUpdatePinnedState,
  onEndReached,
}) => {
  const { t } = useTranslation('clients');
  const notesFloatMenuRef = React.useRef<FloatMenuRef | null>(null);
  const handleEditPress = useCallback((note: NoteDetails) => {
    notesFloatMenuRef.current?.onDismissMenu();
    onEditNote(note);
  }, []);

  const handleDeletePress = useCallback((note: NoteDetails, deleteNoteImages: boolean) => {
    notesFloatMenuRef.current?.onDismissMenu();
    onDeleteNote(note, deleteNoteImages);
  }, []);

  const handleUpdatePinnedState = useCallback((pinnedState: boolean, noteId: NoteId) => {
    notesFloatMenuRef.current?.onDismissMenu();
    onUpdatePinnedState(pinnedState, noteId);
  }, []);

  const renderItem = React.useCallback(({ item }: { item: NoteDetails }) => {
    return (
      <ClientNoteCard
        onUpdatePinnedState={handleUpdatePinnedState}
        notesFloatMenuRef={notesFloatMenuRef}
        onDelete={handleDeletePress}
        onEdit={handleEditPress}
        note={item}
      />
    );
  }, []);

  const keyExtractor = (note: NoteDetails): string => note._id;

  const listHeaderComponent = useCallback(() => {
    if (!notes.length) {
      return null;
    }

    return (
      <Column>
        <Row justifyContent="space-between" alignItems="center">
          <Text style={styles.contentTitle}>{t('journal')}</Text>

          <Pressable onPress={onAddNote}>
            <Title style={{ color: colors.DARK_BLUE }}>{t('addNote')}</Title>
          </Pressable>
        </Row>
      </Column>
    );
  }, [notes.length]);

  return (
    <FlatList
      style={styles.container}
      contentContainerStyle={styles.contentContainer}
      ListHeaderComponent={listHeaderComponent}
      data={notes}
      keyboardShouldPersistTaps="handled"
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      removeClippedSubviews
      windowSize={20}
      scrollEnabled={true}
      onEndReached={onEndReached}
      onEndReachedThreshold={0.5}
      ListEmptyComponent={
        <ClientEmptyListView title="Nu există notițe" subtitle="Notițele clientului vor fi afișate aici">
          <Spacer size="32" />
          <Button
            text="Adaugă notiță"
            onPress={onAddNote}
            size="medium"
            color={colors.DARK_BLUE}
            backgroundColor={colors.SKY_BLUE}
            containerStyle={styles.addNoteButton}
          />
        </ClientEmptyListView>
      }
    />
  );
};

export default ClientNotesList;
