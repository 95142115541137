import { MembershipPurchaseId } from '@mero/api-sdk';
import { ClientId } from '@mero/api-sdk/dist/clients';
import { PurchasedMembership } from '@mero/api-sdk/dist/memberships/purchasedMembership';
import { PageId } from '@mero/api-sdk/dist/pages';
import { Button, colors } from '@mero/components';
import { MeroUnits } from '@mero/shared-sdk';
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Pressable, Text } from 'react-native';

import { Column, Row, Spacer, Title } from '../../../../../../components/shared';
import ClientEmptyListView from '../../../components/ClientEmptyListView';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import { ClientStackParamList } from '../../../../../../types';
import ClientMembershipCard from '../ClientMembershipCard';
import styles from './styles';

interface ClientMembershipsListProps {
  memberships: PurchasedMembership<MeroUnits.Any>[];
  clientId: ClientId;
  pageId: PageId;
  onEndReached: () => void;
  onNewMembership: () => void;
  onMembershipPress: (pageId: PageId, clientId: ClientId, membershipId: MembershipPurchaseId) => void;
}

const ClientMembershipsList: React.FC<ClientMembershipsListProps> = ({
  memberships,
  clientId,
  pageId,
  onEndReached,
  onNewMembership,
  onMembershipPress,
}) => {
  const { t } = useTranslation('clients');

  const handleMembershipPress = (membership: PurchasedMembership<MeroUnits.Any>) => {
    onMembershipPress(pageId, clientId, membership._id);
  };

  const renderItem = React.useCallback(({ item }: { item: PurchasedMembership<MeroUnits.Any> }) => {
    return <ClientMembershipCard onPress={() => handleMembershipPress(item)} item={item} clientId={clientId} />;
  }, []);

  const keyExtractor = (item: PurchasedMembership<MeroUnits.Any>): string => item._id;

  const listHeaderComponent = useCallback(() => {
    if (!memberships.length) {
      return null;
    }

    return (
      <Column>
        <Row justifyContent="space-between" alignItems="center">
          <Text style={styles.contentTitle}>{t('memberships')}</Text>

          <Pressable onPress={onNewMembership}>
            <Title style={{ color: colors.DARK_BLUE }}>{t('addMembership')}</Title>
          </Pressable>
        </Row>
        <Spacer size={16} />
      </Column>
    );
  }, [memberships.length]);

  return (
    <FlatList
      style={styles.container}
      contentContainerStyle={styles.contentContainer}
      ListHeaderComponent={listHeaderComponent}
      data={memberships}
      keyboardShouldPersistTaps="handled"
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      removeClippedSubviews
      onEndReachedThreshold={16}
      onEndReached={onEndReached}
      windowSize={11}
      ListEmptyComponent={
        <ClientEmptyListView title="Nu există abonamente" subtitle="Abonamentele clientului vor fi afișate aici">
          <Spacer size="32" />
          <Button
            text={t('addMembership')}
            onClick={onNewMembership}
            size="medium"
            color={colors.DARK_BLUE}
            backgroundColor={colors.SKY_BLUE}
            containerStyle={styles.addNoteButton}
          />
        </ClientEmptyListView>
      }
      ListFooterComponent={() => <Spacer size={32} />}
    />
  );
};

export default ClientMembershipsList;
