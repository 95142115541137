import { AppError } from './appError';
import { DecodeError } from './decodeError';
import { Logger } from './logger';
import { MeroAppPlatform } from './meroAppPlatform';
import { MeroAppType } from './meroAppType';
import { NetworkError } from './networkError';
import { isDefined } from './utils';
import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import * as E from 'fp-ts/lib/Either';
import { identity, flow, absurd, pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import io from 'socket.io-client';
import SocketIOClient from 'socket.io-client';

export { SocketIOClient };
export type AxiosResponseDecoder<A, O = A> = t.Type<A, O, AxiosResponse<unknown>>;
export type MixedAxiosResponseDecoder = AxiosResponseDecoder<any>;
export type SocketHandler<A, T extends t.Decoder<any, any>> = {
  name: string;
  handler: <A>(v: A) => void;
  codec: T;
};

enum DefaultEvents {
  connect = 'connect',
  authenticated = 'authenticated',
  unauthorized = 'unauthorized',
  disconnect = 'disconnect',
}

export type DefaultSocketEvents<A> = {
  [k in DefaultEvents]: <A>(v: A) => void;
};

export type HttpClient = {
  /**
   * Execute http request and decode response data
   */
  readonly request: <C extends AxiosResponseDecoder<any>>(config: AxiosRequestConfig, codec: C) => Promise<t.TypeOf<C>>;

  /**
   * Enables socket io connection
   */
  readonly socket: <A, T extends t.Decoder<any, any>>(
    config: { route: string; query?: Record<string, string> },
    handlers: SocketHandler<A, T>[],
  ) => Promise<SocketIOClient.Socket>;

  readonly decode: {
    /**
     * Decodes response body using given codec
     */
    readonly data: <A extends t.Mixed>(codec: A) => AxiosResponseDecoder<t.TypeOf<A>, t.OutputOf<A>>;

    /**
     * Decodes response using right codec first then tries the left one
     * IMPORTANT: right codec is tried first as Either is right biased
     */
    readonly either: <L extends MixedAxiosResponseDecoder, R extends MixedAxiosResponseDecoder>(
      left: L,
      right: R,
    ) => AxiosResponseDecoder<E.Either<t.TypeOf<L>, t.TypeOf<R>>, E.Either<t.OutputOf<L>, t.OutputOf<R>>>;

    /**
     * Decodes responses with 2xx OR 404 status using success codec and using error codec otherwise
     *
     * `undefined` value is passed to success codec when status is 404
     *
     * @param error used to decode error responses (status !== 2xx)
     * @param success used to decode success responses (status === 2xx || status === 404)
     */
    readonly optionalResponse: <L, R, LO = L, RO = R>(
      error: t.Type<L, LO, unknown>,
      success: t.Type<R, RO, unknown>,
    ) => AxiosResponseDecoder<E.Either<L, R>, E.Either<LO, RO>>;

    /**
     * Decodes responses with 2xx status using success codec and using error codec otherwise
     *
     * @param error used to decode error responses (status !== 2xx)
     * @param success used to decode success responses (status === 2xx)
     */
    readonly response: <L, R, LO = L, RO = R>(
      error: t.Type<L, LO, unknown>,
      success: t.Type<R, RO, unknown>,
    ) => AxiosResponseDecoder<E.Either<L, R>, E.Either<LO, RO>>;
  };
};

export type AuthStorage<T> = {
  /**
   * Read grant T
   */
  readonly read: () => Promise<T | undefined>;
  /**
   * Refresh grant
   */
  readonly refresh: (grant: T) => Promise<T | undefined>;
  /**
   * Format authorization header using grant T
   */
  readonly formatHeader: (grant: T) => string;
  /**
   * Get access token from grant
   */
  readonly getToken: (grant: T) => string;
};

export const emptyAuth: AuthStorage<never> = {
  read: async () => undefined,
  refresh: absurd,
  formatHeader: absurd,
  getToken: absurd,
};

const data: HttpClient['decode']['data'] = <A extends t.Mixed>(codec: A) =>
  new t.Type<t.TypeOf<A>, t.OutputOf<A>, AxiosResponse<unknown>>(
    `From<AxiosResponse, ${codec.name}>`,
    codec.is,
    (r) => codec.decode(r.data),
    codec.encode,
  );

const unknownLeft = t.type({
  _tag: t.literal('Left'),
  left: t.unknown,
});

const unknownRight = t.type({
  _tag: t.literal('Left'),
  right: t.unknown,
});

const either: HttpClient['decode']['either'] = <
  L extends MixedAxiosResponseDecoder,
  R extends MixedAxiosResponseDecoder,
>(
  left: L,
  right: R,
) =>
  new t.Type<E.Either<t.TypeOf<L>, t.TypeOf<R>>, E.Either<t.OutputOf<L>, t.OutputOf<R>>, AxiosResponse<unknown>>(
    `Either<${left.name},${right.name}>`,
    (u): u is E.Either<t.TypeOf<L>, t.TypeOf<R>> =>
      (unknownLeft.is(u) && left.is(u.left)) || (unknownRight.is(u) && right.is(u.right)),
    (r) =>
      pipe(
        r,
        right.decode,
        E.fold(
          (re) =>
            pipe(
              r,
              left.decode,
              E.fold(
                (le) =>
                  t.failures(
                    re
                      .map(
                        ({ message, ...rest }): t.ValidationError => ({
                          ...rest,
                          message: `Failed to decode Right<${right.name}>${message ? ` : ${message}` : ''}`,
                        }),
                      )
                      .concat(
                        le.map(
                          ({ message, ...rest }): t.ValidationError => ({
                            ...rest,
                            message: `Failed to decode Left<${left.name}>${message ? ` : ${message}` : ''}`,
                          }),
                        ),
                      ),
                  ),
                (r) => E.right(E.left(r)),
              ),
            ),
          (l) => E.right(E.right(l)),
        ),
      ),
    E.fold(
      (l) => E.left(left.encode(l)),
      (r) => E.right(right.encode(r)),
    ),
  );

const optionalResponse: HttpClient['decode']['optionalResponse'] = <L, R, LO = L, RO = R>(
  error: t.Type<L, LO, unknown>,
  success: t.Type<R, RO, unknown>,
) =>
  new t.Type<E.Either<L, R>, E.Either<LO, RO>, AxiosResponse<unknown>>(
    `Either<${error.name},${success.name}>`,
    (u): u is E.Either<L, R> => (unknownLeft.is(u) && error.is(u.left)) || (unknownRight.is(u) && success.is(u.right)),
    (r) => {
      const statusGroup = Math.floor(r.status / 100);

      if (statusGroup === 2) {
        return pipe(r.data, success.decode, E.map(E.right));
      } else if (r.status === 404) {
        return pipe(
          undefined,
          success.decode,
          E.fold(
            () => pipe(r.data, error.decode, E.map(E.left)),
            (a) => E.right(E.right(a)),
          ),
        );
      } else {
        return pipe(r.data, error.decode, E.map(E.left));
      }
    },
    E.fold(
      (l) => E.left(error.encode(l)),
      (r) => E.right(success.encode(r)),
    ),
  );

const response: HttpClient['decode']['response'] = <L, R, LO = L, RO = R>(
  error: t.Type<L, LO, unknown>,
  success: t.Type<R, RO, unknown>,
) =>
  new t.Type<E.Either<L, R>, E.Either<LO, RO>, AxiosResponse<unknown>>(
    `Either<${error.name},${success.name}>`,
    (u): u is E.Either<L, R> => (unknownLeft.is(u) && error.is(u.left)) || (unknownRight.is(u) && success.is(u.right)),
    (r) => {
      const statusGroup = Math.floor(r.status / 100);

      if (statusGroup === 2) {
        return pipe(r.data, success.decode, E.map(E.right));
      } else {
        return pipe(r.data, error.decode, E.map(E.left));
      }
    },
    E.fold(
      (l) => E.left(error.encode(l)),
      (r) => E.right(success.encode(r)),
    ),
  );

export type HttpClientEnv<T> = {
  readonly auth: AuthStorage<T>;
  readonly http: AxiosInstance;
  readonly log: Logger;
  readonly app?: {
    readonly version: string;
    readonly platform: MeroAppPlatform;
    readonly type: MeroAppType;
    readonly onForceUpdate?: () => void;
  };
};

/**
 * Create new HttpClient instance
 */
export const httpClient = <T>(env: HttpClientEnv<T>): HttpClient => {
  const authorizeRequest = async (config: AxiosRequestConfig, grant: T | undefined): Promise<AxiosRequestConfig> => {
    return {
      ...config,
      headers: {
        ...(grant
          ? {
              authorization: env.auth.formatHeader(grant),
            }
          : {}),
        ...(config.headers ?? {}),
        ...(env.app
          ? {
              'x-app-version': env.app.version,
              'x-app-platform': env.app.platform,
              'x-app-type': env.app.type,
            }
          : {}),
      },
    };
  };

  // This response means authorization is not valid (credits to Dragos)
  const invalidAuthResponse = t.type({
    status: t.literal(401),
    data: t.type({
      namespace: t.literal('default'),
      code: t.literal(4),
    }),
  });

  return {
    request: async (config, codec) => {
      try {
        const grant = await env.auth.read();
        const r = await env.http.request<unknown>(await authorizeRequest(config, grant)).catch((e: AxiosError) => {
          if (e.response) {
            return e.response;
          } else {
            throw e;
          }
        });

        try {
          if (isDefined(env.app?.onForceUpdate) && r.status === 426) {
            env?.app?.onForceUpdate();
          }
        } catch (error) {
          env.log.error(
            `HttpClient onForceUpdate handler call failed: ${error instanceof Error ? error.message : String(error)}`,
            { error },
          );
        }

        const parseResponse: (r: AxiosResponse) => t.TypeOf<typeof codec> = flow(
          codec.decode,
          E.fold((e) => {
            throw new DecodeError(e);
          }, identity),
        );

        if (invalidAuthResponse.is(r) && grant !== undefined) {
          try {
            const newGrant = await env.auth.refresh(grant);

            // don't retry request if no token available
            if (newGrant) {
              const r = await env.http.request<unknown>(await authorizeRequest(config, newGrant));

              return parseResponse(r);
            }
          } catch (e) {
            // pass to response
            env.log.exception(e);
          }
        }

        return parseResponse(r);
      } catch (e: unknown) {
        const message = `HTTP ${config.method?.toUpperCase()} ${config.url} request failed`;

        const isNetworkError = typeof e === 'object' && e !== null && !(e as any).response;

        if (isNetworkError) {
          throw new NetworkError(message, e);
        } else {
          throw new AppError(message, e);
        }
      }
    },
    async socket(config, handlers) {
      const { route, query = {} } = config;
      const grant = await env.auth.read();
      const socketIo = io(route, {
        query: { ...query, ...(grant ? { token: env.auth.getToken(grant) } : {}) },
        forceNew: true,
      });

      handlers.forEach(({ name, handler, codec }) =>
        socketIo.on(name, (data: string) =>
          pipe(
            JSON.parse(data),
            codec.decode,
            E.fold((e) => {
              env.log.exception(new AppError(`Failed to parse Socket IO(event=${name})`, new DecodeError(e)));
            }, handler),
          ),
        ),
      );

      return socketIo;
    },
    decode: {
      data: data,
      either: either,
      optionalResponse: optionalResponse,
      response: response,
    },
  };
};
