import { proBootHttpClient } from './boot';
import { proClientFeedbackHttpClient } from './clientFeedback';
import { proClientImagesHttpClient } from './clientImages/proClientImagesHttpClient';
import { proCustomFieldsHttpClient } from './customFields';
import { inventoriesHttpClient } from './inventories';
import { MeroProApi } from './meroProApi';
import { proNotesHttpClient } from './notes/proNotesHttpClient';
import { onlinePaymentsProHttpClient } from './onlinePayments';
import { proOnlineSalesHttpClient } from './onlineSales';
import { pageBillingDetailsHttpClient } from './pageBillingDetails';
import { paymentsProHttpClient } from './payments';
import { proUserSatisfactionHttpClient } from './proUserSatisfaction';
import { productSalesHttpClient } from './productSales';
import { productsHttpClient } from './products';
import { proServicesSearchHttpClient } from './servicesSearch';
import { subscriptionProHttpClient } from './subscription';
import { proWorkingScheduleHttpClient } from './workingSchedule';
import { HttpClient } from '@mero/shared-sdk';

export const meroProHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): MeroProApi => {
  const boot = proBootHttpClient(env);
  const subscription = subscriptionProHttpClient(env);
  const payments = paymentsProHttpClient(env);
  const onlinePayments = onlinePaymentsProHttpClient(env);
  const products = productsHttpClient(env);
  const productSales = productSalesHttpClient(env);
  const inventories = inventoriesHttpClient(env);
  const customFields = proCustomFieldsHttpClient(env);
  const pageBillingDetails = pageBillingDetailsHttpClient(env);
  const clientFeedback = proClientFeedbackHttpClient(env);
  const onlineSales = proOnlineSalesHttpClient(env);
  const userSatisfaction = proUserSatisfactionHttpClient(env);
  const servicesSearch = proServicesSearchHttpClient(env);
  const workingSchedule = proWorkingScheduleHttpClient(env);
  const notes = proNotesHttpClient(env);
  const clientImages = proClientImagesHttpClient(env);

  return {
    boot,
    subscription,
    payments,
    onlinePayments,
    products,
    productSales,
    inventories,
    customFields,
    clientFeedback,
    pageBillingDetails,
    onlineSales,
    userSatisfaction,
    servicesSearch,
    workingSchedule,
    clientImages,
    notes,
  };
};
