import React from 'react';
import { Svg, Path, G } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

const Products = ({ color, size = 24 }: IconProps) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24">
      <G data-name="Group 5923">
        <Path data-name="Rectangle 62" fill="none" d="M0 0H24V24H0z" />
      </G>
      <G
        fill="none"
        stroke={color || '#52577f'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.1}
      >
        <Path d="M13.5 2.992L6.749 0 0 2.992v7.514L6.749 13.5l6.751-2.994z" transform="translate(5.251 5.252)" />
        <Path
          data-name="layer2"
          d="M0 2.992l6.749 2.992L13.5 2.992M3.689 1.35l6.749 2.992M6.749 5.984v7.514"
          transform="translate(5.251 5.252)"
        />
        <Path d="M11.699 6.659l-2.7 1.192v2.542l2.7-1.192z" transform="translate(5.251 5.252)" />
      </G>
    </Svg>
  );
};

export default Products;
