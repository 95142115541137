import { StyleSheet } from 'react-native';

import { sizes } from '../../../../../../styles';

const styles = StyleSheet.create({
  container: { flex: 1, overflow: 'hidden' },
  contentContainer: { paddingLeft: sizes[16], paddingRight: sizes[24], paddingTop: sizes[24] },
  sectionTitle: { fontFamily: 'open-sans-semibold' },
  contentTitle: {
    fontFamily: 'merriweather-bold',
    fontSize: 20,
  },
});

export default styles;
