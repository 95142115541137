import * as React from 'react';
import { useState } from 'react';
import { Modal, View, Image, TouchableOpacity, Platform, Text } from 'react-native';
import Swiper from 'react-native-web-swiper';

import Icon from '../Icon';
import styles from './styles';

type ImageGalleryProps = {
  showGallery: boolean;
  onClose: () => void;
  data: {
    url: string;
  }[];
};

const ImageGallery = ({ data, showGallery, onClose }: ImageGalleryProps): React.ReactElement | null => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const swiperRef = React.useRef<Swiper | null>(null);

  return (
    <Modal
      visible={showGallery}
      transparent={false}
      statusBarTranslucent={Platform.OS === 'android'}
      onRequestClose={onClose}
    >
      <View style={styles.container}>
        <View style={styles.indexWrapper}>
          <Text style={styles.index}>
            {(currentIndex ?? 0) + 1} / {data.length}
          </Text>
        </View>
        <TouchableOpacity onPress={onClose}>
          <Icon type="close" color="#ffffff" />
        </TouchableOpacity>
      </View>
      <Swiper
        ref={swiperRef}
        controlsEnabled={false}
        from={currentIndex}
        loop
        minDistanceForAction={0.1}
        onIndexChanged={setCurrentIndex}
      >
        {data.map(({ url }) => (
          <View key={url} style={styles.imageContainer}>
            {Platform.OS === 'web' ? (
              <Image style={styles.webImage} source={{ uri: url }} resizeMode="contain" />
            ) : (
              <Image style={styles.image} source={{ uri: url }} resizeMode="contain" />
            )}
          </View>
        ))}
      </Swiper>
      {currentIndex > 0 && (
        <TouchableOpacity
          onPress={() => {
            swiperRef.current?.goToPrev();
            setCurrentIndex((swiperRef.current?.getActiveIndex() ?? 0) - 1);
          }}
          style={[styles.previousButton, styles.buttonGallery]}
        >
          <Icon type="previous" />
        </TouchableOpacity>
      )}
      {currentIndex < data.length - 1 && (
        <TouchableOpacity
          onPress={() => {
            swiperRef.current?.goToNext();
            setCurrentIndex((swiperRef.current?.getActiveIndex() ?? 0) + 1);
          }}
          style={[styles.nextButton, styles.buttonGallery]}
        >
          <Icon type="next" />
        </TouchableOpacity>
      )}
    </Modal>
  );
};

export default ImageGallery;
