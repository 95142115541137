import * as luxon from 'luxon';
import { Platform } from 'react-native';

import { NoteBookingDetails } from '../contexts/NoteContext';
import { DEFAULT_TIMEZONE } from './time';

export const getAppointmentDateForNote = (appointment?: NoteBookingDetails) => {
  if (!appointment || !appointment.start) {
    return null;
  }

  const from = luxon.DateTime.fromJSDate(appointment.start).setZone(DEFAULT_TIMEZONE);
  const formatted = from.toFormat('d LLL. yyyy, HH:mm');
  return formatted.toLowerCase();
};

export const getLocalImageUrlForUpload = (uri: string) => {
  return Platform.OS === 'ios' ? uri.replace('file://', '') : uri;
};

export const getFileBlob = async (uri: string) => {
  const response = await fetch(uri);
  return await response.blob();
};

export const getBookingDateForNote = (startDate: Date, timezone: string) => {
  const from = luxon.DateTime.fromJSDate(startDate).setLocale('ro').setZone(timezone);
  return from.toFormat('dd LLL yyyy, HH:mm');
};
