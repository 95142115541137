import React from 'react';
import { Svg, Path, G } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

const Warning = ({ color, size = 24 }: IconProps) => {
  if (size === 20) {
    return (
      <Svg width={20} height={20} viewBox="0 0 20 20">
        <G data-name="Group 6844" transform="translate(-206 -32)">
          <Path data-name="Rectangle 55" transform="translate(206 32)" fill="none" d="M0 0H20V20H0z" />
          <Path
            d="M4.972 1L0 10.114h9.943zm-.415 3.314h.829v2.9h-.829zm.414 4.661a.518.518 0 11.518-.518.518.518 0 01-.517.518z"
            transform="translate(211.028 36.443)"
            fill="#fb6340"
          />
        </G>
      </Svg>
    );
  }

  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <G data-name="Group 6844" transform="translate(-206 -32)">
        <Path data-name="Rectangle 55" transform="translate(206 32)" fill="none" d="M0 0H24V24H0z" />
        <Path
          d="M5.966 1L0 11.937h11.932zm-.5 3.977h.994v3.48h-.991zm.5 5.593a.621.621 0 11.621-.621.622.622 0 01-.621.621z"
          transform="translate(212.034 37.531)"
          fill={color || '#fb6340'}
        />
      </G>
    </Svg>
  );
};

export default Warning;
