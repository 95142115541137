import { StyleSheet } from 'react-native';

import { colors } from '../../../../styles';
import { sizes } from '../../../../styles';

const styles = StyleSheet.create({
  root: { backgroundColor: colors.ALABASTER },
  container: {
    paddingTop: sizes[16],
    paddingHorizontal: sizes[24],
  },
  header: {
    gap: sizes[16],
  },
  content: {
    gap: sizes[16],
    marginTop: sizes[24],
    paddingVertical: sizes[24],
  },
  title: {
    fontFamily: 'open-sans-semibold',
  },
  input: {
    width: '100%',
    height: 180,
  },
  deleteButton: {
    marginTop: 48,
  },

  deleteText: { color: colors.RADICAL_RED },
  footer: { position: 'absolute', left: 0, right: 0, bottom: 0 },
  footerButton: { alignSelf: 'center' },
  modalBorderBottom: {
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
});

export default styles;
