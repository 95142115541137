import { Interval } from '@/utils/reports';
import {
  CheckoutCashRegistryTransactionId,
  CheckoutTransactionId,
  ScaledNumber,
  SavedCheckoutCompany,
  ApiError,
} from '@mero/api-sdk';
import {
  Row,
  colors,
  H1,
  Icon,
  Body,
  SmallBody,
  styles as meroStyles,
  Column,
  Spacer,
  Button,
  H2s,
  FormCard,
  Header,
  useToast,
  Checkbox,
  H3s,
} from '@mero/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';
import Svg, { SvgProps, Path, Line } from 'react-native-svg';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CompositeNavigationProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useClickOutsideWeb } from '../../../../../hooks/useClickOutsideWeb';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { CashRegistryContext } from '../../../../../contexts/CashRegistryContext';
import { CheckoutsContext } from '../../../../../contexts/CheckoutsContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import {
  HomeTabsParamsList,
  HomeDrawerParamsList,
  AuthorizedStackParamList,
  RootStackParamList,
} from '../../../../../types';
import { scaledToString } from '../../../../../utils/scaled';
import ActiveIntervalView from '../PageReportsScreen/ActiveIntervalView';
import TransactionListScreen from './TransactionListScreen';
import { styles } from './styles';

type CashRegistryNavigationProp = CompositeNavigationProp<
  BottomTabNavigationProp<HomeTabsParamsList, 'CashRegistryScreen'>,
  CompositeNavigationProp<
    DrawerNavigationProp<HomeDrawerParamsList, 'HomeTabs'>,
    CompositeNavigationProp<
      StackNavigationProp<AuthorizedStackParamList, 'Home'>,
      StackNavigationProp<RootStackParamList>
    >
  >
>;

type Props = {
  navigation: CashRegistryNavigationProp;
};

const BackIcon = (props: SvgProps) => (
  <Svg width={32} height={32} data-name="icons/back-light" {...props}>
    <Path fill="none" d="M0 0h32v32H0z" />
    <Path d="m13.734 24.921-7.638-8.107a.992.992 0 0 1-.1-.1 1.054 1.054 0 0 1-.282-.677l-.018-.02a1.033 1.033 0 0 1 .042-.284 1.038 1.038 0 0 1 .258-.482.993.993 0 0 1 .1-.09l7.638-8.11a.927.927 0 0 1 1.362 0 1.071 1.071 0 0 1 0 1.451l-6.1 6.478h16.45a.859.859 0 0 1 .859.859v.334a.859.859 0 0 1-.859.859H9.033l6.063 6.438a1.071 1.071 0 0 1 0 1.451.927.927 0 0 1-1.366 0Z" />
  </Svg>
);

const CashRegistryScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation('cashRegistry');
  const { isPhone, isDesktop } = useMediaQueries();
  const toast = useToast();

  const isFocused = navigation.isFocused();

  const [cashRegistryState, { update, loadTransactions, setSelectedCompany }] = CashRegistryContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();
  const [checkoutsState] = CheckoutsContext.useContext();

  const setActiveInterval = async (activeInterval: Interval) => {
    if (pageState.type !== 'Loaded') {
      return;
    }
    loadTransactions({ activeInterval, pageId: pageState.page.details._id });
    update({ activeInterval });
  };

  React.useEffect(() => {
    if (isFocused && cashRegistryState.type === 'Loaded' && pageState.type === 'Loaded') {
      loadTransactions({ activeInterval: cashRegistryState.activeInterval, pageId: pageState.page.details._id });
    }
  }, [isFocused]);

  React.useEffect(() => {
    if (cashRegistryState.type === 'Failed') {
      if (cashRegistryState.error) {
        toast.show({
          type: 'error',
          text: cashRegistryState.error.message,
        });
      } else {
        toast.show({
          type: 'error',
          text: t('errorUnknown'),
        });
      }
    }
  }, [cashRegistryState]);

  const navigateCompanyMenuCallback = React.useCallback(() => {
    update({ isCompanyMenuOpen: true });
    navigation.navigate('CashRegistryCompanyMenu');
  }, [navigation]);

  const navigateOptionsMenuCallback = React.useCallback(() => {
    navigation.navigate('CashRegistryOptionsMenu');
  }, [navigation]);

  const navigateInitialBalanceCallback = React.useCallback(() => {
    navigation.navigate('InitialBalance');
  }, [navigation]);

  const navigateTransactionCallback = React.useCallback(
    (transactionId?: CheckoutCashRegistryTransactionId) => {
      navigation.navigate('TransactionScreen', { transactionId });
    },
    [navigation],
  );

  const navigateCheckoutTransactionCallback = React.useCallback(
    (checkoutTransactionId: CheckoutTransactionId) => {
      navigation.navigate('CombineCheckout', {
        screen: 'CheckoutStack',
        params: {
          screen: 'ProceedDetailsScreen',
          params: {
            checkoutTransactionId,
            backMode: 'one',
          },
        },
      });
    },
    [navigation],
  );

  const navigateCheckoutCallback = React.useCallback(() => {
    navigation.navigate('CheckoutTab', {
      screen: 'CheckoutTabScreen',
    });
  }, [navigation]);

  const companySelectRef = React.useRef<View>(null);
  useClickOutsideWeb({
    ref: companySelectRef,
    isVisible: cashRegistryState.type === 'Loaded' && cashRegistryState.isCompanyMenuOpen,
    onClickOutside() {
      update({ isCompanyMenuOpen: false });
    },
  });

  const onChangeCompany = (company: SavedCheckoutCompany) => {
    if (pageState.type === 'Loaded') {
      try {
        setSelectedCompany({ pageId: pageState.page.details._id, company });
      } catch (error) {
        if (error instanceof ApiError) {
          toast.show({
            type: 'error',
            text: error.message,
          });
        }
      }
    }
  };

  if (cashRegistryState.type !== 'Loaded' || pageState.type !== 'Loaded' || checkoutsState.type !== 'Loaded') {
    return <></>;
  }

  return (
    <ModalScreenContainer style={{ flex: 1, justifyContent: 'space-between' }}>
      {/* Header */}
      {isPhone && (
        <Column style={styles.headerWrapperWithShadow}>
          <Header
            withShadow={false}
            LeftComponent={() => (
              <TouchableOpacity onPress={navigateCheckoutCallback} style={{ marginLeft: 24 }}>
                <BackIcon />
              </TouchableOpacity>
            )}
            CenterComponent={() => {
              if (cashRegistryState.selectedCompany && cashRegistryState.companies.length > 0) {
                return (
                  <TouchableOpacity onPress={navigateCompanyMenuCallback}>
                    <View style={[{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}>
                      <SmallBody style={[meroStyles.text.semibold]}>
                        {cashRegistryState.selectedCompany.billingDetails?.name}
                      </SmallBody>
                      <View style={[{ paddingLeft: 10 }]}>
                        <Icon type="dropdown" rotate={cashRegistryState.isCompanyMenuOpen} />
                      </View>
                    </View>
                  </TouchableOpacity>
                );
              }
              return <SmallBody style={[meroStyles.text.semibold]}>{t('title')}</SmallBody>;
            }}
            RightComponent={() => (
              <TouchableOpacity
                style={{ justifyContent: 'center', height: 50, marginRight: 24 }}
                onPress={navigateOptionsMenuCallback}
              >
                <SmallBody style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>{t('options')}</SmallBody>
              </TouchableOpacity>
            )}
          />
          {cashRegistryState.cashRegistry && cashRegistryState.companies.length > 0 && (
            <>
              <Spacer size={8} />
              <Row style={{ justifyContent: 'center', paddingHorizontal: 24, paddingBottom: 16 }}>
                <ActiveIntervalView
                  fullWidth
                  activeInterval={cashRegistryState.activeInterval}
                  setActiveInterval={setActiveInterval}
                />
              </Row>
            </>
          )}
        </Column>
      )}
      {isDesktop && (
        <>
          <Row
            alignItems="center"
            justifyContent="space-between"
            style={[styles.headerWrapperWithShadow, { paddingHorizontal: 24, paddingVertical: 16 }]}
          >
            <Row style={{ flex: 1, alignItems: 'center' }}>
              <TouchableOpacity onPress={navigateCheckoutCallback}>
                <BackIcon />
              </TouchableOpacity>
              <Column style={{ paddingHorizontal: 16 }}>
                <H1>{t('title')}</H1>
                <Spacer size={4} />
                {cashRegistryState.selectedCompany && cashRegistryState.companies.length > 0 && (
                  <>
                    <TouchableOpacity onPress={() => update({ isCompanyMenuOpen: true })}>
                      <View style={[{ flexDirection: 'row', alignItems: 'center' }]}>
                        <SmallBody style={[meroStyles.text.semibold]}>
                          {cashRegistryState.selectedCompany.billingDetails?.name}
                        </SmallBody>
                        <View style={[{ paddingLeft: 10 }]}>
                          <Icon type="dropdown" rotate={cashRegistryState.isCompanyMenuOpen} />
                        </View>
                      </View>
                    </TouchableOpacity>
                    {cashRegistryState.isCompanyMenuOpen && (
                      <View
                        ref={companySelectRef}
                        style={{
                          position: 'absolute',
                          top: 70,
                          left: 14,
                          right: 0,
                          paddingHorizontal: 16,
                          backgroundColor: '#ffffff',
                          borderRadius: 16,
                          minWidth: 300,
                          shadowColor: '#000000',
                          shadowOffset: { width: 1, height: 1 },
                          shadowOpacity: 0.16,
                          shadowRadius: 16,
                          elevation: 16,
                          zIndex: 2,
                        }}
                      >
                        <Spacer size="16" />
                        <H3s>{t('chooseCashRegister')}</H3s>
                        <Spacer size="16" />
                        {cashRegistryState.companies.map((company) => (
                          <>
                            <TouchableOpacity
                              style={{ flex: 2, flexDirection: 'row' }}
                              onPress={() => onChangeCompany(company)}
                            >
                              <Checkbox
                                color="blue"
                                disabled={false}
                                value={cashRegistryState.selectedCompany?._id === company._id}
                                onValueChange={() => onChangeCompany(company)}
                              />
                              <Body style={{ fontSize: 17, color: '#172B4D', paddingLeft: 12 }}>
                                {company.billingDetails?.name}
                              </Body>
                            </TouchableOpacity>
                            <Spacer size="16" />
                            <Line />
                            <Spacer size="16" />
                          </>
                        ))}
                      </View>
                    )}
                  </>
                )}
              </Column>
            </Row>
            {cashRegistryState.selectedCompany && cashRegistryState.companies.length > 0 && (
              <ActiveIntervalView
                activeInterval={cashRegistryState.activeInterval}
                setActiveInterval={setActiveInterval}
              />
            )}
          </Row>
        </>
      )}

      {!cashRegistryState.selectedCompany && (
        <Column
          style={{
            flex: 1,
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colors.ALABASTER,
          }}
        >
          <H2s>{t('noCompany')}</H2s>
          <Spacer size={8} />
          <Body style={{ textAlign: 'center', paddingHorizontal: 32, width: 400 }}>{t('noCompanyDescription')}</Body>
          <Spacer size={32} />
        </Column>
      )}

      {cashRegistryState.selectedCompany && cashRegistryState.cashRegistry && (
        <TransactionListScreen
          navigateInitialBalance={navigateInitialBalanceCallback}
          navigateOptionsMenu={navigateOptionsMenuCallback}
          navigateTransaction={(transactionId) => navigateTransactionCallback(transactionId)}
          navigateCheckoutTransaction={(transaction) => navigateCheckoutTransactionCallback(transaction)}
        />
      )}

      {cashRegistryState.selectedCompany && !cashRegistryState.cashRegistry && (
        <Column
          style={{
            flex: 1,
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colors.ALABASTER,
          }}
        >
          <H2s>{t('setInitialBalance')}</H2s>
          <Spacer size={8} />
          <Body style={{ textAlign: 'center', paddingHorizontal: 32, width: 400 }}>
            {t('setInitialBalanceDescription')}
          </Body>
          <Spacer size={32} />
          <Button text={t('addInitialBalance')} padding={24} onClick={navigateInitialBalanceCallback} expand={false} />
        </Column>
      )}

      {isPhone && cashRegistryState.cashRegistry && (
        <FormCard
          dropShaddow
          paddings="button"
          style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
        >
          <Row style={{ justifyContent: 'space-between', flex: 1 }}>
            <SmallBody style={meroStyles.text.semibold}>{t('currentBalance')} </SmallBody>
            <SmallBody>
              {scaledToString(cashRegistryState.transactionList?.currentBalance.amount ?? ScaledNumber.zero())}{' '}
            </SmallBody>
          </Row>
          <Spacer size={16} />
          <Button text={t('addTransaction')} onClick={() => navigateTransactionCallback()} />
        </FormCard>
      )}
    </ModalScreenContainer>
  );
};

export default CashRegistryScreen;
