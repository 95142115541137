import {
  AppointmentCustomFields,
  CreateCustomField,
  CustomField,
  CustomFieldId,
  ServicesCustomFieldsResult,
} from '../../customFields';
import { ServiceId } from '../../services';
import { ProCustomFieldsApi } from './index';
import { HttpClient, unsafeRight, UnknownApiError, optionull, Paged } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const proCustomFieldsHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): ProCustomFieldsApi => {
  const { apiBaseUrl, http } = env;

  const unknownError = UnknownApiError;

  const createCustomFieldDecoder = http.decode.response(unknownError, CustomField.Any.JSON);
  const getPageCustomFieldsDecoder = http.decode.response(UnknownApiError, Paged.json(t.array(CustomField.Any.JSON)));
  const getPageCustomFieldsCountDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      customFieldsCount: t.number,
    }),
  );
  const updateCustomFieldDecoder = http.decode.response(unknownError, t.unknown);
  const deleteCustomFieldDecoder = http.decode.response(unknownError, t.unknown);
  const updateServicesForCustomFieldDecoder = http.decode.response(unknownError, t.unknown);
  const updateCustomFieldsForServiceDecoder = http.decode.response(unknownError, t.unknown);
  const getCustomFieldsForServicesDecoder = http.decode.response(unknownError, ServicesCustomFieldsResult.JSON);
  const getAppointmentCustomFieldsDecoder = http.decode.response(
    unknownError,
    t.type({
      appointmentCustomFields: optionull(AppointmentCustomFields.JSON),
    }),
  );
  const createAppointmentCustomFieldsDecoder = http.decode.response(unknownError, AppointmentCustomFields.JSON);
  const updateAppointmentCustomFieldsDecoder = http.decode.response(unknownError, AppointmentCustomFields.JSON);
  const uploadCustomFieldImageDecoder = http.decode.response(
    unknownError,
    t.type({
      _id: t.string,
    }),
  );
  const deleteCustomFieldImageDecoder = http.decode.response(unknownError, t.unknown);

  return {
    createCustomField: async ({ pageId, field }) => {
      const requestBody: CreateCustomField.Any = field;

      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/pro/page/${encodeURIComponent(pageId)}/custom-fields`,
            data: requestBody,
          },
          createCustomFieldDecoder,
        ),
      );
    },
    getPageCustomFields: async ({ pageId, limit, page }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/page/${encodeURIComponent(pageId)}/custom-fields`,
            params: {
              limit: limit,
              page: page,
            },
          },
          getPageCustomFieldsDecoder,
        ),
      );
    },
    getPageCustomFieldsCount: async (pageId) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/page/${encodeURIComponent(pageId)}/custom-fields-count`,
          },
          getPageCustomFieldsCountDecoder,
        ),
      );
    },
    updateCustomField: async ({ pageId, field }) => {
      const requestBody: CreateCustomField.Any = field;

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/pro/page/${encodeURIComponent(pageId)}/custom-fields/${encodeURIComponent(field._id)}`,
            data: requestBody,
          },
          updateCustomFieldDecoder,
        ),
      );
    },
    deleteCustomField: async ({ pageId, customFieldId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/pro/page/${encodeURIComponent(pageId)}/custom-fields/${encodeURIComponent(
              customFieldId,
            )}`,
          },
          deleteCustomFieldDecoder,
        ),
      );
    },
    updateServicesForCustomField: async ({ pageId, customFieldId, serviceIds }) => {
      const requestBody: {
        readonly serviceIds: ServiceId[];
      } = {
        serviceIds: serviceIds,
      };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/pro/page/${encodeURIComponent(pageId)}/custom-fields/${encodeURIComponent(
              customFieldId,
            )}/services`,
            data: requestBody,
          },
          updateServicesForCustomFieldDecoder,
        ),
      );
    },
    updateCustomFieldsForService: async ({ pageId, customFieldIds, serviceId }) => {
      const requestBody: {
        readonly customFieldIds: CustomFieldId[];
      } = {
        customFieldIds: customFieldIds,
      };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/pro/page/${encodeURIComponent(pageId)}/service/${encodeURIComponent(
              serviceId,
            )}/custom-fields`,
            data: requestBody,
          },
          updateCustomFieldsForServiceDecoder,
        ),
      );
    },
    getCustomFieldsForServices: async ({ pageId, serviceIds }) => {
      const requestBody: {
        readonly serviceIds: ServiceId[];
      } = {
        serviceIds: serviceIds,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/pro/page/${encodeURIComponent(pageId)}/services-custom-fields`,
            data: requestBody,
          },
          getCustomFieldsForServicesDecoder,
        ),
      );
    },
    createAppointmentCustomFields: async ({ pageId, appointmentId, occurrenceIndex, services }) => {
      const requestBody: {
        readonly occurrenceIndex: number | undefined;
        readonly services: {
          readonly _id: ServiceId;
          readonly fields: CreateCustomField.Any[];
        };
      } = {
        occurrenceIndex: occurrenceIndex,
        services: services,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/pro/page/${encodeURIComponent(pageId)}/appointment/${encodeURIComponent(
              appointmentId,
            )}/custom-fields`,
            data: requestBody,
          },
          createAppointmentCustomFieldsDecoder,
        ),
      );
    },
    getAppointmentCustomFields: async ({ pageId, appointmentId, occurrenceIndex }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/page/${encodeURIComponent(pageId)}/appointment/${encodeURIComponent(
              appointmentId,
            )}/custom-fields`,
            params: {
              occurrenceIndex: occurrenceIndex,
            },
          },
          getAppointmentCustomFieldsDecoder,
        ),
      );
    },

    updateAppointmentCustomFields: async ({ pageId, appointmentId, occurrenceIndex, services }) => {
      const requestBody: {
        readonly occurrenceIndex: number | undefined;
        readonly services: {
          readonly _id: ServiceId;
          readonly fields: CreateCustomField.Any[];
        };
      } = {
        occurrenceIndex: occurrenceIndex,
        services: services,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/pro/page/${encodeURIComponent(pageId)}/appointment/${encodeURIComponent(
              appointmentId,
            )}/custom-fields`,
            data: requestBody,
          },
          updateAppointmentCustomFieldsDecoder,
        ),
      );
    },
    uploadCustomFieldImage: async ({ pageId, customFieldId, image }) => {
      const formData = new FormData();
      const ext = image.blob.type.split('/')[1];

      if (image.platform === 'web') {
        formData.append('image', new File([image.blob], `customFieldImage.${ext}`, { type: image.blob.type }));
      } else {
        const uri = image.platform === 'ios' ? image.uri.replace('file://', '') : image.uri;

        formData.append('image', {
          name: `customFieldImage.${ext}`,
          type: image.blob.type,
          uri: uri,
        } as any);
      }

      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/pro/page/${encodeURIComponent(pageId)}/custom-field/${encodeURIComponent(
              customFieldId,
            )}/image`,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: formData,
          },
          uploadCustomFieldImageDecoder,
        ),
      );
    },
    deleteCustomFieldImage: async ({ pageId, customFieldId, imageId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/pro/page/${encodeURIComponent(pageId)}/custom-field/${encodeURIComponent(
              customFieldId,
            )}/image/${encodeURIComponent(imageId)}`,
          },
          deleteCustomFieldImageDecoder,
        ),
      );
    },
  };
};
