import { Delimiter } from './delimiter';
import { NoResults } from './noResults';
import { Page } from './page';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Any = Page | Delimiter | NoResults;

const JSON: t.Type<Any, JSONable> = t.union([Page.JSON, Delimiter.JSON, NoResults.JSON], 'Any');

export const Any = {
  JSON,
};
