import { CalendarContext } from '@/contexts/CalendarContext';
import { Icon, Row } from '@mero/components';
import * as React from 'react';
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';

import { useMediaQueries } from '../../hooks/useMediaQueries';

import CompleteProfile from './CompleteProfile';
import ManualNotification from './ManualNotification';

type Props = {
  readonly onPress?: () => void;
  readonly style?: StyleProp<ViewStyle>;
};

const AddBookingPlusButton: React.FC<Props> = ({ style, onPress }) => {
  const { isPhone } = useMediaQueries();
  const [calendarState] = CalendarContext.useContext();

  return (
    <View
      style={[
        style,
        {
          justifyContent: isPhone ? 'flex-end' : 'center',
          alignItems: isPhone ? 'flex-end' : 'center',
          flexDirection: isPhone ? 'column' : 'row-reverse',
          flex: 1,
        },
      ]}
      pointerEvents="box-none"
    >
      <ManualNotification>
        <TouchableOpacity onPress={onPress}>
          <View
            style={[
              {
                height: 52,
                width: 52,
                borderRadius: 25,
                backgroundColor: '#080DE0',
                justifyContent: 'center',
                alignItems: 'center',
              },
            ]}
          >
            <Icon type="plus" color="#ffffff" />
          </View>
        </TouchableOpacity>
      </ManualNotification>
      {/* Only load complete profile data after first calendar request has finished */}
      {calendarState.getAppointmentsCounter > 0 && <CompleteProfile />}
    </View>
  );
};

export default AddBookingPlusButton;
