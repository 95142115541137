import { StyleSheet } from 'react-native';

import { colors } from '../../../../../../styles';

const styles = StyleSheet.create({
  boostDescription: { textAlign: 'center' },
  labelWrapper: { flex: 2, paddingHorizontal: 6 },
  claimClientDescription: {
    fontFamily: 'open-sans-semibold',
    color: colors.COMET,
    textAlign: 'center',
  },
  commissionText: {
    fontFamily: 'open-sans-semibold',
    color: colors.DARK_BLUE,
  },
  card: {
    paddingHorizontal: 14,
    paddingVertical: 16,
  },
  commissionButton: {
    flex: 1,
    backgroundColor: colors.SKY_BLUE,
    borderRadius: 16,
    paddingVertical: 6,
    paddingHorizontal: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  claimButton: {
    flex: 1,
    backgroundColor: 'transparent',
    paddingVertical: 6,
    paddingHorizontal: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
