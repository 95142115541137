import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type ImageUpload = {
  readonly name: string;
  readonly required: boolean;
  readonly type: 'ImageUpload';
};

const JSON: t.Type<ImageUpload, JSONable> = t.strict(
  {
    name: t.string,
    required: t.boolean,
    type: t.literal('ImageUpload'),
  },
  'ImageUpload',
);

export const ImageUpload = {
  JSON,
};
