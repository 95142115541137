import { CustomFieldId } from '../customFieldId';
import { SelectionOption } from '../selectionOption';
import { JSONable, optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type MultipleSelection = {
  /**
   * Custom field id
   */
  readonly _id: CustomFieldId;
  readonly name: string;
  readonly required: boolean;
  readonly type: 'MultipleSelection';
  readonly options: SelectionOption[];
  readonly response:
    | {
        readonly options: SelectionOption[];
      }
    | undefined;
};

const JSON: t.Type<MultipleSelection, JSONable> = t.strict(
  {
    _id: CustomFieldId.JSON,
    name: t.string,
    required: t.boolean,
    type: t.literal('MultipleSelection'),
    options: t.array(SelectionOption.JSON),
    response: optionull(
      t.type(
        {
          options: t.array(SelectionOption.JSON),
        },
        'Response',
      ),
    ),
  },
  'MultipleSelection',
);

export const MultipleSelection = {
  JSON,
};
