import { ClientImage as ClientImageApi, ClientImageId, NoteDetails, NoteId, Paged } from '@mero/api-sdk';
import { WorkerId } from '@mero/api-sdk';
import { AppointmentId, UserAppointment } from '@mero/api-sdk/dist/calendar';
import { ClientId } from '@mero/api-sdk/dist/clients';
import { PageId } from '@mero/api-sdk/dist/pages';
import { createModelContext } from '@mero/components';
import { NonEmptyString } from 'io-ts-types';
import * as React from 'react';

import log from '../../utils/log';
import { meroApi } from '../AuthContext';

type PageAppointmentsWithTotals = Paged<UserAppointment[]> & {
  readonly futureBookingsCount: number;
  readonly pastBookingsCount: number;
};

export type ClientImageWithNew = ClientImageApi & {
  isNew?: boolean;
};

type NoteCreationPayload = {
  pageId: PageId;
  clientId: ClientId;
  text: NonEmptyString;
  imageIds: ClientImageId[];
  appointment?: {
    _id: AppointmentId;
    occurrenceIndex: number;
  };
};

export type NoteBookingDetails = {
  _id?: AppointmentId;
  occurrenceIndex: number;
  start?: Date;
  worker?: {
    readonly _id: WorkerId;
    readonly firstname: string;
    readonly lastname: string;
  };
};

export type NoteCreationState = {
  type: 'New' | 'Saving' | 'Saved' | 'Failed';
  text: NonEmptyString | null;
  noteImages: ClientImageWithNew[];
  clientId?: ClientId;
  pageId?: PageId;
  note?: NoteDetails;
  clientImages: ClientImageWithNew[];
  noteBookingDetails: NoteBookingDetails;
  clientAppointments?: PageAppointmentsWithTotals;
};

const defaultState = (): NoteCreationState => ({
  type: 'New',
  text: null,
  noteImages: [],
  clientImages: [],
  noteBookingDetails: {
    _id: undefined,
    occurrenceIndex: 0,
  },
});

export const NoteCreationContext = createModelContext(
  defaultState(),
  {
    setText: (state, text: NonEmptyString): NoteCreationState => {
      if (state.type === 'New' || state.type === 'Saving' || state.type === 'Failed') {
        return {
          ...state,
          text,
        };
      }
      return state;
    },

    setInitialData: (state, { clientId, pageId, note }): NoteCreationState => {
      return {
        ...state,
        clientId,
        pageId,
        note,
        text: note?.text || null,
        noteImages: note?.images || [],
        noteBookingDetails: {
          start: note?.appointment?.start || undefined,
          _id: note?.appointment?._id,
          occurrenceIndex: note?.appointment?.occurrenceIndex || 0,
          worker: note ? note.worker : undefined,
        },
      };
    },

    setPageId: (state, pageId: PageId): NoteCreationState => {
      return { ...state, pageId };
    },

    setNote: (state, note?: NoteDetails): NoteCreationState => {
      if (note) {
        return {
          ...state,
          note,
          text: note?.text || null,
          noteImages: note?.images || [],
          noteBookingDetails: {
            _id: note?.appointment?._id,
            start: note?.appointment?.start || undefined,
            occurrenceIndex: note?.appointment?.occurrenceIndex || 0,
            worker: note ? note.appointment?.worker : undefined,
          },
        };
      }

      return state;
    },

    setBooking: (state, booking: UserAppointment): NoteCreationState => {
      if (state.type === 'New' || state.type === 'Saving' || state.type === 'Failed') {
        return {
          ...state,
          noteBookingDetails: {
            _id: booking._id,
            occurrenceIndex: booking.occurrenceIndex || 0,
            start: booking.start,
            worker:
              booking.worker && booking.worker.firstname && booking.worker.lastname
                ? {
                    _id: booking.worker._id,
                    firstname: booking.worker.firstname,
                    lastname: booking.worker.lastname,
                  }
                : undefined,
          },
        };
      }
      return state;
    },

    clearBooking: (state): NoteCreationState => {
      if (state.type === 'New' || state.type === 'Saving' || state.type === 'Failed') {
        return {
          ...state,
          noteBookingDetails: {
            _id: undefined,
            occurrenceIndex: 0,
            start: undefined,
            worker: undefined,
          },
        };
      }
      return state;
    },

    setNoteImages: (state, photos: ClientImageWithNew[]): NoteCreationState => {
      if (state.type === 'New' || state.type === 'Saving' || state.type === 'Failed') {
        return {
          ...state,
          noteImages: [...state.noteImages, ...photos],
        };
      }
      return state;
    },

    removePhoto: (state, imageId: string): NoteCreationState => {
      if (state.type === 'New' || state.type === 'Saving' || state.type === 'Failed') {
        const updatedPhotos = state.noteImages.filter((image) => image._id !== imageId);
        return {
          ...state,
          noteImages: updatedPhotos,
        };
      }

      return state;
    },

    resetNewPhotos: (state): NoteCreationState => {
      if (state.type === 'New' || state.type === 'Saving' || state.type === 'Failed') {
        return {
          ...state,
          noteImages: [],
        };
      }
      return state;
    },

    startSaving: (state): NoteCreationState => {
      return {
        ...state,
        type: 'Saving',
      };
    },

    setSaved: (state): NoteCreationState => {
      return {
        ...state,
        type: 'Saved',
      };
    },

    setFailed: (state): NoteCreationState => {
      return {
        ...state,
        type: 'Failed',
      };
    },

    update(state, params: NoteCreationState) {
      return {
        ...state,
        ...params,
      };
    },

    mutate: (s, fn: (s: NoteCreationState) => NoteCreationState): NoteCreationState => fn(s),
    run: (s, f: (s: NoteCreationState) => void) => {
      f(s);
      return s;
    },
  },
  (dispatch) => {
    const createNote = async () => {
      dispatch.startSaving();

      dispatch.run(async (state) => {
        try {
          if (!state.clientId || !state.pageId || !state.text) {
            throw new Error('Missing required fields');
            return;
          }

          const payload = {
            text: state.text,
            clientId: state.clientId,
            pageId: state.pageId,
            imageIds: state.noteImages.map((image) => image._id),
            appointment: state.noteBookingDetails?._id
              ? {
                  _id: state.noteBookingDetails._id,
                  occurrenceIndex: state.noteBookingDetails.occurrenceIndex,
                }
              : undefined,
          };

          await meroApi.pro.notes.createClientNote(payload);
          dispatch.setSaved();
        } catch (error) {
          log.error('Failed to create client note', error);
          dispatch.setFailed();
        }
      });
    };

    const resetNote = () => {
      dispatch.mutate(() => defaultState());
    };

    const getClientBookings = async () => {
      dispatch.run(async (state) => {
        try {
          if (!state.clientId) return;

          const appointments = await meroApi.calendar.fetchPagedClientUserAppointments({
            clientId: state.clientId,
            limit: 10,
          });

          dispatch.update({
            ...state,
            clientAppointments: {
              ...appointments,
              data: appointments.data,
              futureBookingsCount: appointments.futureBookingsCount ?? 0,
              pastBookingsCount: appointments.pastBookingsCount ?? 0,
            },
          });
        } catch (error) {
          log.error('Failed to load client appointments', error);
        }
      });
    };

    const updateClientNote = async () => {
      dispatch.startSaving();

      dispatch.run(async (state) => {
        try {
          if (!state.clientId || !state.pageId || !state.text || !state.note?._id) {
            throw new Error('Missing required fields');
          }

          const payload = {
            noteId: state.note._id,
            text: state.text,
            clientId: state.clientId,
            pageId: state.pageId,
            imageIds: state.noteImages.map((image) => image._id),
            appointment: state.noteBookingDetails?._id
              ? {
                  _id: state.noteBookingDetails._id,
                  occurrenceIndex: state.noteBookingDetails.occurrenceIndex,
                }
              : undefined,
          };

          await meroApi.pro.notes.updateClientNote(payload);
          dispatch.setSaved();
        } catch (error) {
          log.error('Failed to edit client note.', error);
          dispatch.setFailed();
        }
      });
    };

    const loadMoreClientBookings = async () => {
      dispatch.run(async (state) => {
        if (state.clientAppointments?.next && state.clientId) {
          try {
            const appointments = await meroApi.calendar.fetchPagedClientUserAppointments({
              clientId: state.clientId,
              limit: 10,
              page: state.clientAppointments.next,
            });

            dispatch.update({
              ...state,
              clientAppointments: {
                ...appointments,
                data: [...(state.clientAppointments?.data || []), ...appointments.data],
                futureBookingsCount: appointments.futureBookingsCount ?? 0,
                pastBookingsCount: appointments.pastBookingsCount ?? 0,
              },
            });
          } catch (error) {
            log.error('Failed to load client appointments', error);
          }
        }
      });
    };

    const loadClientImages = async () => {
      dispatch.run(async (state) => {
        if (!state.pageId || !state.clientId) return;
        try {
          const clientImages = await meroApi.pro.clientImages.getClientImages({
            pageId: state.pageId,
            clientId: state.clientId,
            hasNote: false,
          });

          dispatch.update({
            ...state,
            clientImages,
          });
        } catch (error) {
          log.error('Failed to load client images', error);
        }
      });
    };
    const deleteClientImage = async (imageId: ClientImageId) => {
      dispatch.run(async (state) => {
        try {
          await meroApi.pro.clientImages.deleteClientImage({
            pageId: state.pageId!,
            clientId: state.clientId!,
            imageId,
          });
        } catch (error) {
          log.error('Failed to delete client image', error);
        }
      });
    };

    const removeClientNote = async (deleteNoteImages: boolean) => {
      dispatch.run(async (state) => {
        if (!state.clientId || !state.pageId || !state.note?._id) {
          throw new Error('Missing required fields');
        }

        try {
          await meroApi.pro.notes.deleteClientNote({
            clientId: state.clientId,
            pageId: state.pageId,
            noteId: state.note._id,
            deleteNoteImages: deleteNoteImages || false,
          });
        } catch (error) {
          log.error('Failed to remove client note', error);
        }
      });
    };

    return {
      init: (): void => {
        dispatch.mutate(() => defaultState());
      },
      createNote,
      removeClientNote,
      loadMoreClientBookings,
      resetNote,
      updateClientNote,
      getClientBookings,
      loadClientImages,
      deleteClientImage,
      setText: dispatch.setText,
      setBooking: dispatch.setBooking,
      clearBooking: dispatch.clearBooking,
      setNoteImages: dispatch.setNoteImages,
      removePhoto: dispatch.removePhoto,
      resetNewPhotos: dispatch.resetNewPhotos,
      setInitialData: dispatch.setInitialData,
      setNote: dispatch.setNote,
    };
  },
);

// This component can be used to initialize the note creation context when mounted.
const ContextInit: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <>{children}</>;
};

export const withNoteCreationContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithNoteCreationContextProvider(props: P) {
    return (
      <NoteCreationContext.Provider>
        <ContextInit>
          <Content {...props} />
        </ContextInit>
      </NoteCreationContext.Provider>
    );
  };
};
