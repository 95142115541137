import { JSONable, UserId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type FoundUser = {
  readonly _id: UserId;
  readonly isGhost: boolean;
  readonly hasPin: boolean;
};

const JSON: t.Type<FoundUser, JSONable> = t.type(
  {
    _id: UserId,
    isGhost: t.boolean,
    hasPin: t.boolean,
  },
  'FoundUser',
);

export const FoundUser = {
  JSON,
};
