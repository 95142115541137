import { Interval } from '@/utils/reports';
import { Portal } from '@gorhom/portal';
import {
  MeroUnits,
  CheckoutSaleOwnerSalesReportItem,
  CheckoutReportBySaleOwnerAndServiceSortType,
  CheckoutReportBySaleOwnerAndProductSortType,
} from '@mero/api-sdk';
import { colors, Column, H1, ModalOverlay, Row, Spacer, styles as meroStyles, Switch } from '@mero/components';
import { SortDirection } from '@mero/shared-sdk';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';
import Svg, { SvgProps, Path } from 'react-native-svg';

import { Authorized, AuthorizedProps } from '../../../../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../../contexts/CurrentBusiness';
import { nameGenerator } from '../../../../../../utils/string';
import ActiveIntervalView from '../ActiveIntervalView';
import ProProducts from './ProProducts';
import ProServices from './ProServices';
import ProductsProDownloadSelector from './ProductsProDownloadSelector';
import ServicesProDownloadSelector from './ServicesProDownloadSelector';

const CloseIcon = (props: SvgProps) => (
  <Svg width={32} height={32} data-name="Group 7763" {...props}>
    <Path fill="none" d="M0 0h32v32H0z" />
    <Path d="M8.458 22.128 14.586 16 8.457 9.872a1 1 0 0 1 1.415-1.414L16 14.586l6.128-6.128a1 1 0 1 1 1.415 1.414L17.415 16l6.128 6.128a1 1 0 0 1-1.414 1.414l-6.128-6.128-6.128 6.128a1 1 0 1 1-1.414-1.415Z" />
  </Svg>
);

const ReportsTabsOptions = [
  {
    label: 'services',
    value: 'services',
  },
  {
    label: 'offlineRetailProducts',
    value: 'offlineRetailProducts',
  },
  {
    label: 'consumableProducts',
    value: 'consumableProducts',
  },
] as const;

type ReportsTabsOptionsType = (typeof ReportsTabsOptions)[number]['value'];

type Props = AuthorizedProps &
  CurrentBusinessProps & {
    details: CheckoutSaleOwnerSalesReportItem<MeroUnits.Any>;
    initialInterval: Interval;
    onClose: () => void;
  };

const ProDetailsModal: React.FC<Props> = ({ page, initialInterval, details, onClose }) => {
  const { t } = useTranslation('reports');

  const [activeInterval, setActiveInterval] = React.useState<Interval>(initialInterval);
  const [selectedTab, setSelectedTab] = React.useState<ReportsTabsOptionsType>(ReportsTabsOptions[0].value);

  const [servicesSort, setServicesSort] = React.useState<
    | {
        by: CheckoutReportBySaleOwnerAndServiceSortType;
        dir: SortDirection;
      }
    | undefined
  >();

  const [productsSort, setProductsSort] = React.useState<
    | {
        by: CheckoutReportBySaleOwnerAndProductSortType;
        dir: SortDirection;
      }
    | undefined
  >();

  const [availability, setAvailability] = React.useState<'consumable' | 'offlineRetail'>('consumable');

  const getFilters = (tab: ReportsTabsOptionsType) => {
    const dateInterval = {
      from: activeInterval.value.start.toJSDate(),
      to: activeInterval.value.end.toJSDate(),
    };

    switch (tab) {
      case 'services':
        return (
          <Row>
            <ServicesProDownloadSelector
              pageId={page.details._id}
              unit="RON"
              interval={dateInterval}
              saleOwnerId={details.saleOwner._id}
              sort={servicesSort}
            />
          </Row>
        );
      case 'offlineRetailProducts':
      case 'consumableProducts':
        return (
          <Row>
            <ProductsProDownloadSelector
              pageId={page.details._id}
              unit="RON"
              interval={dateInterval}
              saleOwnerId={details.saleOwner._id}
              sort={productsSort}
              availability={availability}
            />
          </Row>
        );
      default:
        return null;
    }
  };
  const getContent = (tab: ReportsTabsOptionsType) => {
    const dateInterval = {
      from: activeInterval.value.start.toJSDate(),
      to: activeInterval.value.end.toJSDate(),
    };

    switch (tab) {
      case 'services':
        return (
          <ProServices
            pageId={page.details._id}
            dateInterval={dateInterval}
            saleOwnerId={details.saleOwner._id}
            updateSort={setServicesSort}
          />
        );
      case 'consumableProducts':
      case 'offlineRetailProducts':
        return (
          <ProProducts
            availability={availability}
            pageId={page.details._id}
            dateInterval={dateInterval}
            saleOwnerId={details.saleOwner._id}
            updateSort={setProductsSort}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Portal>
      <ModalOverlay>
        <Column style={{ margin: 32, backgroundColor: colors.WHITE, flex: 1, borderRadius: 12, overflow: 'hidden' }}>
          <Row
            style={{
              height: 91,
              backgroundColor: colors.WHITE,
              shadowColor: colors.BLACK,
              shadowOffset: { width: 1, height: 1 },
              shadowOpacity: 0.16,
              shadowRadius: 16,
              elevation: 16,
              zIndex: 1,
            }}
            alignItems="center"
          >
            <Row style={{ flex: 1 }}>
              <H1 style={{ paddingHorizontal: 16 }}>
                {t('proDetailsTitle', { name: nameGenerator(details.saleOwner, t('noName')) })}
              </H1>
            </Row>
            <Row style={{ paddingRight: 24 }}>
              <ActiveIntervalView
                activeInterval={activeInterval}
                setActiveInterval={setActiveInterval}
                showFutureDates={true}
                withFullCurrentMonth
              />
            </Row>
            <TouchableOpacity onPress={onClose} style={{ paddingRight: 16 }}>
              <CloseIcon />
            </TouchableOpacity>
          </Row>
          <ScrollView style={{ paddingHorizontal: 24 }}>
            <Spacer size="24" />
            <Row alignItems="center" justifyContent="space-between">
              <Column style={{ flex: 1, maxWidth: 480 }}>
                <Switch
                  height={32}
                  textProps={[meroStyles.text.semibold, { fontSize: 13 }]}
                  buttons={ReportsTabsOptions.map((o) => ({ label: t(o.label), value: o.value }))}
                  defaultValue={ReportsTabsOptions[0].value}
                  onChange={(v) => {
                    const tab = v as ReportsTabsOptionsType;
                    setSelectedTab(tab);
                    if (tab === 'consumableProducts') {
                      setAvailability('consumable');
                    }
                    if (tab === 'offlineRetailProducts') {
                      setAvailability('offlineRetail');
                    }
                  }}
                />
              </Column>
              {getFilters(selectedTab)}
            </Row>
            <Spacer size="24" />
            {getContent(selectedTab)}
            <Spacer size="24" />
          </ScrollView>
        </Column>
      </ModalOverlay>
    </Portal>
  );
};

export default pipe(ProDetailsModal, CurrentBusiness, Authorized);
