import { tokenize, match } from '@/utils/search';
import { MergedService } from '@mero/api-sdk/dist/services';
import {
  AvoidKeyboard,
  DismissKeyboard,
  MeroHeader,
  SearchTextInput,
  Spacer,
  Body,
  H1,
  H2s,
  normalize,
} from '@mero/components';
import { IANAZone } from 'luxon';
import * as React from 'react';
import { View, Keyboard } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { debounce } from 'throttle-debounce';

import { useKeyboardIsOpen } from '@mero/components/lib/hooks';
import { styles as text } from '@mero/components/lib/styles/text';

import GroupedServicesList, { ServicesGroup, ServiceGroupPreview } from '../GroupedServicesList';
import ModalScreenContainer from '../ModalScreenContainer';
import { styles } from './styles';

type ServiceSearchIndex = {
  /**
   * Find services matching query string
   */
  readonly search: (q: string) => ServicesGroup[];
};

type SavedServiceSearchText = MergedService & { searchText: string };

type IndexedGroup = {
  readonly group: ServiceGroupPreview;
  readonly services: SavedServiceSearchText[];
};

const buildServiceSearchIndex = (groups: ServicesGroup[]): ServiceSearchIndex => {
  const index: IndexedGroup[] = groups.map(({ group, services }) => ({
    group,
    services: services.map(
      (service): SavedServiceSearchText => ({
        ...service,
        searchText: normalize(`${service.name} ${service.description}`),
      }),
    ),
  }));

  return {
    search: (q) => {
      const tokens = tokenize(q);

      if (tokens.length === 0) {
        return groups;
      }

      return index.reduce((acc: IndexedGroup[], { group, services: allServices }: IndexedGroup) => {
        const services = allServices.filter((service) => match(tokens, service.searchText));
        if (services.length > 0) {
          return acc.concat([{ group, services }]);
        } else {
          return acc;
        }
      }, []);
    },
  };
};

type NotFoundProps = {
  readonly title: string;
  readonly titleL2?: string;
  readonly message?: string;
};

const NotFound: React.FC<NotFoundProps> = ({ title, titleL2, message }: NotFoundProps) => (
  <DismissKeyboard
    style={{
      flex: 1,
      justifyContent: 'flex-start',
      alignContent: 'center',
      paddingTop: 70,
      paddingLeft: 48,
      paddingRight: 48,
    }}
  >
    <H2s style={text.alignCenter}>{title}</H2s>
    {titleL2 !== undefined ? <H2s style={text.alignCenter}>{titleL2}</H2s> : null}
    <Spacer size="16" />
    {message !== undefined ? <Body style={text.alignCenter}>{message}</Body> : null}
  </DismissKeyboard>
);

export type SelectServiceScreenProps = {
  readonly services: ServicesGroup[];
  readonly onBackPressed?: () => void;
  readonly onServiceSelected?: (service: MergedService) => void;
};

export const SelectService: React.FC<SelectServiceScreenProps> = ({
  services,
  onBackPressed,
  onServiceSelected,
}: SelectServiceScreenProps) => {
  const [query, setQuery] = React.useState('');
  const queryIsEmpty = query.trim() === '';

  const [debouncedQuery, setDebouncedQuery] = React.useState('');

  const debounceFunc = React.useMemo(
    () =>
      debounce(100, (q: string) => {
        setDebouncedQuery(q);
      }),
    [],
  );

  React.useEffect(() => {
    debounceFunc(query);
  }, [query]);

  const index = React.useMemo(() => buildServiceSearchIndex(services), [services]);

  const [foundServices, setFoundServices] = React.useState(services);

  React.useEffect(() => {
    setFoundServices(index.search(debouncedQuery));
  }, [debouncedQuery]);

  const isKeyboardOpen = useKeyboardIsOpen();

  const dismissKeyboardCallback = React.useCallback(() => {
    if (isKeyboardOpen) {
      Keyboard.dismiss();
    }
  }, [isKeyboardOpen]);

  const showTitle = !isKeyboardOpen && queryIsEmpty;

  const timeZone = React.useMemo(() => IANAZone.create('Europe/Bucharest'), []);

  return (
    <>
      <MeroHeader title="Alege serviciu" canGoBack={onBackPressed !== undefined} onBack={onBackPressed} />
      <AvoidKeyboard style={{ flex: 1 }}>
        <DismissKeyboard>
          <View style={styles.hrPadding}>
            {showTitle ? (
              <>
                <Spacer size="16" />
                <H1>Alege serviciu</H1>
              </>
            ) : null}
            <Spacer size="24" />
            <SearchTextInput placeholder="Caută serviciu" value={query} onChange={setQuery} />
            <Spacer size="24" />
          </View>
        </DismissKeyboard>
        {foundServices.length > 0 ? (
          <GroupedServicesList
            services={foundServices}
            timeZone={timeZone}
            style={{ flex: 1 }}
            onServiceSelected={onServiceSelected}
            onScrollEndDrag={dismissKeyboardCallback}
            ListFooterComponent={<SafeAreaView edges={['bottom']} />}
          />
        ) : services.length > 0 ? (
          <NotFound
            title="Niciun rezultat pentru"
            titleL2={`"${query}"`}
            message="Verifică ca termenul de căutare să fie corect și încearcă din nou"
          />
        ) : (
          <NotFound
            title="Nu există servicii"
            message="Adaugă serviciile pe care le oferi clienților tăi din profilul tău de business"
          />
        )}
      </AvoidKeyboard>
    </>
  );
};

const SelectServiceScreen: React.FC<SelectServiceScreenProps> = (props) => (
  <ModalScreenContainer edges={['left', 'top', 'right']}>
    <SelectService {...props} />
  </ModalScreenContainer>
);

export default React.memo(SelectServiceScreen);
