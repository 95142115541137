import { AppError } from './appError';

export class NetworkError extends AppError<unknown> {
  constructor(message: string, cause: unknown) {
    super(message, cause);
  }

  public toString(): string {
    return `NetworkError("${this.message}", ${this.originalCause})`;
  }

  static is(e: unknown): e is NetworkError {
    return e instanceof NetworkError;
  }
}
