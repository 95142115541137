import React from 'react';
import { Svg, Path, G } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

const Retry = ({ color, size = 24 }: IconProps) => {
  return (
    <Svg width={size} height={size}>
      <G data-name="Group 6969">
        <G fill={color || '#fff'}>
          <Path d="m8.168 10.169-.736.628a4.806 4.806 0 0 1 8.053-2.2l1.134-1.134a6.413 6.413 0 0 0-10.77 3.04l-.529-.642-1.337.802 2.39 3.145 2.836-2.422ZM20.016 13.335l-2.242-3.221-2.79 2.509 1.072 1.192.679-.61a4.805 4.805 0 0 1-8.053 2.2l-1.133 1.134a6.413 6.413 0 0 0 10.754-2.995l.47.638Z" />
        </G>
        <Path data-name="Rectangle 2669" fill="none" d="M0 0h24v24H0z" />
      </G>
    </Svg>
  );
};

export default Retry;
