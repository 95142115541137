import { MembershipJson } from '../../memberships';
import { MembershipItemConsumptionLockDetails } from '../../memberships/membershipConsumptionLockDetails';
import { NoteDetails } from '../../pro';
import { CalendarEntry } from '../calendarEntry';
import { Recurrence } from '../calendarEntry/recurrence';
import { AppointmentEditableStatus } from './appointmentEditableStatus';
import { MeroUnits, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Appointment = CalendarEntry.Appointment &
  Recurrence.Any & {
    /**
     * Appointment editable status
     * WARNING: this field does not reflect user permissions, please check permissions independently
     */
    readonly editableStatus: AppointmentEditableStatus.Any;
    readonly membershipConsumptionLocks: Option<MembershipItemConsumptionLockDetails<MeroUnits.Any>[]>;
    readonly appointmentNotes: NoteDetails[];
  };

const JSON = t.intersection(
  [
    CalendarEntry.Appointment.JSON,
    Recurrence.Any.JSON,
    t.type(
      {
        editableStatus: AppointmentEditableStatus.Any.JSON,
        membershipConsumptionLocks: Option.json(t.array(MembershipJson.AnyMembershipItemConsumptionLockDetailsC)),
        appointmentNotes: t.array(NoteDetails.JSON),
      },
      '!',
    ),
  ],
  'Appointment',
);

export const Appointment = {
  JSON,
};
