import { Option } from '../../common';

/**
 * Keep up generating items
 */
type KeepUp<T> = {
  readonly stop: false;
  /**
   * Value to yield or None
   */
  readonly value: Option<T>;
};

/**
 * Stop generating items
 */
type Stop<T> = {
  readonly stop: true;
  /**
   * Value to yield or None
   */
  readonly value: Option<T>;
};

export type Any<T> = KeepUp<T> | Stop<T>;

export const keepUp = <T>(value: Option<T>): KeepUp<T> => {
  return {
    stop: false,
    value: value,
  };
};

export const stop = <T>(value: Option<T>): Stop<T> => {
  return {
    stop: true,
    value: value,
  };
};
