import { isDefined, Worker } from '@mero/api-sdk';
import { CalendarSlotsInterval } from '@mero/api-sdk/dist/calendar';
import { PageId, PageMemberPreview } from '@mero/api-sdk/dist/pages';
import { SubscriptionId } from '@mero/api-sdk/dist/payments';
import { SubscriptionSeat } from '@mero/api-sdk/dist/payments/subscription-seat';
import { SavedWorker } from '@mero/api-sdk/dist/workers';
import {
  Avatar,
  Button,
  Checkbox,
  colors,
  Column,
  FormCard,
  H1,
  Header,
  Icon,
  Line,
  ModalOverlay,
  Row,
  Select,
  SmallBody,
  Spacer,
  Title,
  useToast,
  SimpleListItem,
} from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform, ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import SelectCalendarSlotsIntervalScreen, {
  getSlotIntervalOptionTexts,
} from '../../../../../components/SelectCalendarSlotsIntervalScreen';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import { useKeyboardIsOpen } from '@mero/components/lib/hooks';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';
import usePromise from '../../../../../hooks/usePromise';

import {
  CALENDAR_INTERVAL_DEFAULT,
  MAX_APPOINTMENTS_PER_CLIENT_DEFAULT,
  MAX_WAITING_LIST_PER_CLIENT,
  MIN_HOURS_BEFORE_CLIENTS_CAN_CANCEL_APPOINTMENTS_DEFAULT,
} from '../../../../../contexts/AddNewProContext';
import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { ProsEditStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';
import { convertBusinessHoursToSchedule } from '../../../../../utils/schedule';
import AddSeatDialog from './AddSeatDialog';
import DeleteWorkerProfileDialog from './DeleteWorkerProfileDialog';
import InvitedProWarning from './InvitedProWarning';
import { styles } from './ProCalendarSettingsScreen.styles';

export type Props = StackScreenProps<ProsEditStackParamList, 'ProCalendar'>;

const ProCalendarSettingsScreen: React.FC<Props> = ({ route }) => {
  const isKeyboardOpen = useKeyboardIsOpen();
  const goBack = useGoBack();
  const { t } = useTranslation('pros');
  const { isPhone } = useMediaQueries();
  const toast = useToast();
  const { promise, reject, resolve } = usePromise();

  const { userId, workerId } = route.params;

  const [pageState, { getPageWorkersAndServices, reloadAsync }] = CurrentBusinessContext.useContext();

  const scrollRef = React.useRef<ScrollView>(null);
  const block = React.useRef(false);

  const [memberDetails, setMemberDetails] = React.useState<PageMemberPreview | null>(null);
  const [workerDetails, setWorkerDetails] = React.useState<SavedWorker | null>(null);
  const [shouldConfirmDeleteWorker, setShouldConfirmDeleteWorker] = React.useState(false);
  const [scrollToY, setScrollToY] = React.useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState(false);

  const [isActive, setIsActive] = React.useState(true);
  const [isPrivate, setIsPrivate] = React.useState(false);
  const [calendarInterval, setCalendarInterval] = React.useState(CALENDAR_INTERVAL_DEFAULT);
  const [calendarSlotsInterval, setCalendarSlotsInterval] = React.useState<CalendarSlotsInterval>({
    type: 'ServiceDuration',
  });
  const [maxAppointmentsPerClient, setMaxAppointmentsPerClient] = React.useState(MAX_APPOINTMENTS_PER_CLIENT_DEFAULT);
  const [minHoursBeforeClientsCanCancelAppointments, setMinHoursBeforeClientsCanCancelAppointments] = React.useState(
    MIN_HOURS_BEFORE_CLIENTS_CAN_CANCEL_APPOINTMENTS_DEFAULT,
  );
  const [minHoursBeforeTodayAppointments, setMinHoursBeforeTodayAppointments] = React.useState(
    MIN_HOURS_BEFORE_CLIENTS_CAN_CANCEL_APPOINTMENTS_DEFAULT,
  );
  const [maxWaitingListDaysPerClient, setMaxWaitingListDaysPerClient] = React.useState(MAX_WAITING_LIST_PER_CLIENT);
  const [addProInfo, setAddProInfo] = React.useState<
    (SubscriptionSeat & { expiresAt: Date; subscriptionId: SubscriptionId }) | null
  >(null);
  const [addNewSeat, setAddNewSeat] = React.useState(false);
  const [showAddSeatDialog, setShowAddSeatDialog] = React.useState(false);
  const [showSelectCalendarSlotsIntervalDialog, setShowSelectCalendarSlotsIntervalDialog] = React.useState(false);

  const calendarSlotsIntervalTexts = React.useMemo(
    () => getSlotIntervalOptionTexts(calendarSlotsInterval),
    [calendarSlotsInterval],
  );

  React.useEffect(() => {
    try {
      if (isKeyboardOpen && scrollToY !== undefined) {
        setScrollToY(undefined);
        scrollRef?.current?.scrollTo({ y: scrollToY, animated: true });
      }
    } catch (e: unknown) {
      log.exception(e);
    }
  }, [scrollToY, isKeyboardOpen, setScrollToY, scrollRef, scrollRef?.current]);

  const checkPageSeats = async (pageId: PageId) => {
    if (pageState.type !== 'Loaded' || !memberDetails) {
      return;
    }
    try {
      const page = pageState.page;
      const subscription = await meroApi.payments.getPageSubscription(pageId);

      if (!subscription) {
        return;
      }

      log.debug('Info seats', {
        seatsAvailable: subscription?.seatCount ?? 0,
        seatsUsed: page.workers.length,
      });

      // Using first worker from the list as there is no workerId used for this screen (yet, to be fixed)
      const memberWorker = page.workers.find((w) => w.user._id === memberDetails.user._id);
      const hadWorkerRole = isDefined(memberWorker);
      const isInvited = memberWorker ? Worker.isInvited(memberWorker, page.details) : false;

      // pages that are still in trial don't have subscription.seatCount defined
      // so avoid showing the pro-rata modal
      const isSeatRequired = !!subscription.seatCount
        ? hadWorkerRole
          ? subscription.seatCount < page.workers.length
          : subscription.seatCount <= page.workers.length
        : false;

      log.debug('Configu pro-rata', { hadWorkerRole, isInvited, isSeatRequired, memberDetails });

      if (isSeatRequired && !isInvited) {
        const seatInfo = await meroApi.payments.getSubscriptionSeatUpdateInfo({
          subscriptionId: subscription._id,
          newSeats: 1,
        });

        setAddProInfo({
          ...seatInfo.result,
          expiresAt: subscription.expires,
          subscriptionId: subscription._id,
        });
      }
    } catch (error) {
      log.error('Failed to check page seats', error);
      return undefined;
    }
  };

  const cancelAddNewSeat = () => {
    setShowAddSeatDialog(false);
    reject('addNewSeat');
    goBack();
  };

  const confirmAddNewSeat = () => {
    setAddNewSeat(true);
    setShowAddSeatDialog(false);
    resolve('addNewSeat');
  };

  const checkCanAddSeat = async (): Promise<{ canAddNewSeat: boolean; addNewSeat: boolean }> => {
    try {
      if (addNewSeat || addProInfo === null || !isActive) {
        return { canAddNewSeat: true, addNewSeat: addNewSeat };
      }

      if (!addProInfo.shouldConfirmNewPrice || addProInfo.proRataPrice === 0) {
        setAddNewSeat(true);

        return { canAddNewSeat: true, addNewSeat: true };
      }

      await promise('addNewSeat', () => {
        setShowAddSeatDialog(true);
      });

      return { canAddNewSeat: true, addNewSeat: true };
    } catch {
      return { canAddNewSeat: false, addNewSeat: addNewSeat };
    }
  };

  const toggleIsActive = () => {
    setIsActive(!isActive);
  };

  const toggleIsPrivate = () => {
    setIsPrivate(!isPrivate);
  };

  const saveChanges = async (params?: { deleteConfirmed?: true }): Promise<void> => {
    if (pageState.type !== 'Loaded') {
      return;
    }

    const result = await checkCanAddSeat();
    if (!result.canAddNewSeat) {
      return;
    }

    setIsLoading(true);
    try {
      if (workerDetails) {
        if (isActive) {
          await meroApi.calendar.updateSettings({
            pageId: pageState.page.details._id,
            calendarId: workerDetails.calendar._id,
            newSettings: {
              ...workerDetails.calendar.settings,
              isPrivate,
              calendarInterval: {
                ...workerDetails.calendar.settings.calendarInterval,
                value: calendarInterval,
              },
              slotsInterval: calendarSlotsInterval,
              maxAppointmentsPerClient,
              minHoursBeforeClientsCanCancelAppointments,
              minHoursBeforeTodayAppointments,
              maxWaitingListDaysPerClient,
            },
          });
        } else {
          if (params?.deleteConfirmed) {
            setShouldConfirmDeleteWorker(false);
            await meroApi.pages.deletePageMemberWorker({
              pageId: pageState.page.details._id,
              userId,
              workerId: workerDetails._id,
            });
          } else {
            setShouldConfirmDeleteWorker(true);
            return;
          }
        }
      } else if (isActive) {
        const workerId = await meroApi.pages.createPageMemberWorker({
          pageId: pageState.page.details._id,
          userId,
          services: [],
          addNewSeat: result.addNewSeat,
        });
        const workerDetails = await meroApi.pages.getPageWorker({ pageId: pageState.page.details._id, workerId });
        await Promise.all([
          meroApi.calendar.updateSettings({
            pageId: pageState.page.details._id,
            calendarId: workerDetails.calendar._id,
            newSettings: {
              ...workerDetails.calendar.settings,
              isPrivate,
              calendarInterval: {
                ...workerDetails.calendar.settings.calendarInterval,
                value: calendarInterval,
              },
              maxAppointmentsPerClient,
              minHoursBeforeClientsCanCancelAppointments,
              minHoursBeforeTodayAppointments,
              maxWaitingListDaysPerClient,
            },
          }),
          meroApi.pro.workingSchedule.editWorkingSchedule({
            pageId: pageState.page.details._id,
            workerIds: [workerId],
            workingSchedule: convertBusinessHoursToSchedule(pageState.page.details.businessHours),
            overrideSpecificDaySchedules: true,
            startDateSetByUser: true,
            workerScheduleId: undefined,
          }),
        ]);
      }

      await Promise.all([getPageWorkersAndServices(pageState.page.details._id), reloadAsync()]);

      toast.show({
        type: 'success',
        text: t('savedSuccessfully'),
      });

      goBack();
    } catch (error) {
      toast.show({
        type: 'error',
        text: t('errorDetails'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      const member = pageState.page.members.find((member) => member.user._id === userId);
      const worker = pageState.page.workers.find((worker) => worker._id === workerId);

      setMemberDetails(member ?? null);

      if (worker) {
        setIsActive(true);
        setIsPrivate(worker.calendar.settings.isPrivate);
        setCalendarInterval(worker.calendar.settings.calendarInterval.value);
        setMaxAppointmentsPerClient(worker.calendar.settings.maxAppointmentsPerClient);
        setMinHoursBeforeClientsCanCancelAppointments(
          worker.calendar.settings.minHoursBeforeClientsCanCancelAppointments,
        );
        setMinHoursBeforeTodayAppointments(worker.calendar.settings.minHoursBeforeTodayAppointments);
        setCalendarSlotsInterval(worker.calendar.settings.slotsInterval ?? { type: 'ServiceDuration' });
        setMaxWaitingListDaysPerClient(
          worker.calendar.settings.maxWaitingListDaysPerClient ?? MAX_WAITING_LIST_PER_CLIENT,
        );
      } else {
        setIsActive(false);
      }

      setWorkerDetails(worker ?? null);
    }
  }, [pageState.type]);

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      checkPageSeats(pageState.page.details._id);
    }
  }, [memberDetails]);

  const calendarAvailabilities = React.useMemo(
    () =>
      new Array(49).fill(2).map((v, i) => ({
        value: v + i,
        label: t('calendarAvailabilityWeek', { count: v + i }),
      })),
    [],
  );

  const appointmentsPerClient = React.useMemo(
    () =>
      new Array(30).fill(1).map((v, i) => ({
        value: v + i,
        label: t('appointmentPerClientLabel', { count: v + i }),
      })),
    [],
  );

  const cancelHours = React.useMemo(
    () =>
      new Array(25).fill(0).map((v, i) => ({
        value: v + i,
        label: t('cancelHourLabel', { count: v + i }),
      })),
    [],
  );

  const hoursBeforeAppointment = React.useMemo(
    () => [
      ...new Array(25).fill(0).map((v, i) => ({
        value: v + i,
        label: t('beforeAppointmentLabel', { count: v + i }),
      })),
      {
        value: 48,
        label: t('beforeAppointmentLabel', { count: 48 }),
      },
    ],
    [],
  );

  const waitingListDaysPerClient = React.useMemo(
    () => [
      ...new Array(10).fill(0).map((v, i) => ({
        value: i + 1,
        label: i === 0 ? t('waitingListDayPerClientLabel') : t('waitingListDaysPerClientLabel', { count: i + 1 }),
      })),
    ],
    [],
  );

  const isWorkerRole = React.useMemo(() => {
    if (pageState.type === 'Loaded') {
      return memberDetails?.roles.every((r) => pageState.page.roles.find((role) => role.id === r.id)?.isWorkerRole);
    }

    return false;
  }, [memberDetails?.roles]);
  const isInvited = React.useMemo(() => {
    if (pageState.type === 'Loaded' && workerDetails) {
      return Worker.isInvited(workerDetails, pageState.page.details);
    }

    return false;
  }, [workerDetails, pageState.type]);

  const synchronizeCalendar = async () => {
    if (pageState.type !== 'Loaded' || !workerDetails || !workerId) {
      return;
    }
    try {
      const url = meroApi.calendar.getCalendarIcsUrl(workerDetails.calendar._id, workerId);
      const webcalUrl = url.replace(/https?:\/\//, 'webcal://');
      if (Platform.OS === 'ios') {
        await Linking.openURL(webcalUrl);
      } else {
        const googleCalendarUrl = `https://calendar.google.com/calendar/r?cid=${encodeURIComponent(webcalUrl)}`;
        await Linking.openURL(googleCalendarUrl);
      }
    } catch (error) {
      log.error('Failed to open calendar link', error);
    }
  };

  const isEditable = React.useMemo(
    () =>
      workerDetails && pageState.type === 'Loaded' ? !Worker.isInvited(workerDetails, pageState.page.details) : true,
    [workerDetails, pageState],
  );

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('profileCalendar')}
        RightComponent={() => (
          <Column style={{ paddingRight: 24 }}>
            <Avatar
              size={32}
              source={memberDetails?.user.profile.photo?.thumbnail}
              firstname={memberDetails?.user.profile.firstname ?? ''}
              lastname={memberDetails?.user.profile.lastname ?? ''}
            />
          </Column>
        )}
      />
      {(workerDetails || memberDetails) && (
        <ScrollView ref={scrollRef}>
          <Column style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1, paddingBottom: 96 }}>
            <H1 style={{ paddingHorizontal: 8 }}>{t('profileCalendar')}</H1>
            <Spacer size={24} />
            {!isEditable && (
              <>
                <Spacer size={8} />
                <InvitedProWarning />
                <Spacer size={16} />
              </>
            )}
            <FormCard rounded>
              <Row style={{ paddingTop: 8 }}>
                <Column>
                  <Checkbox
                    value={isActive}
                    color="blue"
                    disabled={Boolean((isWorkerRole && workerDetails) || isInvited)}
                    onValueChange={toggleIsActive}
                  />
                </Column>
                <TouchableOpacity
                  style={{ paddingLeft: 12, flex: 1 }}
                  onPress={toggleIsActive}
                  disabled={Boolean((isWorkerRole && workerDetails) || isInvited)}
                >
                  <Title>{t('isActiveTitle')}</Title>
                  <SmallBody style={{ color: colors.COMET, paddingTop: 4 }}>{t('isActiveDescription')}</SmallBody>
                </TouchableOpacity>
              </Row>
              {isActive && (
                <>
                  <Spacer size={12} />
                  <Row style={{ paddingTop: 8 }}>
                    <Column>
                      <Checkbox value={isPrivate} color="blue" disabled={!isEditable} onValueChange={toggleIsPrivate} />
                    </Column>
                    <TouchableOpacity
                      style={{ paddingLeft: 12, flex: 1 }}
                      disabled={!isEditable}
                      onPress={toggleIsPrivate}
                    >
                      <Title>{t('isPrivateTitle')}</Title>
                      <SmallBody style={{ color: colors.COMET, paddingTop: 4 }}>{t('isPrivateDescription')}</SmallBody>
                    </TouchableOpacity>
                  </Row>
                </>
              )}
            </FormCard>
            {isActive && (
              <>
                <Spacer size={16} />
                <FormCard paddings="none" rounded>
                  <Spacer size={24} />
                  <Column style={{ paddingHorizontal: 16 }}>
                    <InputWithLabel label={t('calendarSlotsIntervalsTitle')}>
                      <TouchableOpacity
                        onPress={() => {
                          setShowSelectCalendarSlotsIntervalDialog(true);
                        }}
                        style={{
                          borderWidth: 1,
                          borderStyle: 'solid',
                          borderColor: colors.GEYSER,
                          borderRadius: 5,
                          paddingLeft: 15,
                          paddingRight: 6,
                        }}
                        disabled={!isEditable}
                      >
                        <SimpleListItem icon="arrow-right" iconPosition="right" paddingTop={12} paddingBottom={12}>
                          <Title>{calendarSlotsIntervalTexts.title}</Title>
                          {calendarSlotsIntervalTexts.subtitle ? (
                            <SmallBody style={{ color: colors.COMET, paddingTop: 4 }}>
                              {calendarSlotsIntervalTexts.subtitle}
                            </SmallBody>
                          ) : null}
                        </SimpleListItem>
                      </TouchableOpacity>
                    </InputWithLabel>
                  </Column>
                  <Spacer size={24} />
                  <Line />
                  <Spacer size={24} />
                  <Column style={{ paddingHorizontal: 16 }}>
                    <InputWithLabel label={t('calendarIntervalTitle')}>
                      <Select
                        editable={isEditable}
                        items={calendarAvailabilities}
                        value={calendarInterval}
                        onChange={(v) => setCalendarInterval(v)}
                      />
                    </InputWithLabel>
                    <SmallBody style={{ color: colors.COMET, paddingTop: 4 }}>
                      {t('calendarIntervalDescription')}
                    </SmallBody>
                  </Column>
                  <Spacer size={24} />
                  <Line />
                  <Spacer size={24} />
                  <Column style={{ paddingHorizontal: 16 }}>
                    <InputWithLabel label={t('maxAppointmentsPerClientTitle')}>
                      <Select
                        editable={isEditable}
                        items={appointmentsPerClient}
                        value={maxAppointmentsPerClient}
                        onChange={(v) => setMaxAppointmentsPerClient(v)}
                      />
                    </InputWithLabel>
                    <SmallBody style={{ color: colors.COMET, paddingTop: 4 }}>
                      {t('maxAppointmentsPerClientDescription')}
                    </SmallBody>
                  </Column>
                  <Spacer size={24} />
                  <Line />
                  <Spacer size={24} />
                  <Column style={{ paddingHorizontal: 16 }}>
                    <InputWithLabel label={t('minHoursBeforeClientsCanCancelAppointmentsTitle')}>
                      <Select
                        editable={isEditable}
                        items={cancelHours}
                        value={minHoursBeforeClientsCanCancelAppointments}
                        onChange={(v) => setMinHoursBeforeClientsCanCancelAppointments(v)}
                      />
                    </InputWithLabel>
                    <SmallBody style={{ color: colors.COMET, paddingTop: 4 }}>
                      {t('minHoursBeforeClientsCanCancelAppointmentsDescription')}
                    </SmallBody>
                  </Column>
                  <Spacer size={24} />
                  <Line />
                  <Spacer size={24} />
                  <Column style={{ paddingHorizontal: 16 }}>
                    <InputWithLabel label={t('minHoursBeforeTodayAppointmentsTitle')}>
                      <Select
                        editable={isEditable}
                        items={hoursBeforeAppointment}
                        value={minHoursBeforeTodayAppointments}
                        onChange={(v) => setMinHoursBeforeTodayAppointments(v)}
                      />
                    </InputWithLabel>
                    <SmallBody style={{ color: colors.COMET, paddingTop: 4 }}>
                      {t('minHoursBeforeTodayAppointmentsDescription')}
                    </SmallBody>
                  </Column>
                  <Spacer size={24} />
                  <Line />
                  <Spacer size={24} />
                  <Column style={{ paddingHorizontal: 16 }}>
                    <InputWithLabel label={t('maxWaitingListDaysPerClientTitle')}>
                      <Select
                        editable={isEditable}
                        items={waitingListDaysPerClient}
                        value={maxWaitingListDaysPerClient}
                        onChange={(v) => setMaxWaitingListDaysPerClient(v)}
                      />
                    </InputWithLabel>
                    <SmallBody style={{ color: colors.COMET, paddingTop: 4 }}>
                      {t('maxWaitingListDaysPerClientDescription')}
                    </SmallBody>
                  </Column>
                  <Spacer size={24} />
                </FormCard>
              </>
            )}
          </Column>
          <Spacer size="16" />
        </ScrollView>
      )}
      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button
              disabled={block.current || isLoading || !isEditable}
              text={t('saveChanges')}
              onPress={() => saveChanges()}
            />
          ) : (
            <Button
              disabled={block.current || isLoading || !isEditable}
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={t('saveChanges')}
              onPress={() => saveChanges()}
            />
          )}
        </SafeAreaView>
      </FormCard>
      {shouldConfirmDeleteWorker && (
        <DeleteWorkerProfileDialog
          onSuccess={() => {
            setShouldConfirmDeleteWorker(false);
            saveChanges({ deleteConfirmed: true });
          }}
          onCancel={() => {
            setShouldConfirmDeleteWorker(false);
          }}
        />
      )}
      {showAddSeatDialog && addProInfo ? (
        <AddSeatDialog
          onSuccess={confirmAddNewSeat}
          onCancel={cancelAddNewSeat}
          addonPrice={addProInfo.addonPrice}
          totalPrice={addProInfo.proRataPrice}
          expiresAt={addProInfo.expiresAt}
        />
      ) : null}
      {showSelectCalendarSlotsIntervalDialog ? (
        <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
          <SelectCalendarSlotsIntervalScreen
            slotsInterval={calendarSlotsInterval}
            workerDetails={workerDetails ?? undefined}
            onBackPressed={() => {
              setShowSelectCalendarSlotsIntervalDialog(false);
            }}
            onIntervalSelected={(interval) => {
              setShowSelectCalendarSlotsIntervalDialog(false);
              setCalendarSlotsInterval(interval);
            }}
          />
        </ModalOverlay>
      ) : null}
    </ModalScreenContainer>
  );
};

export default ProCalendarSettingsScreen;
