import { CustomFieldId } from '../customFieldId';
import { JSONable, optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type ImageUpload = {
  /**
   * Custom field id
   */
  readonly _id: CustomFieldId;
  readonly name: string;
  readonly required: boolean;
  readonly type: 'ImageUpload';
  readonly response:
    | {
        readonly imageIds: string[];
      }
    | undefined;
};

const JSON: t.Type<ImageUpload, JSONable> = t.strict(
  {
    _id: CustomFieldId.JSON,
    name: t.string,
    required: t.boolean,
    type: t.literal('ImageUpload'),
    response: optionull(
      t.type(
        {
          imageIds: t.array(t.string),
        },
        'Response',
      ),
    ),
  },
  'ImageUpload',
);

export const ImageUpload = {
  JSON,
};
