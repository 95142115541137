import { colors } from '@/styles';
import { ClientImage } from '@mero/api-sdk';
import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Image, Pressable, ScrollView, useWindowDimensions, View } from 'react-native';

import ModalScreenContainer from '../../../components/ModalScreenContainer';
import {
  Body,
  Button,
  Column,
  FormCard,
  H1,
  H2s,
  ImageGallery,
  MeroHeader,
  Row,
  SafeAreaView,
} from '../../../components/shared';
import Gradient from '@/components/Gradient';
import Icon from '@/components/Icon';

import { CompositeNavigationProp, RouteProp } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';

import { useEscPressWeb } from '../../../hooks/useEscPressWeb';
import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { NoteCreationContext } from '../../../contexts/NoteContext';
import { ClientNoteStackParamList, RootStackParamList } from '../../../types';
import styles from './styles';

type NoteGalleryProps = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<RootStackParamList, 'Authorized'>,
    StackNavigationProp<ClientNoteStackParamList, 'NoteGalleryScreen'>
  >;
  route: RouteProp<ClientNoteStackParamList, 'NoteGalleryScreen'>;
};

const NoteGalleryScreen: React.FC<NoteGalleryProps> = ({ route }) => {
  const [{ noteImages, clientImages }, { setNoteImages, loadClientImages }] = NoteCreationContext.useContext();
  const { t } = useTranslation('gallery');
  const scrollViewRef = useRef<ScrollView | null>(null);
  const [selectedImages, setSelectedImages] = useState<ClientImage[]>([]);
  const [loading, setLoading] = useState(true);
  const [showGallerySlider, setShowGallerySlider] = useState(false);

  const [pageState] = CurrentBusinessContext.useContext();
  const { isPhone } = useMediaQueries();
  const { width } = useWindowDimensions();

  // 24px padding left and right, 6px column gap
  const imageSize = ((width > 768 ? 500 : width) - 2 * 24 - 2 * 6) / 3;
  const goBack = useGoBack();
  const pageId = route.params?.pageId;
  const clientId = route.params?.clientId;
  const filteredImages = clientImages.filter((image) => !noteImages.some((noteImage) => noteImage._id === image._id));

  useEscPressWeb({
    onPress: goBack,
  });

  useEffect(() => {
    if (pageState.type === 'Loaded') {
      loadClientImages();
      setLoading(false);
    }
  }, [pageState.type]);

  const handleSelectImages = () => {
    setNoteImages(selectedImages);
    goBack();
  };

  const handleImagePress = (image: ClientImage) => {
    const isSelected = selectedImages.some((selectedImage) => selectedImage._id === image._id);

    if (isSelected) {
      setSelectedImages(selectedImages.filter((selectedImage) => selectedImage._id !== image._id));
    } else {
      setSelectedImages([...selectedImages, image]);
    }
  };

  const isSelected = (image: ClientImage) => {
    return selectedImages.some((selectedImage) => selectedImage._id === image._id);
  };

  return (
    <ModalScreenContainer style={styles.root}>
      <MeroHeader canGoBack onBack={goBack} title="Alege imagini" />
      {loading && (
        <Column justifyContent="center" alignItems="center" style={styles.emptyContainer}>
          <ActivityIndicator size="large" />
        </Column>
      )}
      {!loading && filteredImages.length === 0 && (
        <Column justifyContent="center" alignItems="center" style={styles.emptyContainer} gap={32}>
          <Column gap={8} alignItems="center">
            <H2s>Nu există imagini în galerie</H2s>
            <Body style={styles.noPhotosBody}>
              Adaugă imagini in galeria clientului pentru a le putea asocia la o notiță
            </Body>
          </Column>
        </Column>
      )}

      {!loading && filteredImages.length > 0 && (
        <>
          <ScrollView style={styles.container} ref={scrollViewRef} contentContainerStyle={styles.contentContainer}>
            <View style={styles.header}>
              <H1>Alege imagini</H1>
              <Body>Doar imaginile care nu sunt deja asociate altor notițe pot fi alese</Body>
            </View>
            <Column style={styles.content}>
              <Column>
                <Row flexWrap="wrap" gap={6}>
                  {filteredImages.map((image: ClientImage) => {
                    return (
                      <Pressable key={image._id} onPress={() => handleImagePress(image)}>
                        <Image
                          source={{ uri: image.large }}
                          style={[styles.image, { width: imageSize, height: imageSize }]}
                        />

                        <Column style={styles.imageOverlay}>
                          {/* <Gradient name="ClientImageGradient" width={imageSize - 1} /> */}
                          <Pressable style={styles.checkButton} onPress={() => handleImagePress(image)}>
                            {isSelected(image) && <Icon name="Checkmark" color={colors.WHITE} />}
                            {!isSelected(image) && <View style={styles.unselectedOverlay} />}
                          </Pressable>
                        </Column>
                      </Pressable>
                    );
                  })}
                </Row>
              </Column>
            </Column>
          </ScrollView>
          <FormCard dropShaddow paddings="button" style={[styles.footer, !isPhone && styles.modalBorderBottom]}>
            <SafeAreaView edges={['bottom']}>
              <Button
                text={`Adaugă${selectedImages.length ? ' ' : ''}${selectedImages.length || ''}${
                  selectedImages.length === 1 ? ' fotografie' : ' fotografii'
                }`}
                onPress={handleSelectImages}
              />
            </SafeAreaView>
          </FormCard>
        </>
      )}
      <ImageGallery
        onClose={() => setShowGallerySlider(false)}
        showGallery={showGallerySlider}
        data={clientImages.map((image) => ({ url: image.large }))}
      />
    </ModalScreenContainer>
  );
};

export default NoteGalleryScreen;
