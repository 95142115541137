import { PublicFeedbackDetailsWithAppointment, WorkerId, PageId } from '@mero/api-sdk';
import * as React from 'react';

import { meroApi } from '../contexts/AuthContext';

export const StarsToScore = {
  allStars: undefined,
  oneStar: 1,
  twoStar: 2,
  threeStar: 3,
  fourStar: 4,
  fiveStar: 5,
} as const;

export const STARS_FILTERS = ['allStars', 'oneStar', 'twoStar', 'threeStar', 'fourStar', 'fiveStar'] as const;
export type StarFilter = (typeof STARS_FILTERS)[number];

export type UseReviewsProps = {
  pageId: PageId;
  onReviewsUpdate?: (filter: StarFilter, worker?: WorkerId) => void;
};

export const useReviews = ({ pageId, onReviewsUpdate }: UseReviewsProps) => {
  const [selectedReview, setSelectedReview] = React.useState<PublicFeedbackDetailsWithAppointment | undefined>(
    undefined,
  );
  const [selectedWorker, setSelectedWorker] = React.useState<WorkerId | undefined>(undefined);
  const [scoreCounts, setScoreCounts] = React.useState<Record<number, number>>({});
  const [showStarsFilter, setShowStarsFilter] = React.useState(false);
  const [starFilter, setStarFilter] = React.useState<StarFilter>('allStars');
  const [showMenu, setShowMenu] = React.useState(false);
  const [showReplyDialog, setShowReplyDialog] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const filterEnabled = React.useRef<boolean>(false);

  const loadReviewsScoreCounts = async () => {
    try {
      const scoreCount = await meroApi.pages.getPageFeedbackScoreCounts({
        pageId,
        workerId: selectedWorker,
      });
      setScoreCounts(scoreCount);
    } catch {}
  };

  const calculateAverage = React.useMemo(() => {
    const total = Object.values(scoreCounts).reduce((acc, curr) => acc + curr, 0);
    const average = Object.entries(scoreCounts).reduce((acc, [score, count]) => acc + count * Number(score), 0);
    const roundedAverage = Math.round((average / total) * 100) / 100;
    return isNaN(roundedAverage) ? 0 : roundedAverage;
  }, [scoreCounts]);

  const clearSelectedReview = React.useCallback(() => {
    setSelectedReview(undefined);
  }, []);

  const addReply = React.useCallback((review: PublicFeedbackDetailsWithAppointment) => {
    setSelectedReview(review);
    setShowReplyDialog(true);
  }, []);

  const showReviewMenu = React.useCallback((review: PublicFeedbackDetailsWithAppointment) => {
    setSelectedReview(review);
    setShowMenu(true);
  }, []);

  const hideReviewMenu = React.useCallback(() => {
    setShowMenu(false);
  }, []);

  const hideStarsFilter = React.useCallback(() => {
    setShowStarsFilter(false);
  }, []);

  const changeReply = React.useCallback(() => {
    setShowReplyDialog(true);
  }, []);

  const hideReplyDialog = React.useCallback(() => {
    setShowReplyDialog(false);
  }, []);

  const deleteReply = React.useCallback(() => {
    setShowDeleteDialog(true);
  }, []);

  const hideDeleteDialog = React.useCallback(() => {
    setShowDeleteDialog(false);
  }, []);

  const handleReplySuccess = React.useCallback(() => {
    hideReplyDialog();
    clearSelectedReview();
    onReviewsUpdate?.(starFilter);
  }, [hideReplyDialog, clearSelectedReview, onReviewsUpdate]);

  const handleDeleteSuccess = React.useCallback(() => {
    hideDeleteDialog();
    clearSelectedReview();
    onReviewsUpdate?.(starFilter);
  }, [hideDeleteDialog, clearSelectedReview, onReviewsUpdate]);

  const handleReplyCancel = React.useCallback(() => {
    hideReplyDialog();
    clearSelectedReview();
  }, [hideReplyDialog, clearSelectedReview]);

  const handleDeleteCancel = React.useCallback(() => {
    hideDeleteDialog();
    clearSelectedReview();
  }, [hideDeleteDialog, clearSelectedReview]);

  const toggleStarsFilter = React.useCallback(() => {
    setShowStarsFilter((prev) => !prev);
  }, []);

  const handleSelectStarsFilter = React.useCallback(
    (filter: StarFilter) => {
      setStarFilter(filter);
      toggleStarsFilter();
      onReviewsUpdate?.(filter, selectedWorker);
    },
    [toggleStarsFilter, onReviewsUpdate, selectedWorker],
  );

  const updateSelectedWorker = React.useCallback(
    (worker: WorkerId) => {
      setSelectedWorker(worker);
      onReviewsUpdate?.(starFilter, worker);
    },
    [onReviewsUpdate, starFilter],
  );

  React.useEffect(() => {
    if (starFilter !== 'allStars') {
      filterEnabled.current = true;
      loadReviewsScoreCounts();
      onReviewsUpdate?.(starFilter, selectedWorker);
    } else if (filterEnabled.current) {
      loadReviewsScoreCounts();
      onReviewsUpdate?.('allStars', selectedWorker);
      filterEnabled.current = false;
    }
  }, [starFilter, onReviewsUpdate, selectedWorker]);

  return {
    starFilter,
    showStarsFilter,
    selectedReview,
    showMenu,
    showReplyDialog,
    showDeleteDialog,
    handleSelectStarsFilter,
    addReply,
    showReviewMenu,
    hideReviewMenu,
    changeReply,
    hideStarsFilter,
    deleteReply,
    handleReplySuccess,
    handleDeleteSuccess,
    handleReplyCancel,
    handleDeleteCancel,
    toggleStarsFilter,
    scoreCounts,
    calculateAverage,
    updateSelectedWorker,
    selectedWorker,
  };
};
