import {
  AppointmentId,
  ClientImage,
  ClientImageId,
  NoteDetails,
  NoteId,
  NotePreview,
  Paged,
  WorkerId,
} from '@mero/api-sdk';
import { UserAppointment } from '@mero/api-sdk/dist/calendar';
import { ClientId, ClientProfile } from '@mero/api-sdk/dist/clients';
import { PageId } from '@mero/api-sdk/dist/pages';
import { ClientImageNotePreview } from '@mero/api-sdk/dist/pro/clientImages/clientImageNotePreview';
import { createModelContext } from '@mero/components';
import { NonEmptyString } from 'io-ts-types';
import * as React from 'react';

import log from '../../utils/log';
import { meroApi } from '../AuthContext';

export type ImageBookingDetails = {
  _id: AppointmentId;
  occurrenceIndex: number;
  start: Date;
  worker?: {
    _id: WorkerId;
    firstname: string;
    lastname: string;
  };
};

export type ImageNoteDetails = {
  id?: NoteId;
  text?: string;
};

type PageAppointmentsWithTotals = Paged<UserAppointment[]> & {
  readonly futureBookingsCount: number;
  readonly pastBookingsCount: number;
};

export type ClientImageDetailsState = {
  readonly type: 'New' | 'Loading' | 'Loaded' | 'Failed';
  readonly clientId?: ClientId;
  readonly pageId?: PageId;
  readonly clientImages: ClientImageNotePreview[];
  readonly selectedNote?: NotePreview;
  readonly selectedBooking?: ImageBookingDetails;
  readonly error?: unknown;
  readonly noteText?: string;
  readonly client?: ClientProfile;
  readonly clientAppointments?: PageAppointmentsWithTotals; // Made readonly to match other state properties
};

const defaultState = (): ClientImageDetailsState => ({
  type: 'New',
  clientImages: [],
  selectedNote: undefined,
  selectedBooking: undefined,
  clientAppointments: undefined,
  clientId: undefined,
  pageId: undefined,
  error: undefined,
  client: undefined,
  noteText: undefined,
});

export const ClientImageDetailsContext = createModelContext(
  defaultState(),
  {
    setLoading: (state, params: { clientId: ClientId; pageId: PageId }): ClientImageDetailsState => ({
      ...defaultState(),
      type: 'Loading',
      clientId: params.clientId,
      pageId: params.pageId,
    }),

    setLoaded: (
      state,
      params: {
        client: ClientProfile;
        clientId: ClientId;
        pageId: PageId;
        clientImages: ClientImageNotePreview[];
      },
    ): ClientImageDetailsState => ({
      ...defaultState(),
      type: 'Loaded',
      clientId: params.clientId,
      pageId: params.pageId,
      client: params.client,
      clientImages: params.clientImages,
    }),

    setFailed: (state, error: unknown): ClientImageDetailsState => ({
      ...defaultState(),
      type: 'Failed',
      error,
    }),
    setImageNote: (state, note?: NotePreview): ClientImageDetailsState => ({
      ...state,
      selectedNote: note,
      noteText: note?.text,
    }),

    setInitialData: (state, params: { clientId: ClientId; pageId: PageId }): ClientImageDetailsState => ({
      ...defaultState(),
      type: 'Loaded',
      clientId: params.clientId,
      pageId: params.pageId,
    }),

    setBooking: (state, booking: UserAppointment): ClientImageDetailsState => {
      if (state.type === 'Loaded') {
        return {
          ...state,
          selectedBooking: {
            _id: booking._id,
            occurrenceIndex: booking.occurrenceIndex || 0,
            start: booking.start,
            worker:
              booking.worker && booking.worker.firstname && booking.worker.lastname
                ? {
                    _id: booking.worker._id,
                    firstname: booking.worker.firstname,
                    lastname: booking.worker.lastname,
                  }
                : undefined,
          },
        };
      }
      return state;
    },

    clearBooking: (state): ClientImageDetailsState => {
      if (state.type === 'Loaded') {
        return {
          ...state,
          selectedBooking: undefined,
        };
      }
      return state;
    },

    clearNote: (state): ClientImageDetailsState => {
      if (state.type === 'Loaded') {
        return {
          ...state,
          selectedNote: undefined,
        };
      }
      return state;
    },

    setNoteText: (state, text: NonEmptyString): ClientImageDetailsState => {
      if (state.type === 'Loaded') {
        return {
          ...state,
          noteText: text,
        };
      }
      return state;
    },

    update(state, params: Partial<Omit<ClientImageDetailsState, 'type'>>): ClientImageDetailsState {
      if (state.type === 'Loaded') {
        return {
          ...state,
          ...params,
        };
      }
      return state;
    },

    mutate: (s, fn: (s: ClientImageDetailsState) => ClientImageDetailsState): ClientImageDetailsState => fn(s),
    run: (s, f: (s: ClientImageDetailsState) => void) => {
      f(s);
      return s;
    },
  },
  (dispatch) => {
    const loadClientImages = async (clientId: ClientId, pageId: PageId) => {
      dispatch.run(async (state) => {
        try {
          const clientImages = await meroApi.pro.clientImages.getClientImages({
            pageId,
            clientId,
          });

          const client = await meroApi.clients.getClientProfileById(clientId);

          dispatch.setLoaded({
            clientId,
            pageId,
            clientImages,
            client,
          });
        } catch (error) {
          log.error('Failed to load client images', error);
          dispatch.setFailed(error);
        }
      });
    };

    const deleteClientImage = async (imageId: ClientImageId) => {
      dispatch.run(async (state) => {
        if (state.type === 'Loaded') {
          try {
            await meroApi.pro.clientImages.deleteClientImage({
              pageId: state.pageId!,
              clientId: state.clientId!,
              imageId,
            });
            dispatch.update({
              ...state,
              selectedNote: undefined,
              selectedBooking: undefined,
              clientImages: state.clientImages.filter((img) => img._id !== imageId),
            });
          } catch (error) {
            log.error('Failed to delete client image', error);
          }
        }
      });
    };

    const updateImageWithNote = async (imageId: ClientImageId, note: NotePreview) => {
      dispatch.run(async (state) => {
        if (state.type === 'Loaded') {
          try {
            dispatch.update({
              ...state,
              selectedNote: note,
              selectedBooking: note.appointment
                ? {
                    _id: note.appointment._id,
                    occurrenceIndex: note.appointment.occurrenceIndex ?? 0,
                    start: note.appointment.start,
                  }
                : undefined,
            });
          } catch (error) {
            log.error('Failed to update image note', error);
          }
        }
      });
    };

    const updateImageWithBooking = async (imageId: ClientImageId, booking: ImageBookingDetails) => {
      dispatch.run(async (state) => {
        if (state.type === 'Loaded') {
          try {
            dispatch.update({
              ...state,
              selectedBooking: booking,
            });
          } catch (error) {
            log.error('Failed to update image booking', error);
          }
        }
      });
    };

    const updateSelectedImageNote = async (note?: NotePreview) => {
      dispatch.run(async (state) => {
        if (state.type === 'Loaded') {
          try {
            dispatch.update({
              ...state,
              selectedNote: note,
              noteText: note?.text,
              selectedBooking: note?.appointment
                ? {
                    _id: note.appointment._id,
                    occurrenceIndex: note.appointment.occurrenceIndex ?? 0,
                    start: note.appointment.start,
                    worker: note.appointment.worker,
                  }
                : undefined,
            });
          } catch (error) {
            log.error('Failed to update image note', error);
          }
        }
      });
    };

    const removeClientNote = async (deleteNoteImages?: boolean) => {
      dispatch.run(async (state) => {
        if (!state.clientId || !state.pageId || !state.selectedNote?._id) {
          throw new Error('Missing required fields');
        }

        try {
          await meroApi.pro.notes.deleteClientNote({
            clientId: state.clientId,
            pageId: state.pageId,
            noteId: state.selectedNote._id,
            deleteNoteImages: deleteNoteImages || false,
          });

          dispatch.update({
            ...state,
            selectedNote: undefined,
            noteText: undefined,
            selectedBooking: undefined,
          });
        } catch (error) {
          log.error('Failed to remove client note', error);
        }
      });
    };

    const updateClientImageNote = async (text: string, imageId: ClientImageId) => {
      dispatch.run(async (state) => {
        if (state.type === 'Loaded') {
          try {
            if (!state.clientId || !state.pageId) {
              throw new Error('Missing required fields');
              return;
            }

            const payload = {
              noteId: state.selectedNote?._id as NoteId,
              text: text as NonEmptyString,
              clientId: state.clientId,
              pageId: state.pageId,
              imageIds: [imageId],
              appointment: state.selectedBooking
                ? {
                    _id: state.selectedBooking._id,
                    occurrenceIndex: state.selectedBooking.occurrenceIndex,
                  }
                : undefined,
            };

            await meroApi.pro.notes.updateClientNote(payload);

            // after it's updated, we need to refresh the image in the state
            const image = await meroApi.pro.clientImages.getClientImageById({
              pageId: state.pageId,
              clientId: state.clientId,
              imageId,
            });

            dispatch.update({
              ...state,
              clientImages: state.clientImages.map((img) => (img._id === imageId ? image : img)),
              selectedNote: image.note,
              noteText: image.note?.text,
            });
          } catch (error) {
            log.error('Failed to update client note', error);
          }
        }
      });
    };

    const removeClientImageNoteReference = async (imageId: ClientImageId) => {
      dispatch.run(async (state) => {
        if (state.type === 'Loaded') {
          await meroApi.pro.notes.deleteNoteImageRef({
            pageId: state.pageId!,
            clientId: state.clientId!,
            noteId: state.selectedNote?._id as NoteId,
            imageId,
          });

          dispatch.update({
            ...state,
            clientImages: state.clientImages.map((img) => (img._id === imageId ? { ...img, note: undefined } : img)),
            selectedNote: undefined,
            noteText: undefined,
            selectedBooking: undefined,
          });
        }
      });
    };

    const createClientImageNote = async (text: string, imageId: ClientImageId) => {
      dispatch.run(async (state) => {
        if (state.type === 'Loaded') {
          try {
            if (!state.clientId || !state.pageId) {
              throw new Error('Missing required fields');
              return;
            }

            const payload = {
              text: text as NonEmptyString,
              clientId: state.clientId,
              pageId: state.pageId,
              imageIds: [imageId],
              appointment: state.selectedBooking
                ? {
                    _id: state.selectedBooking._id,
                    occurrenceIndex: state.selectedBooking.occurrenceIndex,
                  }
                : undefined,
            };

            await meroApi.pro.notes.createClientNote(payload);

            // after it's created, we need to update the image in the state
            const image = await meroApi.pro.clientImages.getClientImageById({
              pageId: state.pageId!,
              clientId: state.clientId!,
              imageId,
            });
            dispatch.update({
              ...state,
              clientImages: state.clientImages.map((img) => (img._id === imageId ? image : img)),
              selectedNote: image.note,
            });
          } catch (error) {
            log.error('Failed to create client note', error);
          }
        }
      });
    };

    const getClientBookings = async (clientId: ClientId) => {
      dispatch.run(async (state) => {
        try {
          if (!clientId) return;

          const appointments = await meroApi.calendar.fetchPagedClientUserAppointments({
            clientId,
            limit: 10,
          });

          dispatch.update({
            ...state,
            clientAppointments: {
              ...appointments,
              data: appointments.data,
              futureBookingsCount: appointments.futureBookingsCount ?? 0,
              pastBookingsCount: appointments.pastBookingsCount ?? 0,
            },
          });
        } catch (error) {
          log.error('Failed to load client appointments', error);
        }
      });
    };

    const loadMoreClientBookings = async (clientId: ClientId) => {
      dispatch.run(async (state) => {
        if (state.type !== 'Loaded') return;

        if (state.clientAppointments?.next && clientId) {
          try {
            const appointments = await meroApi.calendar.fetchPagedClientUserAppointments({
              clientId,
              limit: 10,
              page: state.clientAppointments.next,
            });

            dispatch.update({
              ...state,
              clientAppointments: {
                ...appointments,
                data: [...(state.clientAppointments?.data || []), ...appointments.data],
                futureBookingsCount: appointments.futureBookingsCount ?? 0,
                pastBookingsCount: appointments.pastBookingsCount ?? 0,
              },
            });
          } catch (error) {
            log.error('Failed to load more client appointments', error);
          }
        }
      });
    };

    return {
      loadClientImages,
      deleteClientImage,
      updateImageWithNote,
      updateImageWithBooking,
      getClientBookings,
      loadMoreClientBookings,
      updateClientImageNote,
      createClientImageNote,
      updateSelectedImageNote,
      removeClientNote,
      removeClientImageNoteReference,
      setBooking: dispatch.setBooking,
      clearBooking: dispatch.clearBooking,
      clearNote: dispatch.clearNote,
      setNoteText: dispatch.setNoteText,
      setImageNote: dispatch.setImageNote,
    };
  },
);

const ContextInit: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <>{children}</>;
};

export const withClientImageDetailsContextProvider = <P extends object>(
  Content: React.ComponentType<P>,
): React.FC<P> => {
  return function WithClientImageDetailsContextProvider(props: P) {
    return (
      <ClientImageDetailsContext.Provider>
        <ContextInit>
          <Content {...props} />
        </ContextInit>
      </ClientImageDetailsContext.Provider>
    );
  };
};
