import React from 'react';
import { Svg, Path, G, Circle, Rect } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

const Card = ({ color, size = 32 }: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 32 32">
    <G data-name="Group 7658" transform="translate(-15244 5901)">
      <Circle cx={16} cy={16} r={16} fill="#e9ecef" data-name="Ellipse 685" transform="translate(15244 -5901)" />
      <G fill="none" data-name="Group 7654">
        <Path d="M15249-5896h22v22h-22z" data-name="Rectangle 59" />
        <G transform="translate(15251.75 -5892.562)">
          <Path d="M0 0h17.188v16.5H0z" data-name="Rectangle 2759" />
          <Path
            stroke="#52577f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d="m2.422 6.934-.573-1.589a.625.625 0 0 1 .365-.807L12.786.71a.625.625 0 0 1 .807.365l2.265 6.25a.625.625 0 0 1-.365.807l-2.734.99M2.365 6.726l11.7-4.271m-2.164 2.552 1.172-.417"
          />
          <Rect
            width={12.499}
            height={7.812}
            stroke="#52577f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            rx={1}
            transform="translate(.26 6.933)"
          />
          <Path
            stroke="#52577f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d="M2.344 10.578H5.99m3.124-1.562h1.562m-8.332 3.646h.521m2.083 0h.521m2.083 0h.521m2.083 0h.521"
            data-name="layer1"
          />
        </G>
      </G>
    </G>
  </Svg>
);

export default Card;
