import { BusinessCategoryId } from '../../services/businessCategoryId';
import { MarketplaceSearchDebug } from './marketplaceSearchDebug';
import { DefinedTrimedString, JSONable, LatLng } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type MarketplaceSearchQuery = {
  /**
   * Search text query
   */
  readonly query?: DefinedTrimedString;
  /**
   * Filter by service categories
   */
  readonly categories?: BusinessCategoryId[];
  /**
   * Filter by place location
   */
  readonly geo?:
    | {
        readonly type: 'City';
        readonly name: string;
        readonly area?: string;
      }
    | {
        readonly type: 'Nearby';
        readonly point: LatLng;
      };
  /**
   * Filter by page promo price availability
   */
  readonly hasPromoPrice?: true;
  /**
   * Filter places that are certified by Tabya
   */
  readonly isTabyaCertified?: true;
  /**
   * Filter places that sells (and accept) giftcards
   */
  readonly hasGiftcards?: true;
  /**
   * Filter places that accept card payments
   */
  readonly acceptsCardPayments?: true;
  /**
   * When set to true - returns results for promo page (more boost results, different sort order)
   */
  readonly forPromoSearch?: boolean;
  /**
   * Pagination
   */
  readonly page?:
    | {
        /**
         * Token based pagination
         * Use the token you get in MarketplaceSearchResult
         */
        readonly type: 'Token';
        readonly token: string;
      }
    | {
        /**
         * Server side Rendering pagination
         */
        readonly type: 'SSR';
        /**
         * Starts with 1
         */
        readonly page: number;
      };
  /**
   * Search debug configuration
   */
  readonly debug?: MarketplaceSearchDebug;
};

const JSON: t.Type<MarketplaceSearchQuery, JSONable> = t.partial(
  {
    query: DefinedTrimedString,
    categories: t.array(BusinessCategoryId),
    geo: t.union(
      [
        t.type(
          {
            type: t.literal('City'),
            name: t.string,
          },
          'City',
        ),
        t.type(
          {
            type: t.literal('Nearby'),
            point: LatLng.JSON,
          },
          'Nearby',
        ),
      ],
      'Geo',
    ),
    hasPromoPrice: t.literal(true),
    isTabyaCertified: t.literal(true),
    hasGiftcards: t.literal(true),
    acceptsCardPayments: t.literal(true),
    forPromoSearch: t.boolean,
    page: t.union(
      [
        t.type(
          {
            type: t.literal('Token'),
            token: t.string,
          },
          'Token',
        ),
        t.type(
          {
            type: t.literal('SSR'),
            page: t.number,
          },
          'SSR',
        ),
      ],
      'Page',
    ),
    debug: MarketplaceSearchDebug.JSON,
  },
  'MarketplaceSearchQuery',
);

export const MarketplaceSearchQuery = {
  JSON,
};
