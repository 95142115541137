import * as React from 'react';

import ClientBookingsListScreen from '../screens/Authorized/ClientImageDetails/ClientBookingsListScreen';
import ClientImageDetailsScreen from '../screens/Authorized/ClientImageDetails/ClientImageDetailsScreen';
import ClientImageNoteScreen from '../screens/Authorized/ClientImageDetails/ClientImageNoteScreen';

import { createStackNavigator } from '@react-navigation/stack';

import { withClientImageDetailsContextProvider } from '../contexts/ClientImageDetailsContext';
import { ClientImageStackParamList } from '../types';

const Stack = createStackNavigator<ClientImageStackParamList>();

function ClientImageStackNavigator(): React.ReactElement {
  return (
    <Stack.Navigator
      initialRouteName="ClientImageDetailsScreen"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="ClientImageDetailsScreen" component={ClientImageDetailsScreen} />
      <Stack.Screen name="ClientBookingsListScreen" component={ClientBookingsListScreen} />
      <Stack.Screen name="ClientImageNoteScreen" component={ClientImageNoteScreen} />
    </Stack.Navigator>
  );
}

export default withClientImageDetailsContextProvider(ClientImageStackNavigator);
