import { colors, ConfirmBox, H1, ModalOverlay, SmallBody, Spacer, Title } from '@mero/components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import MobileWebModalWrapper from '../../../../../components/MobileWebModalWrapper';

import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import log from '../../../../../utils/log';
import { roundToDecimals } from '../../../../../utils/number';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
  addonPrice: number;
  totalPrice: number;
  expiresAt: Date;
};

const AddSeatDialog: React.FC<Props> = ({ onSuccess, onCancel, addonPrice, totalPrice, expiresAt }) => {
  const { t } = useTranslation('pros');

  const [pageState] = CurrentBusinessContext.useContext();

  const cancel = () => {
    onCancel();
  };

  const save = async () => {
    try {
      if (pageState.type === 'Loaded') {
        onSuccess();
      }
    } catch (error) {
      log.error('Failed to add new seat', error);
    }
  };

  const addCategoryLeftAction = {
    text: t('cancel'),
    onPress: cancel,
    flex: 10,
  };

  const addCategoryRightAction = {
    text: t('confirm'),
    onPress: save,
    flex: 15,
  };

  const expirationDate = React.useMemo(() => {
    return DateTime.fromJSDate(expiresAt).setLocale('ro').toFormat('dd LLLL yyyy');
  }, [expiresAt]);

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <MobileWebModalWrapper position="center">
        <ConfirmBox
          type="info"
          headerTitle={t('addProSeat')}
          icon="info"
          onClose={cancel}
          leftAction={addCategoryLeftAction}
          rightAction={addCategoryRightAction}
        >
          <H1>{t('addProSeat')}</H1>
          <Spacer size="8" />
          <Title>{t('addProSeatDescription1', { addonPrice: roundToDecimals(addonPrice) })}</Title>
          <Spacer size="4" />
          <SmallBody style={{ color: colors.COMET }}>
            <Trans
              ns="pros"
              t={t}
              i18nKey="addProSeatDescription2"
              values={{
                expiresAt: expirationDate,
                totalPrice,
              }}
            >
              0<SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>1</SmallBody>2
              <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>3</SmallBody>4
            </Trans>
          </SmallBody>
        </ConfirmBox>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default AddSeatDialog;
