import { MembershipTemplateId } from '../../../memberships';
import { HasId } from '@mero/shared-sdk';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type OnlineSaleMembershipItem = {
  readonly type: 'Membership';
  readonly membershipTemplate: HasId<MembershipTemplateId>;
};

const JSON: t.Type<OnlineSaleMembershipItem, JSONable> = t.type(
  {
    type: t.literal('Membership'),
    membershipTemplate: HasId.json(MembershipTemplateId.JSON),
  },
  'OnlineSaleMembershipItem',
);

export const OnlineSaleMembershipItem = {
  JSON,
};
