import { PageId } from '../../pages';
import { CustomFieldId } from '../customFieldId';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type ShortAnswear = {
  readonly _id: CustomFieldId;
  readonly pageId: PageId;
  readonly name: string;
  readonly required: boolean;
  readonly type: 'ShortAnswear';
};

const JSON: t.Type<ShortAnswear, JSONable> = t.strict(
  {
    _id: CustomFieldId.JSON,
    pageId: PageId,
    name: t.string,
    required: t.boolean,
    type: t.literal('ShortAnswear'),
  },
  'ShortAnswear',
);

export const ShortAnswear = {
  JSON,
};
