import * as Ord from 'fp-ts/lib/Ord';
import * as t from 'io-ts';

export type LocalDateObject = {
  /**
   * Year, ex: 2020
   */
  readonly year: number;
  /**
   * Month, [1..12]
   */
  readonly month: number;
  /**
   * Day, [1..31]
   */
  readonly day: number;
};

const equals = (a: LocalDateObject, b: LocalDateObject) => {
  return a.year === b.year && a.month === b.month && a.day === b.day;
};

const compare = (a: LocalDateObject, b: LocalDateObject): -1 | 0 | 1 => {
  if (a.year !== b.year) {
    return a.year < b.year ? -1 : 1;
  }

  if (a.month !== b.month) {
    return a.month < b.month ? -1 : 1;
  }

  if (a.day !== b.day) {
    return a.day < b.day ? -1 : 1;
  }

  return 0;
};

const O: Ord.Ord<LocalDateObject> = Ord.fromCompare(compare);

const JSON: t.Type<LocalDateObject> = t.type(
  {
    year: t.number,
    month: t.number,
    day: t.number,
  },
  'LocalDateObject',
);

export const LocalDateObject = {
  JSON,
  equals,
  compare,
  lt: Ord.lt(O),
  lte: Ord.leq(O),
  gt: Ord.gt(O),
  gte: Ord.geq(O),
  min: Ord.min(O),
  max: Ord.max(O),
  between: Ord.between(O),
};
