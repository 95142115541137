import { checkoutItemConversion, convertMembershipPayment, paymentConversion } from '@/utils/checkout';
import { MembershipPurchaseId, SavedCheckoutCompany, apiError } from '@mero/api-sdk';
import { CreateCheckoutTransactionItem } from '@mero/api-sdk/dist/checkout/checkoutApi';
import { NONE } from '@mero/api-sdk/dist/checkout/checkoutTransactionCompany/checkoutTransactionCompany';
import { computeTransactionTotals } from '@mero/api-sdk/dist/checkout/checkoutTransactionDetails/utils';
import { CheckoutCompanyId } from '@mero/api-sdk/dist/checkout/companies/checkoutCompanyId';
import { ALLOWED } from '@mero/api-sdk/dist/checkout/companies/companyEmitReceiptStatus/companyEmitReceiptStatus';
import {
  Body,
  Checkbox,
  colors,
  Column,
  DismissKeyboard,
  H1,
  Icon,
  Line,
  Row,
  Select,
  SmallBody,
  Spacer,
  TextInput,
  Title,
  useShowError,
  useToast,
} from '@mero/components';
import { styles as meroStyles } from '@mero/components';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';
import { flow, pipe } from 'fp-ts/function';
import { NonEmptyArray } from 'fp-ts/lib/NonEmptyArray';
import * as types from 'io-ts';
import { uniqBy } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform, ScrollView, TouchableOpacity, useWindowDimensions } from 'react-native';
import Svg, { SvgProps, Path, G, Circle } from 'react-native-svg';

import { styles } from '../../../components/AddClientScreen/styles';
import KeyboardAvoidingView from '../../../components/KeyboardAvoidingView';
import ModalScreenContainer from '../../../components/ModalScreenContainer';
import Button from '@mero/components/lib/components/Button';
import FormCard from '@mero/components/lib/components/FormCard';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import MeroHeader from '@mero/components/lib/components/MeroHeader';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { Authorized, AuthorizedProps, meroApi } from '../../../contexts/AuthContext';
import { CashRegistryContext } from '../../../contexts/CashRegistryContext';
import {
  applyDiscountToTotal,
  CheckoutFormContext,
  convertSavedCheckoutCompanyToCheckoutTransactionCompany,
  Item,
  MembershipPayment,
  Payment,
  WithUiKey,
} from '../../../contexts/CheckoutFormContext';
import { CheckoutsContext } from '../../../contexts/CheckoutsContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import {
  AuthorizedStackParamList,
  CheckoutStackParamList,
  CombineCheckoutStackParamList,
  HomeTabsParamsList,
} from '../../../types';
import log from '../../../utils/log';
import {
  convertScale,
  localeNumberValidator,
  localeStringToNumber,
  replaceDecimalSeparator,
  scaledToString,
  stripLocalThousandsSeparators,
} from '../../../utils/scaled';
import EditBankTransfer, { BankTransfer } from './EditBankTransfer';
import PaymentsMenu from './PaymentsMenu';
import { PaymentMethods } from './SelectPaymentScreen';
import { computeDraftTransactionPaymentTotal } from './utils';

const MembershipIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <Path fill="none" d="M0 0h24v24H0Z" data-name="Path 8275" />
    <G
      fill="none"
      stroke="#52577f"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      transform="translate(3 5.25)"
    >
      <Path d="M14.741 0v.295a1.474 1.474 0 0 1-2.948 0V0H5.9v.295a1.474 1.474 0 0 1-2.948 0V0H0v12.972h17.689V0ZM10.613 4.717h4.717m-4.717 2.358h4.717m-4.717 2.359h2.358" />
      <Circle cx={1.5} cy={1.5} r={1.5} data-name="layer1" transform="translate(3.75 3.75)" />
      <Path
        d="M5.307 7.075a2.978 2.978 0 0 0-2.947 3.037v.5h5.9v-.5a2.978 2.978 0 0 0-2.953-3.037Z"
        data-name="layer1"
      />
    </G>
  </Svg>
);

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<CheckoutStackParamList & AuthorizedStackParamList & CombineCheckoutStackParamList, 'PaymentScreen'>;

const getMembershipItems = (items: WithUiKey<Item>[], uiKeys: string[]): WithUiKey<Item>[] => {
  return items
    .map((item) =>
      item.type === 'Booking'
        ? {
            ...item,
            items: item.items.filter((i) => uiKeys.includes(i.uiKey)),
          }
        : item,
    )
    .filter((item) => (item.type === 'Booking' ? item.items.length > 0 : uiKeys.includes(item.uiKey)));
};

const calculatePerMembership = (payment: MembershipPayment, items: WithUiKey<Item>[]) => {
  const uiKeys = payment.items.map((item) => item.uiKey);
  return computeDraftTransactionPaymentTotal(
    convertMembershipPayment(payment),
    getMembershipItems(applyDiscountToTotal(items), uiKeys),
    'RON',
  ).amount;
};

const calculateTotalMembership = (payment: MembershipPayment[], items: WithUiKey<Item>[]) => {
  return payment.reduce((acc, p) => {
    return ScaledNumber.add(acc, calculatePerMembership(p, items));
  }, ScaledNumber.fromNumber(0, 2));
};

const PaymentScreen: React.FC<Props> = ({ navigation, route, page }): React.ReactElement | null => {
  const [
    formState,
    {
      updatePayment,
      removePayment,
      setPayment,
      setCompany,
      removeCompany,
      toggleEmitReceipt,
      selectReceipt,
      cancelAutoSave,
      setCustomerFiscalCode,
    },
  ] = CheckoutFormContext.useContext();
  const [checkoutState, { reloadCompanies }] = CheckoutsContext.useContext();
  const [, { loadTransactions }] = CashRegistryContext.useContext();

  const { t } = useTranslation('checkout');
  const goBack = useGoBack();
  const { isPhone, isDesktop } = useMediaQueries();
  const toast = useToast();
  const showError = useShowError();
  const { width } = useWindowDimensions();
  const isFocused = useIsFocused();

  const [showMenu, setShowMenu] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showFiscalCodeError, setShowFiscalCodeError] = React.useState(false);
  const [isCompanyLoading, setIsCompanyLoading] = React.useState(false);

  const [showMissingCompany, setShowMissingCompany] = React.useState(false);
  const [editBankTransfer, setEditBankTransfer] = React.useState(false);
  const [showEditBankTransfer, setShowEditBankTransfer] = React.useState(false);
  const [showCustomerFiscalCode, setShowCustomerFiscalCode] = React.useState(false);

  const [customerFiscalCodeCompany, setCustomerFiscalCodeCompany] = React.useState<string | undefined>();
  const [selectedBankTransfer, setSelectedBankTransfer] = React.useState<BankTransfer | undefined>(undefined);
  const values = React.useMemo(
    () =>
      formState.draft.payments.reduce(
        (acc, p) => ({
          ...acc,
          [p.type]:
            p.type === 'Membership'
              ? scaledToString(
                  calculateTotalMembership(
                    formState.draft.payments.filter((p): p is MembershipPayment => p.type === 'Membership'),
                    formState.draft.items,
                  ),
                )
              : p.type === 'Online'
              ? ''
              : scaledToString(p.total.amount),
        }),
        {} as { [key in Payment['type']]: string },
      ),
    [formState.draft.payments, formState.draft.items],
  );

  const [tempValues, setTempValues] = React.useState(values);

  const totalValues = React.useMemo(() => {
    return computeTransactionTotals(
      {
        unit: 'RON',
        payments: formState.draft.payments.map(paymentConversion),
        items: formState.draft.items,
        company: formState.draft.company
          ? {
              type: 'Company',
              company: formState.draft.company,
              emitReceipt: formState.draft.emitReceipt,
              receipt: formState.draft.receipt,
            }
          : NONE,
        discount: formState.draft.discount,
      },
      2,
    );
  }, [formState]);

  const membershipValue = React.useMemo(() => {
    const membership = formState.draft.payments.filter((p): p is MembershipPayment => p.type === 'Membership');
    if (membership.length === 0) {
      return {};
    }

    return membership.reduce((acc, p) => {
      return {
        ...acc,
        [p.membership._id]: calculatePerMembership(p, formState.draft.items),
      };
    }, {} as Record<MembershipPurchaseId, ScaledNumber>);
  }, [formState]);

  const totalPayedScaled = React.useMemo(() => {
    return formState.draft.payments.reduce((acc, p) => {
      return ScaledNumber.add(
        acc,
        p.type === 'Membership'
          ? calculatePerMembership(p, formState.draft.items)
          : computeDraftTransactionPaymentTotal(p, formState.draft.items.map(checkoutItemConversion), 'RON').amount,
      );
    }, ScaledNumber.fromNumber(0, 2));
  }, [formState.draft.payments, formState.draft.items]);

  const totalPayed = ScaledNumber.toNumber(totalPayedScaled);

  const isValidTempPayed = React.useMemo(() => {
    const isValid = formState.draft.payments.every((payment) => {
      const tempValue = tempValues[payment.type];
      if (!tempValue) {
        return false;
      }

      const parsed = localeStringToNumber(tempValue);

      return !isNaN(parsed);
    });

    if (!isValid) {
      return false;
    }

    const total = uniqBy(formState.draft.payments, 'type').reduce(
      (acc, payment) => acc + localeStringToNumber(tempValues[payment.type]),
      0,
    );

    return total === ScaledNumber.toNumber(totalValues.total.amount);
  }, [formState.draft.payments, tempValues, totalValues]);

  const onAddPayment = React.useCallback(
    (paymentType: Payment['type']) => {
      const total = ScaledNumber.toNumber(totalValues.total.amount);
      if (paymentType === 'Card' || paymentType === 'Cash' || paymentType === 'BankTransfer') {
        setPayment({
          type: paymentType,
          total: {
            amount: {
              value: total - totalPayed > 0 ? total - totalPayed : 0,
            },
            unit: 'RON',
          },
        });
        // } else if (paymentMethod === 'membershipPayment') {
      } else if (paymentType === 'Giftcard') {
        setPayment({
          type: 'Giftcard',
          code: '',
          total: {
            amount: {
              value: total - totalPayed > 0 ? total - totalPayed : 0,
            },
            unit: 'RON',
          },
        });
      }
    },
    [totalPayed, totalValues.total],
  );

  const onCloseMenu = React.useCallback(() => {
    setShowMenu(false);
  }, []);

  const validatePaymentValue = React.useCallback((value: string, defaultValue = 0) => {
    if (value === '') {
      return 0;
    }

    const parsed = localeStringToNumber(value);

    if (isNaN(parsed)) {
      return defaultValue;
    }

    return parsed;
  }, []);

  const companies = React.useMemo(() => {
    if (checkoutState.type === 'Loaded') {
      return checkoutState.companies;
    }

    return [];
  }, [checkoutState]);

  const setSelectedCompany = async (company: SavedCheckoutCompany) => {
    setIsCompanyLoading(true);
    try {
      if (company.emitReceiptStatus.type === ALLOWED.type) {
        const cashRegister = await meroApi.checkout.listCashRegisters({
          pageId: page.details._id,
          companyId: company._id,
        });

        if (cashRegister.length === 0) {
        } else {
          selectReceipt({
            emit: true,
            cashRegister: cashRegister[0],
            customerFiscalCode: undefined,
          });
        }
      }
      setCompany(convertSavedCheckoutCompanyToCheckoutTransactionCompany(company));
    } catch (error) {
      log.error('Failed to fetch cash registers', error);
      showError(t('selectCompanyError'));
    } finally {
      setIsCompanyLoading(false);
    }
  };

  const handleDownload = async (url: string) => {
    if (page.permissions.checkout.isReceiptPrinter()) {
      try {
        const supported = await Linking.canOpenURL(url);

        if (supported) {
          await Linking.openURL(url);
        } else {
          log.error(`Don't know how to open this URL: ${url}`);
        }
      } catch (error) {
        log.error('Failed to open URL', error);
      }
    }
  };

  const onCompanyChange = React.useCallback(
    async (companyId: CheckoutCompanyId | string) => {
      const company = companies.find((c) => c._id === companyId);

      if (company) {
        setSelectedCompany(company);
      } else {
        removeCompany();
      }
    },
    [companies],
  );

  const selectCompanyItems = React.useMemo(
    () =>
      companies.map((company) => ({
        label: convertSavedCheckoutCompanyToCheckoutTransactionCompany(company).name,
        value: company._id,
      })),
    [companies],
  );

  const save = async () => {
    if (!formState.draft.company) {
      setShowMissingCompany(true);
    }
    if (formState.type === 'Invalid' || formState.draft.items.length === 0 || showFiscalCodeError) {
      return;
    }

    const items = formState.draft.items.map(checkoutItemConversion) as NonEmptyArray<
      CreateCheckoutTransactionItem.Any<MeroUnits.Any>
    >;

    setIsLoading(true);
    try {
      const emitReceipt =
        total > 0 &&
        formState.draft.emitReceipt &&
        formState.draft.payments.some((p) => p.type === 'Cash' || p.type === 'Card') &&
        formState.draft.company?.emitReceiptStatus.type === 'Allowed';

      if (formState.draftId) {
        const checkout = await meroApi.checkout.checkoutDraft({
          _id: formState.draftId,
          unit: 'RON' as MeroUnits.RON,
          isProtocol,
          page: {
            _id: page.details._id,
          },
          client: formState.draft.client.type === 'existing' ? { _id: formState.draft.client.client._id } : undefined,
          items,
          discount: formState.draft.discount,
          payments: formState.draft.payments.map(paymentConversion),
          company: {
            type: 'Company',
            company: { _id: formState.draft.company._id },
            receipt: emitReceipt ? formState.draft.receipt : { emit: false },
          },
        });
        if (emitReceipt && formState.draft.receipt.emit) {
          const receipt = await meroApi.checkout.printReceipt({
            pageId: page.details._id,
            transactionId: checkout._id,
            force: false,
          });

          handleDownload(receipt.downloadUrl);
        }
        cancelAutoSave(formState.autoSaveInterval);
        toast.show({
          type: 'success',
          text: t('checkoutSaved'),
        });
        navigation.popToTop();
        navigation.goBack();
      } else {
        const checkout = await meroApi.checkout.checkout({
          unit: 'RON' as MeroUnits.RON,
          isProtocol,
          page: {
            _id: page.details._id,
          },
          client: formState.draft.client.type === 'existing' ? { _id: formState.draft.client.client._id } : undefined,
          items,
          discount: formState.draft.discount,
          payments: formState.draft.payments.map(paymentConversion),
          company: {
            type: 'Company',
            company: { _id: formState.draft.company._id },
            receipt: emitReceipt ? formState.draft.receipt : { emit: false },
          },
        });
        if (emitReceipt && formState.draft.receipt.emit) {
          const receipt = await meroApi.checkout.printReceipt({
            pageId: page.details._id,
            transactionId: checkout._id,
            force: false,
          });
          handleDownload(receipt.downloadUrl);
        }
        cancelAutoSave(formState.autoSaveInterval);
        toast.show({
          type: 'success',
          text: t('checkoutSaved'),
        });
        navigation.popToTop();
        navigation.goBack();
      }
      loadTransactions({ pageId: page.details._id });
    } catch (error) {
      if (apiError(types.unknown).is(error) && error.code === 135) {
        setShowFiscalCodeError(true);
      } else {
        showError(error);
      }
      log.error('Failed to save checkout', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseEditBankTransfer = React.useCallback(() => {
    setShowEditBankTransfer(false);
    setSelectedBankTransfer(undefined);
    setEditBankTransfer(false);
  }, []);

  React.useEffect(() => {
    if (checkoutState.type === 'Loaded' && checkoutState.companies.length === 1) {
      setSelectedCompany(checkoutState.companies[0]);
    }
  }, [checkoutState.type, companies]);

  React.useEffect(() => {
    reloadCompanies(page.details._id).catch((error) => {
      log.error('Failed to reload companies', error);
    });
  }, [isFocused]);

  const total = ScaledNumber.toNumber(totalValues.total.amount);
  const unit = totalValues.total.unit;

  const activePayments = React.useMemo(() => formState.draft.payments.map((p) => p.type), [formState.draft.payments]);
  const availablePayments = React.useMemo(() => {
    if (
      (activePayments.includes('Card') && activePayments.includes('Cash')) ||
      activePayments.includes('BankTransfer')
    ) {
      return [];
    }

    if (activePayments.includes('Card')) {
      return PaymentMethods.filter((p) => p.label === 'Cash');
    }

    if (activePayments.includes('Cash')) {
      return PaymentMethods.filter((p) => p.label === 'Card');
    }

    return PaymentMethods.filter((p) => p.label === 'BankTransfer' || p.label === 'Cash' || p.label === 'Card');
  }, [activePayments]);

  const updateTempValues = React.useCallback(
    (type: Payment['type']) => (value: string) => {
      setTempValues((values) => ({
        ...values,
        [type]: value,
      }));

      return value;
    },
    [],
  );

  const canEmitReceipt =
    total > 0 &&
    formState.draft.emitReceipt &&
    formState.draft.payments.some((p) => p.type === 'Cash') &&
    formState.draft.company?.emitReceiptStatus.type === 'Allowed';

  const isProtocol = formState.draft.isProtocol;

  React.useEffect(() => {
    const values = formState.draft.payments.reduce(
      (acc, p) => ({
        ...acc,
        [p.type]:
          p.type === 'Membership'
            ? scaledToString(
                calculateTotalMembership(
                  formState.draft.payments.filter((p): p is MembershipPayment => p.type === 'Membership'),
                  formState.draft.items,
                ),
              )
            : p.type === 'Online'
            ? ''
            : scaledToString(p.total.amount),
      }),
      {} as { [key in Payment['type']]: string },
    );

    setTempValues(values);
  }, [formState.draft.payments, membershipValue]);

  const numberValidator = (prev: string) => (next: string) => {
    const parsed = replaceDecimalSeparator(next);
    return localeNumberValidator(parsed) ? parsed : prev;
  };

  const totalMembershipValue = Object.values(membershipValue).reduce(
    (acc, value) => ScaledNumber.add(acc, value),
    ScaledNumber.fromNumber(0, 2),
  );
  const isPaidCompletelyFromMembership = ScaledNumber.equals(totalMembershipValue, totalValues.total.amount);
  const remainingPayment = isProtocol ? 0 : ScaledNumber.toNumber(totalValues.total.amount) - totalPayed;

  const getCompanyDetails = async () => {
    if (
      !formState.draft.emitReceipt ||
      !formState.draft.receipt.emit ||
      formState.draft.receipt.customerFiscalCode === undefined
    ) {
      return;
    }
    try {
      const [company] = await Promise.all([
        meroApi.checkout.getCompanyAutocomplete({
          pageId: page.details._id,
          cif: formState.draft.receipt.customerFiscalCode,
        }),
        meroApi.checkout.getPageDefaults({
          pageId: page.details._id,
        }),
      ]);
      company ? setCustomerFiscalCodeCompany(company.name) : setShowFiscalCodeError(true);
    } catch (error) {
      log.error('Failed to get company details', error);
      setShowFiscalCodeError(true);
    }
  };

  return (
    <>
      {isDesktop && <Column style={{ width }} />}
      <ModalScreenContainer
        style={isDesktop && { width: 500, borderRadius: 0, alignSelf: 'flex-end', paddingBottom: 96 }}
      >
        <MeroHeader
          canGoBack
          onBack={goBack}
          title={t('finalizePayment')}
          containerStyle={{ backgroundColor: colors.ALABASTER }}
        />
        <DismissKeyboard style={{ flex: 1 }}>
          <KeyboardAvoidingView style={{ flex: 1 }} keyboardVerticalOffset={110}>
            <ScrollView style={{ flex: 1 }}>
              <Spacer size={16} />
              <FormCard paddings="none" dropShaddow rounded style={{ marginHorizontal: 16, paddingVertical: 16 }}>
                <Row style={{ justifyContent: 'space-between', paddingHorizontal: 16 }}>
                  <Title>{t('paymentTotal')}</Title>
                  <Title>
                    {scaledToString(totalValues.total.amount)} {t(unit)}
                  </Title>
                </Row>
                <Spacer size={16} />
                <Line />
                <Spacer size={16} />
                <Row style={{ justifyContent: 'space-between', paddingHorizontal: 16 }}>
                  <Body>{t(isProtocol ? 'paymentProtocol' : 'paymentAdded')}</Body>
                  <Body>
                    {isProtocol
                      ? scaledToString(totalValues.total.amount)
                      : scaledToString(ScaledNumber.fromNumber(totalPayed, totalValues.total.amount.scale ?? 2))}{' '}
                    {t(unit)}
                  </Body>
                </Row>
                <Spacer size={16} />
                <Row style={{ justifyContent: 'space-between', paddingHorizontal: 16 }}>
                  <Body style={{ color: remainingPayment > 0 ? colors.OUTRAGEOUS_ORANGE : colors.SHAMROCK }}>
                    {t('paymentRemaining')}
                  </Body>
                  <Body style={{ color: remainingPayment > 0 ? colors.OUTRAGEOUS_ORANGE : colors.SHAMROCK }}>
                    {scaledToString(ScaledNumber.fromNumber(remainingPayment, totalValues.total.amount.scale ?? 2))}{' '}
                    {t(unit)}
                  </Body>
                </Row>
              </FormCard>
              <Spacer size={32} />
              <Column style={{ backgroundColor: colors.WHITE, flex: 1 }}>
                <Spacer size={24} />
                <H1 style={{ paddingHorizontal: 24 }}>{t('finalizePayment')}</H1>
                {isProtocol && (
                  <>
                    <Spacer size={8} />
                    <Body style={{ paddingHorizontal: 24 }}>{t('paymentProtocolDescription')}</Body>
                  </>
                )}
                <Spacer size={24} />
                <Column style={{ paddingHorizontal: 24 }}>
                  <Row style={{ justifyContent: 'space-between' }}>
                    <Title>{t('company')}</Title>
                    <TouchableOpacity
                      onPress={() =>
                        navigation.navigate('Menu', {
                          screen: 'PageCheckoutSettingsScreen',
                          params: { screen: 'BillingDetailsList' },
                        })
                      }
                    >
                      <Title style={{ color: colors.DARK_BLUE, fontSize: 14 }}>{t('addCompany')}</Title>
                    </TouchableOpacity>
                  </Row>
                  <Spacer size={8} />
                  <Select
                    items={selectCompanyItems}
                    value={formState.draft.company?._id}
                    onChange={onCompanyChange}
                    placeholder={t('noCompany')}
                    editable={!isCompanyLoading && selectCompanyItems.length > 0}
                  />
                  {showMissingCompany && !formState.draft.company && (
                    <>
                      <Spacer size={8} />
                      <SmallBody style={{ color: colors.RADICAL_RED }}>{t('missingCompany')}</SmallBody>
                    </>
                  )}
                  <Spacer size={8} />
                </Column>
                <Spacer size={16} />
                {formState.draft.payments.map((payment) => {
                  const value =
                    payment.type === 'Card' ||
                    payment.type === 'Cash' ||
                    payment.type === 'BankTransfer' ||
                    payment.type === 'Giftcard'
                      ? payment.total.amount
                      : ScaledNumber.fromNumber(0, 2);

                  const generateContent = () => {
                    switch (payment.type) {
                      case 'BankTransfer':
                        return (
                          <TouchableOpacity
                            onPress={() => {
                              setSelectedBankTransfer(payment);
                              setShowEditBankTransfer(true);
                              setEditBankTransfer(true);
                            }}
                          >
                            <InputWithLabel
                              label={`${t(payment.type)} (${t('RON')})`}
                              // isError={showErrors && !discountPriceValid}
                              errorText={t('paymentValueError')}
                            >
                              <TextInput
                                styles={{
                                  textInput: { backgroundColor: colors.WHITE },
                                  container: { backgroundColor: colors.WHITE },
                                }}
                                value={scaledToString(value)}
                                keyboardType="numeric"
                                editable={false}
                              />
                            </InputWithLabel>
                          </TouchableOpacity>
                        );
                      case 'Membership': {
                        return (
                          <TouchableOpacity
                            style={{
                              paddingVertical: 12,
                              paddingLeft: 12,
                              paddingRight: 8,
                              borderWidth: 1,
                              borderColor: colors.GEYSER,
                              borderRadius: 4,
                              flexDirection: 'row',
                            }}
                            onPress={() => {
                              navigation.navigate('CheckoutSubStack', {
                                screen: 'SelectMembershipPaymentScreen',
                              });
                            }}
                          >
                            <MembershipIcon />
                            <Title style={{ flex: 1, paddingLeft: 8 }}>{payment.membership.name}</Title>
                            <Body>
                              {scaledToString(
                                convertScale(
                                  membershipValue[payment.membership._id] ?? ScaledNumber.fromNumber(0, 2),
                                  2,
                                ),
                                undefined,
                              )}{' '}
                              {t('RON')}
                            </Body>
                            <Icon type="next" color={colors.DARK_BLUE} />
                          </TouchableOpacity>
                        );
                      }
                      case 'Online': {
                        return null;
                      }
                      default:
                        return (
                          <InputWithLabel
                            label={`${t(payment.type)} (${t('RON')})`}
                            // isError={showErrors && !discountPriceValid}
                            errorText={t('paymentValueError')}
                          >
                            <TextInput
                              returnKeyType="done"
                              value={tempValues[payment.type] ?? scaledToString(value)}
                              onChange={flow(
                                numberValidator(tempValues[payment.type] ?? scaledToString(value)),
                                updateTempValues(payment.type),
                              )}
                              onFocus={() => {
                                updateTempValues(payment.type)(
                                  stripLocalThousandsSeparators(tempValues[payment.type] ?? scaledToString(value)),
                                );
                              }}
                              onBlur={() => {
                                pipe(
                                  tempValues[payment.type] ?? scaledToString(value),
                                  validatePaymentValue,
                                  (value) => {
                                    updatePayment({
                                      ...payment,
                                      total: {
                                        ...payment.total,
                                        amount: ScaledNumber.fromNumber(value, 2),
                                      },
                                    });
                                  },
                                );
                              }}
                              keyboardType="numeric"
                            />
                          </InputWithLabel>
                        );
                    }
                  };

                  return (
                    <Column key={payment.type} style={{ paddingHorizontal: 24 }}>
                      {generateContent()}
                      <Spacer size={24} />
                    </Column>
                  );
                })}
                {availablePayments.length > 0 && !isProtocol && !isPaidCompletelyFromMembership && (
                  <Column style={{ paddingHorizontal: 24 }}>
                    <Button
                      padding={1}
                      size="medium"
                      color={colors.DARK_BLUE}
                      backgroundColor={colors.SKY_BLUE}
                      text={t('addNewPayment')}
                      onPress={() => setShowMenu(true)}
                    />
                  </Column>
                )}
                {total > 0 &&
                  formState.draft.payments.some((p) => p.type === 'Cash' || p.type === 'Card') &&
                  formState.draft.company?.emitReceiptStatus.type === 'Allowed' &&
                  formState.draft.receipt.emit &&
                  Platform.OS === 'web' && (
                    <>
                      <Spacer size={24} />
                      <TouchableOpacity
                        style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 24 }}
                        onPress={toggleEmitReceipt}
                      >
                        <Checkbox value={formState.draft.emitReceipt} onValueChange={toggleEmitReceipt} />
                        <Body
                          style={{
                            fontFamily: 'open-sans-semibold',
                            paddingLeft: 16,
                          }}
                        >
                          {t('emitReceiptCheck')}
                        </Body>
                      </TouchableOpacity>
                      <Spacer size={8} />
                    </>
                  )}
                {formState.draft.emitReceipt &&
                  formState.draft.receipt.emit &&
                  !showCustomerFiscalCode &&
                  !isProtocol && (
                    <>
                      <Button
                        color={colors.DARK_BLUE}
                        containerStyle={{ alignSelf: 'flex-start', paddingHorizontal: 8 }}
                        backgroundColor={colors.WHITE}
                        text={t('addReceiptFiscalCode')}
                        onPress={() => setShowCustomerFiscalCode(true)}
                      />
                    </>
                  )}
                {formState.draft.emitReceipt &&
                  formState.draft.receipt.emit &&
                  showCustomerFiscalCode &&
                  !isProtocol && (
                    <Column style={{ paddingHorizontal: 24 }}>
                      <Spacer size={16} />
                      <InputWithLabel label={t('receiptFiscalCode')} isError={showFiscalCodeError}>
                        <TextInput
                          value={formState.draft.receipt.customerFiscalCode}
                          onChange={(newValue: string) => {
                            setShowFiscalCodeError(false);
                            setCustomerFiscalCode(newValue);
                            setCustomerFiscalCodeCompany(undefined);
                          }}
                          onBlur={getCompanyDetails}
                          editable={true}
                          isError={showFiscalCodeError}
                          placeholder={t('fiscalCodePlaceholder')}
                        />
                      </InputWithLabel>
                      {showFiscalCodeError && (
                        <SmallBody style={{ color: colors.RADICAL_RED, paddingTop: 8 }}>
                          {t('receiptFiscalCodeError')}
                        </SmallBody>
                      )}
                      {customerFiscalCodeCompany && !showFiscalCodeError && (
                        <SmallBody style={[meroStyles.text.hint, { paddingTop: 8, textTransform: 'uppercase' }]}>
                          {customerFiscalCodeCompany}
                        </SmallBody>
                      )}
                    </Column>
                  )}
                <Spacer size={isPhone ? 144 : 48} />
              </Column>
            </ScrollView>
          </KeyboardAvoidingView>
        </DismissKeyboard>
      </ModalScreenContainer>
      {showMenu && <PaymentsMenu onSelect={flow(onAddPayment, onCloseMenu)} onDismiss={onCloseMenu} />}
      {!showMenu && (
        <FormCard
          dropShaddow
          paddings="button"
          style={[
            { position: 'absolute', bottom: 0, right: 0 },
            !isPhone && styles.modalBorderBottom,
            isPhone ? { left: 0 } : { width: 500 },
          ]}
        >
          <SafeAreaView edges={['bottom']}>
            <Button
              text={
                isPaidCompletelyFromMembership
                  ? t('completePaymentWithMembership')
                  : isProtocol
                  ? t('completeProtocol')
                  : formState.draft.payments.length === 0 ||
                    (totalPayed === 0 && ScaledNumber.toNumber(totalMembershipValue) === 0)
                  ? t('addPayment')
                  : canEmitReceipt
                  ? `${t('completePaymentWithReceipt', { value: total })}`
                  : `${t('completePayment', { value: total })} ${t(totalValues.total.unit)}`
              }
              onPress={save}
              disabled={
                !isProtocol &&
                total > 0 &&
                (formState.draft.payments.length === 0 ||
                  totalPayed !== total ||
                  totalPayed === 0 ||
                  isLoading ||
                  !isValidTempPayed ||
                  showFiscalCodeError)
              }
            />
          </SafeAreaView>
        </FormCard>
      )}
      {showEditBankTransfer && selectedBankTransfer && (
        <EditBankTransfer
          isEdit={editBankTransfer}
          amount={selectedBankTransfer}
          onSave={flow(updatePayment, onCloseEditBankTransfer)}
          onDelete={flow(removePayment, onCloseEditBankTransfer)}
          onCancel={onCloseEditBankTransfer}
        />
      )}
    </>
  );
};

export default pipe(PaymentScreen, CurrentBusiness, Authorized);
