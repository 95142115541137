import { buildServiceSearchIndex } from '@/utils/services';
import { PageId } from '@mero/api-sdk/dist/pages';
import { WorkerService } from '@mero/api-sdk/dist/services';
import { ServiceGroup } from '@mero/api-sdk/dist/services/group';
import { GroupWithServices } from '@mero/api-sdk/dist/services/group-with-services';
import { SavedWorker } from '@mero/api-sdk/dist/workers';
import {
  Body,
  colors,
  Column,
  FormCard,
  H1,
  H2s,
  H3s,
  Header,
  Icon,
  Row,
  SearchTextInput,
  SimpleListItem,
  Spacer,
  styles as meroStyles,
  Text,
  Title,
  Button,
  ModalOverlay,
  SafeAreaView,
} from '@mero/components';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Pressable, ScrollView, TouchableOpacity, View } from 'react-native';
import { debounce } from 'throttle-debounce';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CompositeNavigationProp, RouteProp, useIsFocused } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, ProsEditStackParamList } from '../../../../../types';
import { getPrice } from '../../../ServicesScreen/ServicesMobileScreen';
import { styles } from './ProServicesScreen.styles';

const formatDuration = (duration: number) => {
  if (duration > 60) {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;

    return `${hours} h ${minutes} min`;
  }

  return `${duration} min`;
};

type Props = {
  readonly navigation: CompositeNavigationProp<
    StackNavigationProp<ProsEditStackParamList, 'ProServices'>,
    DrawerNavigationProp<AuthorizedStackParamList, 'ProsEdit'>
  >;
  readonly route: RouteProp<ProsEditStackParamList, 'ProServices'>;
};

const ProServicesScreen: React.FC<Props> = ({ navigation, route }) => {
  const goBack = useGoBack();
  const { t } = useTranslation('pros');
  const isFocused = useIsFocused();
  const { isPhone } = useMediaQueries();

  const { workerId } = route.params;

  const [pageState] = CurrentBusinessContext.useContext();

  const [showOptionsMenu, setShowOptionsMenu] = React.useState(false);
  const [proDetails, setProDetails] = React.useState<SavedWorker | null>(null);
  const [groupedServices, setGroupedServices] = React.useState<GroupWithServices[]>([]);

  const [foundServices, setFoundServices] = React.useState(groupedServices);

  const [query, setQuery] = React.useState<string>('');
  const queryIsEmpty = query.trim() === '';

  const [debouncedQuery, setDebouncedQuery] = React.useState('');

  const getGroupedServices = async (pageId: PageId, workerServices: WorkerService[]) => {
    try {
      const groupedServices = await meroApi.pages.getGroupedServices(pageId);

      const grouped = groupedServices.grouped
        .map((group) => {
          const serviceIds = group.services.map((s) => s._id);
          return {
            ...group,
            services: workerServices
              .filter((s) => serviceIds.includes(s._id))
              .sort((a, b) => serviceIds.indexOf(a._id) - serviceIds.indexOf(b._id)),
          };
        })
        .filter((group) => group.services.length > 0);

      const otherServicesIds = groupedServices.others.map((s) => s._id);
      const others = workerServices
        .filter((s) => otherServicesIds.includes(s._id))
        .sort((a, b) => otherServicesIds.indexOf(a._id) - otherServicesIds.indexOf(b._id));

      const groupedWithOthers = [
        ...grouped,
        ...(others.length === 0
          ? []
          : [{ group: { name: 'Alte servicii', _id: '1' } as ServiceGroup, services: others }]),
      ];

      setGroupedServices(groupedWithOthers);
    } catch {}
  };

  const index = React.useMemo(() => buildServiceSearchIndex(groupedServices), [groupedServices]);

  React.useEffect(() => {
    if (pageState.type === 'Loaded' && isFocused) {
      const worker = pageState.page.workers.find((worker) => worker._id === workerId);
      if (worker) {
        getGroupedServices(
          pageState.page.details._id,
          worker.services.filter((s) => s.pageId === pageState.page.details._id),
        );
      }
      setProDetails(worker ?? null);
    }
  }, [pageState.type, isFocused]);

  const debounceFunc = React.useMemo(
    () =>
      debounce(100, (q: string) => {
        setDebouncedQuery(q);
      }),
    [],
  );

  React.useEffect(() => {
    debounceFunc(query);
  }, [query]);

  React.useEffect(() => {
    setFoundServices(index.search(debouncedQuery));
  }, [debouncedQuery, index]);

  const servicesCounter = React.useMemo(() => {
    return foundServices.reduce((acc, group) => acc + group.services.length, 0);
  }, [foundServices]);

  const goToBulkOperationsCallback = React.useCallback(() => {
    navigation.navigate('ServicesBulkOperations', {
      workerId: workerId,
    });
  }, [navigation]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('proServices')}
        RightComponent={() => (
          <Column>
            <Button
              onPress={() => setShowOptionsMenu(true)}
              expand={false}
              text={t('services:options')}
              backgroundColor={colors.ALABASTER}
              color={colors.DARK_BLUE}
            />
          </Column>
        )}
      />
      {proDetails && (
        <ScrollView>
          <Column style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1 }}>
            <H1 style={{ paddingHorizontal: 8 }}>{t('proServices')}</H1>
            <Body style={{ padding: 8 }}>
              <Trans
                ns={'pros'}
                t={t}
                i18nKey="proServicesDescription"
                values={{ name: `${proDetails.user.firstname} ${proDetails.user.lastname}` }}
              >
                0<Title style={{ fontFamily: 'open-sans-semibold' }}></Title>2
              </Trans>
            </Body>
            <Spacer size={16} />
            <SearchTextInput
              placeholder={'Caută după numele serviciului'}
              value={query}
              // autoFocus={autoFocus}
              onChange={setQuery}
            />
            <Spacer size={16} />
            <Row style={{ paddingHorizontal: 8, justifyContent: 'space-between' }}>
              <Title style={{ color: '#32325D' }}>{t('services', { count: servicesCounter })}</Title>
              <TouchableOpacity onPress={() => navigation.navigate('ProServicesSelect', { workerId })}>
                <Title style={[meroStyles.text.link]}>{t('selectServices')}</Title>
              </TouchableOpacity>
            </Row>
            <Spacer size={16} />
            {foundServices.length === 0 ? (
              <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingTop: 32 }}>
                <H2s>{t('noServicesTitle')}</H2s>
                <Body style={{ textAlign: 'center', paddingHorizontal: 40, paddingTop: 8 }}>
                  {t('noServicesDescription')}
                </Body>
              </Column>
            ) : (
              <FormCard rounded paddings="none">
                {foundServices.map((groupedService, groupIndex) => (
                  <Column
                    key={groupedService.group._id}
                    style={{
                      paddingHorizontal: 16,
                      paddingTop: 24,
                      paddingBottom: 16,
                      borderBottomWidth: groupIndex < groupedServices.length - 1 ? 1 : 0,
                      borderBottomColor: '#E9ECEF',
                    }}
                  >
                    <H3s>{groupedService.group.name}</H3s>
                    <Spacer size={8} />
                    {groupedService.services.map((service, index) => (
                      <TouchableOpacity
                        key={service._id}
                        style={{
                          borderBottomWidth: index < groupedService.services.length - 1 ? 1 : 0,
                          borderBottomColor: '#E9ECEF',
                          paddingVertical: 16,
                        }}
                        onPress={() => navigation.navigate('ProEditService', { workerId, serviceId: service._id })}
                      >
                        <SimpleListItem
                          paddingTop={0}
                          paddingBottom={0}
                          title={service.name}
                          subtitle={
                            <Text smallBody>
                              {formatDuration(service.durationInMinutes)},{' '}
                              {service.price.type === 'fixed' && service.price?.promo ? (
                                <>
                                  <Text smallBody>
                                    {service.price.promo} lei{' '}
                                    <Text
                                      smallBody
                                      style={{
                                        textDecorationLine: 'line-through',
                                        textDecorationStyle: 'solid',
                                      }}
                                    >
                                      ({service.price.fixed} lei)
                                    </Text>
                                  </Text>
                                </>
                              ) : (
                                <Text smallBody>{getPrice(service.price)}</Text>
                              )}
                            </Text>
                          }
                          IconComponent={() => <Icon type="arrow-right" />}
                          iconPosition="right"
                        />
                      </TouchableOpacity>
                    ))}
                  </Column>
                ))}
                <Spacer size={16} />
              </FormCard>
            )}
          </Column>
          <Spacer size="16" />
        </ScrollView>
      )}

      {showOptionsMenu && (
        <ModalOverlay>
          <Pressable style={styles.optionsStretchContainer} onPress={() => setShowOptionsMenu(false)} />
          <View style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom]}>
            <SafeAreaView edges={['bottom']}>
              <TouchableOpacity
                style={styles.optionsMenuItem}
                delayPressIn={0}
                onPress={() => {
                  setShowOptionsMenu(false);
                  goToBulkOperationsCallback();
                }}
              >
                <Body style={meroStyles.text.semibold}>{t('services:servicesMultipleActions')}</Body>
              </TouchableOpacity>
            </SafeAreaView>
          </View>
        </ModalOverlay>
      )}
    </ModalScreenContainer>
  );
};

export default ProServicesScreen;
