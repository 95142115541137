import { Portal } from '@gorhom/portal';
import { colors, ModalOverlay, SafeAreaView, sizes } from '@mero/components';
import * as React from 'react';
import { useRef, useState } from 'react';
import {
  Dimensions,
  type LayoutRectangle,
  Platform,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';

import Column from '@mero/components/lib/components/Layout/Column';

export type Ref = {
  isVisible: boolean;
  onDismissMenu: () => void;
  onShowMenu: () => void;
  disabled?: boolean;
};

type Props = {
  button: React.ReactNode;
  mode?: 'default' | 'bottom';
  onDismiss?: () => void;
  onShow?: () => void;
  disabled?: boolean;
  touchableActiveOpacity?: number;
};

const FloatMenu = React.forwardRef<Ref, React.PropsWithChildren<Props>>(function FloatMenu(
  { button, onShow, onDismiss, mode = 'default', disabled, children, touchableActiveOpacity },
  ref,
) {
  const buttonRef = useRef<TouchableOpacity | null>(null);

  const [isVisible, setIsVisible] = React.useState(false);
  const [position, setPosition] = useState<LayoutRectangle | null>(null);
  const [menuDimensions, setMenuDimensions] = useState<{ width: number; height: number } | null>(null);

  const onDismissMenu = () => {
    setIsVisible(false);
    onDismiss?.();
  };

  const onShowMenu = () => {
    buttonRef.current?.measure((x, y, width, height, pageX, pageY) => {
      setPosition({ x: pageX, y: pageY, width, height });
      setIsVisible(true);
      onShow?.();
    });
  };

  const toggleMenu = () => {
    if (isVisible) {
      onDismissMenu();
    } else {
      onShowMenu();
    }
  };

  React.useImperativeHandle(ref, () => ({
    isVisible,
    onDismissMenu,
    onShowMenu,
  }));

  const getMenuStyle = () => {
    if (!position || !menuDimensions) return {};

    const { width, height } = Dimensions.get('window');
    const { width: menuWidth, height: menuHeight } = menuDimensions;

    let left = position.x;
    let top = position.y;

    const margin = 8;

    if (left + menuWidth > width) {
      left = width - menuWidth - margin;
    }

    if (top + position.height + menuHeight > height) {
      top = position.y - menuHeight - margin;
    } else {
      top = position.y + position.height + margin;
    }

    if (top < 0) {
      top = margin;
    }

    return {
      left,
      top,
      opacity: 1,
    };
  };

  return (
    <Column style={styles.container}>
      <TouchableOpacity ref={buttonRef} activeOpacity={touchableActiveOpacity} onPress={toggleMenu} disabled={disabled}>
        {button}
      </TouchableOpacity>
      {isVisible && mode === 'default' && (
        <Portal>
          <TouchableWithoutFeedback onPress={toggleMenu}>
            <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />
          </TouchableWithoutFeedback>
          <View
            style={[styles.selectMenu, getMenuStyle()]}
            onLayout={(event) => {
              const { width, height } = event.nativeEvent.layout;
              setMenuDimensions({ width, height });
            }}
          >
            {children}
          </View>
        </Portal>
      )}
      {isVisible && mode === 'bottom' && (
        <Portal>
          <ModalOverlay>
            <TouchableWithoutFeedback onPress={toggleMenu}>
              <View style={styles.bottomTop} />
            </TouchableWithoutFeedback>
            <View style={[styles.optionsListContainer]}>
              <SafeAreaView edges={['bottom']}>{children}</SafeAreaView>
            </View>
          </ModalOverlay>
        </Portal>
      )}
    </Column>
  );
});

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    width: '100%',
  },
  selectMenu: {
    position: 'absolute',
    borderRadius: 12,
    ...Platform.select({
      web: {
        overflow: 'hidden',
      },
    }),
    backgroundColor: colors.WHITE,
    shadowColor: '#000000',
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.16,
    shadowRadius: 16,
    elevation: 16,
    zIndex: 1000,
    opacity: 0,
  },
  bottomTop: {
    flex: 1,
    alignSelf: 'stretch',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent',
  },
  optionsListContainer: {
    marginTop: 0,
    flexDirection: 'column',
    backgroundColor: colors.WHITE,
    borderTopLeftRadius: sizes[12],
    borderTopRightRadius: sizes[12],
    minHeight: sizes[32],
    overflow: 'hidden',
  },
  modalBorderBottom: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
});

export default FloatMenu;
