import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import Icon, { IconName } from '../../../../../../components/Icon';
import { Label } from '../../../../../../components/shared';

import { colors } from '../../../../../../styles';
import styles from './styles';

interface ClientBookingLabelProps {
  type: 'clientBlocked' | 'clientWarned' | 'clientIsBoost';
}

interface LabelDataProps {
  icon: IconName;
  text: string;
  backgroundColor: string;
  textColor: string;
}

interface LabelData {
  clientBlocked: LabelDataProps;
  clientWarned: LabelDataProps;
  clientIsBoost: LabelDataProps;
}

const ClientBookingLabel: React.FC<ClientBookingLabelProps> = ({ type }) => {
  const { t } = useTranslation('clients');

  const DATA: LabelData = {
    clientBlocked: {
      icon: 'Block',
      text: t('blocked'),
      backgroundColor: '#FAE6EA',
      textColor: colors.RADICAL_RED,
    },
    clientWarned: {
      icon: 'Warning',
      text: t('warned'),
      backgroundColor: '#FBEBE7',
      textColor: colors.OUTRAGEOUS_ORANGE,
    },
    clientIsBoost: {
      icon: 'Boost',
      text: t('clientBoost'),
      backgroundColor: '#ECE6F9',
      textColor: '#7431F7',
    },
  };

  const { backgroundColor, textColor, text, icon } = DATA[type];

  return (
    <View style={[styles.container, { backgroundColor }]}>
      <Icon name={icon} size={20} />
      <Label style={[styles.text, { color: textColor }]}>{text}</Label>
    </View>
  );
};

export default ClientBookingLabel;
