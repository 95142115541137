import { ServiceId } from '../services';
import { CustomField } from './customField';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type ServicesCustomFieldsResult =
  | {
      readonly hasCustomFields: true;
      readonly services: {
        readonly _id: ServiceId;
        readonly customFields: CustomField.Any[];
      }[];
    }
  | {
      readonly hasCustomFields: false;
    };

const JSON: t.Type<ServicesCustomFieldsResult, JSONable> = t.union(
  [
    t.type({
      hasCustomFields: t.literal(true),
      services: t.array(
        t.type(
          {
            _id: ServiceId,
            customFields: t.array(CustomField.Any.JSON),
          },
          'ServiceWithCustomFields',
        ),
        'ServicesWithCustomFields',
      ),
    }),
    t.type({
      hasCustomFields: t.literal(false),
    }),
  ],
  'ServicesCustomFieldsResult',
);

export const ServicesCustomFieldsResult = {
  JSON,
};
