import { colors } from '@/styles';
import { CheckoutTransactionPreview } from '@mero/api-sdk';
import { ScaledNumber } from '@mero/shared-sdk';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable } from 'react-native';

import Icon, { IconName } from '../Icon';
import { Column, FormCard, H2s, Label, Row, SmallBody, Spacer } from '../shared';
import { styles } from './styles';

export type PaymentMethods = 'Card' | 'Cash' | 'CardAndCash' | 'BankTransfer' | 'Membership';

const PaymentIcon: React.FC<{ type: PaymentMethods }> = React.memo(({ type }) => {
  const iconName = React.useMemo(() => {
    const icons: Record<PaymentMethods, IconName> = {
      CardAndCash: 'CardAndCash',
      Cash: 'Cash',
      Card: 'Card',
      BankTransfer: 'BankTransfer',
      Membership: 'Membership',
    };
    return icons[type];
  }, [type]);

  return (
    <Column>
      <Icon name={iconName} size={32} />
    </Column>
  );
});

PaymentIcon.displayName = 'PaymentIcon';

type FinishedTransaction = CheckoutTransactionPreview.AnyFinished | CheckoutTransactionPreview.AnyDeleted;

export interface TransactionCardProps {
  transaction: FinishedTransaction;
  onPress: () => void;
}

const getPaymentType = (paymentTypes: string[]): PaymentMethods => {
  if (paymentTypes.includes('Card') && paymentTypes.includes('Cash')) {
    return 'CardAndCash';
  }
  if (paymentTypes.includes('Card')) return 'Card';
  if (paymentTypes.includes('Cash')) return 'Cash';
  if (paymentTypes.includes('Membership')) return 'Membership';
  if (paymentTypes.includes('BankTransfer')) return 'BankTransfer';
  return 'CardAndCash';
};

const formatClientName = (client: any, t: any): string => {
  if (!client) return t('noClient');
  const { firstname, lastname } = client.user.profile;
  if (!firstname && !lastname) return t('noName');
  return `${firstname || ''} ${lastname || ''}`.trim();
};

const TransactionCard: React.FC<TransactionCardProps> = React.memo(({ transaction, onPress }) => {
  const { t } = useTranslation('checkout');
  const paymentType = React.useMemo(() => getPaymentType(transaction.paymentTypes), [transaction.paymentTypes]);

  const isFinished = transaction.status === 'Finished';
  const isProtocol = transaction.isProtocol;

  const statusText = React.useMemo(() => {
    if (isProtocol && (isFinished || transaction.status === 'Deleted')) {
      return t(isFinished ? 'proceedProtocol' : 'proceedProtocolDeleted');
    }
    return t(transaction.status);
  }, [isProtocol, isFinished, transaction.status, t]);

  const formattedDate = React.useMemo(() => {
    return DateTime.fromJSDate(transaction.finishedAt).toFormat('dd MMM. yyyy, HH:mm').toLowerCase();
  }, [transaction.finishedAt]);

  return (
    <FormCard dropShaddow style={styles.card} rounded paddings="none">
      <Pressable
        key={transaction._id}
        style={styles.container}
        onPress={onPress}
        accessibilityRole="button"
        accessibilityLabel={`Transaction ${statusText}`}
      >
        <PaymentIcon type={paymentType} />
        <Row style={styles.contentContainer} justifyContent="space-between">
          <Column style={styles.detailsContainer}>
            <Label style={[styles.statusLabel, isFinished ? styles.finishedStatus : styles.deletedStatus]}>
              {statusText}
            </Label>
            <H2s>
              {ScaledNumber.toNumber(transaction.total.total.amount)} {t(transaction.total.total.unit)}
            </H2s>
            <Spacer size={2} />
            <SmallBody>{formatClientName(transaction.client, t)}</SmallBody>
            <Spacer size={6} />
            <SmallBody style={styles.dateText}>{formattedDate}</SmallBody>
          </Column>
        </Row>
        <Icon name="Arrow" color={colors.DARK_BLUE} />
      </Pressable>
    </FormCard>
  );
});

TransactionCard.displayName = 'TransactionCard';

export default TransactionCard;
