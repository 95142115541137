import { NoteDetails } from '@mero/api-sdk';
import * as React from 'react';
import { Pressable, Text, View } from 'react-native';

import NoteImage from '../../screens/Authorized/NoteDetailsScreen/components/NoteImage';

import { colors } from '../../styles';
import { getBookingDateForNote } from '../../utils/booking';
import { DEFAULT_TIMEZONE } from '../../utils/time';
import Icon from '../Icon';
import { Body, Column, FormCard, Row, StatusLabel } from '../shared';
import styles from './styles';

interface ClientNoteCardProps {
  onOptionsPress: (note: NoteDetails) => void;
  note: NoteDetails;
  isProfilePinned?: boolean;
}
const ClientNoteCard = ({ onOptionsPress, note, isProfilePinned }: ClientNoteCardProps) => {
  const handleNotePress = React.useCallback(() => {
    onOptionsPress(note);
  }, [note, onOptionsPress]);

  return (
    <FormCard dropShaddow style={styles.card} rounded>
      <Row gap={12}>
        {!isProfilePinned && (
          <View style={[styles.icon, note.isPinned ? styles.iconActive : styles.iconInactive]}>
            <Icon name="Document" color={note.isPinned ? '#3193F8' : colors.COMET} size={18} />
          </View>
        )}
        <Column flex={1}>
          <Row justifyContent="space-between" flex={1}>
            <Column flex={1}>
              {note.isPinned && <Text style={styles.pinnedText}>Notiță fixată</Text>}
              <Body>{note.text}</Body>
            </Column>
            <Pressable style={styles.iconContainer} onPress={handleNotePress}>
              <Icon name="Dots" color={colors.DARK_BLUE} />
            </Pressable>
          </Row>
          <Column gap={8}>
            {note.appointment && (
              <Row>
                <StatusLabel icon="Calendar" text={getBookingDateForNote(note.appointment.start, DEFAULT_TIMEZONE)} />
              </Row>
            )}

            {note.images?.length > 0 && (
              <Row gap={4} style={styles.imagesWrapper}>
                {note.images?.slice(0, 4).map((photo, index) => {
                  const showOverlay = note.images.length > 4 && index === 3;
                  return <NoteImage key={index} url={photo.small} showOverlay={showOverlay} />;
                })}
              </Row>
            )}
            {note.createdBy && (
              <Text style={styles.author}>{`${note.createdBy.lastname} ${
                note.createdBy.firstname
              } - ${getBookingDateForNote(note.date, DEFAULT_TIMEZONE)}`}</Text>
            )}
          </Column>
        </Column>
      </Row>
    </FormCard>
  );
};

export default ClientNoteCard;
