import React from 'react';
import { Svg, Path } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

const Dropdown = ({ color, size = 24 }: IconProps) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path d="M7 10h10l-5 5-5-5z" fill={color || '#080DE0'} />
    </Svg>
  );
};

export default Dropdown;
