import * as React from 'react';
import { Pressable } from 'react-native';

import * as icons from '../../../assets/icons';
import { colors } from '../../../styles';
import Icon from '../../Icon';
import Column from '../Layout/Column';
import Row from '../Layout/Row';
import StatusLabel from '../StatusLabel';
import Body from '../Text/Body';
import SmallBody from '../Text/SmallBody';
import styles from './styles';

type SelectableListItemProps = {
  onPress: () => void;
  onClear: () => void;
  onOptionPress?: () => void;
  title: string;
  subtitle?: string;
  value?: string;
  valueSubtitle?: string;
  labelText?: string;
  labelIcon?: keyof typeof icons;
};

const SelectableListItem = ({
  onPress,
  onClear,
  onOptionPress,
  title,
  subtitle,
  value,
  valueSubtitle,
  labelText,
  labelIcon,
}: SelectableListItemProps) => {
  const icon = value ? (onOptionPress !== undefined ? 'Dots' : 'Close') : 'Arrow';
  const iconAction = value ? (onOptionPress !== undefined ? onOptionPress : onClear) : onPress;

  return (
    <Pressable onPress={!value ? onPress : undefined} style={styles.container}>
      <Row justifyContent="space-between">
        <Column flex={1}>
          <Body style={styles.title}>{title}</Body>
          {!value && <SmallBody style={styles.subtitle}>{subtitle}</SmallBody>}
          {value ? (
            <Column>
              <SmallBody style={styles.value} numberOfLines={1} ellipsizeMode="tail">
                {value}
              </SmallBody>
              {valueSubtitle && <SmallBody style={styles.subtitle}>{valueSubtitle}</SmallBody>}
            </Column>
          ) : null}
          {labelText && (
            <Row style={styles.label}>
              <StatusLabel icon={labelIcon} text={labelText} />
            </Row>
          )}
        </Column>
        <Pressable onPress={iconAction}>
          <Icon name={icon} size={18} color={colors.DARK_BLUE} />
        </Pressable>
      </Row>
    </Pressable>
  );
};

export default SelectableListItem;
