import { paymentConversion } from '@/utils/checkout';
import { checkoutItemConversion, convertMembershipPayment } from '@/utils/checkout';
import {
  CompanyVatStatus,
  Firstname,
  OptionalLastname,
  PortionPercentScaledNumber,
  PositiveInt,
  SavedCheckoutCompany,
  StrictPhoneNumber,
} from '@mero/api-sdk';
import { CheckoutCashRegisterId } from '@mero/api-sdk/dist/checkout/cashRegisters/checkoutCashRegisterId';
import { CreateCheckoutTransactionItem } from '@mero/api-sdk/dist/checkout/checkoutApi/createCheckoutTransactionItem';
import { Membership } from '@mero/api-sdk/dist/checkout/checkoutApi/createCheckoutTransactionPayment/membership';
import { CheckoutClientPreview } from '@mero/api-sdk/dist/checkout/checkoutClientPreview';
import { Company, NONE } from '@mero/api-sdk/dist/checkout/checkoutTransactionCompany/checkoutTransactionCompany';
import { computeTransactionTotals } from '@mero/api-sdk/dist/checkout/checkoutTransactionDetails/utils';
import { CheckoutTransactionId } from '@mero/api-sdk/dist/checkout/checkoutTransactionId';
import { CheckoutTransactionItem } from '@mero/api-sdk/dist/checkout/checkoutTransactionItem';
import { BankTransfer } from '@mero/api-sdk/dist/checkout/checkoutTransactionPayment/bankTransfer';
import { Card } from '@mero/api-sdk/dist/checkout/checkoutTransactionPayment/card';
import { Cash } from '@mero/api-sdk/dist/checkout/checkoutTransactionPayment/cash';
import { Giftcard } from '@mero/api-sdk/dist/checkout/checkoutTransactionPayment/giftcard';
import { Online } from '@mero/api-sdk/dist/checkout/checkoutTransactionPayment/online';
import { CheckoutUserPreview } from '@mero/api-sdk/dist/checkout/checkoutUserPreview';
import { NO_VAT } from '@mero/api-sdk/dist/checkout/companies/companyVatStatus/noVat';
import { AvailableMembershipItems } from '@mero/api-sdk/dist/memberships/membershipAvailableItems';
import { PageId } from '@mero/api-sdk/dist/pages';
import { createModelContext } from '@mero/components';
import { AppliedDiscount, HasId, MeroUnits, ScaledNumber, Option } from '@mero/shared-sdk';
import * as Ap from 'fp-ts/Apply';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { isLeft } from 'fp-ts/lib/Either';
import { NonEmptyArray } from 'fp-ts/lib/NonEmptyArray';
import { NonEmptyString } from 'io-ts-types';
import * as React from 'react';
import { Platform } from 'react-native';
import uuid from 'react-native-uuid';

import { computeDraftTransactionPaymentTotal } from '../../screens/Authorized/CheckoutScreen/utils';

import log from '../../utils/log';
import { distributeAmount, findAllIndexes } from '../../utils/payment';
import { meroApi } from '../AuthContext';
import { CurrentBusinessContext } from '../CurrentBusiness';

const getUiKey = (): UiKey => {
  const key = uuid.v4();
  return (Array.isArray(key) ? key.join('') : key) as UiKey;
};

export const convertSavedCheckoutCompanyToCheckoutTransactionCompany = (
  company: SavedCheckoutCompany,
): Company['company'] => ({
  _id: company._id,
  name: company.billingDetails?.name ?? ('' as NonEmptyString),
  regNo: company.billingDetails?.registrationNumber ?? ('' as NonEmptyString),
  fiscalCode: company.billingDetails?.fiscalCode ?? ('' as NonEmptyString),
  countryCode: (company.billingDetails?.country as NonEmptyString) ?? ('RO' as NonEmptyString),
  county: company.billingDetails?.address.county,
  city: company.billingDetails?.address.city ?? ('' as NonEmptyString),
  address: company.billingDetails?.address.address ?? ('' as NonEmptyString),
  phone: company.billingDetails?.contact.phone,
  email: company.billingDetails?.contact.email,
  vatStatus: company.billingDetails?.isVatPayer
    ? CompanyVatStatus.payer(PortionPercentScaledNumber.unsafeFromNumber(19, 0), company.billingDetails?.fiscalCode)
    : NO_VAT,
  emitReceiptStatus: company.emitReceiptStatus,
});

export const calculateDiscountValue = (
  total: ScaledNumber,
  discount?: AppliedDiscount<ScaledNumber, MeroUnits.Any>,
) => {
  if (!discount) {
    return ScaledNumber.zero();
  }

  if (discount.type === 'Value') {
    return discount.value.amount;
  }

  return ScaledNumber.mul(total, discount.percent);
};

export const applyDiscountToTotal = (items: WithUiKey<Item>[]) => {
  return items.map((item) => {
    if (item.type === 'Booking') {
      return {
        ...item,
        items: item.items.map((i) => ({
          ...i,
          total: {
            ...i.total,
            amount: {
              ...i.total.amount,
              amount: ScaledNumber.sub(
                i.total.amount.amount,
                calculateDiscountValue(i.total.amount.amount, i.total.discount),
              ),
            },
          },
        })),
      };
    }

    if (item.type === 'Membership' || item.type === 'MembershipInstallment') {
      return item;
    }

    return {
      ...item,
      total: {
        ...item.total,
        amount: {
          ...item.total.amount,
          amount: ScaledNumber.sub(
            item.total.amount.amount,
            calculateDiscountValue(item.total.amount.amount, item.total.discount),
          ),
        },
      },
    };
  });
};

export type ClientNone = {
  readonly type: 'none';
};

const ClientNone: ClientNone = {
  type: 'none',
};

export type ClientExisting = Readonly<{
  type: 'existing';
  client: CheckoutClientPreview;
}>;

export type ClientNewEmpty = Readonly<{
  type: 'new';
  isValid: true;
  isEmpty: true;
  fullname?: undefined;
  phone?: undefined;
}>;

export type ClientNewValid = Readonly<{
  type: 'new';
  isValid: true;
  isEmpty: false;
  fullname: string;
  firstname: Firstname;
  lastname: OptionalLastname;
  phone: StrictPhoneNumber;
}>;

export type ClientNewInvalid = Readonly<{
  type: 'new';
  isValid: false;
  isEmpty: false;
  fullname?: string;
  phone?: string;
}>;

export type UiKey = string & { readonly Brand: unique symbol };

export type WithUiKey<T> = T & { uiKey: UiKey };

export type Client = ClientNone | ClientExisting | ClientNewEmpty | ClientNewValid | ClientNewInvalid;

export type MembershipItem = WithUiKey<
  CheckoutTransactionItem.Membership<MeroUnits.Any>['membershipTemplate']['items'][number]
>;

export type MembershipEdit = Omit<CheckoutTransactionItem.Membership<MeroUnits.Any>, 'membershipTemplate'> & {
  membershipTemplate: Omit<CheckoutTransactionItem.Membership<MeroUnits.Any>['membershipTemplate'], 'items'> & {
    items: NonEmptyArray<MembershipItem>;
  };
};
export type ItemService = CheckoutTransactionItem.Service<MeroUnits.Any>;

export type ItemBooking = Omit<CheckoutTransactionItem.Booking<MeroUnits.Any>, 'items'> & {
  items: WithUiKey<CheckoutTransactionItem.Booking<MeroUnits.Any>['items'][number]>[];
};

export type ItemProduct = CheckoutTransactionItem.Product<MeroUnits.Any>;

export type ItemMembership = CheckoutTransactionItem.Membership<MeroUnits.Any>;

export type ItemMembershipInstallment = CheckoutTransactionItem.MembershipInstallment<MeroUnits.Any>;

export type ItemAmount = CheckoutTransactionItem.Amount<MeroUnits.Any>;

export type ItemDiscount = AppliedDiscount<ScaledNumber, MeroUnits.Any>;

export type Item = ItemBooking | ItemService | ItemProduct | MembershipEdit | ItemMembershipInstallment | ItemAmount;

export const withUiKey = <T extends Record<string, unknown>>(item: T): WithUiKey<T> => ({
  uiKey: getUiKey(),
  ...item,
});

export type BankTransferPayment = BankTransfer<ScaledNumber, MeroUnits.Any>;
export type CardPayment = Card<ScaledNumber, MeroUnits.Any>;
export type CashPayment = Cash<ScaledNumber, MeroUnits.Any>;
export type GiftcardPayment = Giftcard<ScaledNumber, MeroUnits.Any>;
export type OnlinePayment = Online<ScaledNumber, MeroUnits.Any>;

export type MembershipPayment = Omit<Membership<ScaledNumber, MeroUnits.Any>, 'membership' | 'items'> &
  Omit<AvailableMembershipItems<MeroUnits.Any>, 'items'> & {
    items: WithUiKey<
      AvailableMembershipItems<MeroUnits.Any>['items'][number] & {
        quantity: PositiveInt;
        usedFor?: UiKey;
      }
    >[];
  };

export type Payment =
  | BankTransferPayment
  | CardPayment
  | CashPayment
  | GiftcardPayment
  | MembershipPayment
  | OnlinePayment;

export const getEmptyAmount = (owner: CheckoutUserPreview): WithUiKey<ItemAmount> =>
  withUiKey({
    type: 'Amount',
    total: {
      amount: {
        amount: ScaledNumber.fromNumber(0, 2),
        unit: 'RON',
      },
      vatStatus: {
        type: 'Included',
        rate: undefined,
      },
    },
    saleOwner: owner,
  });

export const EmptyDiscount: ItemDiscount = {
  type: 'Value',
  value: {
    amount: ScaledNumber.fromNumber(0, 2),
    unit: 'RON',
  },
};

const isValidClient = (client: Client): client is ClientNone | ClientExisting | ClientNewValid | ClientNewEmpty =>
  client.type === 'none' || client.type === 'existing' || (client.type === 'new' && client.isValid);

const isValidItem = (item: Item): item is WithUiKey<Item> =>
  item.type === 'Booking' ||
  item.type === 'Service' ||
  item.type === 'Product' ||
  item.type === 'Membership' ||
  item.type === 'MembershipInstallment' ||
  item.type === 'Amount';

const isValidPayment = (payment: Payment): payment is Payment =>
  payment.type === 'Card' ||
  payment.type === 'Cash' ||
  payment.type === 'Membership' ||
  payment.type === 'Giftcard' ||
  payment.type === 'BankTransfer';

const isValidCompany = (company: Company['company'] | undefined): company is Company['company'] =>
  company !== undefined;

export type Receipt =
  | {
      readonly emit: false;
    }
  | {
      readonly emit: true;
      readonly cashRegister: HasId<CheckoutCashRegisterId>;
      readonly customerFiscalCode: Option<NonEmptyString>;
    };

type InvalidForm = Readonly<{
  type: 'Invalid';
  draftId?: CheckoutTransactionId;
  autoSaveInterval: number;
  hasChanges: boolean;
  draft: {
    pageId: PageId | undefined;
    client: Client;
    items: WithUiKey<Item>[];
    payments: Payment[];
    company: Company['company'] | undefined;
    discount: ItemDiscount | undefined;
    emitReceipt: boolean;
    receipt: Receipt;
    isProtocol: boolean;
  };
}>;

type ValidForm = {
  type: 'Valid';
  draftId?: CheckoutTransactionId;
  autoSaveInterval: number;
  hasChanges: boolean;
  draft: {
    pageId: PageId;
    client: Client;
    items: NonEmptyArray<WithUiKey<Item>>;
    payments: Payment[];
    company: Company['company'];
    discount: ItemDiscount | undefined;
    emitReceipt: boolean;
    receipt: Receipt;
    isProtocol: boolean;
  };
};

type State = InvalidForm | ValidForm;

const validate = (state: State): State =>
  pipe(
    Ap.sequenceT(O.Apply)(
      pipe(state.draft.pageId, O.fromNullable, O.filter(PageId.is)),
      pipe(state.draft.client, O.fromNullable, O.filter(isValidClient)),
      pipe(state.draft.items, O.of, O.filter(A.every(isValidItem)), O.filter(A.isNonEmpty)),
      pipe({ payments: state.draft.payments, isProtocol: state.draft.isProtocol }, ({ payments, isProtocol }) =>
        isProtocol ? O.some([]) : pipe(payments, O.of, O.filter(A.every(isValidPayment)), O.filter(A.isNonEmpty)),
      ),
      pipe(state.draft.company, O.fromNullable, O.filter(isValidCompany)),
      pipe(state.draft.emitReceipt, O.fromNullable),
      pipe(state.draft.receipt, O.fromNullable),
    ),
    O.map(
      ([pageId, client, items, payments, company, emitReceipt, receipt]): ValidForm => ({
        type: 'Valid',
        draftId: state.draftId,
        autoSaveInterval: state.autoSaveInterval,
        hasChanges: state.hasChanges,
        draft: {
          pageId,
          client,
          items,
          payments,
          company,
          discount: state.draft.discount,
          emitReceipt,
          receipt,
          isProtocol: state.draft.isProtocol,
        },
      }),
    ),
    O.getOrElseW(() => state),
  );

const defaultState = (): State => ({
  type: 'Invalid',
  autoSaveInterval: 0,
  hasChanges: false,
  draft: {
    pageId: undefined,
    client: ClientNone,
    items: [],
    payments: [],
    company: undefined,
    discount: undefined,
    emitReceipt: Platform.OS === 'web',
    isProtocol: false,
    receipt: {
      emit: false,
    },
  },
});

export const CheckoutFormContext = createModelContext(
  defaultState(),
  {
    reset() {
      return defaultState();
    },
    setDraftId: (state, draftId: CheckoutTransactionId) => {
      return validate({
        ...state,
        type: 'Invalid',
        draftId,
      });
    },
    setPageId: (state, pageId: PageId) => {
      return validate({
        ...state,
        type: 'Invalid',
        draft: {
          ...state.draft,
          pageId,
        },
      });
    },
    setClient: (state, client: Client) =>
      validate({
        ...state,
        type: 'Invalid',
        hasChanges: true,
        draft: {
          ...state.draft,
          client,
        },
      }),
    setItem: (state, item: Item) =>
      validate({
        ...state,
        type: 'Invalid',
        hasChanges: true,
        draft: {
          ...state.draft,
          items: [...state.draft.items, withUiKey(item)],
        },
      }),
    updateItem: (state, item: WithUiKey<Item>) =>
      validate({
        ...state,
        type: 'Invalid',
        hasChanges: true,
        draft: {
          ...state.draft,
          items: state.draft.items.map((i) => (item.uiKey === i.uiKey ? item : i)),
        },
      }),
    removeItem: (state, item: WithUiKey<Item>) =>
      validate({
        ...state,
        type: 'Invalid',
        hasChanges: true,
        draft: {
          ...state.draft,
          items: state.draft.items.filter((i) => item.uiKey !== i.uiKey),
        },
      }),
    addDiscount: (state, discount: ItemDiscount) =>
      validate({
        ...state,
        type: 'Invalid',
        hasChanges: true,
        draft: {
          ...state.draft,
          discount,
        },
      }),
    updateDiscount: (state, discount: ItemDiscount) =>
      validate({
        ...state,
        type: 'Invalid',
        hasChanges: true,
        draft: {
          ...state.draft,
          discount,
        },
      }),
    removeDiscount: (state) =>
      validate({
        ...state,
        type: 'Invalid',
        hasChanges: true,
        draft: {
          ...state.draft,
          discount: undefined,
        },
      }),
    toggleEmitReceipt: (state) =>
      validate({
        ...state,
        type: 'Invalid',
        hasChanges: true,
        draft: {
          ...state.draft,
          emitReceipt: !state.draft.emitReceipt,
        },
      }),
    setCustomerFiscalCode: (state, customerFiscalCode: string) => {
      if (state.draft.receipt.emit === true) {
        if (customerFiscalCode.trim() === '') {
          return validate({
            ...state,
            type: 'Invalid',
            hasChanges: true,
            draft: {
              ...state.draft,
              receipt: {
                ...state.draft.receipt,
                customerFiscalCode: undefined,
              },
            },
          });
        }

        const decodedFiscalCode = NonEmptyString.decode(customerFiscalCode);

        if (isLeft(decodedFiscalCode)) {
          return state;
        }

        return validate({
          ...state,
          type: 'Invalid',
          hasChanges: true,
          draft: {
            ...state.draft,
            receipt: {
              ...state.draft.receipt,
              customerFiscalCode: decodedFiscalCode.right,
            },
          },
        });
      }
      return state;
    },
    selectReceipt: (state, receipt: Receipt) =>
      validate({
        ...state,
        type: 'Invalid',
        hasChanges: true,
        draft: {
          ...state.draft,
          receipt,
        },
      }),
    initiatePayment: (state, payments: Payment[]) =>
      validate({
        ...state,
        type: 'Invalid',
        hasChanges: true,
        draft: {
          ...state.draft,
          payments,
        },
      }),
    setPayment: (state, payment: Payment) =>
      validate({
        ...state,
        type: 'Invalid',
        hasChanges: true,
        draft: {
          ...state.draft,
          payments: [...state.draft.payments, payment],
        },
      }),
    updatePayment: (state, payment: Payment) =>
      validate({
        ...state,
        type: 'Invalid',
        hasChanges: true,
        draft: {
          ...state.draft,
          payments: state.draft.payments.map((p) => (p.type === payment.type ? payment : p)),
        },
      }),
    setPayments: (state, payments: Payment[]) =>
      validate({
        ...state,
        type: 'Invalid',
        hasChanges: true,
        draft: {
          ...state.draft,
          payments,
        },
      }),
    removePayment: (state, payment: Payment) =>
      validate({
        ...state,
        type: 'Invalid',
        hasChanges: true,
        draft: {
          ...state.draft,
          payments: state.draft.payments.filter((p) => p.type !== payment.type),
        },
      }),
    resetMembershipByType: (state, type: Payment['type']) =>
      validate({
        ...state,
        type: 'Invalid',
        hasChanges: true,
        draft: {
          ...state.draft,
          payments: state.draft.payments.filter((p) => p.type !== type),
        },
      }),
    setCompany: (state, company: Company['company']) =>
      validate({
        ...state,
        type: 'Invalid',
        hasChanges: true,
        draft: {
          ...state.draft,
          company,
        },
      }),
    removeCompany: (state) =>
      validate({
        ...state,
        type: 'Invalid',
        hasChanges: true,
        draft: {
          ...state.draft,
          company: undefined,
        },
      }),
    addProtocol: (state) =>
      validate({
        ...state,
        type: 'Invalid',
        hasChanges: true,
        draft: {
          ...state.draft,
          payments: [],
          isProtocol: true,
        },
      }),
    removeProtocol: (state) =>
      validate({
        ...state,
        type: 'Invalid',
        hasChanges: true,
        draft: {
          ...state.draft,
          isProtocol: false,
        },
      }),
    update: (state, payload: Partial<Omit<State, 'draft'> & { draft: Partial<State['draft']> }>) => {
      const { draft, ...rest } = payload;
      return validate({
        ...state,
        ...rest,
        type: 'Invalid',
        draft: {
          ...state.draft,
          ...draft,
        },
      });
    },
    cancelAutoSave: (state) => {
      return {
        ...state,
        autoSaveInterval: 0,
      };
    },
    run: (s, f: (s: State) => void) => {
      f(s);
      return s;
    },
  },
  (dispatch) => {
    const calculatePaymentValues = (params: {
      payment: Payment;
      payments: Payment[];
      items: WithUiKey<Item>[];
      discount?: ItemDiscount;
      company: Company['company'] | undefined;
      receipt: Receipt;
    }): Payment[] => {
      const { payment, payments, items, discount, company, receipt } = params;

      const totalValue = computeTransactionTotals(
        {
          unit: 'RON',
          payments: payments.map(paymentConversion),
          items,
          company: company
            ? {
                type: 'Company',
                company: company,
                emitReceipt: receipt.emit,
                receipt,
              }
            : NONE,
          discount,
        },
        2,
      );

      const membershipPayments = payments.filter((p): p is MembershipPayment => p.type === 'Membership');
      const membershipValue =
        membershipPayments.length > 0
          ? membershipPayments.reduce(
              (acc, membershipPayment) =>
                ScaledNumber.toNumber(
                  computeDraftTransactionPaymentTotal(
                    convertMembershipPayment(membershipPayment),
                    applyDiscountToTotal(items),
                    'RON',
                  ).amount,
                ) + acc,
              0,
            )
          : 0;

      const otherPaymentsValue = ScaledNumber.toNumber(totalValue.total.amount) - membershipValue;

      const paymentValue =
        payment.type === 'Cash' ||
        payment.type === 'Card' ||
        payment.type === 'Giftcard' ||
        payment.type === 'BankTransfer'
          ? ScaledNumber.toNumber(payment.total.amount)
          : 0;

      const index = payments.findIndex(
        (p) =>
          (payment.type === 'Membership' && p.type === 'Membership' && p.membership._id === payment.membership._id) ||
          (payment.type !== 'Membership' && p.type !== 'Membership' && p.type === payment.type),
      );

      const isMaxValue =
        paymentValue > otherPaymentsValue ||
        (index === -1 && payments.length === 0) ||
        (index === 0 && payments.length === 1);

      const excludeIndexes = findAllIndexes(payments, (p) => p.type === 'Membership');

      const totalSlots = index === -1 ? payments.length + 1 : payments.length;
      const values = distributeAmount(
        otherPaymentsValue,
        isMaxValue ? otherPaymentsValue : paymentValue,
        totalSlots,
        index === -1 ? totalSlots - 1 : index,
        excludeIndexes,
      );

      return (index === -1 ? [...payments, payment] : payments).map((p, index) => {
        if (p.type === payment.type && p.type !== 'Membership' && p.type !== 'Online') {
          return isMaxValue
            ? {
                ...payment,
                total: {
                  ...p.total,
                  amount: ScaledNumber.fromNumber(otherPaymentsValue, 2),
                },
              }
            : payment;
        }

        if (p.type === 'Membership' && p.type === payment.type && p.membership._id === payment.membership._id) {
          return payment;
        }

        if (p.type === 'Membership' || p.type === 'Online') {
          return p;
        }
        return {
          ...p,
          total: {
            ...p.total,
            amount: ScaledNumber.fromNumber(values[index], 2),
          },
        };
      });
    };

    const saveDraft = async (pageId: PageId, draft: State['draft'], draftId?: State['draftId']) => {
      if (draft.items.length === 0) {
        return;
      }
      const items = draft.items.map(checkoutItemConversion) as NonEmptyArray<
        CreateCheckoutTransactionItem.Any<MeroUnits.Any>
      >;

      if (draftId) {
        await meroApi.checkout.updateDraft({
          _id: draftId,
          unit: 'RON' as MeroUnits.RON,
          page: {
            _id: pageId,
          },
          isProtocol: draft.isProtocol,
          client: draft.client.type === 'existing' ? { _id: draft.client.client._id } : undefined,
          items,
          discount: draft.discount,
          payments: [],
          company: draft.company
            ? {
                type: 'Company',
                company: { _id: draft.company._id },
                receipt:
                  draft.company.emitReceiptStatus.type === 'Allowed' && draft.emitReceipt
                    ? draft.receipt
                    : { emit: false },
              }
            : { type: 'None' },
        });
        return draftId;
      }

      const newDraft = await meroApi.checkout.createDraft({
        unit: 'RON' as MeroUnits.RON,
        page: {
          _id: pageId,
        },
        isProtocol: false,
        client: draft.client.type === 'existing' ? { _id: draft.client.client._id } : undefined,
        items,
        discount: undefined,
        payments: [],
        company: draft.company
          ? {
              type: 'Company',
              company: { _id: draft.company._id },
              receipt:
                draft.company.emitReceiptStatus.type === 'Allowed' && draft.emitReceipt
                  ? draft.receipt
                  : { emit: false },
            }
          : { type: 'None' },
      });

      return newDraft._id;
    };

    return {
      init: dispatch.setPageId,
      reset: dispatch.reset,
      setClient: dispatch.setClient,
      findOrCreateClient: () =>
        new Promise((resolve, reject): void => {
          return dispatch.run(async (state): Promise<void> => {
            try {
              if (!state.draft.pageId) {
                return reject(new Error('Page id is not set'));
              }
              const client = state.draft.client;
              if (client.type === 'new' && client.isValid && !client.isEmpty) {
                const users = await meroApi.users.findUsers({ phone: client.phone });

                if (users.length === 0) {
                  const clientId = await meroApi.clients.createClientByPhone({
                    pageId: state.draft.pageId,
                    phone: client.phone,
                    firstname: client.firstname,
                    lastname: client.lastname,
                  });

                  const clientDetails = await meroApi.clients.getClientById(clientId);

                  if (clientDetails) {
                    dispatch.setClient({
                      type: 'existing',
                      client: {
                        _id: clientDetails._id,
                        pageId: state.draft.pageId,
                        user: {
                          _id: clientDetails.user._id,
                          phone: clientDetails.user.phone,
                          profile: {
                            firstname: clientDetails.user.firstname,
                            lastname: clientDetails.user.lastname,
                            photo: clientDetails.user.photo,
                          },
                        },
                      },
                    });

                    return resolve('');
                  }
                } else {
                  const userDetails = users[0];

                  const clients = await meroApi.clients.search({
                    pageId: state.draft.pageId,
                    userId: userDetails._id,
                  });

                  if (clients.length > 0) {
                    const clientDetails = await meroApi.clients.getClientById(clients[0]._id);

                    if (clientDetails) {
                      dispatch.setClient({
                        type: 'existing',
                        client: {
                          _id: clientDetails._id,
                          pageId: state.draft.pageId,
                          user: {
                            _id: clientDetails.user._id,
                            phone: clientDetails.user.phone,
                            profile: {
                              firstname: clientDetails.user.firstname,
                              lastname: clientDetails.user.lastname,
                              photo: clientDetails.user.photo,
                            },
                          },
                        },
                      });

                      return resolve('');
                    }
                  } else {
                    const clientId = await meroApi.clients.createClientByPhone({
                      pageId: state.draft.pageId,
                      phone: client.phone,
                      firstname: client.firstname,
                      lastname: client.lastname,
                    });

                    const clientDetails = await meroApi.clients.getClientById(clientId);

                    if (clientDetails) {
                      dispatch.setClient({
                        type: 'existing',
                        client: {
                          _id: clientDetails._id,
                          pageId: state.draft.pageId,
                          user: {
                            _id: clientDetails.user._id,
                            phone: clientDetails.user.phone,
                            profile: {
                              firstname: clientDetails.user.firstname,
                              lastname: clientDetails.user.lastname,
                              photo: clientDetails.user.photo,
                            },
                          },
                        },
                      });

                      return resolve('');
                    }
                  }
                }
              }

              return reject(new Error('Invalid client'));
            } catch (error) {
              log.error('failed to find or create client', error);
              reject(error);
            }
          });
        }),
      setItem: dispatch.setItem,
      updateItem: dispatch.updateItem,
      removeItem: dispatch.removeItem,
      addDiscount: dispatch.addDiscount,
      updateDiscount: dispatch.updateDiscount,
      removeDiscount: dispatch.removeDiscount,
      addProtocol: dispatch.addProtocol,
      removeProtocol: dispatch.removeProtocol,
      toggleEmitReceipt: dispatch.toggleEmitReceipt,
      selectReceipt: dispatch.selectReceipt,
      initiatePayment: dispatch.initiatePayment,
      setPayment: (payment: Payment | Payment[]) => {
        const newPayments = Array.isArray(payment) ? payment : [payment];
        dispatch.run((state) => {
          const computedPayments = newPayments.reduce(
            (acc, payment) =>
              calculatePaymentValues({
                payment,
                payments: acc,
                items: state.draft.items,
                company: state.draft.company,
                discount: state.draft.discount,
                receipt: state.draft.receipt,
              }),
            state.draft.payments,
          );

          dispatch.setPayments(computedPayments);
        });
      },
      updatePayment: (payment: Payment | Payment[]) => {
        const newPayments = Array.isArray(payment) ? payment : [payment];
        dispatch.run((state) => {
          const computedPayments = newPayments.reduce(
            (acc, payment) =>
              calculatePaymentValues({
                payment,
                payments: acc,
                items: state.draft.items,
                company: state.draft.company,
                discount: state.draft.discount,
                receipt: state.draft.receipt,
              }),
            state.draft.payments,
          );

          dispatch.setPayments(computedPayments);
        });
      },
      removePayment: dispatch.removePayment,
      resetMembershipByType: dispatch.resetMembershipByType,
      setCompany: dispatch.setCompany,
      removeCompany: dispatch.removeCompany,
      setPageId: dispatch.setPageId,
      setCustomerFiscalCode: dispatch.setCustomerFiscalCode,
      saveDraft: () =>
        new Promise<void>((resolve, reject) => {
          dispatch.run(async (state) => {
            try {
              if (state.draft.pageId && state.draft.items.length > 0) {
                const draftId = await saveDraft(state.draft.pageId, state.draft, state.draftId);
                if (!draftId) {
                  return resolve();
                }
                dispatch.setDraftId(draftId);
                resolve();
              }
            } catch (error) {
              log.error('failed to save draft', error);
              reject(error);
            }
          });
        }),
      loadTransaction: (draftId: CheckoutTransactionId, pageId: PageId) => {
        dispatch.run(async (state) => {
          if (state.draftId !== draftId) {
            try {
              const transaction = await meroApi.checkout.getTransaction({
                pageId: pageId,
                transactionId: draftId,
              });

              let receipt: Receipt = {
                emit: false,
              };
              if (transaction.company.type === 'Company') {
                const cashRegister = await meroApi.checkout.listCashRegisters({
                  pageId: pageId,
                  companyId: transaction.company.company._id,
                });
                if (cashRegister.length > 0) {
                  receipt = {
                    emit: true,
                    cashRegister: {
                      _id: cashRegister[0]._id,
                    },
                    customerFiscalCode: undefined,
                  } as const;
                }
              }

              dispatch.update({
                draftId,
                draft: {
                  items: transaction.items.map((item) => {
                    if (item.type === 'Booking') {
                      return withUiKey({
                        ...item,
                        items: item.items.map((i) => withUiKey(i)),
                      });
                    }
                    return withUiKey(item);
                  }) as NonEmptyArray<WithUiKey<Item>>,
                  payments: [],
                  company: transaction.company.type === 'Company' ? transaction.company.company : undefined,
                  client: transaction.client
                    ? {
                        type: 'existing',
                        client: transaction.client,
                      }
                    : {
                        type: 'none',
                      },
                  discount: transaction.discount,
                  emitReceipt: transaction.company.type === 'Company' ? receipt.emit : false,
                  receipt,
                },
              });
            } catch (error) {}
          }
        });
      },
      initiateAutoSave: () => {
        dispatch.run((state) => {
          if (state.autoSaveInterval === 0) {
            const autoSaveInterval = window.setInterval(() => {
              log.debug(`running autosave ${autoSaveInterval}`);
              dispatch.run(async (state) => {
                if (state.draft.pageId && state.draft.items.length > 0 && state.hasChanges) {
                  try {
                    const draftId = await saveDraft(state.draft.pageId, state.draft, state.draftId);
                    dispatch.update({
                      draftId,
                      hasChanges: false,
                    });
                  } catch (error) {
                    log.error(`failed to autosave draft ${autoSaveInterval}`, error);
                  }
                }
              });
            }, 5000);
            log.debug(`initiated autosave with id ${autoSaveInterval}`);

            dispatch.update({
              autoSaveInterval,
            });
          }
        });
      },
      cancelAutoSave: (interval: number) => {
        log.debug(`cancel autosave with id ${interval}`);
        window.clearInterval(interval);
        dispatch.cancelAutoSave();
      },
    };
  },
);

const ContextInit: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  const [formState, { init, reset, setPageId }] = CheckoutFormContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      init(pageState.page.details._id);
    }
  }, [pageState.type]);

  React.useEffect(() => {
    if (pageState.type === 'Loaded' && pageState.page.details._id !== formState.draft.pageId) {
      reset();
      setPageId(pageState.page.details._id);
    }
  }, [pageState, formState.draft.pageId]);

  return <>{children}</>;
};

export const withCheckoutFormContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithCheckoutFormContextProvider(props: P) {
    return (
      <CheckoutFormContext.Provider>
        <ContextInit>
          <Content {...props} />
        </ContextInit>
      </CheckoutFormContext.Provider>
    );
  };
};
