import { JSONable } from './jsonable';
import * as t from 'io-ts';

export type ErrorObject = {
  // readonly name: string;
  readonly message: string;
  readonly stack?: string;
};

const JSON: t.Type<ErrorObject, JSONable> = t.type(
  {
    // name: t.string,
    message: t.string,
  },
  'ErrorObject',
);

const JSON_WITH_STACK: t.Type<ErrorObject, JSONable> = t.intersection(
  [
    JSON,
    t.partial(
      {
        stack: t.string,
      },
      '?',
    ),
  ],
  'ErrorObject',
);

export const ErrorObject = {
  JSON,
  JSON_WITH_STACK,
};
