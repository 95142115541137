import { DateString } from './dateString';
import { JSONable } from './jsonable';
import * as t from 'io-ts';

export type DateStringInterval = {
  readonly from: DateString;
  readonly to: DateString;
};

const JSON: t.Type<DateStringInterval, JSONable> = t.type(
  {
    from: DateString.JSON,
    to: DateString.JSON,
  },
  'DateStringInterval',
);

const isValid = (interval: DateStringInterval): boolean => {
  return interval.from <= interval.to;
};

export const DateStringInterval = {
  JSON,
  isValid,
};
