import { PhoneNumber } from '@mero/api-sdk';
import { Body, ConfirmBox, H1, Spacer, styles as meroStyles } from '@mero/components';
import * as React from 'react';

import ModalOverlay from '@mero/components/lib/components/ModalOverlay';

import { useMediaQueries } from '../../../../hooks/useMediaQueries';

export type Props = {
  readonly phone: PhoneNumber;
  readonly onDismiss: () => void;
  readonly onConfirm: () => void;
};

/**
 * Client blocking confirmation modal
 */
const ClientConfirmBlockModal: React.FC<Props> = ({
  phone,
  onDismiss,
  onConfirm,
}: Props): React.ReactElement | null => {
  const { isDesktop } = useMediaQueries();
  const leftAction = {
    text: 'Anulează',
    onPress: onDismiss,
  };

  const rightAction = {
    text: 'Blochează client',
    onPress: onConfirm,
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <ConfirmBox
        style={{ maxWidth: isDesktop ? 468 : undefined }}
        type="error"
        icon="info"
        headerTitle="Actiune importantă"
        canClose={true}
        onClose={onDismiss}
        leftAction={leftAction}
        rightAction={rightAction}
      >
        <H1>Confirmă blocare client</H1>
        <Spacer size="8" />
        <Body>
          Clientul asociat cu numărul de telefon <Body style={meroStyles.text.semibold}>{phone}</Body> nu va mai putea
          face programări la niciun profesionist din profilul afacerii tale.
        </Body>
      </ConfirmBox>
    </ModalOverlay>
  );
};

export default ClientConfirmBlockModal;
