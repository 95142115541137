import { Platform, StyleSheet } from 'react-native';

import { sizes } from '../../../../../styles';

const styles = StyleSheet.create({
  container: { flex: 1, overflow: 'hidden' },
  contentContainer: {
    ...Platform.select({
      web: {
        paddingLeft: sizes[16],
        paddingRight: sizes[24],
        gap: sizes[16],
        paddingTop: sizes[24],
        paddingBottom: sizes[24],
      },
      ios: { paddingHorizontal: sizes[16], gap: sizes[16] },
    }),
  },
  contentTitle: {
    fontFamily: 'merriweather-bold',
    fontSize: 20,
  },
});

export default styles;
