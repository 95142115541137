import { BusinessHours, WorkInterval } from '../../business';
import { WorkingScheduleDay } from './workingScheduleDay';
import { DayTime, JSONable, unsafeDecode, IsoWeekDay } from '@mero/shared-sdk';
import * as t from 'io-ts';

type WeekDay = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

const ISO_WEEK_DAY_MAP: Record<IsoWeekDay, WeekDay> = {
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
  7: 'sunday',
};

const getIsoWeekDaySchedule = (isoWeekDay: IsoWeekDay, weekDaysSchedule: WeekDaysSchedule): WorkingScheduleDay => {
  return weekDaysSchedule[ISO_WEEK_DAY_MAP[isoWeekDay]];
};

const fromBusinessHours = (businessHours: BusinessHours): WeekDaysSchedule => {
  if (businessHours.length !== 7) {
    throw Error('Invalid business hours length');
  }

  const getDaySchedule = (dayBusinessHours: WorkInterval): WorkingScheduleDay => {
    return dayBusinessHours.active
      ? {
          active: true,
          intervals: [
            dayBusinessHours.wholeDay
              ? {
                  from: DayTime.of(0, 0),
                  to: DayTime.of(0, 0),
                }
              : {
                  from: DayTime.of(dayBusinessHours.from.hour, dayBusinessHours.from.minute),
                  to: DayTime.of(dayBusinessHours.to.hour, dayBusinessHours.to.minute),
                },
          ],
        }
      : {
          active: false,
        };
  };

  const schedule = {
    monday: getDaySchedule(businessHours[0]),
    tuesday: getDaySchedule(businessHours[1]),
    wednesday: getDaySchedule(businessHours[2]),
    thursday: getDaySchedule(businessHours[3]),
    friday: getDaySchedule(businessHours[4]),
    saturday: getDaySchedule(businessHours[5]),
    sunday: getDaySchedule(businessHours[6]),
  };

  return unsafeDecode(WeekDaysSchedule.JSON, schedule);
};

const equals = (a: WeekDaysSchedule, b: WeekDaysSchedule): boolean => {
  return (
    WorkingScheduleDay.equals(a.monday, b.monday) &&
    WorkingScheduleDay.equals(a.tuesday, b.tuesday) &&
    WorkingScheduleDay.equals(a.wednesday, b.wednesday) &&
    WorkingScheduleDay.equals(a.thursday, b.thursday) &&
    WorkingScheduleDay.equals(a.friday, b.friday) &&
    WorkingScheduleDay.equals(a.saturday, b.saturday) &&
    WorkingScheduleDay.equals(a.sunday, b.sunday)
  );
};

export type WeekDaysSchedule = {
  readonly monday: WorkingScheduleDay;
  readonly tuesday: WorkingScheduleDay;
  readonly wednesday: WorkingScheduleDay;
  readonly thursday: WorkingScheduleDay;
  readonly friday: WorkingScheduleDay;
  readonly saturday: WorkingScheduleDay;
  readonly sunday: WorkingScheduleDay;
};

const JSON: t.Type<WeekDaysSchedule, JSONable> = t.type(
  {
    monday: WorkingScheduleDay.JSON,
    tuesday: WorkingScheduleDay.JSON,
    wednesday: WorkingScheduleDay.JSON,
    thursday: WorkingScheduleDay.JSON,
    friday: WorkingScheduleDay.JSON,
    saturday: WorkingScheduleDay.JSON,
    sunday: WorkingScheduleDay.JSON,
  },
  'WeekDaysSchedule',
);

const INACTIVE: WeekDaysSchedule = {
  monday: WorkingScheduleDay.INACTIVE,
  tuesday: WorkingScheduleDay.INACTIVE,
  wednesday: WorkingScheduleDay.INACTIVE,
  thursday: WorkingScheduleDay.INACTIVE,
  friday: WorkingScheduleDay.INACTIVE,
  saturday: WorkingScheduleDay.INACTIVE,
  sunday: WorkingScheduleDay.INACTIVE,
};

export const WeekDaysSchedule = {
  JSON,
  INACTIVE,
  getIsoWeekDaySchedule,
  fromBusinessHours,
  equals,
};
