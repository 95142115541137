import {
  CheckoutTransactionPreview,
  ClientImage,
  NoteDetails,
  NoteId,
  Paged,
  PageFeedbackScoreCounts,
  PublicFeedbackDetailsWithAppointment,
  WorkerId,
} from '@mero/api-sdk';
import { UserAppointment } from '@mero/api-sdk/dist/calendar';
import { ClientHistoryRecord, ClientId, ClientProfile } from '@mero/api-sdk/dist/clients';
import { PageClientStats } from '@mero/api-sdk/dist/clients/page-client-stats';
import { PurchasedMembership } from '@mero/api-sdk/dist/memberships/purchasedMembership';
import { PageId } from '@mero/api-sdk/dist/pages/page-id';
import { ClientImageNotePreview } from '@mero/api-sdk/dist/pro/clientImages/clientImageNotePreview';
import { ProductSale } from '@mero/api-sdk/dist/pro/productSales/productSale';
import { createModelContext } from '@mero/components';
import { MeroUnits, Option } from '@mero/shared-sdk';
import { DateTime } from 'luxon';
import * as React from 'react';

import { StarFilter, StarsToScore } from '../../hooks/useReviews';

import log, { logCatch } from '../../utils/log';
import { meroApi } from '../AuthContext';

type PageAppointmentsWithTotals = Paged<UserAppointment[]> & {
  readonly futureBookingsCount: number;
  readonly pastBookingsCount: number;
};

export type FinishedTransaction = CheckoutTransactionPreview.AnyFinished | CheckoutTransactionPreview.AnyDeleted;

export type ClientDetailsContextState =
  | {
      readonly type: 'New';
    }
  | {
      readonly type: 'Loading';
      readonly clientId: ClientId;
      readonly prevClient?: ClientProfile & { readonly pinnedNote: Option<NoteDetails> };
    }
  | {
      readonly type: 'Loaded';
      readonly clientId: ClientId;
      readonly clientReviews: PublicFeedbackDetailsWithAppointment[];
      readonly client: ClientProfile & { readonly pinnedNote: Option<NoteDetails> };
      readonly history: ClientHistoryRecord[];
      readonly appointments: PageAppointmentsWithTotals;
      readonly memberships: Paged<PurchasedMembership<MeroUnits.Any>[]>;
      readonly productSales: Paged<ProductSale[]>;
      readonly notes: Paged<NoteDetails[]>;
      readonly clientImages: ClientImageNotePreview[];
      readonly reports: PageClientStats<'RON'>;
      readonly scoreCounts?: PageFeedbackScoreCounts;
      readonly clientTransactions: Paged<FinishedTransaction[]>;
    }
  | {
      readonly type: 'NotFound';
      readonly clientId: ClientId;
    }
  | {
      readonly type: 'Failed';
      readonly clientId: ClientId;
      readonly error: unknown;
    };

const defaultState = (): ClientDetailsContextState => ({
  type: 'New',
});

export const ClientDetailsContext = createModelContext(
  defaultState(),
  {
    trySetResult: (
      state,
      params: {
        clientImages: ClientImageNotePreview[];
        clientId: ClientId;
        client: ClientProfile & { readonly pinnedNote: Option<NoteDetails> };
        history: ClientHistoryRecord[];
        appointments: PageAppointmentsWithTotals;
        memberships: Paged<PurchasedMembership<MeroUnits.Any>[]>;
        productSales: Paged<ProductSale[]>;
        reports: PageClientStats<'RON'>;
        notes: Paged<NoteDetails[]>;
        clientTransactions: Paged<FinishedTransaction[]>;
        clientReviews: PublicFeedbackDetailsWithAppointment[];
      },
    ) => {
      if (state.type === 'Loading') {
        return {
          type: 'Loaded',
          clientId: params.clientId,
          client: params.client,
          history: params.history,
          appointments: params.appointments,
          memberships: params.memberships,
          productSales: params.productSales,
          reports: params.reports,
          notes: params.notes,
          clientImages: params.clientImages,
          clientReviews: params.clientReviews,
          clientTransactions: params.clientTransactions,
        };
      } else {
        // pass, result is for different query
        return state;
      }
    },
    update(
      state,
      params: Partial<{
        clientId: ClientId;
        client: ClientProfile & { readonly pinnedNote: Option<NoteDetails> };
        notes: Paged<NoteDetails[]>;
        clientImages: ClientImageNotePreview[];
        history: ClientHistoryRecord[];
        appointments: PageAppointmentsWithTotals;
        memberships: Paged<PurchasedMembership<MeroUnits.Any>[]>;
        productSales: Paged<ProductSale[]>;
        clientReviews: PublicFeedbackDetailsWithAppointment[];
        scoreCounts: PageFeedbackScoreCounts;
        clientTransactions: Paged<FinishedTransaction[]>;
      }>,
    ) {
      return {
        ...state,
        ...params,
      };
    },
    trySetNotFound: (state, clientId: ClientId) => {
      if (state.type === 'Loading' && clientId === state.clientId) {
        return {
          type: 'NotFound',
          clientId: clientId,
        };
      } else {
        // pass, result is for different query
        return state;
      }
    },
    setFailed: (state, params: { clientId: ClientId; error: unknown }) => {
      return {
        type: 'Failed',
        clientId: params.clientId,
        error: params.error,
      };
    },
    mutate: (s, fn: (s: ClientDetailsContextState) => ClientDetailsContextState): ClientDetailsContextState => fn(s),
    run: (s, f: (s: ClientDetailsContextState) => void) => {
      f(s);
      return s;
    },
  },
  (dispatch) => {
    const reload = async (clientId: ClientId, pageId: PageId) => {
      try {
        log.debug('Start (re)loading client details');

        const client = await meroApi.clients.getClientProfileById(clientId);
        if (client) {
          const [appointments, memberships, reports] = await Promise.all([
            meroApi.calendar
              .fetchPagedClientUserAppointments({ clientId, limit: 10 })
              .catch(logCatch('fetchPagedClientUserAppointments')),
            meroApi.memberships
              .getUserPurchasedMembershipsByPro({ pageId, userId: client.user._id, limit: 10 })
              .catch(logCatch('getUserPurchasedMembershipsByPro')),
            meroApi.clients
              .getPageClientStats({
                pageId,
                clientId,
                currency: 'RON',
              })
              .catch(logCatch('getPageClientStats')),
          ]);

          dispatch.trySetResult({
            clientId: clientId,
            client,
            history: [],
            notes: { data: [], next: undefined, prev: undefined },
            clientReviews: [],
            clientTransactions: { data: [], next: undefined, prev: undefined },
            clientImages: [],
            productSales: { data: [], next: undefined, prev: undefined },
            appointments: {
              ...appointments,
              futureBookingsCount: appointments.futureBookingsCount ?? 0,
              pastBookingsCount: appointments.pastBookingsCount ?? 0,
            },
            reports,
            memberships,
          });
        } else {
          dispatch.trySetNotFound(clientId);
        }
      } catch (error) {
        dispatch.setFailed({
          clientId: clientId,
          error: error,
        });

        log.exception(error);
      }
    };

    return {
      loadMoreAppointments: async (clientId: ClientId) =>
        new Promise<void>((resolve) => {
          dispatch.run(async (state) => {
            if (state.type === 'Loaded' && state.appointments.next) {
              try {
                const appointments = await meroApi.calendar.fetchPagedClientUserAppointments({
                  clientId,
                  limit: 10,
                  page: state.appointments.next,
                });

                dispatch.update({
                  appointments: {
                    ...appointments,
                    data: [...state.appointments.data, ...appointments.data],
                    futureBookingsCount: state.appointments.futureBookingsCount,
                    pastBookingsCount: state.appointments.pastBookingsCount,
                  },
                });
              } catch (error) {
                log.error('Failed to load more appointments', error);
              } finally {
                resolve();
              }
            }
          });
        }),
      reloadHistory: async () => {
        dispatch.run(async (state) => {
          if (state.type === 'Loaded') {
            try {
              const history = await meroApi.clients.getClientHistory(state.clientId);

              dispatch.update({ history });
            } catch (error) {
              log.error('Failed to load client history', error);
            }
          }
        });
      },
      loadClientReviews: async (starFilter: StarFilter, workerId?: WorkerId) => {
        dispatch.run(async (state) => {
          if (state.type === 'Loaded') {
            try {
              const reviews = await meroApi.pages.getPagePrivateFeedback({
                pageId: state.client.pageId,
                byUserId: state.client.user._id,
                limit: 10000,
                score: StarsToScore[starFilter],
                workerId,
              });
              dispatch.update({ clientReviews: reviews });
            } catch (error) {
              log.error('Failed to load client reviews', error);
            }
          }
        });
      },

      loadClientProducts: async (pageId: PageId, clientId: ClientId) => {
        dispatch.run(async (state) => {
          if (state.type === 'Loaded') {
            try {
              const productSales = await meroApi.pro.productSales.getProductSales({
                limit: 10,
                pageId,
                clientId,
              });
              dispatch.update({ productSales });
            } catch (error) {
              log.error('Failed to load client products', error);
            }
          }
        });
      },

      loadClientTransactions: async (pageId: PageId, clientId: ClientId) => {
        dispatch.run(async (state) => {
          if (state.type === 'Loaded') {
            try {
              const transactions = await meroApi.checkout.listClientSubmittedTransactions({
                pageId,
                clientId,
                limit: 20,
              });
              dispatch.update({ clientTransactions: transactions });
            } catch (error) {
              log.error('Failed to load client transactions', error);
            }
          }
        });
      },

      loadMoreClientTransactions: async (pageId: PageId, clientId: ClientId) => {
        dispatch.run(async (state) => {
          if (state.type === 'Loaded' && state.clientTransactions.next) {
            try {
              const transactions = await meroApi.checkout.listClientSubmittedTransactions({
                pageId,
                clientId,
                limit: 20,
                page: state.clientTransactions.next,
              });
              dispatch.update({
                clientTransactions: {
                  ...transactions,
                  data: [...state.clientTransactions.data, ...transactions.data],
                },
              });
            } catch (error) {
              log.error('Failed to load client transactions', error);
            }
          }
        });
      },
      removeClientNote: async ({
        noteId,
        pageId,
        deleteNoteImages,
      }: {
        noteId: NoteId;
        pageId: PageId;
        deleteNoteImages: boolean;
      }) => {
        dispatch.run(async (state) => {
          if (state.type === 'Loaded') {
            console.log('deleteNoteImages', {
              clientId: state.clientId,
              pageId,
              noteId,
              deleteNoteImages,
            });
            try {
              await meroApi.pro.notes.deleteClientNote({
                clientId: state.clientId,
                pageId,
                noteId,
                deleteNoteImages,
              });

              dispatch.update({
                notes: {
                  ...state.notes,
                  data: state.notes.data.filter((note) => note._id !== noteId),
                },
              });
            } catch (error) {
              log.error('Failed to remove client note', error);
            }
          }
        });
      },
      updatePinnedNote: async ({
        noteId,
        pageId,
        clientId,
        pinnedState,
      }: {
        noteId: NoteId;
        pageId: PageId;
        clientId: ClientId;
        pinnedState: boolean;
      }) => {
        dispatch.run(async (state) => {
          if (state.type === 'Loaded') {
            try {
              // If there's a currently pinned note and it's different from the one being updated
              if (state.client.pinnedNote && state.client.pinnedNote._id !== noteId) {
                const previousPinnedNoteId = state.client.pinnedNote._id;

                await meroApi.pro.notes.updateNoteIsPinned({
                  clientId,
                  pageId,
                  noteId: previousPinnedNoteId,
                  isPinned: false,
                });
              }

              // Update the new note's pinned state
              await meroApi.pro.notes.updateNoteIsPinned({
                clientId,
                pageId,
                noteId,
                isPinned: pinnedState,
              });

              const client = await meroApi.clients.getClientProfileById(clientId);

              // Update both the newly pinned/unpinned note and the previously pinned note
              dispatch.update({
                client,
                notes: {
                  ...state.notes,
                  data: state.notes.data.map((note) => {
                    if (note._id === noteId) {
                      return { ...note, isPinned: pinnedState };
                    }
                    if (state.client.pinnedNote && note._id === state.client.pinnedNote._id) {
                      return { ...note, isPinned: false };
                    }
                    return note;
                  }),
                },
              });
            } catch (error) {
              log.error('Failed to update note pinned state', error);
            }
          }
        });
      },
      loadClientNotes: async (pageId: PageId, clientId: ClientId) => {
        dispatch.run(async (state) => {
          if (state.type === 'Loaded') {
            try {
              const notes = await meroApi.pro.notes.getClientNotes({
                pageId,
                clientId,
                limit: 30,
                page: undefined,
              });
              dispatch.update({ notes });
            } catch (error) {
              log.error('Failed to load client notes', error);
            }
          }
        });
      },
      loadMoreClientNotes: async (pageId: PageId, clientId: ClientId) => {
        dispatch.run(async (state) => {
          if (state.type === 'Loaded' && state.notes.next) {
            console.log('loadMoreClientNotes', {
              clientId,
              pageId,
              next: state.notes.next,
            });
            try {
              const notes = await meroApi.pro.notes.getClientNotes({
                pageId,
                clientId,
                limit: 5,
                page: state.notes.next,
              });
              dispatch.update({
                notes: {
                  ...notes,
                  data: [...state.notes.data, ...notes.data],
                },
              });
            } catch (error) {
              log.error('Failed to load more client notes', error);
            }
          }
        });
      },
      loadMoreProductSales: async (pageId: PageId, clientId: ClientId) => {
        dispatch.run(async (state) => {
          if (state.type === 'Loaded' && state.productSales.next) {
            try {
              const productSales = await meroApi.pro.productSales.getProductSales({
                limit: 10,
                pageId,
                clientId,
                page: state.productSales.next,
              });

              dispatch.update({
                productSales: {
                  ...productSales,
                  data: [...state.productSales.data, ...productSales.data],
                },
              });
            } catch (error) {
              log.error('Failed to load client products', error);
            }
          }
        });
      },
      loadClientImages: async (pageId: PageId, clientId: ClientId) => {
        dispatch.run(async (state) => {
          if (state.type === 'Loaded') {
            try {
              const clientImages = await meroApi.pro.clientImages.getClientImages({
                pageId,
                clientId,
              });

              dispatch.update({
                clientImages,
              });
            } catch (error) {
              log.error('Failed to load client images', error);
            }
          }
        });
      },
      reloadMemberships: async (pageId: PageId) => {
        dispatch.run(async (state) => {
          if (state.type === 'Loaded') {
            try {
              const memberships = await meroApi.memberships.getUserPurchasedMembershipsByPro({
                pageId,
                userId: state.client.user._id,
                limit: 10,
              });

              dispatch.update({ memberships });
            } catch (error) {
              log.error('Failed to load memberships', error);
            }
          }
        });
      },
      reloadAppointments: async () => {
        dispatch.run(async (state) => {
          if (state.type === 'Loaded') {
            try {
              const appointments = await meroApi.calendar.fetchPagedClientUserAppointments({
                clientId: state.clientId,
                limit: 10,
              });
              dispatch.update({
                appointments: {
                  ...appointments,
                  futureBookingsCount: appointments.futureBookingsCount ?? 0,
                  pastBookingsCount: appointments.pastBookingsCount ?? 0,
                },
              });
            } catch (error) {
              log.error('Failed to load memberships', error);
            }
          }
        });
      },
      loadMoreMemberships: async (clientId: ClientId, pageId: PageId) => {
        dispatch.run(async (state) => {
          if (state.type === 'Loaded' && state.memberships.next) {
            try {
              const memberships = await meroApi.memberships.getUserPurchasedMembershipsByPro({
                pageId,
                userId: state.client.user._id,
                limit: 10,
                page: state.memberships.next,
              });

              dispatch.update({
                memberships: {
                  ...memberships,
                  data: [...state.memberships.data, ...memberships.data],
                },
              });
            } catch (error) {
              log.error('Failed to load memberships', error);
            }
          }
        });
      },
      reload: (clientId: ClientId, pageId: PageId): void => {
        dispatch.mutate((state) => {
          if (state.type === 'Loaded' || state.type === 'New' || state.type === 'Failed') {
            reload(clientId, pageId).catch(log.exception);

            return {
              type: 'Loading',
              clientId: clientId,
              prevClient: state.type === 'Loaded' && state.clientId === clientId ? state.client : undefined,
            };
          }

          return state;
        });
      },
      reloadClientProfile: (clientId: ClientId): void => {
        dispatch.run(async (state) => {
          if (state.type === 'Loaded') {
            try {
              const client = await meroApi.clients.getClientProfileById(clientId);
              dispatch.update({ client });
            } catch (error) {
              log.error('Failed to load memberships', error);
            }
          }
        });
      },
    };
  },
);

export type ClientDetailsContextProviderProps = React.PropsWithChildren<{
  readonly clientId: ClientId;
  readonly pageId: PageId;
}>;

const ContextInit: React.FC<ClientDetailsContextProviderProps> = ({ clientId, pageId, children }) => {
  const [, { reload }] = ClientDetailsContext.useContext();

  React.useEffect(() => {
    reload(clientId, pageId);
  }, [reload, clientId]);

  return <>{children}</>;
};

export const ClientDetailsContextProvider: React.FC<ClientDetailsContextProviderProps> = ({ children, ...props }) => {
  return (
    <ClientDetailsContext.Provider>
      <ContextInit {...props}>{children}</ContextInit>
    </ClientDetailsContext.Provider>
  );
};
