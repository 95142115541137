import { H2s, Row, Title } from '@mero/components';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, View } from 'react-native';

import { styles } from '../../screens/Authorized/ClientDetailsScreen/components/styles';

import Line from '@mero/components/lib/components/Line';
import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import SimpleListItem from '@mero/components/lib/components/SimpleListItem';

import SmsIcon from './SmsIcon';
import WhatsappIcon from './WhatsappIcon';

interface Props {
  onDismiss: () => void;
  onSmsPress: () => void;
  onWhatsappPress: () => void;
  type: 'EDIT' | 'CREATE' | 'CANCEL' | null;
}

const ClientMessagingOptionsModal: React.FC<Props> = ({ onDismiss, onSmsPress, onWhatsappPress, type }) => {
  const { t } = useTranslation('booking');

  const title = useCallback(() => {
    switch (type) {
      case 'CREATE':
        return t('confirmBookingModalTitle');
      case 'EDIT':
        return t('editBookingModalTitle');
      case 'CANCEL':
        return t('cancelBookingModalTitle');
      default:
        return '';
    }
  }, [type]);

  return (
    <ModalOverlay style={styles.overlay}>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <View style={styles.optionsListContainer}>
        <SafeAreaView edges={['bottom']}>
          <SimpleListItem style={styles.optionsMenuItem}>
            <H2s>{title()}</H2s>
          </SimpleListItem>
          <Pressable style={styles.optionsMenuItem} onPress={onSmsPress}>
            <SimpleListItem>
              <Row style={styles.optionRow}>
                <SmsIcon />
                <Title style={styles.smsTitle}>{t('sendSmsMessage')}</Title>
              </Row>
            </SimpleListItem>
          </Pressable>
          <Line />
          <Pressable style={styles.optionsMenuItem} onPress={onWhatsappPress}>
            <SimpleListItem>
              <Row style={styles.optionRow}>
                <WhatsappIcon />
                <Title style={styles.whatsappTitle}>{t('sendWhatsappMessage')}</Title>
              </Row>
            </SimpleListItem>
          </Pressable>
          <Line />
          <Pressable style={styles.optionsMenuItem} onPress={onDismiss}>
            <SimpleListItem>
              <Title style={styles.cancelTitle}>{t('dontSendMessage')}</Title>
            </SimpleListItem>
          </Pressable>
        </SafeAreaView>
      </View>
    </ModalOverlay>
  );
};

export default ClientMessagingOptionsModal;
