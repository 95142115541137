import { StyleSheet } from 'react-native';

import { colors, sizes } from '../../../styles';

const styles = StyleSheet.create({
  root: { backgroundColor: colors.ALABASTER },
  container: { flex: 1, overflow: 'hidden' },
  contentContainer: { paddingLeft: sizes[16], paddingRight: sizes[24], paddingTop: sizes[24] },
  header: {
    gap: sizes[16],
    marginBottom: sizes[24],
  },
  subtitle: {
    width: '95%',
  },
  clientName: {
    fontFamily: 'open-sans-bold',
  },
});

export default styles;
