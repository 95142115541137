import { normalize } from '@mero/components';

export const tokenize = (s: string): string[] => {
  const ss = normalize(s);
  if (ss !== '') {
    return ss.split(/\s+/);
  } else {
    return [];
  }
};

export const match = (tokens: string[], text: string): boolean => !tokens.some((token) => !text.includes(token));
