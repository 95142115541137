import { MarketplaceSearchApi } from './marketplaceSearchApi';
import { MarketplaceSearchQuery } from './marketplaceSearchQuery';
import { MarketplaceSearchResult } from './marketplaceSearchResult';
import { HttpClient, UnknownApiError, unsafeRight } from '@mero/shared-sdk';

export const marketplaceSearchHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): MarketplaceSearchApi => {
  const { apiBaseUrl, http } = env;

  const findDecoder = http.decode.response(UnknownApiError, MarketplaceSearchResult.JSON);

  return {
    find: async (params) => {
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/mp/search`,
            data: MarketplaceSearchQuery.JSON.encode(params),
          },
          findDecoder,
        ),
      );
    },
  };
};
