import { Body, ConfirmBox, H1, Spacer } from '@mero/components';
import * as React from 'react';

import ModalOverlay from '@mero/components/lib/components/ModalOverlay';

import { useMediaQueries } from '@/hooks/useMediaQueries';

export type DeleteImageConfirmModalProps = {
  readonly onDismiss: () => void;
  readonly onConfirm: () => void;
};

const DeleteImageConfirmModal: React.FC<DeleteImageConfirmModalProps> = ({
  onDismiss,
  onConfirm,
}: DeleteImageConfirmModalProps): React.ReactElement | null => {
  const { isDesktop } = useMediaQueries();

  const leftAction = {
    text: 'Anulează',
    onPress: onDismiss,
  };

  const rightAction = {
    text: 'Confirmă ștergere',
    onPress: onConfirm,
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <ConfirmBox
        style={{ maxWidth: isDesktop ? 468 : undefined }}
        type="error"
        icon="info"
        headerTitle="Actiune ireversibilă"
        canClose={true}
        onClose={onDismiss}
        leftAction={leftAction}
        rightAction={rightAction}
      >
        <H1>Confirmă ștergerea imaginii</H1>
        <Spacer size="8" />
        <Body>Imaginea va fi ștearsă definitiv din galeria clientului, notițe cât și din programare, după caz.</Body>
      </ConfirmBox>
    </ModalOverlay>
  );
};

export default DeleteImageConfirmModal;
