import { ClientId } from '../../clients/client-id';
import { PageId } from '../../pages/page-id';
import { ClientImageId } from './clientImageId';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type ClientImage = {
  readonly _id: ClientImageId;
  readonly pageId: PageId;
  readonly clientId: ClientId;
  readonly thumbnail: string;
  readonly small: string;
  readonly medium: string;
  readonly large: string;
};

const JSON: t.Type<ClientImage, JSONable> = t.type(
  {
    _id: ClientImageId.JSON,
    pageId: PageId,
    clientId: ClientId,
    thumbnail: t.string,
    small: t.string,
    medium: t.string,
    large: t.string,
  },
  'ClientImage',
);

export const ClientImage = {
  JSON,
};
