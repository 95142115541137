import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
    alignItems: 'center',
    height: 20,
    gap: 2,
    paddingLeft: 2,
    paddingRight: 6,
    borderRadius: 4,
  },
  text: {
    fontSize: 10,
    fontFamily: 'open-sans-semibold',
  },
});

export default styles;
