import { OccurrenceIndex, optionull } from '@mero/api-sdk';
import { AppointmentId, CalendarId, CalendarEntry } from '@mero/api-sdk/dist/calendar';
import { ClientFeedbackId } from '@mero/api-sdk/dist/pro/clientFeedback/clientFeedbackId';
import { Body, ConfirmBox, H1, ModalOverlay, Spacer, useShowError, useToast } from '@mero/components';
import * as Ap from 'fp-ts/lib/Apply';
import * as E from 'fp-ts/lib/Either';
import { identity, pipe } from 'fp-ts/lib/function';
import { DateFromISOString, NumberFromString } from 'io-ts-types';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Dimensions, Linking, Platform, View } from 'react-native';

import BookingDetailsScreenView from '../../../components/BookingDetailsScreen';
import ClientMessagingOptionsModal from '../../../components/ClientMessagingOptionsModal';

import { CompositeNavigationProp, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useEscPressWeb } from '../../../hooks/useEscPressWeb';
import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { AppEventsContext } from '../../../contexts/AppEvents';
import { AuthContext, meroApi } from '../../../contexts/AuthContext';
import { BookingDeleteContext, withBookingDeleteContextProvider } from '../../../contexts/BookingDeleteContext';
import { BookingFormContext, getBookingEnd } from '../../../contexts/BookingFormContext';
import { CalendarContext } from '../../../contexts/CalendarContext';
import {
  CalendarEntryContext,
  HasCalendarEntryState,
  withCalendarEntryContext,
} from '../../../contexts/CalendarEntryContext';
import { withClientFeedbackContext } from '../../../contexts/ClientFeedbackContext';
import { CurrentBusiness, CurrentBusinessContext, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import {
  UpdateBookingStatusContext,
  withUpdateBookingStatusContextProvider,
} from '../../../contexts/UpdateBookingStatusContext';
import { AuthorizedStackParamList, BookingStackParamList, RootStackParamList } from '../../../types';
import log from '../../../utils/log';
import { getAppointmentClient, getAppointmentNotes } from '../BookingEditScreen';
import ConfirmDeleteBooking from './ConfirmDeleteBooking';
import ConfirmDeleteRecurrentBooking from './ConfirmDeleteRecurrentBooking';

const MARK_NO_SHOW_LIMIT = 2 * 24 * 60 * 60 * 1000; // 2 days

type BookingDetailsScreenNavigationProp = CompositeNavigationProp<
  StackNavigationProp<BookingStackParamList, 'BookingDetailsScreen'>,
  CompositeNavigationProp<
    StackNavigationProp<AuthorizedStackParamList, 'Booking'>,
    StackNavigationProp<RootStackParamList, 'Authorized'>
  >
>;

type Props = {
  navigation: BookingDetailsScreenNavigationProp;
  route: RouteProp<BookingStackParamList, 'BookingDetailsScreen'>;
} & CurrentBusinessProps;

type ComponentProps = Props & HasCalendarEntryState;

const BookingDetailsComponent = withClientFeedbackContext(
  withCalendarEntryContext(({ calendarEntryState: state, route, navigation }: ComponentProps): React.ReactElement => {
    type Params = {
      start?: Date;
    };
    const params: Params = pipe(
      Ap.sequenceS(E.either)({
        calendarId: CalendarId.decode(route.params.calendarId),
        calendarEntryId: AppointmentId.decode(route.params.calendarEntryId),
        start: optionull(DateFromISOString).decode(route.params.start),
      }),
      E.fold(() => ({}), identity),
    );

    const [{ messagingOptionsDetails }, { reload: reloadCalendar, showMessagingModal, hideMessagingModal }] =
      CalendarContext.useContext();
    const [, { pushEvent, subscribe: subscribeAppEvents }] = AppEventsContext.useContext();
    const [pageState] = CurrentBusinessContext.useContext();
    const { isDesktop } = useMediaQueries();

    const goBack = useGoBack();
    useEscPressWeb({
      onPress: goBack,
    });

    const showError = useShowError();
    const toast = useToast();

    const [, { reload: reloadCalendarEntry }] = CalendarEntryContext.useContext();
    const [authState] = AuthContext.useContext();

    React.useEffect(() => {
      if (state.type == 'Loaded' && state.entry.type !== CalendarEntry.Type.Appointment.VALUE) {
        // Go back if not an appointment
        toast.show({
          type: 'error',
          text: 'Evenimentul incărcat nu este o programare',
        });
        goBack();
      } else if (state.type === 'NotFound') {
        toast.show({
          type: 'error',
          text: 'Programarea nu a fost gasită',
        });
      } else if (state.type === 'Failed') {
        if (state.error) {
          showError(state.error);
        } else {
          toast.show({
            type: 'error',
            text: 'S-a produs o eroare la incărcarea programarii. Vă rugăm sa mai incercați odata in câteva clipe.',
          });
        }
      }
    }, [state]);

    const viewClientCallback = React.useCallback(() => {
      if (state.type === 'Loaded' && state.entry.type === CalendarEntry.Type.Appointment.VALUE) {
        const appointment = state.entry;
        if (appointment.payload.client?._id) {
          navigation.navigate('ClientDetails', {
            screen: 'DetailsScreen',
            params: {
              pageId: appointment.payload.page._id,
              clientId: appointment.payload.client._id,
            },
          });
        } else {
          log.warn(`Cannot navigate client details - no client for appointment ${appointment._id}`);
        }
      }
    }, [navigation, state]);

    const editAppointmentCallback = React.useCallback(() => {
      if (state.type == 'Loaded' && state.entry.type === CalendarEntry.Type.Appointment.VALUE) {
        navigation.navigate('Booking', {
          screen: 'BookingEditScreen',
          params: {
            calendarId: state.entry.calendarId,
            calendarEntryId: state.entry._id,
            occurrenceIndex: `${state.entry.occurrenceIndex}`,
          },
        });
      }
    }, [navigation, state]);

    const navigateClientFeedbackCallback = React.useCallback(
      (params?: { selectedScore?: number; clientFeedbackId?: ClientFeedbackId }) => {
        if (state.type == 'Loaded' && state.entry.type === CalendarEntry.Type.Appointment.VALUE) {
          navigation.navigate('Booking', {
            screen: 'ClientFeedbackScreen',
            params: {
              calendarId: state.entry.calendarId,
              calendarEntryId: state.entry._id,
              occurrenceIndex: `${state.entry.occurrenceIndex}`,
              selectedScore: params?.selectedScore,
              clientFeedbackId: params?.clientFeedbackId,
            },
          });
        }
      },
      [navigation, state],
    );

    const [, { reset }] = BookingFormContext.useContext();

    const onBookAgain = React.useCallback(() => {
      if (state.type === 'Loaded' && state.entry.type === CalendarEntry.Type.Appointment.VALUE) {
        const appointment = state.entry;
        // TODO: use calendar zone
        const newStart = DateTime.now().setZone('Europe/Bucharest').startOf('hour').plus({ hours: 1 }).toJSDate();

        reset({
          start: newStart,
          end: getBookingEnd({
            newStart: newStart,
            services: appointment.payload.bookedServices.map((service) => ({ service })),
          }),
          client: getAppointmentClient(appointment),
          recurrenceRule: appointment.recurrent ? appointment.recurrenceRule : undefined,
          notes: getAppointmentNotes(appointment),
          services: appointment.payload.bookedServices,
        });

        navigation.navigate('Booking', {
          screen: 'BookingCreateScreen',
          params: {
            workerId: appointment.payload.worker._id,
            date: newStart.toISOString(),
          },
        });
      }
    }, [navigation, state]);

    const [updateStatusState, { updateBookingStatus, reset: resetBookingStatusState }] =
      UpdateBookingStatusContext.useContext();

    const canMarkAsNoShow =
      state.type === 'Loaded' &&
      state.entry.type === CalendarEntry.Type.Appointment.VALUE &&
      state.entry.payload.status === 'accepted' &&
      state.entry.start.getTime() < new Date().getTime() &&
      state.entry.start.getTime() >= new Date().getTime() - MARK_NO_SHOW_LIMIT;

    const onMarkAsNoShow = React.useCallback(() => {
      if (
        state.type === 'Loaded' &&
        state.entry.type === CalendarEntry.Type.Appointment.VALUE &&
        state.entry.payload.status === 'accepted' &&
        state.entry.start.getTime() < new Date().getTime() &&
        updateStatusState.type !== 'Updating'
      ) {
        updateBookingStatus({
          calendarId: state.entry.calendarId,
          appointmentId: state.entry._id,
          status: 'noShow',
          occurrenceIndex: state.entry.occurrenceIndex,
        });
      }
    }, [state, updateStatusState, updateBookingStatus]);

    React.useEffect(() => {
      if (updateStatusState.type === 'Updated') {
        // reload booking details
        resetBookingStatusState();
        reloadCalendar();
        reloadCalendarEntry();
        pushEvent({
          type: 'AppointmentUpdated',
          calendarId: updateStatusState.calendarId,
          appointmentId: updateStatusState.appointmentId,
        });
      } else if (updateStatusState.type === 'Failed') {
        resetBookingStatusState();
        if (updateStatusState.error) {
          showError(updateStatusState.error);
        }
      }
    }, [updateStatusState, reloadCalendarEntry, resetBookingStatusState]);

    const acceptAppointmentCallback = React.useCallback(async () => {
      if (
        state.type === 'Loaded' &&
        state.entry.type === CalendarEntry.Type.Appointment.VALUE &&
        state.entry.payload.status === 'pending' &&
        updateStatusState.type !== 'Updating'
      ) {
        try {
          await updateBookingStatus({
            calendarId: state.entry.calendarId,
            appointmentId: state.entry._id,
            status: 'accepted',
            occurrenceIndex: state.entry.occurrenceIndex,
          }).then(() => {
            log.debug(`Booking ${state.entry._id} was marked as accepted, issue AppointmentRequestAccepted event`);
            pushEvent({
              type: 'AppointmentRequestAccepted',
            });
          });

          if (appointment?.type === CalendarEntry.Type.Appointment.VALUE) {
            const client = getAppointmentClient(appointment);
            if (client?.phone && !client.hideBoostDetails) {
              sendNotification(appointment._id, client.phone, 'Confirmed');
            }
          }
        } catch (error) {
          log.error('Failed to accept appointment', error);
        }
      }
    }, [state, updateStatusState, updateBookingStatus, pushEvent]);

    /**
     * Delete/Reject booking
     */
    const [deleteCalendarEntryState, { deleteCalendarEntry, reset: resetDeleteCalendarEntryState }] =
      BookingDeleteContext.useContext();

    const [showConfirmReject, setShowConfirmReject] = React.useState(false);

    // React to booking delete action
    React.useEffect(() => {
      if (deleteCalendarEntryState.type === 'Deleted') {
        resetDeleteCalendarEntryState();
        reloadCalendar();
        pushEvent({
          type: 'AppointmentDeleted',
          calendarId: deleteCalendarEntryState.calendarId,
          appointmentId: deleteCalendarEntryState.calendarEntryId,
        });
        toast.show({
          type: 'success',
          text: 'Programarea a fost ștearsă',
        });
        // Will dismiss by app event handler
      } else if (deleteCalendarEntryState.type === 'Failed') {
        resetDeleteCalendarEntryState();
        setShowConfirmReject(false);
        if (deleteCalendarEntryState.error) {
          showError(deleteCalendarEntryState.error);
        } else {
          toast.show({
            type: 'error',
            text: 'Incercarea de a șterge programarea a eșuat',
          });
        }
      }
    }, [deleteCalendarEntryState, resetDeleteCalendarEntryState, setShowConfirmReject, goBack, toast, goBack]);

    React.useEffect(
      () =>
        subscribeAppEvents((event) => {
          switch (event.type) {
            case 'AppointmentDeleted': {
              if (
                state.type === 'Loaded' &&
                state.entry.calendarId === event.calendarId &&
                state.entry._id === event.appointmentId
              ) {
                goBack();
              }
              return;
            }
          }
        }),
      [subscribeAppEvents, state],
    );

    const cancelConfirmRejectCallback = React.useCallback(() => {
      setShowConfirmReject(false);
    }, [setShowConfirmReject]);

    const rejectAppointmentCallback = React.useCallback(() => {
      if (
        state.type === 'Loaded' &&
        state.entry.type === CalendarEntry.Type.Appointment.VALUE &&
        state.entry.payload.status === 'pending' &&
        updateStatusState.type !== 'Updating'
      ) {
        setShowConfirmReject(true);
      }
    }, [state, updateStatusState]);

    const deleteInProgress =
      deleteCalendarEntryState.type === 'Deleting' || deleteCalendarEntryState.type === 'Deleted';

    const confirmRejectLeftAction = {
      text: 'Anulează',
      onPress: deleteInProgress ? undefined : cancelConfirmRejectCallback,
      flex: 10,
    };

    const confirmRejectRightAction = {
      text: 'Refuză programare',
      onPress: deleteInProgress
        ? undefined
        : () => {
            if (state.type === 'Loaded' && state.entry.type === CalendarEntry.Type.Appointment.VALUE) {
              deleteCalendarEntry({
                calendarId: state.entry.calendarId,
                calendarEntryId: state.entry._id,
                occurrenceIndex: state.entry.occurrenceIndex,
                onlyOnce: true, // Confirm delete modal should not be show for recurrent bookings
                reason: '',
              }).then(() => {
                log.debug(`Booking ${state.entry._id} was deleted, issue AppointmentRequestRejected event`);
                pushEvent({
                  type: 'AppointmentRequestRejected',
                });
                if (state.entry.type === CalendarEntry.Type.Appointment.VALUE) {
                  const client = getAppointmentClient(state.entry);
                  if (client?.phone && !client.hideBoostDetails) {
                    sendNotification(state.entry._id, client.phone, 'Deleted');
                  }
                }
              });
            }
          },
      flex: 15,
    };

    const now = new Date();

    const canApproveAppointment =
      state.type === 'Loaded' &&
      state.entry.type === CalendarEntry.Type.Appointment.VALUE &&
      state.entry.payload.status === 'pending' &&
      state.entry.start > now;
    const canRejectAppointment = canApproveAppointment;
    const canEditAppointment =
      state.type === 'Loaded' &&
      state.entry.type === CalendarEntry.Type.Appointment.VALUE &&
      state.entry.editableStatus.type === 'Editable' &&
      state.entry.editableStatus.validUntil > now;
    const canBookAgain = pageState.type === 'Loaded' && pageState.page.permissions.bookings.canWriteOwnBookings();

    /**
     * Delete booking
     */
    const [showRecurrentDeleteOptions, setShowRecurrentDeleteOptions] = React.useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);
    const shouldConfirmRecurrentDelete =
      state.type === 'Loaded' && state.entry.type === CalendarEntry.Type.Appointment.VALUE && state.entry.recurrent;
    const appointment = state.type === 'Loaded' ? state.entry : undefined;
    const canDeleteAppointment =
      state.type === 'Loaded' &&
      state.entry.type === CalendarEntry.Type.Appointment.VALUE &&
      state.entry.payload.status === 'accepted' &&
      state.entry.start > now;

    const deleteBookingCallback = () => {
      if (state.type === 'Loaded' && state.entry.type === CalendarEntry.Type.Appointment.VALUE) {
        if (shouldConfirmRecurrentDelete) {
          return setShowRecurrentDeleteOptions(true);
        }

        const client = getAppointmentClient(state.entry);

        if (client?.phone) {
          return setShowConfirmDelete(true);
        }

        return deleteBooking(state.entry, true, '');
      }
    };

    const cancelConfirmDeleteCallback = () => {
      setShowConfirmDelete(false);
    };

    const confirmDeleteLeftAction = {
      text: 'Anulează',
      onPress: deleteInProgress ? undefined : cancelConfirmDeleteCallback,
      flex: 10,
    };

    const sendNotification = React.useCallback(
      async (appointmentId: AppointmentId, phoneNumber: string, type: 'Confirmed' | 'Deleted') => {
        try {
          if (pageState.type !== 'Loaded' || isDesktop) {
            return;
          }
          const notificationSettings = await meroApi.pages.getPageNotificationSettings({
            pageId: pageState.page.details._id,
          });

          if (!notificationSettings.appointmentMadeByPage && type === 'Confirmed') {
            return;
          }

          if (!notificationSettings.appointmentCancelled && type === 'Deleted') {
            return;
          }

          const message = await (type === 'Deleted'
            ? meroApi.notifications.getRenderedTextForAppointmentDeleted({
                appointmentId,
                occurrenceIndex: state.type === 'Loaded' ? (state.entry.occurrenceIndex as OccurrenceIndex) : undefined,
              })
            : meroApi.notifications.getRenderedTextForAppointmentCreated({
                appointmentId,
              }));

          if (type === 'Deleted') {
            handleDeleteBooking(message.text, phoneNumber);
          } else {
            const separator = Platform.OS === 'ios' ? '&' : '?';
            const url = `sms:${phoneNumber}${separator}body=${encodeURIComponent(message.text)}`;

            const supported = await Linking.canOpenURL(url);

            if (supported) {
              await Linking.openURL(url);
            }
          }
        } catch (error) {
          log.error('Failed to get page notification settings', error);
        }
      },
      [],
    );

    const handleDeleteBooking = async (message: string, clientPhoneNumber: string) => {
      try {
        showMessagingModal(message, clientPhoneNumber, 'CANCEL');
      } catch (error) {
        console.error('Cannot set messaging modal data:', error);
      }
    };

    const sendSmsNotification = async () => {
      const separator = Platform.OS === 'ios' ? '&' : '?';

      const smsUrl = `sms:${messagingOptionsDetails?.phoneNumber}${separator}body=${encodeURIComponent(
        messagingOptionsDetails.message || '',
      )}`;

      const supported = await Linking.canOpenURL(smsUrl);

      if (supported) {
        await Linking.openURL(smsUrl).finally(() => hideMessagingModal());
      } else {
        log.error('Failed to send sms notification');
      }
    };

    const sendWhatsappNotification = async () => {
      const { phoneNumber, message } = messagingOptionsDetails;
      const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message || '')}`;
      const supported = await Linking.canOpenURL(whatsappUrl);

      if (supported) {
        await Linking.openURL(whatsappUrl).finally(() => hideMessagingModal());
      } else {
        log.error('Failed to send whatsapp notification');
      }
    };

    const handleMessagingModalDismiss = () => {
      hideMessagingModal();
    };

    const deleteBooking = React.useCallback(
      async (appointment: CalendarEntry.Any, onlyOnce: boolean, reason: string) => {
        try {
          await deleteCalendarEntry({
            calendarId: appointment.calendarId,
            calendarEntryId: appointment._id,
            occurrenceIndex: appointment.occurrenceIndex,
            onlyOnce, // Confirm delete modal should not be show for recurrent bookings
            reason,
          });

          if (appointment.type === CalendarEntry.Type.Appointment.VALUE) {
            const client = getAppointmentClient(appointment);
            if (client?.phone && !client.hideBoostDetails) {
              sendNotification(appointment._id, client.phone, 'Deleted');
            }
          }
        } catch (error) {}
      },
      [],
    );

    const confirmDeleteRightAction = {
      text: 'Șterge programare',
      onPress: deleteInProgress
        ? undefined
        : (reason: string) => {
            if (appointment !== undefined) {
              deleteBooking(appointment, true, reason);
            }
          },
      flex: 15,
    };

    const confirmDeleteBookingOnce = (reason: string) => {
      if (appointment !== undefined) {
        deleteBooking(appointment, true, reason);
      }
    };

    const confirmDeleteBookingAll = (reason: string) => {
      if (appointment !== undefined) {
        deleteBooking(appointment, false, reason);
      }
    };

    const ConfirmRejectModal = () => (
      <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
        <ConfirmBox
          type="error"
          icon="info"
          headerTitle="Actiune ireversibilă"
          canClose={!deleteInProgress}
          onClose={cancelConfirmRejectCallback}
          leftAction={confirmRejectLeftAction}
          rightAction={confirmRejectRightAction}
        >
          <H1>Refuză programare</H1>
          <Spacer size="8" />
          <Body>Clientul va fi notificat că programarea a fost refuzată.</Body>
        </ConfirmBox>
      </ModalOverlay>
    );

    if (state.type === 'Loaded') {
      if (state.entry.type === CalendarEntry.Type.Appointment.VALUE) {
        return (
          <>
            <BookingDetailsScreenView
              data={{ type: 'Loaded', appointment: state.entry }}
              now={now}
              onClosePress={goBack}
              onClientPress={viewClientCallback}
              onAcceptAppointment={canApproveAppointment ? acceptAppointmentCallback : undefined}
              onRejectAppointment={canRejectAppointment ? rejectAppointmentCallback : undefined}
              onEditAppointment={canEditAppointment ? editAppointmentCallback : undefined}
              navigateClientFeedback={navigateClientFeedbackCallback}
              onBookAgain={canBookAgain ? onBookAgain : undefined}
              onMarkAsNoShow={canMarkAsNoShow ? onMarkAsNoShow : undefined}
              onDeleteAppointment={canDeleteAppointment ? deleteBookingCallback : undefined}
              calendarId={route.params.calendarId as CalendarId}
            />
            {/* modals */}
            {messagingOptionsDetails.visible && (
              <ClientMessagingOptionsModal
                type={messagingOptionsDetails.type}
                onSmsPress={sendSmsNotification}
                onWhatsappPress={sendWhatsappNotification}
                onDismiss={handleMessagingModalDismiss}
              />
            )}
            {showConfirmReject ? <ConfirmRejectModal /> : null}
            {showConfirmDelete ? (
              <ConfirmDeleteBooking
                canClose={!deleteInProgress}
                onClose={cancelConfirmDeleteCallback}
                leftCallback={cancelConfirmDeleteCallback}
                rightCallback={(reason: string) => {
                  if (appointment !== undefined) {
                    deleteBooking(appointment, true, reason);
                  }
                }}
              />
            ) : null}
            {showRecurrentDeleteOptions ? (
              <ConfirmDeleteRecurrentBooking
                onDeleteAll={() => confirmDeleteBookingAll('')}
                onDeleteOnlyOne={() => confirmDeleteBookingOnce('')}
                onDismiss={() => {
                  setShowRecurrentDeleteOptions(false);
                }}
              />
            ) : null}
          </>
        );
      } else {
        return (
          <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Body>Not an appointment</Body>
          </View>
        );
      }
    } else {
      return (
        <>
          <BookingDetailsScreenView
            data={{ type: 'Loading', ...params }}
            now={now}
            onClosePress={goBack}
            onClientPress={viewClientCallback}
            onAcceptAppointment={canApproveAppointment ? acceptAppointmentCallback : undefined}
            onRejectAppointment={canRejectAppointment ? rejectAppointmentCallback : undefined}
            onEditAppointment={canEditAppointment ? editAppointmentCallback : undefined}
            navigateClientFeedback={navigateClientFeedbackCallback}
            onBookAgain={canBookAgain ? onBookAgain : undefined}
            onMarkAsNoShow={canMarkAsNoShow ? onMarkAsNoShow : undefined}
            onDeleteAppointment={canDeleteAppointment ? deleteBookingCallback : undefined}
            calendarId={route.params.calendarId as CalendarId}
          />
          {showConfirmReject ? <ConfirmRejectModal /> : null}
        </>
      );
    }
  }),
);

const BookingDetailsScreen: React.FC<Props> = ({ route, navigation, page }: Props) => {
  return (
    <BookingDetailsComponent
      pageId={page.details._id}
      page={page}
      calendarEntryId={route.params.calendarEntryId}
      occurrenceIndex={pipe(
        route.params.occurrenceIndex,
        NumberFromString.decode,
        E.getOrElse(() => 0),
      )}
      route={route}
      navigation={navigation}
    />
  );
};

export default pipe(
  BookingDetailsScreen,
  CurrentBusiness,
  withUpdateBookingStatusContextProvider,
  withBookingDeleteContextProvider,
);
