import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type OnlineSaleSettings = {
  /**
   * Sell item on page's marketplace profile
   */
  readonly marketplaceSale: boolean;
  /**
   * Sell item on Tazz
   */
  readonly extraPromotion: boolean;
};

const JSON: t.Type<OnlineSaleSettings, JSONable> = t.type(
  {
    marketplaceSale: t.boolean,
    extraPromotion: t.boolean,
  },
  'OnlineSaleSettings',
);

const DEFAULT: OnlineSaleSettings = {
  marketplaceSale: false,
  extraPromotion: false,
};

export const OnlineSaleSettings = {
  JSON,
  DEFAULT,
};
