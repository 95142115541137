import { StyleSheet } from 'react-native';

import { colors, sizes } from '../../../styles';

const styles = StyleSheet.create({
  container: {
    paddingTop: sizes[16],
    paddingHorizontal: sizes[24],
  },
  contentContainer: {
    paddingBottom: 140,
  },
  header: {
    gap: sizes[16],
  },
  title: {
    fontFamily: 'open-sans-semibold',
  },
  content: {
    marginTop: sizes[24],
  },
  deleteButton: {
    marginTop: 48,
  },

  deleteText: { color: colors.RADICAL_RED },
  footer: { position: 'absolute', left: 0, right: 0, bottom: 0 },
  galleryButton: {
    paddingVertical: sizes[12],
    paddingLeft: sizes[12],
    paddingRight: sizes[8],
    borderWidth: 1,
    borderRadius: 6,
    borderColor: colors.GEYSER,
  },
  footerButton: { alignSelf: 'center' },
  input: {
    width: '100%',
    height: 180,
  },
  subtitle: {
    color: colors.COMET,
  },
  gallery: {
    marginTop: sizes[24],
  },
  modalBorderBottom: {
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
});

export default styles;
