import { tokenize, match } from '@/utils/search';
import { GroupWithServices } from '@mero/api-sdk/dist/services/group-with-services';
import { normalize } from '@mero/components';

type SavedServiceSearchText = GroupWithServices['services'][number] & { searchText: string };

type IndexedGroup = Pick<GroupWithServices, 'group'> & { services: SavedServiceSearchText[] };

type ServiceSearchIndex = {
  /**
   * Find services matching query string
   */
  readonly search: (q: string) => GroupWithServices[];
};

export const buildServiceSearchIndex = (groups: GroupWithServices[]): ServiceSearchIndex => {
  const index: IndexedGroup[] = groups.map(({ group, services }) => ({
    group,
    services: services.map(
      (service): SavedServiceSearchText => ({
        ...service,
        searchText: normalize(`${service.name} ${service.description}`),
      }),
    ),
  }));

  return {
    search: (q) => {
      const tokens = tokenize(q);

      if (tokens.length === 0) {
        return groups;
      }

      return index.reduce((acc: IndexedGroup[], { group, services: allServices }: IndexedGroup) => {
        const services = allServices.filter((service) => match(tokens, service.searchText));
        if (services.length > 0) {
          return acc.concat([{ group, services }]);
        } else {
          return acc;
        }
      }, []);
    },
  };
};
