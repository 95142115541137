import { StyleSheet } from 'react-native';

import { colors, sizes } from '../../../../styles';

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.ALABASTER,
  },
  imageContainer: {
    paddingHorizontal: 24,
    paddingTop: 8,
  },
  column: {
    flex: 1,
    position: 'relative',
  },
  deleteText: {
    fontFamily: 'open-sans-semibold',
  },
  navigationButton: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 218, // imageHeight/2
    borderRadius: 50,
    backgroundColor: colors.WHITE,
    padding: 4,
  },
  nextButton: {
    right: 16,
  },
  prevButton: {
    transform: [{ rotate: '180deg' }],
    left: 16,
  },

  deleteButton: {
    paddingBottom: 16,
    paddingRight: 24,
  },
  contentContainer: {
    paddingHorizontal: sizes[24],
    marginTop: sizes[24],
  },
  image: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#EEF0F2',
  },
  footer: { position: 'absolute', left: 0, right: 0, bottom: 0 },
  footerButton: { alignSelf: 'center', width: '100%' },
  modalBorderBottom: {
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  listContainer: {
    paddingTop: sizes[16],
  },
});

export default styles;
