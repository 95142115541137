import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type SelectionOption = {
  readonly text: string;
};

const JSON: t.Type<SelectionOption, JSONable> = t.type(
  {
    text: t.string,
  },
  'SelectionOption',
);

export const SelectionOption = {
  JSON,
};
