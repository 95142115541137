import { PagesJson } from '../../../pages';
import { PageSearchWithHasPromoPrice } from '../../../pages/page-search';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Page = PageSearchWithHasPromoPrice & {
  readonly type: 'Page';
};

const JSON: t.Type<Page, JSONable> = t.intersection(
  [
    PagesJson.PageSearchWithHasPromoPriceC,
    t.type({
      type: t.literal('Page'),
    }),
  ],
  'Page',
);

export const Page = {
  JSON,
};
