import { AppError, isAppError } from './appError';
import * as errors from '@mero/novabooker-errors';
import { setLocale, format } from 'exceptional.js';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import i18n from 'i18next';
import * as t from 'io-ts';

errors.loadNovabookerErrors();
setLocale(i18n.language);

i18n.on('languageChanged', (lng) => {
  setLocale(lng);
});

/**
 * Class for errors returned by API server
 * ApiError message field is to be shown to the user
 */
export class ApiError<Payload> extends AppError<undefined> {
  constructor(
    message: string,
    public readonly namespace: string,
    public readonly code: number,
    public readonly payload: Payload,
  ) {
    super(message, undefined);
  }

  public toString(): string {
    return `ApiError("${this.message}", ${this.namespace}, ${this.code}, ${JSON.stringify(this.payload)})`;
  }
}

/**
 * @returns ApiError<Payload> instance if found within given error hierarchy [e], using provided type guard [isPayload], or undefined otherwise
 */
export const extractApiError = <Payload>(
  isPayload: (p: unknown) => p is Payload,
): ((e: unknown) => ApiError<Payload> | undefined) => {
  const isApiErr = isApiError(isPayload);
  const isAppErr = isAppError(t.unknown.is);

  const extract = (e: unknown): ApiError<Payload> | undefined =>
    isApiErr(e) ? e : isAppErr(e) ? extract(e.originalCause) : undefined;

  return extract;
};

export type ApiErrorObject<T> = {
  readonly namespace: string;
  readonly code: number;
  readonly payload: T;
};

const apiErrorObject = <T extends t.Mixed>(payload: T) =>
  t.type(
    {
      namespace: t.string,
      code: t.number,
      payload: payload,
    },
    `ApiErrorObject<${payload.name}>`,
  );

const formatMessageInternal = (e: ApiErrorObject<unknown>): string =>
  `ApiError(namespace=${e.namespace}, code=${e.code}, payload=${e.payload})`;

export const formatMessage = (e: ApiErrorObject<unknown>, defaultMessage?: string): string => {
  const m = defaultMessage ?? formatMessageInternal(e);
  try {
    return format(e) ?? m;
  } catch {
    return m;
  }
};

const isApiError =
  <Payload>(isPayload: (p: unknown) => p is Payload) =>
  (e: unknown): e is ApiError<Payload> =>
    e instanceof ApiError && isPayload(e.payload);

/**
 * Build new ApiError<T> codec, from/to ApiErrorObject<T>
 */
export const apiError = <T extends t.Mixed>(payload: T): t.Type<ApiError<t.TypeOf<T>>, ApiErrorObject<t.TypeOf<T>>> => {
  const o = apiErrorObject(payload);

  return new t.Type<ApiError<t.TypeOf<T>>, ApiErrorObject<t.TypeOf<T>>>(
    `ApiError<${payload.name}>`,
    isApiError(payload.is),
    (i, c) =>
      pipe(
        o.validate(i, c),
        E.map((e) => new ApiError(formatMessage(e), e.namespace, e.code, e.payload)),
      ),
    (error) => ({
      namespace: error.namespace,
      code: error.code,
      payload: error.code,
    }),
  );
};

export const UnknownApiError = apiError(t.unknown);
