import React, { memo, useMemo } from 'react';

import * as icons from '../../assets/icons';

export interface IconProps {
  name?: keyof typeof icons;
  fillColor?: string;
  color?: string;
  size?: number;
  rotation?: number;
}

export type IconName = keyof typeof icons;

const Icon = ({ name, color, size, fillColor, rotation }: IconProps) => {
  const SvgIcon = useMemo(() => (name ? icons[name] : null), [name]);

  if (!SvgIcon) return null;

  return <SvgIcon color={color} rotation={rotation} size={size} fillColor={fillColor} />;
};

export default memo(Icon);
