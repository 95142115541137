import { ImageType, Status } from '@/utils/gallery';
import { ClientImageId, NoteId } from '@mero/api-sdk';
import { Button } from '@mero/components';
import { NonEmptyString } from 'io-ts-types';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Pressable, ScrollView, useWindowDimensions, View } from 'react-native';

import ModalScreenContainer from '../../../components/ModalScreenContainer';
import TextInput from '../../../components/TextInput';
import {
  Body,
  ClientGalleryImage,
  Column,
  FormCard,
  H1,
  MeroHeader,
  Row,
  SafeAreaView,
  Title,
} from '../../../components/shared';
import ClientConfirmNoteDeleteModal from '../ClientDetailsScreen/components/ClientConfirmNoteDeleteModal';
import DeleteNoteOptions from './components/DeleteNoteOptions/DeleteNoteOptions';
import GalleryPickerOptions from './components/GalleryPickerOptions/ClientNoteOptionsModal';
import NoteBookingCard from './components/NoteBookingCard';

import { CompositeNavigationProp, RouteProp } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';

import { useEscPressWeb } from '../../../hooks/useEscPressWeb';
import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';
import { useImageUpload } from '@/hooks/useImageUpload';

import { AppEventsContext } from '../../../contexts/AppEvents';
import { NoteCreationContext } from '../../../contexts/NoteContext';
import { colors } from '../../../styles';
import { RootStackParamList, ClientNoteStackParamList } from '../../../types';
import styles from './styles';

type NoteDetailsProps = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<ClientNoteStackParamList, 'NoteDetailsScreen'>,
    StackNavigationProp<RootStackParamList, 'Authorized'>
  >;
  route: RouteProp<ClientNoteStackParamList, 'NoteDetailsScreen'>;
};

const NoteDetailsScreen: React.FC<NoteDetailsProps> = ({ route, navigation }) => {
  const [
    { type, noteImages, text, noteBookingDetails },
    {
      setText,
      clearBooking,
      createNote,
      setNoteImages,
      setInitialData,
      updateClientNote,
      removePhoto,
      deleteClientImage,
      removeClientNote,
    },
  ] = NoteCreationContext.useContext();
  const {
    handleSelectImages,
    images,
    removeImage,
    retryUpload,
    setImages,
    handleSelectImagesFromCamera,
    uploadImages,
    isUploading,
  } = useImageUpload({
    onUploadComplete(image) {
      setNoteImages([{ ...image, isNew: true }]);
    },
    skipUpload: true, // Skip automatic upload on selection
  });

  const [removedImageIds, setRemovedImageIds] = useState<string[]>([]);
  const [showGalleryPickerOptions, setShowGalleryPickerOptions] = useState(false);
  const [showDeleteNoteOptions, setShowDeleteNoteOptions] = useState(false);
  const [showClientNoteDeleteConfirmModal, setShowClientNoteDeleteConfirmModal] = React.useState<
    | {
        noteId: NoteId;
        deleteNoteImages: boolean;
      }
    | undefined
  >(undefined);
  const clientId = route.params?.clientId;
  const pageId = route.params?.pageId;
  const note = route.params?.note;
  const { isPhone } = useMediaQueries();
  const { width } = useWindowDimensions();
  // 24px padding left and right, 6px column gap
  const imageSize = ((width > 768 ? 500 : width) - 2 * 24 - 2 * 6) / 3;

  const isLoading = type === 'Saving';
  const goBack = useGoBack();
  const [, { pushEvent }] = AppEventsContext.useContext();

  useEffect(() => {
    setImages(noteImages);
  }, [noteImages]);

  useEscPressWeb({
    onPress: goBack,
  });

  useEffect(() => {
    setInitialData({ clientId, pageId, note });
  }, [clientId, pageId, setInitialData]);

  const handleSaveNote = async () => {
    // Upload any pending images before saving the note
    const pendingImages = images.filter((img) => (img as ImageType).status === Status.IDLE) as ImageType[];
    if (pendingImages.length > 0) {
      await uploadImages(pendingImages, pageId, clientId);
    }

    // setNoteImages(images as ClientImage[]);
    await createNote();
    handleGoBack();
  };

  const handleEditNote = async () => {
    // Upload any pending images before updating the note
    const pendingImages = images.filter((img) => (img as ImageType).status === Status.IDLE) as ImageType[];
    if (pendingImages.length > 0) {
      await uploadImages(pendingImages, pageId, clientId);
    }

    await Promise.all(removedImageIds.map((imageId) => deleteClientImage(imageId as ClientImageId)));
    await updateClientNote();
    handleGoBack();
  };

  const handleGoToGallery = useCallback(
    () =>
      navigation.navigate('NoteGalleryScreen', {
        pageId,
        clientId,
      }),
    [pageId, clientId, navigation],
  );

  const handleGoToBookings = useCallback(() => navigation.navigate('NoteBookingsListScreen'), [navigation]);

  const handleGoBack = () => {
    console.log('handleGoBack');
    pushEvent({
      type: 'PageReloadNotes',
    });
    goBack();
  };

  const handleDeleteNote = async (withImages: boolean) => {
    setShowDeleteNoteOptions(false);
    await removeClientNote(withImages);
    handleGoBack();
  };
  const handleShowGalleryPickerOptions = useCallback(() => {
    setShowGalleryPickerOptions(true);
  }, [setShowGalleryPickerOptions]);

  const handleCloseGalleryPickerOptions = useCallback(() => {
    setShowGalleryPickerOptions(false);
  }, [setShowGalleryPickerOptions]);

  const handleOpenGallery = useCallback(() => {
    handleCloseGalleryPickerOptions();
    handleSelectImages(pageId, clientId);
  }, [handleCloseGalleryPickerOptions, handleSelectImages, pageId, clientId]);

  const handleOpenCamera = useCallback(() => {
    handleCloseGalleryPickerOptions();
    handleSelectImagesFromCamera(pageId, clientId);
  }, [handleCloseGalleryPickerOptions, handleSelectImages, pageId, clientId]);

  const handleOpenClientGallery = useCallback(() => {
    handleCloseGalleryPickerOptions();
    handleGoToGallery();
  }, [handleCloseGalleryPickerOptions, handleGoToGallery]);

  const handleDeleteImage = (imageId: string) => {
    const image = noteImages.find((img) => img._id === imageId);
    if (image?.isNew) {
      deleteClientImage(imageId as ClientImageId);
    } else if (note) {
      // If editing a note, track removed images to delete on save
      setRemovedImageIds((prev) => [...prev, imageId]);
    }
    removePhoto(imageId);
    removeImage(imageId);
  };

  const handleDeleteLocalImage = useCallback(
    (imageId: string) => {
      removeImage(imageId);
    },
    [removeImage],
  );

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader canGoBack onBack={goBack} title="Adaugă notiță" />
      <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
        <View style={styles.header}>
          <H1>{note ? 'Modifică notiță' : 'Adaugă notiță'}</H1>
          <Body>Notița va fi vizibilă doar intern, de către profesioniștii cu acces la fișa clientului</Body>
        </View>
        <Column gap={16} style={styles.content}>
          <Column gap={8}>
            <Body style={styles.title}>Detalii</Body>
            <TextInput
              textInputProps={{
                autoCorrect: false,
              }}
              value={text}
              onChange={(value) => setText(value as NonEmptyString)}
              placeholder="Adaugă detalii…"
              styles={{ textInput: styles.input }}
              multiline
              numberOfLines={7}
            />
          </Column>
          <NoteBookingCard
            onPress={noteBookingDetails._id ? undefined : handleGoToBookings}
            onClearNoteBooking={clearBooking}
            booking={noteBookingDetails}
          />
        </Column>
        <Column style={styles.gallery} gap={8}>
          <Column gap={16}>
            <Row flexWrap="wrap" gap={6}>
              {images.map((image) => {
                return (
                  <ClientGalleryImage
                    onDeleteClientImage={handleDeleteImage}
                    onDeleteLocalImage={handleDeleteLocalImage}
                    key={image._id}
                    image={image as any}
                    onRetry={() => retryUpload(image as ImageType, pageId, clientId)}
                    imageContainerStyle={{ width: imageSize, height: imageSize }}
                  />
                );
              })}
            </Row>
          </Column>
          <Button
            color={colors.DARK_BLUE}
            backgroundColor={colors.SKY_BLUE}
            size="large"
            text="Adaugă foto"
            onPress={handleShowGalleryPickerOptions}
            disabled={isLoading}
          />
        </Column>
        {note && (
          <Pressable style={styles.deleteButton} onPress={() => setShowDeleteNoteOptions(true)}>
            <Row justifyContent="center">
              <Title style={styles.deleteText}>Șterge notiță</Title>
            </Row>
          </Pressable>
        )}
      </ScrollView>
      <FormCard dropShaddow paddings="button" style={[styles.footer, !isPhone && styles.modalBorderBottom]}>
        <SafeAreaView edges={['bottom']}>
          <Button
            text="Salvează modificări"
            onPress={note ? handleEditNote : handleSaveNote}
            disabled={isLoading || isUploading}
          />
        </SafeAreaView>
      </FormCard>
      {showGalleryPickerOptions && (
        <GalleryPickerOptions
          onDismiss={handleCloseGalleryPickerOptions}
          onOpenGallery={handleOpenGallery}
          onOpenCamera={handleOpenCamera}
          onOpenClientGallery={handleOpenClientGallery}
        />
      )}
      {showDeleteNoteOptions && (
        <DeleteNoteOptions
          onDismiss={() => setShowDeleteNoteOptions(false)}
          onDeleteNoteWithImages={() => {
            if (note) {
              setShowClientNoteDeleteConfirmModal({ noteId: note._id, deleteNoteImages: true });
            }
          }}
          onDeleteNoteWithoutImages={() => {
            if (note) {
              setShowClientNoteDeleteConfirmModal({ noteId: note._id, deleteNoteImages: false });
            }
          }}
        />
      )}
      {showClientNoteDeleteConfirmModal !== undefined ? (
        <ClientConfirmNoteDeleteModal
          noteId={showClientNoteDeleteConfirmModal.noteId}
          deleteNoteImages={showClientNoteDeleteConfirmModal.deleteNoteImages}
          onDismiss={() => {
            setShowClientNoteDeleteConfirmModal(undefined);
          }}
          onConfirm={() => {
            setShowClientNoteDeleteConfirmModal(undefined);
            handleDeleteNote(showClientNoteDeleteConfirmModal.deleteNoteImages);
          }}
        />
      ) : null}
    </ModalScreenContainer>
  );
};

export default NoteDetailsScreen;
