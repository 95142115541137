import { Item, MembershipPayment, Payment, WithUiKey } from '@/contexts/CheckoutFormContext';
import { CreateCheckoutTransactionPayment } from '@mero/api-sdk';
import { CreateCheckoutTransactionItem } from '@mero/api-sdk/dist/checkout/checkoutApi';
import { CheckoutTransactionPayment } from '@mero/api-sdk/dist/checkout/checkoutTransactionPayment';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';

export const paymentConversion = (payment: Payment): CheckoutTransactionPayment.Any<ScaledNumber, MeroUnits.Any> => {
  switch (payment.type) {
    case 'Membership':
      return {
        type: 'Membership',
        membership: payment.membership,
        items: payment.items.map((item) => {
          switch (item.type) {
            case 'Service':
              return {
                ...item,
                transactionItemId: item.type,
                effectivePrice: {
                  amount: item.effectivePrice,
                  vatStatus: {
                    type: 'Included',
                    rate: undefined,
                  },
                },
              };
            default:
              return item;
          }
        }),
      };
    default:
      return payment;
  }
};

export const checkoutItemConversion = (item: WithUiKey<Item>): CreateCheckoutTransactionItem.Any<MeroUnits.Any> => {
  switch (item.type) {
    case 'MembershipInstallment':
      return {
        type: 'MembershipInstallment',
        membership: item.membership,
        total: item.total,
        saleOwner: item.saleOwner,
      };
    default:
      return item;
  }
};

export const convertMembershipPayment = (
  payment: MembershipPayment,
): CreateCheckoutTransactionPayment.Membership<ScaledNumber, MeroUnits.Any> => ({
  ...payment,
  items: payment.items.map((item) => ({
    ...item,
    effectivePrice:
      item.type === 'Service'
        ? {
            amount: item.effectivePrice,
            vatStatus: {
              type: 'Included',
              rate: undefined,
            },
          }
        : {
            amount: {
              amount: ScaledNumber.fromNumber(0, 2),
              unit: 'RON',
            },
            vatStatus: {
              type: 'Included',
              rate: undefined,
            },
          },
  })),
});
