import * as React from 'react';
import { Pressable } from 'react-native';

import { Column, Label, Row, SmallBody } from '../../../../../../components/shared';

import { sizes } from '../../../../../../styles';
import CopyIcon from '../CopyIcon';
import SendIcon from '../SendIcon';
import WhatsAppIcon from '../WhatsAppIcon';
import styles from './styles';

interface ClientDetailsListItemProps {
  label: string;
  value?: string;
  onWhatAppPress?: () => void;
  onSendPress?: () => void;
  onCopyPress?: () => void;
}

const ClientDetailsListItem: React.FC<ClientDetailsListItemProps> = ({
  label,
  value,
  onWhatAppPress,
  onSendPress,
  onCopyPress,
}) => {
  if (!value) {
    return null;
  }

  return (
    <Row style={styles.content} alignItems="flex-end">
      <Column flex={1} gap={6}>
        <Label style={styles.itemTitle}>{label}</Label>
        <Row justifyContent="space-between" alignItems="center" gap={8}>
          <SmallBody style={{ lineHeight: 28 }} numberOfLines={1} ellipsizeMode={'tail'}>
            {value}
          </SmallBody>
        </Row>
      </Column>
      <Row gap={sizes[12]}>
        {onSendPress && (
          <Pressable onPress={onSendPress}>
            <SendIcon />
          </Pressable>
        )}
        {onWhatAppPress && (
          <Pressable onPress={onWhatAppPress}>
            <WhatsAppIcon />
          </Pressable>
        )}
        {onCopyPress && (
          <Pressable onPress={onCopyPress}>
            <CopyIcon />
          </Pressable>
        )}
      </Row>
    </Row>
  );
};

export default ClientDetailsListItem;
