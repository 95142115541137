import { JSONable } from '../common';
import { Money } from '../money';
import { Numbers } from '../numbers';
import { MeroCurrency } from './meroCurrency';
import { MeroUnits } from './meroUnits';
import * as t from 'io-ts';

export type Currency = MeroUnits.EUR | MeroUnits.RON;

/**
 * Get type of {@link MeroMoney} for given {@link Unit}
 */
export type Of<Unit extends MeroUnits.Any> = Money<number, Unit>;

/**
 * Any mero money supported
 */
export type Any = Of<MeroUnits.EUR> | Of<MeroUnits.RON>;

export const json = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<Of<Unit>, JSONable> => {
  return t.type(
    {
      amount: t.number,
      unit: t.literal(unit),
    },
    `MeroMoney<${unit}>`,
  );
};

export const JSON = t.type(
  {
    amount: t.number,
    unit: MeroUnits.JSON,
  },
  'MeroMoney',
);

export const of = <Unit extends MeroUnits.Any>(amount: number, unit: Unit): Of<Unit> => {
  return {
    amount,
    unit,
  };
};

/**
 * Round {@link amount} to max {@link unit} decimal positions, ex: 2 decimals for RON
 */
export const roundedOf = <Unit extends MeroUnits.Any>(amount: number, unit: Unit): Of<Unit> => {
  return of(MeroCurrency[unit].roundToFixed(amount), unit);
};

/**
 * Rounds given {@link money.amount} to the least significant digit, based un {@link money.unit}
 * ex. 1/100 for EUR/cent
 */
export const roundToFixed = <Unit extends MeroUnits.Any>(money: Of<Unit>): Of<Unit> => {
  return roundedOf(money.amount, money.unit);
};

export const getAmountFixed = <Unit extends MeroUnits.Any>(money: Of<Unit>): string => {
  return MeroCurrency[money.unit].toFixed(money.amount);
};

export const ron = (amount: number): Of<MeroUnits.RON> => of(amount, MeroUnits.RON.code);

export const eur = (amount: number): Of<MeroUnits.EUR> => of(amount, MeroUnits.EUR.code);

/**
 * Compares two {@link MeroMoney} instances to be equals down to least significant digit
 */
export const equalsDownToFixed = (a: Any, b: Any): boolean => {
  if (a.unit !== b.unit) {
    return false;
  }

  const fixedA = getAmountFixed(a);
  const fixedB = getAmountFixed(b);

  return fixedA === fixedB;
};

/**
 * Compute (and round) VAT value for given netValue
 * @param netValue origin value to compute VAT for. Not rounded before computations.
 * @param vatRate VAT rate to compute VAT value, (0..1, ex. 19% -> 0.19). Not rounded before computations.
 * @returns rounded VAT value (to be added to netValue)
 */
export const getVatValueOf = <Unit extends MeroUnits.Any>(netValue: Of<Unit>, vatRate: number): Of<Unit> => {
  return roundedOf(netValue.amount * vatRate, netValue.unit);
};

/**
 * Compute (and round) Net value for given grossValue, so that when vatRate is applied Net + VAT === grossValue
 * There are cases where it is not possible to compute rounded VAT and NET so that we can recreate Gross value,
 *  ex. for 5.73 Gross value NET = 4.82 and VAT = 0.91, but getVatValueOf(4.82) == 0.92
 *
 * @param grossValue to compute VAT from. Not rounded before computations.
 * @param vatRate VAT rate to subtract from Gross value to obtain NET value, (0...1, ex. 19% -> 0.19). Not rounded before computations.
 * @returns rounded NET value (included in grossValue)
 */
export const getNetValueFrom = <Unit extends MeroUnits.Any>(grossValue: Of<Unit>, vatRate: number): Of<Unit> => {
  return roundedOf(grossValue.amount / (1 + vatRate), grossValue.unit);
};

/**
 * Compute (and round) VAT value for given grossValue
 * VAT value is computed as GROSS - NET where NET is computed using getNetValueFrom
 *
 * @param grossValue to compute VAT from. Not rounded before computations.
 * @param vatRate VAT rate to compute included VAT value, (0...1, ex. 19% -> 0.19). Not rounded before computations.
 * @returns rounded VAT value (included in grossValue)
 */
export const getVatValueFrom = <Unit extends MeroUnits.Any>(grossValue: Of<Unit>, vatRate: number): Of<Unit> => {
  return roundedOf(grossValue.amount - getNetValueFrom(grossValue, vatRate).amount, grossValue.unit);
};

const MeroMoneyM = Money.build(Numbers, MeroUnits);

export const zero = MeroMoneyM.zero;
export const add = MeroMoneyM.add;
export const sub = MeroMoneyM.sub;
export const mul = MeroMoneyM.mul;
export const isZero = MeroMoneyM.isZero;
export const hasUnit = MeroMoneyM.hasUnit;
export const equals = MeroMoneyM.equals;
