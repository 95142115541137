import log from './log';

export const promiseTimeout = (ms: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

export const promiseSequence = async (promises: (() => Promise<unknown>)[]) => {
  for (const promise of promises) {
    await promise();
  }
};

export const allSettled = <T>(
  promises: Promise<T>[],
): Promise<({ status: 'fulfilled'; value: T } | { status: 'rejected'; reason: Error })[]> => {
  return Promise.all(
    promises.map((promise) =>
      promise
        .then((value): { status: 'fulfilled'; value: T } => ({ status: 'fulfilled', value }))
        .catch((reason): { status: 'rejected'; reason: Error } => ({ status: 'rejected', reason })),
    ),
  );
};

export const catchTrow = (e: unknown) => {
  log.error('Request error', JSON.stringify(e));
  throw e;
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
