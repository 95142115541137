import { Title } from '@mero/components';
import * as React from 'react';
import { Pressable, TouchableOpacity, View } from 'react-native';

import Line from '@mero/components/lib/components/Line';
import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import SimpleListItem from '@mero/components/lib/components/SimpleListItem';

import { useMediaQueries } from '@/hooks/useMediaQueries';

import { styles } from './styles';

interface GalleryPickerOptionsProps {
  onDismiss: () => void;
  onOpenGallery: () => void;
  onOpenCamera?: () => void;
  onOpenClientGallery?: () => void;
}

const GalleryPickerOptions: React.FC<GalleryPickerOptionsProps> = ({
  onDismiss,
  onOpenGallery,
  onOpenCamera,
  onOpenClientGallery,
}) => {
  const { isPhone } = useMediaQueries();

  return (
    <ModalOverlay>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <View style={styles.optionsListContainer}>
        <SafeAreaView edges={['bottom']}>
          <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onOpenGallery}>
            <SimpleListItem>
              {isPhone ? <Title>Adaugă din galeria telefonului</Title> : <Title>Încarcă imagini</Title>}
            </SimpleListItem>
          </TouchableOpacity>
          <Line />
          {isPhone && (
            <>
              <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onOpenCamera}>
                <SimpleListItem>
                  <Title>Camera foto</Title>
                </SimpleListItem>
              </TouchableOpacity>
              {onOpenClientGallery && <Line />}
            </>
          )}
          {onOpenClientGallery && (
            <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={onOpenClientGallery}>
              <SimpleListItem>
                <Title>Alege din fișa clientului</Title>
              </SimpleListItem>
            </TouchableOpacity>
          )}
        </SafeAreaView>
      </View>
    </ModalOverlay>
  );
};

export default GalleryPickerOptions;
