import { Platform, StyleSheet } from 'react-native';

import { colors, sizes } from '../../../../../styles';

const styles = StyleSheet.create({
  container: { flex: 1, overflow: 'hidden' },
  contentContainer: {
    ...Platform.select({
      web: { paddingLeft: sizes[16], paddingRight: sizes[24], paddingTop: sizes[24], gap: sizes[16] },
      default: { paddingHorizontal: sizes[16], gap: sizes[16] },
    }),
  },
  contentTitle: {
    fontFamily: 'merriweather-bold',
    fontSize: 20,
  },
  filterButton: { flexDirection: 'row', alignItems: 'center' },
  filterButtonText: { color: colors.DARK_BLUE, fontFamily: 'open-sans-semibold' },
  filterButtonIcon: { width: 24, height: 24, justifyContent: 'center', alignItems: 'center' },
});

export default styles;
