import { ClientId } from '../../clients/client-id';
import { PageId } from '../../pages/page-id';
import { ClientImage } from '../clientImages';
import { NoteAppointment } from './noteAppointment';
import { NoteId } from './noteId';
import { Firstname, JSONable, Lastname, UserId } from '@mero/shared-sdk';
import { Option } from '@mero/shared-sdk';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

export type NoteDetails = {
  readonly _id: NoteId;
  readonly pageId: PageId;
  readonly clientId: Option<ClientId>;
  readonly isPinned: boolean;
  readonly images: ClientImage[];
  readonly date: Date;
  readonly text: tt.NonEmptyString;
  readonly createdBy: Option<{
    readonly userId: UserId;
    readonly firstname: Option<Firstname>;
    readonly lastname: Option<Lastname>;
    readonly phone: string;
  }>;
  readonly updatedBy: Option<{
    readonly userId: UserId;
    readonly firstname: Option<Firstname>;
    readonly lastname: Option<Lastname>;
    readonly phone: string;
  }>;
  readonly appointment: Option<NoteAppointment>;
};

const JSON: t.Type<NoteDetails, JSONable> = t.type(
  {
    _id: NoteId.JSON,
    pageId: PageId,
    clientId: Option.json(ClientId),
    isPinned: t.boolean,
    images: t.array(ClientImage.JSON),
    date: tt.DateFromISOString,
    text: tt.NonEmptyString,
    createdBy: Option.json(
      t.type(
        {
          userId: UserId,
          firstname: Option.json(Firstname),
          lastname: Option.json(Lastname),
          phone: t.string,
        },
        'CreatedBy',
      ),
    ),
    updatedBy: Option.json(
      t.type(
        {
          userId: UserId,
          firstname: Option.json(Firstname),
          lastname: Option.json(Lastname),
          phone: t.string,
        },
        'UpdatedBy',
      ),
    ),
    appointment: Option.json(NoteAppointment.JSON),
  },
  'NoteDetails',
);

export const NoteDetails = {
  JSON,
};
