import { OnlineSaleMembershipItem } from './onlineSaleMembershipItem';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type OnlineSaleItem = OnlineSaleMembershipItem;

const JSON: t.Type<OnlineSaleItem, JSONable> = OnlineSaleMembershipItem.JSON;

export const OnlineSaleItem = {
  JSON,
};
