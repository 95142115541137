import { WorkInterval } from './workingHours';
import { IsoWeekDay } from '@mero/shared-sdk';
import * as A from 'fp-ts/Array';
import * as Eq from 'fp-ts/Eq';
import * as t from 'io-ts';

export type BusinessHours = WorkInterval[];

const JSON = t.array(WorkInterval.JSON);

const eq: Eq.Eq<BusinessHours> = A.getEq(WorkInterval);

type WeekDay = 'mo' | 'to' | 'we' | 'th' | 'fr' | 'sa' | 'su';

const WEEK_DAY_INDEX: Record<WeekDay, number> = {
  mo: 0,
  to: 1,
  we: 2,
  th: 3,
  fr: 4,
  sa: 5,
  su: 6,
};

const getWeekDayInterval = (weekDay: WeekDay, businessHours: BusinessHours): WorkInterval | undefined => {
  return businessHours[WEEK_DAY_INDEX[weekDay]];
};

const getIsoWeekDayInterval = (isoWeekDay: IsoWeekDay, businessHours: BusinessHours): WorkInterval | undefined => {
  return businessHours[isoWeekDay - 1];
};

const NONE: BusinessHours = [
  WorkInterval.FULLDAY_INACTIVE,
  WorkInterval.FULLDAY_INACTIVE,
  WorkInterval.FULLDAY_INACTIVE,
  WorkInterval.FULLDAY_INACTIVE,
  WorkInterval.FULLDAY_INACTIVE,
  WorkInterval.FULLDAY_INACTIVE,
  WorkInterval.FULLDAY_INACTIVE,
];

const NON_STOP: BusinessHours = [
  WorkInterval.FULLDAY_ACTIVE,
  WorkInterval.FULLDAY_ACTIVE,
  WorkInterval.FULLDAY_ACTIVE,
  WorkInterval.FULLDAY_ACTIVE,
  WorkInterval.FULLDAY_ACTIVE,
  WorkInterval.FULLDAY_ACTIVE,
  WorkInterval.FULLDAY_ACTIVE,
];

export const BusinessHours = {
  JSON,
  NONE,
  NON_STOP,
  ...eq,
  getWeekDayInterval,
  getIsoWeekDayInterval,
};
