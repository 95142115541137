import { Authentication, GrantResponse, GrantStorage } from './auth';
import { FoundUser } from './foundUser';
import { IsPhoneRegisteredResponse } from './isPhoneRegisteredResponse';
import { User } from './user';
import { UserId } from './user-id';
import { UsersApi, GrantType } from './users-api';
import {
  AuthStorage,
  HttpClient,
  PhoneNumber,
  apiError,
  unsafeRight,
  Logger,
  ApiError,
  AppError,
  UnknownApiError,
} from '@mero/shared-sdk';
import { ProfileImage } from '@mero/shared-sdk/dist/assets';
import * as t from 'io-ts';
import promiseRetry from 'promise-retry';

const HasMessage = t.type(
  {
    message: t.string,
  },
  'HasMesasge',
);

const hasMessageApiError = apiError(HasMessage);

/**
 * @returns true when given error means "refresh token is invalid or expired"
 */
const isInvalidRefreshTokenError = (e: unknown): e is ApiError<unknown> =>
  UnknownApiError.is(e) && e.namespace === 'users' && (e.code === 12 || e.code === 19);

/**
 * Build new AuthStorage<> instance over GrantStorage, to be used with http clients
 */
export const buildHttpAuthStorage = (env: {
  auth: GrantStorage;
  apiBaseUrl: string;
  http: HttpClient;
  oauthClient: {
    id: string;
    secret?: string;
  };
  log: Logger;
}): AuthStorage<GrantResponse> => {
  const { auth, apiBaseUrl, http, oauthClient, log } = env;

  type RefreshResponse =
    /**
     * Token refresh request succeed
     */
    | {
        type: 'Success';
        auth: GrantResponse;
      }
    /**
     * Token refresh request failed
     */
    | {
        type: 'Failed';
        error: unknown;
      }
    /**
     * Refresh token is invalid or expired
     */
    | {
        type: 'InvalidToken';
        error: ApiError<unknown>;
      };

  const requestRefresh = async (refreshToken: string): Promise<RefreshResponse> => {
    try {
      return await promiseRetry(
        async (retry, attempt) => {
          try {
            log.debug(`RefreshToken: trying to refresh (attempt #${attempt}) `);

            // Check if grant didn't change in the meantime
            try {
              const grant = await auth.getGrant();

              if (grant && grant.refreshToken !== refreshToken) {
                log.debug('RefreshToken: grant changed concurrently, stop refreshing');

                return {
                  type: 'Success',
                  auth: grant,
                };
              }
            } catch (e) {
              log.exception(e);
            }

            const requestBody: {
              grantType: 'refresh_token';
              state: string;
              clientId: string;
              clientSecret?: string;
              refreshToken: string;
            } = {
              grantType: 'refresh_token',
              clientId: oauthClient.id,
              clientSecret: oauthClient.secret,
              refreshToken: refreshToken,
              state: new Date().toISOString(),
            };

            return {
              type: 'Success',
              auth: unsafeRight(
                await http.request(
                  {
                    method: 'POST',
                    url: `${apiBaseUrl}/user/oauth/token`,
                    data: requestBody,
                  },
                  http.decode.response(UnknownApiError, GrantResponse),
                ),
              ),
            };
          } catch (e) {
            if (!isInvalidRefreshTokenError(e)) {
              log.debug('RefreshToken: failed with non-invalid-token error, retry');
              retry(e);
            }

            throw e;
          }
        },
        {
          retries: 5,
        },
      );
    } catch (e: unknown) {
      if (isInvalidRefreshTokenError(e)) {
        return {
          type: 'InvalidToken',
          error: e,
        };
      } else {
        return {
          type: 'Failed',
          error: e,
        };
      }
    }
  };

  return {
    read: async () => {
      const grant = await auth.getGrant();

      return grant;
    },
    refresh: async (grant) => {
      try {
        if (grant.refreshToken) {
          log.debug('RefreshToken: start');
          const response: RefreshResponse = await requestRefresh(grant.refreshToken);

          switch (response.type) {
            case 'Success': {
              log.debug('RefreshToken: success');
              await env.auth.saveGrant(response.auth);
              return response.auth;
            }
            case 'Failed': {
              log.debug('RefreshToken: failed');
              const e = response.error;

              if (hasMessageApiError.is(e)) {
                throw new AppError(
                  `RefreshToken request failed: ${e.payload.message} (namespace=${e.namespace}, code=${e.code})`,
                  e,
                );
              } else {
                throw new AppError(`RefreshToken request failed: ${e}`, e);
              }
            }
            case 'InvalidToken': {
              try {
                const e = response.error;
                log.error(`RefreshToken: invalid (error: ${e.message}, payload: ${JSON.stringify(e.payload)})`);
              } catch (e) {
                // will not log error of error log
              }

              await env.auth.deleteGrant();
              return undefined;
            }
          }
        } else {
          log.debug('RefreshToken: no refreshToken present in grant');
          await env.auth.deleteGrant();

          return undefined;
        }
      } catch (e) {
        log.exception(e);

        return undefined;
      }
    },
    formatHeader: (grant) => `${grant.tokenType} ${grant.accessToken}`,
    getToken: (grant) => grant.accessToken,
  };
};

export const usersHttpClient = (env: {
  apiBaseUrl: string;
  http: HttpClient;
  auth: GrantStorage;
  oauthClient: {
    id: string;
    secret?: string;
  };
}): UsersApi => {
  const { apiBaseUrl, http, oauthClient } = env;

  const unknownError = UnknownApiError;
  const authResponseDecoder = http.decode.response(unknownError, Authentication);
  const unknownResponseDecoder = http.decode.response(unknownError, t.unknown);

  const registerNovaAuthDecoder = authResponseDecoder;
  const loginNovaAuthDecoder = authResponseDecoder;
  const loginFacebookDecoder = authResponseDecoder;
  const linkFacebookDecoder = unknownResponseDecoder;
  const loginAppleIdDecoder = authResponseDecoder;
  const linkAppleIdDecoder = unknownResponseDecoder;
  const loginGoogleDecoder = authResponseDecoder;
  const linkGoogleDecoder = unknownResponseDecoder;
  const getMyProfileDecoder = http.decode.response(unknownError, User);
  const updateProfileInfoDecoder = unknownResponseDecoder;
  const updatePhoneNumberDecoder = unknownResponseDecoder;
  const updateEmailDecoder = unknownResponseDecoder;
  const updateProfilePhotoDecoder = http.decode.response(unknownError, ProfileImage);
  const setPinDecoder = unknownResponseDecoder;
  const findUsersDecoder = http.decode.response(unknownError, t.array(FoundUser.JSON));
  const createLocalAccountDecoder = http.decode.response(
    unknownError,
    t.type({
      _id: UserId,
    }),
  );
  const createGhostAccountDecoder = http.decode.response(
    unknownError,
    t.type({
      _id: UserId,
    }),
  );
  const updateLocalAccountProfileInfoDecoder = unknownResponseDecoder;
  const updateLocalAccountPhoneNoDecoder = unknownResponseDecoder;
  const importUsersDecoder = http.decode.response(unknownError, t.array(UserId));
  const removeAccountDecoder = unknownResponseDecoder;
  const isPinValidDecoder = http.decode.response(
    unknownError,
    t.type({
      isValid: t.boolean,
    }),
  );
  const isPhoneRegisteredDecoder = http.decode.response(unknownError, IsPhoneRegisteredResponse.JSON);
  const getUserMeroCloudSmsSettingsDecoder = http.decode.response(
    unknownError,
    t.type({
      meroCloudSmsNotificationsEnabled: t.boolean,
    }),
  );
  const unsubscribeFromMeroCloudSmsDecoder = unknownResponseDecoder;

  return {
    registerNovaAuth: async (params) => {
      const requestBody: {
        grantType: GrantType;
        clientId: string;
        clientSecret?: string;
        token: string;
        firstname: string;
        lastname: string;
        email?: string;
        tags: string[];
      } = {
        ...params,
        grantType: params.grantType ?? 'password',
        clientId: oauthClient.id,
        clientSecret: oauthClient.secret,
        tags: params.tags ?? [],
      };

      const auth = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/user/register-nova-auth`,
            data: requestBody,
            withCredentials: true,
          },
          registerNovaAuthDecoder,
        ),
      );

      await env.auth.saveGrant(auth);

      return auth.user;
    },
    loginNovaAuth: async (params) => {
      const requestBody: {
        grantType: GrantType;
        state: string;
        clientId: string;
        clientSecret?: string;
        token: string;
        extraPayload?: unknown;
      } = {
        ...params,
        grantType: params.grantType ?? 'password',
        clientId: oauthClient.id,
        clientSecret: oauthClient.secret,
        state: new Date().toISOString(),
      };

      const auth = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/user/login-nova-auth`,
            data: requestBody,
          },
          loginNovaAuthDecoder,
        ),
      );

      await env.auth.saveGrant(auth);

      return auth.user;
    },

    loginWithPinCode: async (params) => {
      const requestBody: {
        grantType: GrantType;
        state: string;
        clientId: string;
        clientSecret?: string;
        pin: string;
        phone: string;
        extraPayload?: unknown;
      } = {
        ...params,
        grantType: params.grantType ?? 'password',
        clientId: oauthClient.id,
        clientSecret: oauthClient.secret,
        state: new Date().toISOString(),
      };

      const auth = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/user/login`,
            data: requestBody,
          },
          loginNovaAuthDecoder,
        ),
      );

      await env.auth.saveGrant(auth);

      return auth.user;
    },

    loginFacebook: async (params) => {
      const requestBody: {
        grantType: GrantType;
        clientId: string;
        clientSecret?: string;
        state: string;
      } & ({ fbAuthenticationToken: string } | { fbAccessToken: string }) = {
        ...params,
        grantType: params.grantType ?? 'password',
        clientId: oauthClient.id,
        clientSecret: oauthClient.secret,
        state: new Date().toISOString(),
      };

      const auth = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/user/facebook-login`,
            data: requestBody,
          },
          loginFacebookDecoder,
        ),
      );

      await env.auth.saveGrant(auth);

      return auth.user;
    },
    linkFacebook: async (params) => {
      const requestBody: { fbAuthenticationToken: string } | { fbAccessToken: string } = params;

      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/user/facebook`,
            data: requestBody,
          },
          linkFacebookDecoder,
        ),
      );
    },
    loginAppleId: async (params) => {
      const requestBody: {
        grantType: GrantType;
        clientId: string;
        clientSecret?: string;
        state: string;
        appleAuthCode: string;
      } = {
        ...params,
        grantType: params.grantType ?? 'password',
        clientId: oauthClient.id,
        clientSecret: oauthClient.secret,
        state: new Date().toISOString(),
      };

      const auth = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/user/apple-login`,
            data: requestBody,
          },
          loginAppleIdDecoder,
        ),
      );

      await env.auth.saveGrant(auth);

      return auth.user;
    },
    linkAppleId: async (params) => {
      const requestBody: { idToken: string } = { idToken: params.idToken };

      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/user/apple`,
            data: requestBody,
          },
          linkAppleIdDecoder,
        ),
      );
    },
    loginGoogle: async (params) => {
      const requestBody: {
        grantType: GrantType;
        clientId: string;
        clientSecret?: string;
        googleIdToken: string;
        state: string;
      } = {
        ...params,
        grantType: params.grantType ?? 'password',
        clientId: oauthClient.id,
        clientSecret: oauthClient.secret,
        state: new Date().toISOString(),
      };

      const auth = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/user/google-login`,
            data: requestBody,
          },
          loginGoogleDecoder,
        ),
      );

      await env.auth.saveGrant(auth);

      return auth.user;
    },
    linkGoogle: async (params) => {
      const requestBody: { idToken: string } = { idToken: params.googleIdToken };

      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/user/google`,
            data: requestBody,
          },
          linkGoogleDecoder,
        ),
      );
    },
    getMyProfile: async () => {
      const profile = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/user/profile`,
          },
          getMyProfileDecoder,
        ),
      );

      return profile;
    },
    updateProfileInfo: async (params) => {
      const requestBody: { firstname: string; lastname: string } = params;

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/user/profile-info`,
            data: requestBody,
          },
          updateProfileInfoDecoder,
        ),
      );
    },
    updatePhoneNumber: async (params) => {
      const requestBody: { token: string } = params;

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/user/phone`,
            data: requestBody,
          },
          updatePhoneNumberDecoder,
        ),
      );
    },
    updateEmail: async (params) => {
      const requestBody: { email: string } = params;

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/user/email`,
            data: requestBody,
          },
          updateEmailDecoder,
        ),
      );
    },
    updateProfilePhoto: async (profileImage) => {
      const formData = new FormData();
      const ext = profileImage.blob.type.split('/')[1];

      if (profileImage.platform === 'web') {
        formData.append(
          'photo',
          new File([profileImage.blob], `profileImage.${ext}`, { type: profileImage.blob.type }),
        );
      } else {
        // https://www.reactnativeschool.com/how-to-upload-images-from-react-native
        const uri = profileImage.platform === 'ios' ? profileImage.uri.replace('file://', '') : profileImage.uri;

        formData.append('photo', {
          name: `profileImage.${ext}`,
          type: profileImage.blob.type,
          uri: uri,
        } as any);
      }

      return unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/user/profile-photo`,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
          updateProfilePhotoDecoder,
        ),
      );
    },
    setPin: async (params) => {
      const requestBody: { pin: string; pinRepeat: string; token: string } = params;
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/user/pin`,
            data: requestBody,
          },
          setPinDecoder,
        ),
      );
    },
    isPinValid: async (pin) => {
      const requestBody = {
        pin: pin,
      };
      const result = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/user/check-pin`,
            data: requestBody,
          },
          isPinValidDecoder,
        ),
      );
      return result.isValid;
    },

    findUsers: async (params) => {
      const queryParams: { phone: string } = params;

      const profiles = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/user`,
            params: queryParams,
          },
          findUsersDecoder,
        ),
      );

      return profiles;
    },
    isPhoneRegistered: async (params) => {
      const requestBody: { phone: string } = params;

      const result = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/user/check-registered-phone`,
            data: requestBody,
          },
          isPhoneRegisteredDecoder,
        ),
      );

      return result;
    },
    createLocalAccount: async (params) => {
      const requestBody: { firstname: string; lastname: string; phone?: PhoneNumber } = params;

      const user = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/user/local`,
            data: requestBody,
          },
          createLocalAccountDecoder,
        ),
      );

      return user._id;
    },
    createGhostAccount: async (params) => {
      const requestBody: { firstname: string; lastname: string; phone: PhoneNumber } = params;

      const user = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/user/ghost`,
            data: requestBody,
          },
          createGhostAccountDecoder,
        ),
      );

      return user._id;
    },
    updateLocalAccountProfileInfo: async ({ userId, firstname, lastname }) => {
      const requestBody: { firstname: string; lastname: string } = {
        firstname: firstname,
        lastname: lastname,
      };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/user/${userId}/profile-info`,
            data: requestBody,
          },
          updateLocalAccountProfileInfoDecoder,
        ),
      );
    },
    updateLocalAccountPhoneNo: async ({ userId, phone }) => {
      const requestBody: { phone: PhoneNumber } = {
        phone,
      };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/user/${userId}/phone`,
            data: requestBody,
          },
          updateLocalAccountPhoneNoDecoder,
        ),
      );
    },
    importUsers: async (users) => {
      const requestBody: {
        userData: { phone: PhoneNumber; firstname: string; lastname: string }[];
      } = {
        userData: users,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/user`,
            data: requestBody,
          },
          importUsersDecoder,
        ),
      );
    },
    removeAccount: async (params: { dryRun?: boolean }) => {
      const query: any = {};
      if (typeof params.dryRun === 'boolean') {
        query.dryRun = params.dryRun;
      }

      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/user`,
            params: query,
          },
          removeAccountDecoder,
        ),
      );
    },
    getUserMeroCloudSmsSettings: async ({ phone }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/users/check-mero-cloud-sms-settings`,
            params: { phone: phone },
          },
          getUserMeroCloudSmsSettingsDecoder,
        ),
      );
    },
    unsubscribeFromMeroCloudSms: async ({ token }) => {
      const requestBody: {
        readonly token: string;
      } = {
        token: token,
      };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/users/unsubscribe/mero-cloud-sms`,
            data: requestBody,
          },
          unsubscribeFromMeroCloudSmsDecoder,
        ),
      );
    },
  };
};
