import { MarketplaceSearchConfig } from './marketplaceSearchConfig';
import { MarketplaceSearchHit } from './marketplaceSearchHit';
import { JSONable, Option, Paged } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type MarketplaceSearchResult = {
  readonly hits: Paged<MarketplaceSearchHit.Any[]>;
  readonly ssrPage: {
    readonly next: Option<number>;
    readonly prev: Option<number>;
  };
  readonly config: MarketplaceSearchConfig;
};

const setHitsData = (result: MarketplaceSearchResult, data: MarketplaceSearchHit.Any[]) => {
  return {
    ...result,
    hits: {
      ...result.hits,
      data: data,
    },
  };
};

const setNextPage = (result: MarketplaceSearchResult, next: Option<string>): MarketplaceSearchResult => {
  return {
    ...result,
    hits: {
      ...result.hits,
      next: next,
    },
  };
};

const setPrevPage = (result: MarketplaceSearchResult, prev: Option<string>): MarketplaceSearchResult => {
  return {
    ...result,
    hits: {
      ...result.hits,
      prev: prev,
    },
  };
};

const JSON: t.Type<MarketplaceSearchResult, JSONable> = t.type(
  {
    hits: Paged.json(t.array(MarketplaceSearchHit.JSON)),
    ssrPage: t.type(
      {
        next: Option.json(t.number),
        prev: Option.json(t.number),
      },
      'SsrPage',
    ),
    config: MarketplaceSearchConfig.JSON,
  },
  'MarketplaceSearchResult',
);

export const MarketplaceSearchResult = {
  JSON,
  setHitsData,
  setNextPage,
  setPrevPage,
};
