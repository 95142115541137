import * as React from 'react';
import { View } from 'react-native';
import { ViewStyle, StyleProp, StyleSheet } from 'react-native';

import { ATHENS_GRAY, COMET } from '../../../styles/colors';
import Avatar from '../Avatar';
import { IconProps, default as Icon } from '../Icon';
import ListItem from '../ListItem';
import Spacer from '../Spacer';
import { TextProps } from '../Text';
import SmallBody from '../Text/SmallBody';
import Title from '../Text/Title';

export type Props = React.PropsWithChildren<{
  style?: StyleProp<ViewStyle>;
  IconComponent?: React.ComponentType;
  icon?: IconProps['type'];
  iconColor?: IconProps['color'];
  image?: {
    src?: string;
    firstname?: string;
    lastname?: string;
  };
  title?: string | React.ReactElement;
  titleProps?: TextProps;
  titleNumberOfLines?: number;
  subtitle?: string | React.ReactElement;
  subtitleNumberOfLines?: number;
  alignContent?: 'middle' | 'top';
  iconPosition?: 'left' | 'right';
  paddingTop?: number;
  paddingBottom?: number;
  centerContainerStyle?: StyleProp<ViewStyle>;
}>;

const AvatarWrapper: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: ATHENS_GRAY,
        borderRadius: 24,
        width: 48,
        height: 48,
      }}
    >
      {children}
    </View>
  );
};

const SimpleListItem: React.FC<Props> = ({
  style,
  alignContent = 'top',
  IconComponent,
  icon,
  iconColor,
  iconPosition = 'left',
  title,
  titleProps,
  titleNumberOfLines,
  subtitle,
  subtitleNumberOfLines,
  image,
  centerContainerStyle,
  children,
  paddingTop = 16,
  paddingBottom = 16,
}: Props) => {
  const justifyContent = alignContent === 'middle' ? 'center' : 'flex-start';

  const FinalIconComponent = IconComponent
    ? IconComponent
    : icon
    ? () => <Icon type={icon} disabled={true} size={24} color={iconColor} />
    : undefined;
  const iconContainerStyle: StyleProp<ViewStyle> =
    icon && !IconComponent ? { justifyContent, width: 24 } : { justifyContent };

  const AvatarComponent = image
    ? () => (
        <AvatarWrapper>
          <Avatar source={image.src} firstname={image.firstname ?? ''} lastname={image.lastname ?? ''} size={46} />
        </AvatarWrapper>
      )
    : undefined;
  const avatarContainerStyle: StyleProp<ViewStyle> = image ? { justifyContent, width: 48 } : undefined;

  const iconLeft = iconPosition === 'left';
  const LeftComponent = iconLeft ? FinalIconComponent : AvatarComponent;
  const leftContainerStyle = iconLeft ? iconContainerStyle : avatarContainerStyle;
  const RightComponent = iconLeft ? AvatarComponent : FinalIconComponent;
  const rightContainerStyle = iconLeft ? avatarContainerStyle : iconContainerStyle;

  return (
    <ListItem
      style={StyleSheet.compose({ paddingTop, paddingBottom }, style)}
      LeftComponent={LeftComponent}
      leftContainerStyle={leftContainerStyle}
      centerContainerStyle={StyleSheet.compose(
        { justifyContent, marginRight: 16, marginLeft: LeftComponent ? 16 : 0 },
        centerContainerStyle,
      )}
      RightComponent={RightComponent}
      rightContainerStyle={rightContainerStyle}
    >
      {!!title && (
        <>
          <Title numberOfLines={titleNumberOfLines} ellipsizeMode="tail" {...titleProps}>
            {title}
          </Title>
          {!!subtitle && <Spacer size="xxs" />}
        </>
      )}
      {!!subtitle && (
        <SmallBody style={{ color: COMET }} numberOfLines={subtitleNumberOfLines} ellipsizeMode="tail">
          {subtitle}
        </SmallBody>
      )}
      {children}
    </ListItem>
  );
};

export default SimpleListItem;
