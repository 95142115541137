import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function SMSIcon() {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <G data-name="Group 8003">
        <G data-name="Group 8801" transform="translate(117 -275) translate(-24300 606)" fill="none">
          <Path data-name="Rectangle 2786" transform="translate(24183 -331)" d="M0 0H24V24H0z" />
          <Path
            d="M24187.393-310.5l5.6-2.055a11.047 11.047 0 002.442.268c4.943 0 8.933-3.186 8.933-7.146s-3.993-7.146-8.935-7.146-8.933 3.186-8.933 7.146a6.571 6.571 0 002.71 5.121z"
            stroke="#080de0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.5}
          />
        </G>
      </G>
    </Svg>
  );
}

export default SMSIcon;
