import { NoteId } from '../notes/noteId';
import { ClientImage } from './clientImage';
import { ClientImageNotePreview } from './clientImageNotePreview';
import { ProClientImagesApi } from './proClientImagesApi';
import { HasId, HttpClient, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const proClientImagesHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): ProClientImagesApi => {
  const { apiBaseUrl, http } = env;

  const uploadClientImageDecoder = http.decode.response(UnknownApiError, ClientImage.JSON);
  const deleteClientImageDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getClientImagesDecoder = http.decode.response(UnknownApiError, t.array(ClientImageNotePreview.JSON));
  const getClientImageByIdDecoder = http.decode.response(UnknownApiError, ClientImageNotePreview.JSON);
  const createNoteForClientImageDecoder = http.decode.response(UnknownApiError, HasId.json(NoteId.JSON));

  return {
    uploadClientImage: async ({ clientId, image, pageId }) => {
      const formData = new FormData();
      const ext = image.blob.type.split('/')[1];

      if (image.platform === 'web') {
        formData.append('image', new File([image.blob], `galleryImage.${ext}`, { type: image.blob.type }));
      } else {
        const uri = image.platform === 'ios' ? image.uri.replace('file://', '') : image.uri;

        formData.append('image', {
          name: `galleryImage.${ext}`,
          type: image.blob.type,
          uri: uri,
        } as any);
      }

      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/clients/${encodeURIComponent(clientId)}/images`,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
          uploadClientImageDecoder,
        ),
      );
    },

    deleteClientImage: async ({ clientId, imageId, pageId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/clients/${encodeURIComponent(
              clientId,
            )}/images/${encodeURIComponent(imageId)}`,
          },
          deleteClientImageDecoder,
        ),
      );
    },

    createNoteForClientImage: async ({ clientId, pageId, imageId, text, appointment }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/clients/${encodeURIComponent(
              clientId,
            )}/images/${encodeURIComponent(imageId)}/note`,
            data: {
              text: text,
              appointment: appointment,
            },
          },
          createNoteForClientImageDecoder,
        ),
      );
    },

    getClientImages: async ({ clientId, pageId, hasNote }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/clients/${encodeURIComponent(clientId)}/images`,
            params: {
              hasNote: hasNote,
            },
          },
          getClientImagesDecoder,
        ),
      );
    },

    getClientImageById: async ({ clientId, pageId, imageId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/clients/${encodeURIComponent(
              clientId,
            )}/images/${encodeURIComponent(imageId)}`,
          },
          getClientImageByIdDecoder,
        ),
      );
    },
  };
};
