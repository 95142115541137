import { colors, sizes } from '@mero/components';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  overlay: { zIndex: 10000, height: '100%' },
  smsTitle: { color: colors.DARK_BLUE },
  whatsappTitle: { color: colors.SHAMROCK },
  cancelTitle: { color: colors.RADICAL_RED },
  modalBorderBottom: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  optionsListContainer: {
    marginTop: 0,
    flexDirection: 'column',
    backgroundColor: colors.WHITE,
    borderTopLeftRadius: sizes[12],
    borderTopRightRadius: sizes[12],
    minHeight: sizes[32],
  },
  optionsMenuItem: {
    paddingLeft: sizes[24],
    paddingRight: sizes[24],
  },
  optionsStretchContainer: {
    flex: 1,
    alignSelf: 'stretch',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent',
  },
  optionRow: {
    alignItems: 'center',
    gap: sizes[12],
  },
});
