import { MergedService, ServiceId } from '@mero/api-sdk/dist/services';
import {
  MeroHeader,
  Spacer,
  Body,
  H1,
  Column,
  Button,
  FormCard,
  Checkbox,
  Line,
  H3s,
  SmallBody,
} from '@mero/components';
import { formatRangeDuration } from '@mero/shared-components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { getPrice } from '../../screens/Authorized/ServicesScreen/ServicesMobileScreen';

import { useMediaQueries } from '../../hooks/useMediaQueries';

import { ServicesGroup } from '../GroupedServicesList';
import ModalScreenContainer from '../ModalScreenContainer';
import { styles } from './styles';

export type SelectServicesScreenProps = {
  readonly groupedServices: ServicesGroup[];
  readonly alreadySelectedServices: ServiceId[];
  readonly onBackPressed?: () => void;
  readonly onSave: (selectedServices: MergedService[]) => void;
};

export const SelectServices: React.FC<SelectServicesScreenProps> = ({
  groupedServices,
  alreadySelectedServices,
  onBackPressed,
  onSave,
}: SelectServicesScreenProps) => {
  const { t } = useTranslation('services');
  const { isPhone } = useMediaQueries();

  const allServices = groupedServices.flatMap((group) => group.services);
  const [selectedServices, setSelectedServices] = React.useState<
    Record<ServiceId, { service: MergedService; selected: boolean }>
  >(
    allServices.reduce((services, service) => {
      return {
        ...services,
        [service._id]: {
          service: service,
          selected: alreadySelectedServices.some((selectedServiceId) => selectedServiceId === service._id),
        },
      };
    }, {}),
  );

  const allServiceIds = allServices.map((service) => service._id);

  const toggleServices = (serviceIds: ServiceId[], newValue?: boolean) => {
    const newSelectedServices = { ...selectedServices };
    serviceIds.forEach((serviceId) => {
      newSelectedServices[serviceId] = {
        service: newSelectedServices[serviceId].service,
        selected: newValue ?? !newSelectedServices[serviceId].selected,
      };
    });
    setSelectedServices(newSelectedServices);
  };

  const totalServicesCount = React.useMemo(
    () => groupedServices.reduce((acc, group) => acc + group.services.length, 0),
    [groupedServices],
  );

  const selectedServicesCount = React.useMemo(
    () => Object.values(selectedServices).filter((v) => v.selected).length,
    [selectedServices],
  );

  const saveChanges = async () => {
    onSave(
      Object.values(selectedServices)
        .filter((value) => value.selected)
        .map((value) => value.service),
    );
  };

  return (
    <>
      <MeroHeader title={t('chooseServices')} canGoBack={onBackPressed !== undefined} onBack={onBackPressed} />
      <ScrollView>
        <Column style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1, paddingBottom: 96 }}>
          <H1 style={{ paddingHorizontal: 8 }}>{t('chooseServices')}</H1>
          <Spacer size={16} />
          {groupedServices.length > 0 && (
            <FormCard rounded>
              <TouchableOpacity
                style={{ flexDirection: 'row', alignItems: 'center' }}
                onPress={() => toggleServices(allServiceIds, totalServicesCount !== selectedServicesCount)}
              >
                <Checkbox
                  value={totalServicesCount === selectedServicesCount}
                  onValueChange={() => toggleServices(allServiceIds, totalServicesCount !== selectedServicesCount)}
                />
                <Body style={{ fontSize: 17, paddingLeft: 12 }}>{`${t('allServices')} (${totalServicesCount})`}</Body>
              </TouchableOpacity>
              <Spacer size={24} />
              <Line />
              {groupedServices.map((groupedService, groupIndex) => {
                const allServicesSelected = groupedService.services.every(
                  (service) => selectedServices[service._id].selected,
                );
                const servicesIds = groupedService.services.map((service) => service._id);
                return (
                  <Column
                    key={groupedService.group.name}
                    style={{
                      paddingTop: 24,
                      paddingBottom: 16,
                      borderBottomWidth: groupIndex < groupedServices.length - 1 ? 1 : 0,
                      borderBottomColor: '#E9ECEF',
                    }}
                  >
                    <TouchableOpacity
                      style={{ flexDirection: 'row', alignItems: 'center' }}
                      onPress={() => toggleServices(servicesIds, !allServicesSelected)}
                    >
                      <Checkbox
                        value={allServicesSelected}
                        onValueChange={() => toggleServices(servicesIds, !allServicesSelected)}
                      />
                      <H3s style={{ paddingLeft: 12 }}>
                        {groupedService.group.name} ({groupedService.services.length})
                      </H3s>
                    </TouchableOpacity>
                    {groupedService.services.map((service) => (
                      <TouchableOpacity
                        key={service._id}
                        style={{ flexDirection: 'row', paddingTop: 24 }}
                        onPress={() => toggleServices([service._id])}
                      >
                        <Checkbox
                          value={selectedServices[service._id].selected}
                          onValueChange={() => toggleServices([service._id])}
                        />
                        <Column>
                          <Body style={{ fontSize: 17, paddingLeft: 12 }}>{service.name}</Body>
                          <SmallBody style={{ paddingLeft: 12 }}>
                            {formatRangeDuration(service.durationInMinutes.from, service.durationInMinutes.to)},{' '}
                            {service.price.type === 'fixed' && service.price?.promo ? (
                              <>
                                <SmallBody>
                                  {service.price.promo} lei{' '}
                                  <SmallBody
                                    style={{
                                      textDecorationLine: 'line-through',
                                      textDecorationStyle: 'solid',
                                    }}
                                  >
                                    ({service.price.fixed} lei)
                                  </SmallBody>
                                </SmallBody>
                              </>
                            ) : (
                              <SmallBody>{getPrice(service.price)}</SmallBody>
                            )}
                          </SmallBody>
                        </Column>
                      </TouchableOpacity>
                    ))}
                  </Column>
                );
              })}
            </FormCard>
          )}
        </Column>
      </ScrollView>
      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button text={t('saveChanges')} onClick={saveChanges} />
          ) : (
            <Button
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={t('saveChanges')}
              onClick={saveChanges}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </>
  );
};

const SelectServicesScreen: React.FC<SelectServicesScreenProps> = (props) => (
  <ModalScreenContainer edges={['left', 'top', 'right']}>
    <SelectServices {...props} />
  </ModalScreenContainer>
);

export default React.memo(SelectServicesScreen);
