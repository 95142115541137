import React from 'react';
import { Svg, Path } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

const Camera = ({ color, size = 24 }: IconProps) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M18.75 8.5H15.65L14 6.5H10L8.35 8.5H5.25C5.05109 8.5 4.86032 8.57902 4.71967 8.71967C4.57902 8.86032 4.5 9.05109 4.5 9.25V16.75C4.5 16.9489 4.57902 17.1397 4.71967 17.2803C4.86032 17.421 5.05109 17.5 5.25 17.5H18.75C18.9489 17.5 19.1397 17.421 19.2803 17.2803C19.421 17.1397 19.5 16.9489 19.5 16.75V9.25C19.5 9.05109 19.421 8.86032 19.2803 8.71967C19.1397 8.57902 18.9489 8.5 18.75 8.5Z"
        stroke={color || '#222'}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12 16C13.933 16 15.5 14.433 15.5 12.5C15.5 10.567 13.933 9 12 9C10.067 9 8.5 10.567 8.5 12.5C8.5 14.433 10.067 16 12 16Z"
        stroke={color || '#222'}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M17.5 10.75C17.6381 10.75 17.75 10.6381 17.75 10.5C17.75 10.3619 17.6381 10.25 17.5 10.25C17.3619 10.25 17.25 10.3619 17.25 10.5C17.25 10.6381 17.3619 10.75 17.5 10.75Z"
        stroke={color || '#222'}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12 14C12.8284 14 13.5 13.3284 13.5 12.5C13.5 11.6716 12.8284 11 12 11C11.1716 11 10.5 11.6716 10.5 12.5C10.5 13.3284 11.1716 14 12 14Z"
        stroke={color || '#222'}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Camera;
