export type InvoiceTracking = {
  readonly series: string;
  readonly subseries: string;
  readonly number: number;
};

const renderName = (tracking: InvoiceTracking) => {
  return `${tracking.series} / ${tracking.subseries} ${tracking.number}`;
};

export const InvoiceTracking = {
  renderName,
};
