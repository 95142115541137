import React from 'react';
import { Svg, Path, G, Circle } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

const BankTransfer = ({ color, size = 32 }: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 32 32">
    <G data-name="Group 7661" transform="translate(-15244 5765)">
      <Circle cx={16} cy={16} r={16} fill="#e9ecef" data-name="Ellipse 688" transform="translate(15244 -5765)" />
      <G fill="none" data-name="Group 7656">
        <Path d="M15249-5760h22v22h-22Z" data-name="Path 8275" />
        <G stroke="#52577f" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10}>
          <Path d="m15258.513-5752.835 2.445-2.447a.933.933 0 0 1 1.311 0l.328.328m-2.219 2.22 1.335 1.336" />
          <Path
            d="M15264.261-5753.945a.958.958 0 0 0-1.336-1.336l-7.663 7.638-.807 2.168 2.168-.832 7.638-7.638Z"
            data-name="layer2"
          />
          <Path d="M15261.338-5751.021h6.226v8.571h-15.126v-8.571h6.226m6.882 4.033h-4.008m4.008 2.017h-6.529" />
        </G>
      </G>
    </G>
  </Svg>
);

export default BankTransfer;
