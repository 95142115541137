import { AppErrorObject } from './appErrorObject';
import { JSONable } from './jsonable';
import * as t from 'io-ts';

/**
 * Base application error class
 */
export class AppError<Cause> extends Error {
  public readonly message: string;
  public readonly originalCause: Cause;

  constructor(message: string, originalCause: Cause) {
    super(message);
    this.message = message;
    this.originalCause = originalCause;
  }

  toJSON(): JSONable {
    return AppError.toObject(this);
  }

  public static toObject(error: Error, keepStack = false): JSONable {
    return Object.fromEntries(
      [['.constructorName', error.constructor.name]].concat(
        Object.getOwnPropertyNames(error).map((pn) => {
          // hide stack trace
          if (!keepStack && pn === 'stack') {
            return [pn, undefined];
          }

          const pd = Object.getOwnPropertyDescriptor(error, pn);
          const value = pd?.get?.() ?? pd?.value;

          return [pn, value instanceof Error ? AppError.toObject(value, keepStack) : value];
        }),
      ),
    );
  }

  static json<Cause>(cause: t.Type<Cause, JSONable>): t.Type<AppError<Cause>, JSONable> {
    return AppErrorObject.json(cause).pipe(
      new t.Type<AppError<Cause>, AppErrorObject<Cause>, AppErrorObject<Cause>>(
        `AppError<${cause.name}>`,
        (e): e is AppError<Cause> => {
          return e instanceof AppError && cause.is(e.originalCause);
        },
        (e) => {
          return t.success(new AppError(e.message, e.originalCause));
        },
        (e) => {
          return {
            message: e.message,
            originalCause: e.originalCause,
          };
        },
      ),
    );
  }
}

/**
 * Builds new AppError<Cause> type guard using given [isCause] type guard
 */
export const isAppError = <Cause>(isCause: (c: unknown) => c is Cause) => {
  return (e: unknown): e is AppError<Cause> => {
    return e instanceof AppError && isCause(e.originalCause);
  };
};
