import { enumType } from '../common';
import { OrderId } from './order-id';
import { SubscriptionId } from './subscription-id';
import { SubscriptionTier } from './subscriptionTier';
import { BillingCompanyId, MeroMoney, MeroUnits, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

enum OrderTypesEnum {
  novabooker,
  merchant,
}

const OrderTypes = enumType<OrderTypesEnum>(OrderTypesEnum);

enum OrderStatusEnum {
  pending = 0,
  complete = 1,
  cancelled = 2,
  refund = 3,
  deleted = 4,
  completing = 5,
}

const OrderStatus = enumType<OrderStatusEnum>(OrderStatusEnum);

const Currency = t.union([t.literal('EUR'), t.literal('RON')]);

const OrderTracking = t.type(
  {
    series: t.string,
    subseries: t.string,
    number: t.number,
  },
  'OrderTracking',
);

export enum ProductScopeEnum {
  base = 0,
  workerSeat = 1,
  workerSeatProRata = 2,
  smsNotifications = 3,
  outstandingBalance = 4,
  boost = 5,
}

const ProductScope = enumType<ProductScopeEnum>(ProductScopeEnum);

const OrderProduct = t.type(
  {
    name: t.string,
    pcode: t.string,
    price: t.number,
    scope: ProductScope,
    isPriceEur: Option.json(t.boolean),
    tier: Option.json(SubscriptionTier.JSON),
    seatCount: Option.json(t.number),
  },
  'OrderProduct',
);

const OrderLineItem = t.type(
  {
    product: OrderProduct,
    quantity: t.number,
  },
  'OrderLineItem',
);

export const Order = t.type(
  {
    _id: OrderId,
    type: OrderTypes,
    createdAt: DateFromISOString,
    status: OrderStatus,
    subscriptionId: SubscriptionId,
    discountPercent: t.number,
    meroCompany: BillingCompanyId.JSON,
    /**
     * Order value before discount or VAT applied
     */
    subtotal: Option.json(t.number),
    /**
     * Order final value (to be paid by user), with discount and VAT applied
     */
    total: t.number,
    totalRON: Option.json(MeroMoney.json(MeroUnits.RON.code)),
    /**
     * Discount value when applied to total (includes VAT)
     */
    subtotalDiscount: Option.json(t.number),
    tracking: OrderTracking,
    ronEurExchangeRate: t.number,
    currency: Currency,
    items: t.array(OrderLineItem),
  },
  'Order',
);

export type Order = t.TypeOf<typeof Order>;
