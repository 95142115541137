import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type PurchasedMembershipSource =
  | {
      readonly type: 'Online';
      readonly platform: 'Marketplace' | 'Tazz';
    }
  | {
      readonly type: 'Offline';
    };

const JSON: t.Type<PurchasedMembershipSource, JSONable> = t.union(
  [
    t.type(
      {
        type: t.literal('Online'),
        platform: t.union([t.literal('Marketplace'), t.literal('Tazz')], 'Platform'),
      },
      'Online',
    ),
    t.type(
      {
        type: t.literal('Offline'),
      },
      'Offline',
    ),
  ],
  'PurchasedMembershipSource',
);

export const PurchasedMembershipSource = {
  JSON,
};
