import {
  ApiError,
  apiError,
  dateStringUtils,
  DefinedString,
  Email,
  HasOptionalLastname,
  StrictPhoneNumber,
  UndefinedString,
} from '@mero/api-sdk';
import { ClientId } from '@mero/api-sdk/dist/clients';
import { Firstname } from '@mero/api-sdk/dist/common';
import { PageId } from '@mero/api-sdk/dist/pages';
import { createModelContext } from '@mero/components';
import * as t from 'io-ts';
import * as React from 'react';

import log from '../../utils/log';
import { meroApi } from '../AuthContext';

export const OptionalRemark = t.union([DefinedString, UndefinedString], 'OptionalNotes');
export type OptionalRemark = t.TypeOf<typeof OptionalRemark>;

export const DateCodec: t.Type<Date> = new t.Type<Date>(
  'DateCodec', // A name for the codec
  (input: unknown): input is Date => input instanceof Date,
  (input, context) => (input instanceof Date ? t.success(input) : t.failure(input, context)),
  t.identity, // For encoding, we can just use the identity function
);

export const ClientDetails = t.intersection(
  [
    t.type({
      firstname: Firstname,
      phoneNumber: StrictPhoneNumber,
      showRemarkOnCalendar: t.boolean,
    }),
    HasOptionalLastname,
    t.partial({
      remark: OptionalRemark,
      email: Email.JSON,
      birthday: DateCodec,
    }),
  ],
  'ClientDetails',
);

export type ClientDetails = t.TypeOf<typeof ClientDetails>;

type ClientCreateContextState =
  | {
      readonly type: 'Ready';
    }
  | {
      readonly type: 'Creating';
      readonly pageId: PageId;
      readonly details: ClientDetails;
    }
  | {
      readonly type: 'Created';
      readonly pageId: PageId;
      readonly details: ClientDetails;
      readonly clientId: ClientId;
    }
  | {
      readonly type: 'Failed';
      readonly error?: ApiError<unknown>;
    };

const defaultState = (): ClientCreateContextState => ({ type: 'Ready' });

export const ClientCreateContext = createModelContext(
  defaultState(),
  {
    tryReset: (state) => {
      if (state.type === 'Created' || state.type === 'Failed') {
        return {
          type: 'Ready',
        };
      }

      return state;
    },
    setCreating: (
      _,
      payload: {
        pageId: PageId;
        details: ClientDetails;
      },
    ) => ({
      type: 'Creating',
      ...payload,
    }),
    setCreated: (_, payload: { pageId: PageId; details: ClientDetails; clientId: ClientId }) => ({
      type: 'Created',
      ...payload,
    }),
    setFailed: (_, payload: { error?: ApiError<unknown> }) => ({
      type: 'Failed',
      ...payload,
    }),
  },
  (dispatch) => {
    return {
      createClient: (payload: { pageId: PageId; details: ClientDetails }): void => {
        const create = async () => {
          try {
            log.debug(
              `going to create new client with phone number "${payload.details.phoneNumber}" for page ${payload.pageId}`,
            );
            dispatch.setCreating(payload);
            const clientId = await meroApi.clients.createClientByPhone({
              pageId: payload.pageId,
              phone: payload.details.phoneNumber,
              firstname: payload.details.firstname,
              lastname: payload.details.lastname,
              // showRemarkOnCalendar: payload.details.showRemarkOnCalendar,
              // TODO: check if this is needed
              email: payload.details.email,
              birthday: payload.details.birthday ? dateStringUtils.fromDate(payload.details.birthday) : undefined,
            });
            log.debug(
              `new client with with id: ${clientId} created by phone number "${payload.details.phoneNumber}" for page ${payload.pageId}`,
            );
            dispatch.setCreated({
              ...payload,
              clientId,
            });
          } catch (e) {
            log.exception(e);

            if (apiError(t.unknown).is(e)) {
              dispatch.setFailed({
                error: e,
              });
            } else {
              dispatch.setFailed({});
            }
          }
        };

        create().catch(log.exception);
      },
      tryReset: dispatch.tryReset,
    };
  },
);

export const ClientCreateContextProvider: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  return <ClientCreateContext.Provider>{children}</ClientCreateContext.Provider>;
};

export const withClientCreateContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithClientCreateContextProvider(props: P) {
    return (
      <ClientCreateContextProvider>
        <Content {...props} />
      </ClientCreateContextProvider>
    );
  };
};
