import { StyleSheet } from 'react-native';

import { colors, sizes } from '../../../../../../styles';

const styles = StyleSheet.create({
  card: {
    padding: sizes[16],
  },
  container: { alignItems: 'flex-start' },
  iconContainer: { marginRight: sizes[12] },
  dateWrapper: { flex: 1 },
  dotsButton: { width: 24, height: 24, alignSelf: 'baseline' },
  textWrapper: { paddingLeft: 44 },
  author: {
    marginTop: 4,
    fontFamily: 'open-sans',
    fontSize: 12,
    lineHeight: 17,
    color: colors.COMET,
  },
  imagesWrapper: {
    marginTop: 8,
  },
  icon: {
    width: 32,
    height: 32,
    borderRadius: 99,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconActive: {
    backgroundColor: '#EAF4FF',
  },
  iconInactive: {
    backgroundColor: colors.ATHENS_GRAY,
  },
  pinnedText: {
    fontFamily: 'open-sans-semibold',
    fontSize: 10,
    color: '#3193F8',
    textTransform: 'uppercase',
  },
});

export default styles;
