import { NoteDetails } from './noteDetails';
import { NoteId } from './noteId';
import { ProNotesApi } from './proNotesApi';
import { HasId, HttpClient, Paged, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const proNotesHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): ProNotesApi => {
  const { apiBaseUrl, http } = env;

  const createNoteDecoder = http.decode.response(UnknownApiError, HasId.json(NoteId.JSON));
  const updateNoteDecoder = http.decode.response(UnknownApiError, t.unknown);
  const deleteNoteDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getClientNotesDecoder = http.decode.response(UnknownApiError, Paged.json(t.array(NoteDetails.JSON)));
  const getClientNoteByIdDecoder = http.decode.response(UnknownApiError, NoteDetails.JSON);

  return {
    createClientNote: async ({ clientId, text, imageIds, appointment, pageId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/clients/${encodeURIComponent(clientId)}/notes`,
            data: {
              text: text,
              imageIds: imageIds,
              appointment: appointment,
            },
          },
          createNoteDecoder,
        ),
      );
    },
    createAppointmentNote: async ({ text, imageIds, appointmentId, occurrenceIndex, pageId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/appointments/${encodeURIComponent(
              appointmentId,
            )}/notes`,
            data: {
              text: text,
              imageIds: imageIds,
            },
            params: {
              occurrenceIndex: occurrenceIndex,
            },
          },
          createNoteDecoder,
        ),
      );
    },
    getClientNotes: async ({ clientId, pageId, limit, page }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/clients/${encodeURIComponent(clientId)}/notes`,
            params: {
              limit: limit,
              page: page,
            },
          },
          getClientNotesDecoder,
        ),
      );
    },
    getClientNoteById: async ({ clientId, pageId, noteId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/clients/${encodeURIComponent(
              clientId,
            )}/notes/${encodeURIComponent(noteId)}`,
          },
          getClientNoteByIdDecoder,
        ),
      );
    },
    updateNoteIsPinned: async ({ pageId, noteId, clientId, isPinned }) => {
      unsafeRight(
        await http.request(
          {
            method: 'PATCH',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/clients/${encodeURIComponent(
              clientId,
            )}/notes/${encodeURIComponent(noteId)}/pin`,
            params: {
              isPinned,
            },
          },
          updateNoteDecoder,
        ),
      );
    },
    updateClientNote: async ({ noteId, clientId, text, imageIds, appointment, pageId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/clients/${encodeURIComponent(
              clientId,
            )}/notes/${encodeURIComponent(noteId)}`,
            data: {
              text: text,
              imageIds: imageIds,
              appointment: appointment,
            },
          },
          updateNoteDecoder,
        ),
      );
    },
    updateAppointmentNote: async ({ noteId, text, imageIds, appointmentId, occurrenceIndex, pageId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/appointments/${encodeURIComponent(
              appointmentId,
            )}/notes/${encodeURIComponent(noteId)}`,
            data: {
              text: text,
              imageIds: imageIds,
            },
            params: {
              occurrenceIndex: occurrenceIndex,
            },
          },
          updateNoteDecoder,
        ),
      );
    },
    deleteClientNote: async ({ noteId, pageId, clientId, deleteNoteImages }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/clients/${encodeURIComponent(
              clientId,
            )}/notes/${encodeURIComponent(noteId)}`,
            params: {
              deleteNoteImages: deleteNoteImages,
            },
          },
          deleteNoteDecoder,
        ),
      );
    },
    deleteAppointmentNote: async ({ noteId, pageId, appointmentId, occurrenceIndex }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/appointments/${encodeURIComponent(
              appointmentId,
            )}/notes/${encodeURIComponent(noteId)}`,
            params: {
              occurrenceIndex: occurrenceIndex,
            },
          },
          deleteNoteDecoder,
        ),
      );
    },
    deleteNoteImageRef: async ({ noteId, pageId, clientId, imageId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/clients/${encodeURIComponent(
              clientId,
            )}/notes/${encodeURIComponent(noteId)}/images/${encodeURIComponent(imageId)}`,
          },
          deleteNoteDecoder,
        ),
      );
    },
  };
};
