import { PageId } from '../../pages';
import { CustomFieldId } from '../customFieldId';
import { SelectionOption } from '../selectionOption';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type SingleSelection = {
  readonly _id: CustomFieldId;
  readonly pageId: PageId;
  readonly name: string;
  readonly required: boolean;
  readonly type: 'SingleSelection';
  readonly options: SelectionOption[];
};

const JSON: t.Type<SingleSelection, JSONable> = t.strict(
  {
    _id: CustomFieldId.JSON,
    pageId: PageId,
    name: t.string,
    required: t.boolean,
    type: t.literal('SingleSelection'),
    options: t.array(SelectionOption.JSON),
  },
  'SingleSelection',
);

export const SingleSelection = {
  JSON,
};
