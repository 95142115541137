import { SelectionOption } from '../selectionOption';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type SingleSelection = {
  readonly name: string;
  readonly required: boolean;
  readonly type: 'SingleSelection';
  readonly options: SelectionOption[];
};

const JSON: t.Type<SingleSelection, JSONable> = t.strict(
  {
    name: t.string,
    required: t.boolean,
    type: t.literal('SingleSelection'),
    options: t.array(SelectionOption.JSON),
  },
  'SingleSelection',
);

export const SingleSelection = {
  JSON,
};
