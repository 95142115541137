import { MembershipPurchaseId, MembershipTemplateId } from '../../memberships';
import { MarketplaceOnlineSalesApi } from './marketplaceOnlineSalesApi';
import { HttpClient, unsafeRight, UnknownApiError } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const marketplaceOnlineSalesHttpClient = (env: {
  apiBaseUrl: string;
  http: HttpClient;
}): MarketplaceOnlineSalesApi => {
  const { apiBaseUrl, http } = env;

  const unknownError = UnknownApiError;
  const purchaseMembershipDecoder = http.decode.response(
    unknownError,
    t.type({
      _id: MembershipPurchaseId.JSON,
    }),
  );

  return {
    purchaseMembership: async ({ pageId, membershipTemplateId, paymentMethodId }) => {
      const requestBody: {
        membershipTemplateId: MembershipTemplateId;
        paymentMethodId: string;
      } = {
        membershipTemplateId: membershipTemplateId,
        paymentMethodId: paymentMethodId,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/mp/page/${encodeURIComponent(pageId)}/purchase-membership`,
            data: requestBody,
          },
          purchaseMembershipDecoder,
        ),
      );
    },
  };
};
