import { StyleSheet } from 'react-native';

import { colors, sizes } from '../../../styles';

const styles = StyleSheet.create({
  root: { backgroundColor: colors.ALABASTER },
  container: {
    paddingTop: sizes[16],
    paddingHorizontal: sizes[24],
  },
  unselectedOverlay: {
    width: 24,
    height: 24,
    borderRadius: 12,
    borderWidth: 1.5,
    borderColor: colors.WHITE,
  },
  noPhotosBody: {
    textAlign: 'center',
    color: colors.COMET,
  },
  contentContainer: {
    paddingBottom: 120,
  },
  imageOverlay: {
    borderRadius: 12,
    position: 'absolute',
    top: 0,
    // left: 0,
    right: 0,
  },
  checkButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    height: 24,
    width: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },

  emptyContainer: {
    flex: 1,
    paddingHorizontal: 56,
  },
  emptyButton: { marginHorizontal: 35, flex: 1 },
  header: {
    gap: sizes[16],
  },
  image: {
    borderRadius: 12,
    overflow: 'hidden',
    position: 'relative',
    borderWidth: 1,
    borderColor: '#EEF0F2',
  },
  footer: { position: 'absolute', left: 0, right: 0, bottom: 0 },
  content: {
    marginTop: sizes[24],
    // padding: sizes[16],
  },
  footerButton: { alignSelf: 'center' },
  modalBorderBottom: {
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  closeIconWrapper: {
    height: 32,
    width: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: `rgba(0, 0, 0, 0.5)`,
  },
  closeIconContainer: {
    position: 'absolute',
    top: 4,
    bottom: 0,
    right: 4,
    borderRadius: 4,
    zIndex: 1,
  },
});

export default styles;
