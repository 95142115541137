import { PageId } from '../pages';
import { OnlineSaleSettings } from '../pro';
import { ServiceId } from '../services';
import { MembershipItem } from './membershipItem';
import { MembershipTemplateId } from './membershipTemplateId';
import { MembershipTemplateValidity } from './membershipTemplateValidity';
import {
  AppliedDiscount,
  AppliedDiscountScaledNumber,
  DefinedString,
  MeroCurrency,
  MeroUnits,
  Money,
  OptionalDefinedString,
  ScaledNumber,
} from '@mero/shared-sdk';

const MoneyScaledNumber = Money.build(ScaledNumber, MeroUnits);

export type MembershipTemplate<Unit extends MeroUnits.Any> = {
  readonly _id: MembershipTemplateId;
  readonly unit: Unit;
  readonly pageId: PageId;
  readonly name: DefinedString;
  readonly validFor: MembershipTemplateValidity;
  readonly items: MembershipItem<Unit>[];
  readonly status: 'Active' | 'Inactive' | 'Deleted';
  readonly discount: AppliedDiscount<ScaledNumber, Unit> | undefined;
  readonly description: OptionalDefinedString;
  readonly termsAndConditions: OptionalDefinedString;
  readonly onlineSaleSettings: OnlineSaleSettings;
};

/**
 * Returns membership selling price, with discount applied (if defined)
 */
const getSellingPrice = <Unit extends MeroUnits.Any>(
  membershipTemplate: Pick<MembershipTemplate<Unit>, 'items' | 'discount' | 'unit'>,
): Money<ScaledNumber, Unit> => {
  const zero = MoneyScaledNumber.zero(membershipTemplate.unit);
  const totalPrice = membershipTemplate.items.reduce(
    (acc: Money<ScaledNumber, Unit>, item) =>
      MoneyScaledNumber.add(acc, MembershipItem.getSellingPrice(item, membershipTemplate.unit)),
    zero,
  );

  if (membershipTemplate.discount) {
    return AppliedDiscountScaledNumber.applyTo(
      totalPrice,
      membershipTemplate.discount,
      MeroCurrency[membershipTemplate.unit].exponent,
    );
  }

  return totalPrice;
};

/**
 * Get item effective price from given MembershipTemplate
 *
 * Effective price is the real money amount user paid for given item (with all discounts applied)
 */
const getItemEffectivePrice = <Unit extends MeroUnits.Any>(
  template: Pick<MembershipTemplate<Unit>, 'items' | 'discount' | 'unit'>,
  //TODO: add support for products
  item: {
    readonly _id: ServiceId;
    readonly type: 'Service';
  },
): Money<ScaledNumber, Unit> => {
  const zero = MoneyScaledNumber.zero(template.unit);
  const templateItem = template.items.find(
    (templateItem) => templateItem.type === item.type && templateItem.service._id === item._id,
  );
  const unit = template.unit;

  if (templateItem) {
    switch (templateItem.type) {
      case 'Service': {
        if (templateItem.quantity.type === 'Unlimited') {
          return zero;
        } else {
          let price = MembershipItem.getSellingPrice(templateItem, template.unit);
          if (template.discount) {
            if (template.discount.type === 'Percent') {
              price = AppliedDiscountScaledNumber.applyTo(
                price,
                template.discount,
                MeroCurrency[template.unit].exponent,
              );
            } else {
              /**
               * Get template item discount from template total discount
               * proportional to the item value from template total price
               * Formula: price * (discount / (templatePrice + discount))
               */
              const templatePrice = getSellingPrice(template);
              const templateDiscount = template.discount.value;
              const itemDiscount = AppliedDiscount.value(
                MoneyScaledNumber.div(
                  MoneyScaledNumber.mul(price, templateDiscount.amount),
                  ScaledNumber.add(templatePrice.amount, templateDiscount.amount),
                  MeroCurrency[unit].exponent,
                ),
              );
              price = AppliedDiscountScaledNumber.applyTo(price, itemDiscount, MeroCurrency[template.unit].exponent);
            }
          }

          return MoneyScaledNumber.div(
            price,
            ScaledNumber.fromInteger(templateItem.quantity.value),
            MeroCurrency[unit].exponent,
          );
        }
      }
      case 'Product': {
        return zero;
      }
    }
  } else {
    throw new Error(`Could not find membership template item ${item.type} ${item._id}`);
  }
};

const isDiscountValid = <Unit extends MeroUnits.Any>(membershipTemplate: MembershipTemplate<Unit>): boolean => {
  if (!membershipTemplate.discount) {
    return true;
  }

  if (membershipTemplate.discount.type === 'Percent') {
    return true;
  }

  const total = membershipTemplate.items.reduce(
    (sum, item) => MoneyScaledNumber.add(sum, MembershipItem.getSellingPrice(item, membershipTemplate.unit)),
    MoneyScaledNumber.zero(membershipTemplate.unit),
  );

  if (ScaledNumber.lessThan(total.amount, membershipTemplate.discount.value.amount)) {
    return false;
  }

  return true;
};

export const MembershipTemplate = {
  getSellingPrice,
  getItemEffectivePrice,
  isDiscountValid,
};
