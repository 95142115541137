import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';

import config from '../config';
import log from '../utils/log';

type GoogleAnalytics = {
  init: () => void;
  logEvent: (args: UaEventOptions & Record<string, unknown>) => void;
};

export const googleAnalytics = ((): GoogleAnalytics => {
  let initialized = false;

  let _logEvent: GoogleAnalytics['logEvent'] = (): void => {
    log.debug('Google Analytics logEvent');
  };

  const _init = () => {
    if (!initialized) {
      log.debug(`Google Analytics init ${config.google.analytics}`);
      ReactGA.initialize(config.google.analytics);
      ReactGA.gtag('consent', 'update', {
        analytics_storage: 'granted',
        functionality_storage: 'granted',
      });
      initialized = true;

      _logEvent = (args): void => {
        log.debug('Google Analytics w/ consent', args);
        const { action, category, label, value, nonInteraction, transport, ...rest } = args;

        ReactGA.event({ action, category, label, value, nonInteraction, transport }, rest);
      };
    }
  };
  return {
    init: () => _init(),
    logEvent: (...args) => _logEvent(...args),
  };
})();
