import { AppointmentId } from '../calendar/appointment-id';
import { NoteDetails } from '../pro';
import { SavedClient } from './saved-client';
import { Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const ClientProfile = t.intersection(
  [
    SavedClient,
    t.type(
      {
        boostStatus: t.union(
          [
            t.type(
              {
                /**
                 * Not a boost client
                 */
                isBoost: t.literal(false),
              },
              'ClientProfileBoostStatusNotBoost',
            ),
            t.intersection(
              [
                t.type(
                  {
                    /**
                     * This is a boost client
                     */
                    isBoost: t.literal(true),
                    /**
                     * Client boost details must be hidden (like phone)
                     */
                    hideBoostDetails: t.boolean,
                    /**
                     * Merchant can send claim request for this client
                     */
                    canClaim: t.boolean,
                    /**
                     * There is a valid pending claim for this client
                     */
                    isClaimPending: t.boolean,
                    /**
                     * Merchant can accept commission for this client
                     */
                    canAcceptCommission: t.boolean,
                  },
                  '!',
                ),
                t.partial(
                  {
                    /**
                     * Boost commission vaule
                     */
                    boostCost: t.number,
                    /**
                     * Client profit value (total value minus boost commission)
                     */
                    boostProfit: t.number,
                    /**
                     * ID of Boost appointment that converted this client
                     */
                    boostAppointmentId: AppointmentId,
                  },
                  '?',
                ),
              ],
              'ClientProfileBoostStatusBoost',
            ),
          ],
          'ClientProfileBoostStatus',
        ),
        pinnedNote: Option.json(NoteDetails.JSON),
      },
      '!',
    ),
  ],
  'ClientProfile',
);

export interface ClientProfile extends t.TypeOf<typeof ClientProfile> {}
