import { ImageUpload } from './imageUpload';
import { MultipleSelection } from './multipleSelection';
import { ShortAnswear } from './shortAnswear';
import { SingleSelection } from './singleSelection';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Any = ImageUpload | ShortAnswear | SingleSelection | MultipleSelection;

const JSON: t.Type<Any, JSONable> = t.union(
  [ImageUpload.JSON, MultipleSelection.JSON, ShortAnswear.JSON, SingleSelection.JSON],
  'CustomFieldResponse',
);

export const Any = {
  JSON,
};
