import { AppointmentCustomFields, CreateCustomField, ServicesCustomFieldsResult } from '../../customFields';
import { ServiceId } from '../../services';
import { MarketplaceCustomFieldsApi } from './index';
import { HttpClient, unsafeRight, UnknownApiError, optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const marketplaceCustomFieldsHttpClient = (env: {
  apiBaseUrl: string;
  http: HttpClient;
}): MarketplaceCustomFieldsApi => {
  const { apiBaseUrl, http } = env;

  const unknownError = UnknownApiError;

  const getCustomFieldsForServicesDecoder = http.decode.response(unknownError, ServicesCustomFieldsResult.JSON);
  const getAppointmentCustomFieldsDecoder = http.decode.response(
    unknownError,
    t.type({
      appointmentCustomFields: optionull(AppointmentCustomFields.JSON),
    }),
  );
  const createAppointmentCustomFieldsDecoder = http.decode.response(unknownError, AppointmentCustomFields.JSON);
  const updateAppointmentCustomFieldsDecoder = http.decode.response(unknownError, AppointmentCustomFields.JSON);
  const uploadCustomFieldImageDecoder = http.decode.response(
    unknownError,
    t.type({
      _id: t.string,
    }),
  );
  const deleteCustomFieldImageDecoder = http.decode.response(unknownError, t.unknown);

  return {
    getCustomFieldsForServices: async ({ pageId, serviceIds }) => {
      const requestBody: {
        readonly serviceIds: ServiceId[];
      } = {
        serviceIds: serviceIds,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/mp/page/${encodeURIComponent(pageId)}/services-custom-fields`,
            data: requestBody,
          },
          getCustomFieldsForServicesDecoder,
        ),
      );
    },
    getAppointmentCustomFields: async ({ appointmentId, occurrenceIndex }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/mp/appointment/${encodeURIComponent(appointmentId)}/custom-fields`,
            params: {
              occurrenceIndex: occurrenceIndex,
            },
          },
          getAppointmentCustomFieldsDecoder,
        ),
      );
    },
    createAppointmentCustomFields: async ({ appointmentId, occurrenceIndex, services }) => {
      const requestBody: {
        readonly occurrenceIndex: number | undefined;
        readonly services: {
          readonly _id: ServiceId;
          readonly fields: CreateCustomField.Any[];
        };
      } = {
        occurrenceIndex: occurrenceIndex,
        services: services,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/mp/appointment/${encodeURIComponent(appointmentId)}/custom-fields`,
            data: requestBody,
          },
          createAppointmentCustomFieldsDecoder,
        ),
      );
    },
    updateAppointmentCustomFields: async ({ appointmentId, occurrenceIndex, services }) => {
      const requestBody: {
        readonly occurrenceIndex: number | undefined;
        readonly services: {
          readonly _id: ServiceId;
          readonly fields: CreateCustomField.Any[];
        };
      } = {
        occurrenceIndex: occurrenceIndex,
        services: services,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/mp/appointment/${encodeURIComponent(appointmentId)}/custom-fields`,
            data: requestBody,
          },
          updateAppointmentCustomFieldsDecoder,
        ),
      );
    },
    uploadCustomFieldImage: async ({ pageId, customFieldId, image }) => {
      const formData = new FormData();
      const ext = image.blob.type.split('/')[1];

      if (image.platform === 'web') {
        formData.append('image', new File([image.blob], `customFieldImage.${ext}`, { type: image.blob.type }));
      } else {
        const uri = image.platform === 'ios' ? image.uri.replace('file://', '') : image.uri;

        formData.append('image', {
          name: `customFieldImage.${ext}`,
          type: image.blob.type,
          uri: uri,
        } as any);
      }

      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/mp/page/${encodeURIComponent(pageId)}/custom-field/${encodeURIComponent(
              customFieldId,
            )}/image`,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: formData,
          },
          uploadCustomFieldImageDecoder,
        ),
      );
    },
    deleteCustomFieldImage: async ({ pageId, customFieldId, imageId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/mp/page/${encodeURIComponent(pageId)}/custom-field/${encodeURIComponent(
              customFieldId,
            )}/image/${encodeURIComponent(imageId)}`,
          },
          deleteCustomFieldImageDecoder,
        ),
      );
    },
  };
};
