import { MarketplaceSearchQuery } from '../marketplaceSearchQuery';
import { JSONable, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

/**
 * Search for given query returned no results
 */
export type NoResults = {
  readonly type: 'NoResults';
  readonly query: MarketplaceSearchQuery;
  readonly customMessage: Option<string>;
};

const JSON: t.Type<NoResults, JSONable> = t.type({
  type: t.literal('NoResults'),
  query: MarketplaceSearchQuery.JSON,
  customMessage: Option.json(t.string),
});

export const NoResults = {
  JSON,
};
