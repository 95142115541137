import {
  CheckoutTransactionId,
  ClientImage,
  ClientImageId,
  DateString,
  Email,
  NoteDetails,
  NoteId,
  PageFeedbackScoreCounts,
  PageId,
  PhoneNumber,
  PublicFeedbackDetailsWithAppointment,
  PurchasedMembership,
  WorkerId,
} from '@mero/api-sdk';
import { UserAppointment } from '@mero/api-sdk/dist/calendar';
import { ClientHistoryRecord, ClientId, ClientProfile, SavedClient } from '@mero/api-sdk/dist/clients';
import { PageClientStats } from '@mero/api-sdk/dist/clients/page-client-stats';
import { ClientImageNotePreview } from '@mero/api-sdk/dist/pro/clientImages/clientImageNotePreview';
import {
  FormCard,
  MeroHeader,
  Title,
  styles as meroStyles,
  Spacer,
  Avatar,
  HSpacer,
  H1,
  Button,
  colors,
  SmallBody,
  Label,
  Column,
  Row,
  useShowError,
  SafeAreaView,
} from '@mero/components';
import { MeroUnits, Option } from '@mero/shared-sdk';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity, ScrollView, Linking, ScrollViewProps } from 'react-native';
import Svg, { SvgProps, G, Path, Ellipse } from 'react-native-svg';

import ClaimDialog from '../../MenuScreen/screens/MarketingSettingsScreen/ClaimDialog';
import { styles } from '../../MenuScreen/screens/PageOnlinePaymentsSettingsScreen/styles';
import DeleteReplyDialog from '../../MenuScreen/screens/PageReviewsScreen/DeleteReplyDialog';
import ReplyDialog from '../../MenuScreen/screens/PageReviewsScreen/ReplyDialog';
import ReviewMenu from '../../MenuScreen/screens/PageReviewsScreen/ReviewMenu';
import SelectStarsFilterMenu from '../../MenuScreen/screens/PageReviewsScreen/SelectStarsFilterMenu';

import ClientBlockedLabel from '../../../../components/ClientBlockedLabel';
import ClientBoostedLabel from '../../../../components/ClientBoostedLabel';
import ClientNoteCard from '../../../../components/ClientNoteCard';
import ClientWarnedLabel from '../../../../components/ClientWarnedLabel';
import ModalScreenContainer from '../../../../components/ModalScreenContainer';
import ScrollableSwitch from '../../../../components/ScrollableSwitch';
import GalleryPickerOptions from '../../NoteDetailsScreen/components/GalleryPickerOptions/ClientNoteOptionsModal';
import ClientAppointmentsListView from '../components/ClientAppointmentsListView';
import ClientCallOptionsModal from '../components/ClientCallOptionsModal';
import ClientConfirmBlockModal from '../components/ClientConfirmBlockModal';
import ClientConfirmDeleteModal from '../components/ClientConfirmDeleteModal';
import ClientConfirmNoteDeleteModal from '../components/ClientConfirmNoteDeleteModal';
import ClientGalleryList from '../components/ClientGalleryList';
import ClientHistoryListView from '../components/ClientHistoryListView';
import ClientMembershipsListView from '../components/ClientMembershipsListView';
import ClientNoteOptionsModal from '../components/ClientNoteOptionsModal';
import ClientNotesListView from '../components/ClientNotesListView';
import ClientOptionsModal from '../components/ClientOptionsModal';
import ClientReviewsList from '../components/ClientReviewsList';
import ClientTransactionsList, { FinishedTransaction } from '../components/ClientTransactionsList';
import ClientViewCommissionModal from '../components/ClientViewCommissionModal';
import H3s from '@mero/components/lib/components/Text/H3s';

import { useIsFocused } from '@react-navigation/native';

import { useMediaQueries } from '../../../../hooks/useMediaQueries';
import { StarFilter, STARS_FILTERS, useReviews } from '../../../../hooks/useReviews';
import { useImageUpload } from '@/hooks/useImageUpload';

import { meroApi } from '../../../../contexts/AuthContext';
import { ClientDetailsContext } from '../../../../contexts/ClientDetailsContext';
import log from '../../../../utils/log';
import { scaledToString } from '../../../../utils/scaled';
import { DEFAULT_TIMEZONE } from '../../../../utils/time';

const Phone = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 7728">
      <Path
        fill="#fff"
        d="M16.139 14.047a1.2 1.2 0 0 0-1.627.325c-.6.726-1.327 1.927-4.055-.8s-1.552-3.479-.826-4.08a1.2 1.2 0 0 0 .325-1.627l-1.7-2.6c-.225-.325-.526-.851-1.226-.751A3.417 3.417 0 0 0 4.5 7.889c0 2.253 1.777 5.006 4.2 7.434s5.181 4.18 7.409 4.18a3.628 3.628 0 0 0 3.379-2.5c.075-.5-.425-1-.751-1.226Z"
      />
      <Path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 2785" />
    </G>
  </Svg>
);

const Message = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 7729">
      <Path
        fill="#080de0"
        d="m4.22 19.294 5.093-2.027a9.893 9.893 0 0 0 2.187.24c4.427 0 8-2.853 8-6.4s-3.573-6.4-8-6.4-8 2.853-8 6.373a5.885 5.885 0 0 0 2.427 4.587Z"
      />
      <Path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 2786" />
    </G>
  </Svg>
);

const WhatsApp = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 7731">
      <Path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 2786" />
      <Path
        fill="#080de0"
        d="m3.192 21.001 1.243-4.546a8.77 8.77 0 1 1 3.406 3.325l-4.649 1.221Zm4.865-2.807a7.272 7.272 0 1 0-2.022-1.968l-.737 2.69 2.76-.723Zm8.4-4.029c-.055-.091-.2-.146-.42-.256s-1.3-.64-1.5-.713-.347-.11-.493.11-.566.713-.694.859-.256.164-.475.055a5.981 5.981 0 0 1-1.763-1.09 6.6 6.6 0 0 1-1.219-1.519c-.128-.219-.013-.338.1-.447s.219-.256.329-.384a1.437 1.437 0 0 0 .213-.365.4.4 0 0 0-.018-.384c-.052-.109-.49-1.188-.672-1.63s-.359-.369-.493-.376l-.417-.004a.8.8 0 0 0-.584.274 2.459 2.459 0 0 0-.767 1.828 4.265 4.265 0 0 0 .895 2.267 9.771 9.771 0 0 0 3.739 3.311 12.68 12.68 0 0 0 1.249.462 3.012 3.012 0 0 0 1.381.087 2.257 2.257 0 0 0 1.479-1.049 1.826 1.826 0 0 0 .128-1.043Z"
      />
    </G>
  </Svg>
);

const More = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 6841">
      <Path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 2" />
      <G fill="#080de0" data-name="Group 5789" transform="translate(-313 -2158)">
        <Ellipse cx={2} cy={1.919} opacity={0.9} rx={2} ry={1.919} transform="translate(317 2168)" />
        <Ellipse cx={2} cy={1.919} data-name="Oval" opacity={0.9} rx={2} ry={1.919} transform="translate(330 2168)" />
        <Ellipse cx={2} cy={1.919} data-name="Oval" opacity={0.9} rx={2} ry={1.919} transform="translate(323.5 2168)" />
      </G>
    </G>
  </Svg>
);

type Props = {
  readonly now: DateTime;
  readonly pageId: PageId;
  readonly clientId: ClientId;
  readonly notes: NoteDetails[];
  readonly client: ClientProfile & { readonly pinnedNote: Option<NoteDetails> };
  readonly history: ClientHistoryRecord[];
  readonly totalAppointments: {
    readonly futureBookingsCount: number;
    readonly pastBookingsCount: number;
  };
  readonly appointments: UserAppointment[];
  readonly memberships: PurchasedMembership<MeroUnits.Any>[];
  readonly onBack: () => void;
  readonly onViewSalesReport?: () => void;
  readonly onNewMembership: () => void;
  readonly onNewBooking?: () => void;
  readonly onEdit: () => void;
  readonly handleAddNotePress: (note?: NoteDetails) => void;
  readonly onCallPhone: (phone: PhoneNumber) => void;
  readonly onCopyToClipboard: (text: PhoneNumber | Email) => void;
  readonly onSendSms: (phone: PhoneNumber) => void;
  readonly onSendWhatsappMessage: (phone: PhoneNumber) => void;
  readonly onShare: (text: string) => void;
  readonly onDelete: (client: SavedClient) => void;
  readonly onChangeIsBlocked: (client: SavedClient, isBlocked: boolean) => void;
  readonly onChangeIsWarned: (client: SavedClient, isWarned: boolean) => void;
  readonly onOpenClientAppointment: (appointment: UserAppointment) => void;
  readonly onDeleteNote: ({ noteId, deleteNoteImages }: { noteId: NoteId; deleteNoteImages: boolean }) => void;
  readonly onUpdatePinnedNote: ({ pinnedState, noteId }: { pinnedState: boolean; noteId: NoteId }) => void;
  readonly onHistoryReload: () => void;
  readonly clientImages: ClientImageNotePreview[];
  readonly onClientImagePress: (imageId: ClientImageId) => void;
  readonly clientReviews: PublicFeedbackDetailsWithAppointment[];
  readonly canManageAllReviews: boolean;
  readonly workers: { label: string; value: any }[];
  readonly pageFeedbackScore?: string;
  readonly clientTransactions: FinishedTransaction[];
  readonly onTransactionPress: (checkoutTransactionId: CheckoutTransactionId) => void;
};

const ClientDetailsLoadedScreenView: React.FC<Props> = ({
  now,
  client,
  clientId,
  notes,
  pageId,
  history,
  totalAppointments,
  appointments,
  memberships,
  onBack,
  onNewBooking,
  onNewMembership,
  onEdit,
  onCallPhone,
  onCopyToClipboard,
  onSendSms,
  onSendWhatsappMessage,
  onShare,
  onDelete,
  onChangeIsBlocked,
  onChangeIsWarned,
  onOpenClientAppointment,
  onDeleteNote,
  onHistoryReload,
  handleAddNotePress,
  onUpdatePinnedNote,
  clientImages,
  onClientImagePress,
  clientReviews,
  canManageAllReviews,
  workers,
  pageFeedbackScore,
  clientTransactions,
  onTransactionPress,
}: Props) => {
  const { firstname, lastname } = client.user;
  const { isPhone } = useMediaQueries();
  const { t } = useTranslation('clients');
  const showError = useShowError();
  const isFocused = useIsFocused();
  const {
    addReply,
    starFilter,
    showReviewMenu,
    selectedReview,
    showReplyDialog,
    showDeleteDialog,
    hideStarsFilter,
    hideReviewMenu,
    showStarsFilter,
    showMenu,
    changeReply,
    deleteReply,
    handleReplySuccess,
    handleDeleteSuccess,
    handleSelectStarsFilter,
    handleReplyCancel,
    toggleStarsFilter,
    handleDeleteCancel,
    scoreCounts,
    calculateAverage,
    updateSelectedWorker,
    selectedWorker,
  } = useReviews({
    pageId,
    onReviewsUpdate: (filter: StarFilter, workerId?: WorkerId) => {
      loadClientReviews(filter, workerId);
    },
  });

  const { removeImage, retryUpload, setImages, images, handleSelectImages, handleSelectImagesFromCamera } =
    useImageUpload();

  const [
    ,
    {
      reload: reloadClientDetails,
      loadClientImages,
      loadMoreAppointments,
      loadMoreMemberships,
      loadClientNotes,
      loadClientReviews,
      loadClientTransactions,
      loadMoreClientNotes,
    },
  ] = ClientDetailsContext.useContext();

  const fullName = React.useMemo(
    () => `${firstname ?? ''} ${lastname ?? ''}`.trim() || 'Fără nume',
    [firstname, lastname],
  );

  const [selectedTab, setSelectedTab] = React.useState<Tabs>('bookings');
  const [showClientOptionsMenu, setShowClientOptionsMenu] = React.useState(false);
  const [showViewCommissionModal, setShowViewCommissionModal] = React.useState(false);
  const [showClientCallOptionsMenu, setShowClientCallOptionsMenu] = React.useState(false);
  const [showGalleryPickerOptions, setShowGalleryPickerOptions] = React.useState(false);
  const [showClientNoteOptionsModalMenu, setShowClientNoteOptionsModalMenu] = React.useState<NoteDetails | undefined>(
    undefined,
  );
  const [showClientDeleteConfirmModal, setShowClientDeleteConfirmModal] = React.useState(false);
  const [showClientBlockConfirmModal, setShowClientBlockConfirmModal] = React.useState(false);
  const [showClientNoteDeleteConfirmModal, setShowClientNoteDeleteConfirmModal] = React.useState<
    | {
        noteId: NoteId;
        deleteNoteImages: boolean;
      }
    | undefined
  >(undefined);
  const [showClaimDialog, setShowClaimDialog] = React.useState(false);
  const [reports, setReports] = React.useState<PageClientStats<MeroUnits.Any>>();

  const onNoteOptionsPressCallback = React.useCallback(
    (note: NoteDetails) => {
      setShowClientNoteOptionsModalMenu(note);
    },
    [setShowClientNoteOptionsModalMenu],
  );

  const isBoost = client.boostStatus.isBoost;
  const hideBoostDetails = client.boostStatus.isBoost && client.boostStatus.hideBoostDetails;
  const isClaimPending = isBoost && client.boostStatus.isClaimPending;
  const canClaim = isBoost && client.boostStatus.canClaim;
  const canAcceptCommission = isBoost && client.boostStatus.canAcceptCommission;

  const toggleShowCommissionModal = () => {
    setShowViewCommissionModal(!showViewCommissionModal);
  };

  const toggleClaimDialog = () => {
    setShowClaimDialog(!showClaimDialog);
  };

  const handleEditNotePress = (note: NoteDetails) => {
    setShowClientNoteOptionsModalMenu(undefined);
    handleAddNotePress(note);
  };

  const handleUpdateNotePinnedState = (pinnedState: boolean, noteId: NoteId) => {
    onUpdatePinnedNote({ pinnedState, noteId });
    setShowClientNoteOptionsModalMenu(undefined);
  };

  const getReportsData = async () => {
    try {
      const reports = await meroApi.clients.getPageClientStats({
        pageId,
        clientId,
        currency: 'RON',
      });
      setReports(reports);
    } catch (error) {
      log.error('Failed to get reports data', error);
      showError(error);
    }
  };

  const handleCloseGalleryPickerOptions = useCallback(() => {
    setShowGalleryPickerOptions(false);
  }, [setShowGalleryPickerOptions]);

  const handleOpenGallery = useCallback(() => {
    handleCloseGalleryPickerOptions();
    handleSelectImages(pageId, clientId);
  }, [handleCloseGalleryPickerOptions, handleSelectImages, pageId, clientId]);

  const handleOpenCamera = useCallback(() => {
    handleCloseGalleryPickerOptions();
    handleSelectImagesFromCamera(pageId, clientId);
  }, [handleCloseGalleryPickerOptions, handleSelectImages, pageId, clientId]);

  const getHeader = (tab: Tabs) => {
    switch (tab) {
      case 'bookings': {
        if (appointments.length > 0) {
          return (
            <>
              <H1>{Tabs[selectedTab].label}</H1>
            </>
          );
        } else {
          return null;
        }
      }
      case 'notes': {
        if (notes.length > 0) {
          return (
            <>
              <View style={{ flexDirection: 'row' }}>
                <H1 style={{ flex: 1 }}>{Tabs[selectedTab].label}</H1>
                <TouchableOpacity onPress={() => handleAddNotePress(undefined)}>
                  <Title style={{ color: colors.DARK_BLUE }}>Adaugă notiță</Title>
                </TouchableOpacity>
              </View>
              <Spacer size="24" />
            </>
          );
        } else {
          return null;
        }
      }
      case 'history': {
        if (history.length) {
          return (
            <>
              <H1>{Tabs[selectedTab].label}</H1>
              <Spacer size="24" />
            </>
          );
        } else {
          return null;
        }
      }
      case 'reviews': {
        if (clientReviews.length) {
          return (
            <>
              <H1>{Tabs[selectedTab].label}</H1>
              <Spacer size="24" />
            </>
          );
        } else {
          return null;
        }
      }
      case 'memberships': {
        if (memberships.length > 0) {
          return (
            <>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <H1 style={{ flex: 1 }}>{Tabs[selectedTab].label}</H1>
                <TouchableOpacity onPress={onNewMembership}>
                  <Title style={{ color: colors.DARK_BLUE }}>Adaugă abonament</Title>
                </TouchableOpacity>
              </View>
              <Spacer size="24" />
              <Spacer size="24" />
            </>
          );
        } else {
          return null;
        }
      }
    }
  };

  const getContent = (tab: Tabs) => {
    switch (tab) {
      case 'bookings': {
        return (
          <ClientAppointmentsListView
            totals={totalAppointments}
            appointments={appointments}
            boostAppointmentId={isBoost ? client.boostStatus.boostAppointmentId : undefined}
            now={now}
            onItemPress={onOpenClientAppointment}
          />
        );
      }
      case 'notes': {
        return (
          <ClientNotesListView
            onEndReached={() => loadMoreClientNotes(pageId, clientId)}
            notes={notes}
            onNoteOptionsPress={onNoteOptionsPressCallback}
            onAddNote={() => handleAddNotePress(undefined)}
          />
        );
      }
      case 'history': {
        return <ClientHistoryListView history={history} now={now.toJSDate()} />;
      }
      case 'memberships': {
        return (
          <ClientMembershipsListView memberships={memberships} clientId={clientId} onNewMembership={onNewMembership} />
        );
      }
      case 'transactions': {
        return (
          <ClientTransactionsList onTransactionPress={onTransactionPress} clientTransactions={clientTransactions} />
        );
      }
      case 'gallery': {
        return (
          <ClientGalleryList
            removeImage={removeImage}
            retryUpload={retryUpload}
            setImages={setImages}
            images={clientImages}
            clientImages={clientImages}
            pageId={pageId}
            clientId={clientId}
            onImagePress={onClientImagePress}
            onAddNewImage={() => setShowGalleryPickerOptions(true)}
          />
        );
      }
      case 'reviews': {
        return (
          <ClientReviewsList
            workers={workers}
            selectedWorker={selectedWorker}
            averageScore={calculateAverage}
            onReviewWorkerFilterChange={updateSelectedWorker}
            scoreCounts={scoreCounts}
            pageFeedbackScore={pageFeedbackScore}
            reviewsFilter={starFilter}
            onShowStarsFilters={toggleStarsFilter}
            onShowReviewMenu={showReviewMenu}
            onShowReplyDialog={addReply}
            clientReviews={clientReviews}
            canManageAllReviews={canManageAllReviews}
          />
        );
      }
    }
  };

  const handleScroll: ScrollViewProps['onScroll'] = ({ nativeEvent }) => {
    const isCloseToBottom =
      nativeEvent.layoutMeasurement.height + nativeEvent.contentOffset.y >= nativeEvent.contentSize.height - 20;
    if (isCloseToBottom) {
      switch (selectedTab) {
        case 'bookings':
          return loadMoreAppointments(clientId);
        case 'memberships':
          return loadMoreMemberships(clientId, pageId);
      }
      // You can do something here when the end is reached
    }
  };

  React.useEffect(() => {
    isFocused && getReportsData();
  }, [isFocused]);

  const handleTabChange = (tab: Tabs) => {
    if (tab === 'history') {
      onHistoryReload();
    }

    if (tab === 'notes') {
      loadClientNotes(pageId, clientId);
    }

    if (tab === 'gallery') {
      loadClientImages(pageId, clientId);
    }

    if (tab === 'reviews') {
      loadClientReviews(starFilter);
    }

    if (tab === 'transactions') {
      loadClientTransactions(pageId, clientId);
    }

    setSelectedTab(tab);
  };

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER, overflow: 'hidden' }}>
        <MeroHeader
          canGoBack
          onBack={onBack}
          title={fullName}
          RightComponent={
            <TouchableOpacity
              style={{ justifyContent: 'center', height: 50, marginRight: 8 }}
              onPress={() => {
                setShowClientOptionsMenu(true);
              }}
            >
              <SmallBody style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>Opțiuni</SmallBody>
            </TouchableOpacity>
          }
        />
        <ScrollView onScroll={handleScroll} scrollEventThrottle={400}>
          <View style={[meroStyles.layout.padding16, { backgroundColor: colors.ALABASTER }]}>
            <Column alignItems="center">
              <Avatar
                source={client.user.photo?.medium}
                firstname={firstname ?? ''}
                lastname={lastname ?? ''}
                size={60}
              />
              <Spacer size={12} />
              <H1>{fullName}</H1>
              {!hideBoostDetails && <SmallBody>{client.user.phone}</SmallBody>}
            </Column>
            <Column alignItems="center">
              {client.isBlocked || client.isWarned || isBoost ? (
                <>
                  <Spacer size="8" />
                  <View style={meroStyles.layout.row}>
                    {client.isBlocked ? (
                      <>
                        <ClientBlockedLabel />
                        <HSpacer left={8} />
                      </>
                    ) : null}
                    {client.isWarned ? (
                      <>
                        <ClientWarnedLabel />
                        <HSpacer left={8} />
                      </>
                    ) : null}
                    {isBoost ? (
                      <>
                        <ClientBoostedLabel />
                        <HSpacer left={8} />
                      </>
                    ) : null}
                  </View>
                  <Spacer size="8" />
                </>
              ) : null}
            </Column>
            <Spacer size={16} />
            {hideBoostDetails ? (
              <>
                <SmallBody style={{ textAlign: 'center' }}>{t('boostDescription')}</SmallBody>
                <Spacer size={12} />

                <Row justifyContent="center" alignItems="center">
                  {isClaimPending ? (
                    <>
                      <Column style={{ flex: 2, paddingHorizontal: 6 }}>
                        <Label
                          style={{
                            fontFamily: 'open-sans-semibold',
                            color: colors.COMET,
                            textAlign: 'center',
                            paddingVertical: 12,
                          }}
                        >
                          {t('claimBoostClientProcessing')}
                        </Label>
                      </Column>
                    </>
                  ) : canClaim ? (
                    <>
                      <Column style={{ flex: 2, paddingHorizontal: 6 }}>
                        <Button
                          size="medium"
                          onClick={toggleClaimDialog}
                          backgroundColor={colors.SKY_BLUE}
                          color={colors.DARK_BLUE}
                          text={t('claimBoostClient')}
                        />
                      </Column>
                    </>
                  ) : null}
                  {canAcceptCommission && (
                    <>
                      <Column style={{ flex: 2, paddingHorizontal: 6 }}>
                        <Button size="medium" text={t('viewCommission')} onClick={toggleShowCommissionModal} />
                      </Column>
                    </>
                  )}
                </Row>
              </>
            ) : (
              <Row justifyContent="center">
                <TouchableOpacity
                  style={{ alignItems: 'center' }}
                  onPress={() => {
                    onCallPhone(client.user.phone);
                  }}
                >
                  <Column
                    style={{
                      backgroundColor: colors.DARK_BLUE,
                      width: 44,
                      height: 44,
                      borderRadius: 22,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Phone />
                  </Column>
                  <Spacer size={8} />
                  <SmallBody style={[meroStyles.text.semibold, { fontSize: 12, color: colors.DARK_BLUE }]}>
                    {t('call')}
                  </SmallBody>
                </TouchableOpacity>
                <HSpacer right={24} />
                <TouchableOpacity
                  style={{ alignItems: 'center' }}
                  onPress={() => {
                    onSendSms(client.user.phone);
                  }}
                >
                  <Column
                    style={{
                      backgroundColor: colors.SKY_BLUE,
                      width: 44,
                      height: 44,
                      borderRadius: 22,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Message />
                  </Column>
                  <Spacer size={8} />
                  <SmallBody style={[meroStyles.text.semibold, { fontSize: 12, color: colors.DARK_BLUE }]}>
                    {t('message')}
                  </SmallBody>
                </TouchableOpacity>
                <HSpacer right={24} />
                <TouchableOpacity
                  style={{ alignItems: 'center' }}
                  onPress={() => {
                    onSendWhatsappMessage(client.user.phone);
                  }}
                >
                  <Column
                    style={{
                      backgroundColor: colors.SKY_BLUE,
                      width: 44,
                      height: 44,
                      borderRadius: 22,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <WhatsApp />
                  </Column>
                  <Spacer size={8} />
                  <SmallBody style={[meroStyles.text.semibold, { fontSize: 12, color: colors.DARK_BLUE }]}>
                    {t('whatsApp')}
                  </SmallBody>
                </TouchableOpacity>
                <HSpacer right={24} />
                <TouchableOpacity
                  style={{ alignItems: 'center' }}
                  onPress={() => {
                    setShowClientCallOptionsMenu(true);
                  }}
                >
                  <Column
                    style={{
                      backgroundColor: colors.SKY_BLUE,
                      width: 44,
                      height: 44,
                      borderRadius: 22,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <More />
                  </Column>
                  <Spacer size={8} />
                  <SmallBody style={[meroStyles.text.semibold, { fontSize: 12, color: colors.DARK_BLUE }]}>
                    {t('more')}
                  </SmallBody>
                </TouchableOpacity>
              </Row>
            )}
            {client.pinnedNote ? (
              <>
                <Spacer size={24} />
                <ClientNoteCard isProfilePinned note={client.pinnedNote} onOptionsPress={onNoteOptionsPressCallback} />
              </>
            ) : (
              <Spacer size={8} />
            )}
            {reports ? (
              <>
                <Spacer size={16} />
                <FormCard rounded dropShaddow paddings="none" style={{ paddingHorizontal: 16 }}>
                  <Spacer size={16} />
                  <Row>
                    <Column style={{ flex: 3 }}>
                      <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET, fontSize: 12 }}>
                        {t('appointmentReports').toLocaleUpperCase()}
                      </SmallBody>
                      <Spacer size={4} />
                      <H3s>{reports.completedBookings}</H3s>
                    </Column>
                    <Column style={{ flex: 3 }}>
                      <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET, fontSize: 12 }}>
                        {t('canceledReports').toLocaleUpperCase()}
                      </SmallBody>
                      <Spacer size={4} />
                      <H3s>{reports.cancelledBookings}</H3s>
                    </Column>
                    <Column style={{ flex: 3 }}>
                      <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET, fontSize: 12 }}>
                        {t('noShowReports').toLocaleUpperCase()}
                      </SmallBody>
                      <Spacer size={4} />
                      <H3s>{reports.noShowBookings}</H3s>
                    </Column>
                  </Row>
                  <Spacer size={16} />
                  <Row justifyContent="space-between">
                    <Column style={{ flex: 3 }}>
                      <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET, fontSize: 12 }}>
                        {t('lastAppointmentReports').toLocaleUpperCase()}
                      </SmallBody>
                      <Spacer size={4} />
                      <H3s>
                        {reports.lastCompletedBookingTs
                          ? DateTime.fromJSDate(reports.lastCompletedBookingTs).toFormat('dd.MM.yy')
                          : '-'}
                      </H3s>
                    </Column>
                    <Column style={{ flex: 3 }}>
                      <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET, fontSize: 12 }}>
                        {t('revenueReports').toLocaleUpperCase()}
                      </SmallBody>
                      <Spacer size={4} />
                      <H3s>
                        {scaledToString(reports.totalCheckoutCharge.amount)} {t(reports.totalCheckoutCharge.unit)}
                      </H3s>
                    </Column>
                    <Column style={{ flex: 3 }}>
                      <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET, fontSize: 12 }}>
                        {t('unpaidReports').toLocaleUpperCase()}
                      </SmallBody>
                      <Spacer size={4} />
                      <H3s>
                        {scaledToString(reports.debt.amount)} {t(reports.debt.unit)}
                      </H3s>
                    </Column>
                  </Row>
                  <Spacer size={16} />
                </FormCard>
              </>
            ) : null}
          </View>
          <Spacer size="32" color={colors.ALABASTER} />
          <View style={[meroStyles.layout.hrPadding16, { backgroundColor: colors.WHITE, paddingTop: 16 }]}>
            <ScrollableSwitch selectedTab={selectedTab} setSelectedTab={handleTabChange} tabsOptions={TabsOptions} />
            <Spacer size={32} />
            {getHeader(selectedTab)}
          </View>
          <Column style={{ backgroundColor: colors.WHITE }}>{getContent(selectedTab)}</Column>
        </ScrollView>
        {onNewBooking && selectedTab === 'bookings' ? (
          <>
            <Spacer size={84} />
            <FormCard
              dropShaddow
              paddings="button"
              style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
            >
              <SafeAreaView edges={['bottom']}>
                {isPhone ? (
                  <Button text={t('newAppointment')} onClick={onNewBooking} />
                ) : (
                  <Button
                    expand={false}
                    containerStyle={{ alignSelf: 'center' }}
                    text={t('newAppointment')}
                    onClick={onNewBooking}
                  />
                )}
              </SafeAreaView>
            </FormCard>
          </>
        ) : null}
      </ModalScreenContainer>

      {showClientOptionsMenu ? (
        <ClientOptionsModal
          isBlocked={client.isBlocked}
          isWarned={client.isWarned}
          onDismiss={() => {
            setShowClientOptionsMenu(false);
          }}
          onChangeIsBlocked={(isBlocked) => {
            setShowClientOptionsMenu(false);
            if (isBlocked) {
              // Show client block confirmation
              setShowClientBlockConfirmModal(true);
            } else {
              // Unblock client
              onChangeIsBlocked(client, false);
            }
          }}
          onChangeIsWarned={(isWarned) => {
            onChangeIsWarned(client, isWarned);
          }}
          onDelete={
            // Only allow deleting clients that are not boosted
            !isBoost
              ? () => {
                  setShowClientOptionsMenu(false);
                  setShowClientDeleteConfirmModal(true);
                }
              : undefined
          }
          onEdit={
            // Only allow editing clients that are not boosted or boost details unlocked
            !hideBoostDetails
              ? () => {
                  setShowClientOptionsMenu(false);
                  onEdit();
                }
              : undefined
          }
        />
      ) : null}

      {showViewCommissionModal && (
        <ClientViewCommissionModal
          client={client}
          onDismiss={toggleShowCommissionModal}
          onConfirm={() => {
            reloadClientDetails(client._id, pageId);
            toggleShowCommissionModal();
          }}
        />
      )}

      {!hideBoostDetails && showClientCallOptionsMenu ? (
        <ClientCallOptionsModal
          phone={client.user.phone}
          email={client.user.email as Email | undefined}
          birthday={client.user.birthday ? DateString.toDate(client.user.birthday, DEFAULT_TIMEZONE) : undefined}
          onDismiss={() => {
            setShowClientCallOptionsMenu(false);
          }}
          onCopy={(phone) => {
            setShowClientCallOptionsMenu(false);
            onCopyToClipboard(phone);
          }}
          onOpen={(url: string) => {
            setShowClientCallOptionsMenu(false);
            Linking.openURL(url).catch(log.error);
          }}
          onShare={() => {
            setShowClientCallOptionsMenu(false);
            onShare(`${client.user.firstname} ${client.user.lastname} - ${client.user.phone}`);
          }}
        />
      ) : null}

      {showClientNoteOptionsModalMenu !== undefined ? (
        <ClientNoteOptionsModal
          onDeleteNoteAndImages={(note) => {
            setShowClientNoteOptionsModalMenu(undefined);
            setShowClientNoteDeleteConfirmModal({ noteId: note._id, deleteNoteImages: true });
          }}
          onDeleteNoteAndKeepImages={(note) => {
            setShowClientNoteOptionsModalMenu(undefined);
            setShowClientNoteDeleteConfirmModal({ noteId: note._id, deleteNoteImages: false });
          }}
          isPinned={showClientNoteOptionsModalMenu.isPinned}
          onUpdatePinnedState={handleUpdateNotePinnedState}
          note={showClientNoteOptionsModalMenu}
          onDismiss={() => {
            setShowClientNoteOptionsModalMenu(undefined);
          }}
          onEdit={handleEditNotePress}
        />
      ) : null}

      {showClientDeleteConfirmModal ? (
        <ClientConfirmDeleteModal
          deleteInProgress={false}
          onDismiss={() => {
            setShowClientDeleteConfirmModal(false);
          }}
          onConfirm={() => {
            setShowClientDeleteConfirmModal(false);
            onDelete(client);
          }}
        />
      ) : null}

      {showClientBlockConfirmModal ? (
        <ClientConfirmBlockModal
          phone={client.user.phone}
          onDismiss={() => {
            setShowClientBlockConfirmModal(false);
          }}
          onConfirm={() => {
            setShowClientBlockConfirmModal(false);
            // Client should be blocked
            onChangeIsBlocked(client, true);
          }}
        />
      ) : null}

      {showClientNoteDeleteConfirmModal !== undefined ? (
        <ClientConfirmNoteDeleteModal
          noteId={showClientNoteDeleteConfirmModal.noteId}
          deleteNoteImages={showClientNoteDeleteConfirmModal.deleteNoteImages}
          onDismiss={() => {
            setShowClientNoteDeleteConfirmModal(undefined);
          }}
          onConfirm={() => {
            setShowClientNoteDeleteConfirmModal(undefined);
            onDeleteNote(showClientNoteDeleteConfirmModal);
          }}
        />
      ) : null}

      {showClaimDialog && (
        <ClaimDialog
          onSuccess={() => reloadClientDetails(client._id, pageId)}
          onCancel={toggleClaimDialog}
          userId={client.user._id}
        />
      )}
      {selectedReview && showReplyDialog && (
        <ReplyDialog onSuccess={handleReplySuccess} onCancel={handleReplyCancel} review={selectedReview} />
      )}

      {selectedReview && showMenu && (
        <ReviewMenu onDeleteReply={deleteReply} onChangeReply={changeReply} onDismiss={hideReviewMenu} />
      )}

      {selectedReview && showDeleteDialog && (
        <DeleteReplyDialog
          onSuccess={handleDeleteSuccess}
          onCancel={handleDeleteCancel}
          feedbackId={selectedReview._id}
        />
      )}

      {showStarsFilter && (
        <SelectStarsFilterMenu
          list={[...STARS_FILTERS.filter((item) => item !== 'allStars'), 'allStars']}
          onSelect={handleSelectStarsFilter}
          onDismiss={hideStarsFilter}
        />
      )}
      {showGalleryPickerOptions && (
        <GalleryPickerOptions
          onDismiss={() => setShowGalleryPickerOptions(false)}
          onOpenGallery={handleOpenGallery}
          onOpenCamera={handleOpenCamera}
        />
      )}
    </>
  );
};

const Tabs = {
  bookings: { label: 'Programări', value: 'bookings' },
  memberships: { label: 'Abonamente', value: 'memberships' },
  notes: { label: 'Jurnal', value: 'notes' },
  history: { label: 'Istoric', value: 'history' },
  gallery: { label: 'Galerie foto', value: 'gallery' },
  transactions: { label: 'Încasări', value: 'transactions' },
  reviews: { label: 'Recenzii', value: 'reviews' },
} as const;

export type Tabs = keyof typeof Tabs;

const TabsOptions = [
  Tabs.bookings,
  Tabs.memberships,
  Tabs.transactions,
  Tabs.gallery,
  Tabs.reviews,
  Tabs.notes,
  Tabs.history,
];

export default ClientDetailsLoadedScreenView;
