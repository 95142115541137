import React from 'react';
import { Svg, Path } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

const Receipt = ({ color, size = 24 }: IconProps) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M8.86108 12.561H12.4511M8.86108 14.357H11.5551M14.2501 12.557H15.1501M14.2501 14.357H15.1501"
        stroke={color || '#52577F'}
        strokeWidth="1.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.61999 6.375V17.849L7.96199 16.954L9.32299 17.854L10.665 16.954L12.013 17.849L13.355 16.954L14.702 17.849L16.045 16.954L17.391 17.849V6.375M5.81299 6.375H18.191"
        stroke={color || '#52577F'}
        strokeWidth="1.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M10.7261 9.57203L11.6451 10.491L13.2801 8.85803"
        stroke={color || '#52577F'}
        strokeWidth="1.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Receipt;
