import * as React from 'react';

import NoteBookingsListScreen from '../screens/Authorized/NoteBookingsListScreen';
import NoteDetailsScreen from '../screens/Authorized/NoteDetailsScreen';
import NoteGalleryScreen from '../screens/Authorized/NoteGalleryScreen';

import { createStackNavigator } from '@react-navigation/stack';

import { withNoteCreationContextProvider } from '../contexts/NoteContext';
import { ClientNoteStackParamList } from '../types';

const Stack = createStackNavigator<ClientNoteStackParamList>();

function ClientNoteStackNavigator(): React.ReactElement {
  return (
    <Stack.Navigator
      initialRouteName="NoteDetailsScreen"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="NoteDetailsScreen" component={NoteDetailsScreen} />
      <Stack.Screen name="NoteGalleryScreen" component={NoteGalleryScreen} />
      <Stack.Screen name="NoteBookingsListScreen" component={NoteBookingsListScreen} />
    </Stack.Navigator>
  );
}

export default withNoteCreationContextProvider(ClientNoteStackNavigator);
