import * as t from 'io-ts';

/**
 * https://en.wikipedia.org/wiki/ISO_week_date
 * 1 - Monday
 * 7 - Sunday
 */
export type IsoWeekDay = 1 | 2 | 3 | 4 | 5 | 6 | 7;

const MONDAY = 1 as const;
const TUESDAY = 2 as const;
const WEDNESDAY = 3 as const;
const THURSDAY = 4 as const;
const FRIDAY = 5 as const;
const SATURDAY = 6 as const;
const SUNDAY = 7 as const;

const JSON: t.Type<IsoWeekDay> = t.union(
  [
    t.literal(MONDAY),
    t.literal(TUESDAY),
    t.literal(WEDNESDAY),
    t.literal(THURSDAY),
    t.literal(FRIDAY),
    t.literal(SATURDAY),
    t.literal(SUNDAY),
  ],
  'IsoWeekDay',
);

const unsafeFrom = (value: number): IsoWeekDay => {
  if (!JSON.is(value)) {
    throw new Error('Invalid IsoWeekDay value');
  }

  return value;
};

export const IsoWeekDay = {
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
  JSON,
  is: JSON.is,
  unsafeFrom,
};
