import { DayTime } from '@mero/shared-sdk';
import * as t from 'io-ts';

/**
 * A left-closed, right-open working interval of time in a day
 * ex. if to is 18:00 it means at 18:00 the worker is not working anymore
 *
 * Full day is considered 00:00 - 00:00
 */
export type WorkScheduleInterval = {
  readonly from: DayTime;
  readonly to: DayTime;
};

const JSON = t.type(
  {
    from: DayTime.JSON,
    to: DayTime.JSON,
  },
  'WorkScheduleInterval',
);

const FULL_DAY = {
  from: DayTime.of(0, 0),
  to: DayTime.of(0, 0),
};

const equals = (a: WorkScheduleInterval, b: WorkScheduleInterval): boolean => {
  return DayTime.equals(a.from, b.from) && DayTime.equals(a.to, b.to);
};

const isWholeDay = (interval: WorkScheduleInterval): boolean => {
  return equals(interval, FULL_DAY);
};

const isValid = (interval: WorkScheduleInterval): boolean => {
  return isWholeDay(interval) || DayTime.lt(interval.from, interval.to) || DayTime.equals(FULL_DAY.to, interval.to);
};

const of = (from: DayTime, to: DayTime): WorkScheduleInterval => {
  const interval = {
    from: from,
    to: to,
  };

  if (!isValid(interval)) {
    throw new Error('Schedule interval is invalid');
  }

  return interval;
};

/**
 * Get duration in minutes
 */
const getDuration = (interval: WorkScheduleInterval): number => {
  const toHour = interval.to.hour === 0 && interval.to.minute === 0 ? 24 : interval.to.hour;

  return toHour * 60 + interval.to.minute - interval.from.hour * 60 - interval.from.minute;
};

/**
 * Merge intervals if interval's `from` is equal to other interval's `to`
 */
const tryToMergeConsecutiveIntervals = (a: WorkScheduleInterval, b: WorkScheduleInterval): WorkScheduleInterval[] => {
  const sortedIntervals = DayTime.lt(a.from, b.from) ? [a, b] : [b, a];
  if (DayTime.gte(sortedIntervals[0].to, sortedIntervals[1].from)) {
    const to =
      DayTime.equals(FULL_DAY.to, sortedIntervals[0].to) || DayTime.equals(FULL_DAY.to, sortedIntervals[1].to)
        ? FULL_DAY.to
        : DayTime.max(sortedIntervals[0].to, sortedIntervals[1].to);
    return [of(sortedIntervals[0].from, to)];
  }

  return [a, b];
};

export const WorkScheduleInterval = {
  of,
  equals,
  isWholeDay,
  getDuration,
  isValid,
  tryToMergeConsecutiveIntervals,
  FULL_DAY,
  JSON,
};
