import { UserOrderPaymentId } from '../../../../pro';
import { TazzOrder } from './tazzOrder';
import { UserOrderPayment } from './userOrderPayment';

export type Any = UserOrderPayment | TazzOrder;

export const userOrderPayment = (params: { readonly paymentId: UserOrderPaymentId }): UserOrderPayment => {
  return {
    type: 'UserOrderPayment',
    paymentId: params.paymentId,
  };
};

export const tazzOrder = (params: { readonly tazzOrderId: string }): TazzOrder => {
  return {
    type: 'TazzOrder',
    tazzOrderId: params.tazzOrderId,
  };
};
