import { AppointmentId, CalendarId } from '@mero/api-sdk/dist/calendar';
import { ClientId } from '@mero/api-sdk/dist/clients';
import { PageId } from '@mero/api-sdk/dist/pages';
import { createModelContext } from '@mero/components';
import * as React from 'react';

import { ClientDetails } from '../../contexts/ClientCreateContext';
import log from '../../utils/log';

type AppEvent =
  | {
      type: 'PageClientAdded';
      pageId: PageId;
      clientId: ClientId;
      details: ClientDetails;
    }
  | {
      type: 'PageClientUpdated';
      pageId: PageId;
      clientId: ClientId;
    }
  | {
      type: 'PageClientDeleted';
      pageId: PageId;
      clientId: ClientId;
    }
  | {
      type: 'PageClientsImported';
      pageId: PageId;
    }
  | {
      type: 'PageClientNoteCreated';
    }
  | {
      type: 'PageClientNoteUpdated';
    }
  | {
      type: 'AppointmentRequestAccepted';
    }
  | {
      type: 'AppointmentRequestRejected';
    }
  | {
      type: 'ClientImageDeleted';
    }
  | {
      type: 'PageReloadNotes';
    }
  | {
      type: 'AppointmentCreated';
      calendarId: CalendarId;
      appointmentId: AppointmentId;
    }
  | {
      type: 'AppointmentUpdated';
      calendarId: CalendarId;
      appointmentId: AppointmentId;
    }
  | {
      type: 'AppointmentDeleted';
      calendarId: CalendarId;
      appointmentId: AppointmentId;
    };

type AppEventHandler = (event: AppEvent) => void;
type Unsubscribe = () => void;

type AppEventsState = {
  subscriptions: AppEventHandler[];
};

const defaultState = (): AppEventsState => ({
  subscriptions: [],
});

export const AppEventsContext = createModelContext(
  defaultState(),
  {
    /**
     * Subscription usually called synchronously on component render, and if it will trigger state change
     * component will re-render and enter inifinite loop, so addSubscription shouldn't update state object
     *
     * Usage example:
     * ```typescript
     * React.useEffect(
     *  () => subscribe((event) => {
     *    log.debug('got new event', event)
     *  }),
     *  []
     * )
     * ```
     */
    addSubscription: (state, handler: AppEventHandler) => {
      // Don't create new state object to avoid infinite loop on component render
      state.subscriptions.push(handler);
      return state;
    },
    removeSubscription: (state, subscription: AppEventHandler) => {
      state.subscriptions = state.subscriptions.filter((s) => s !== subscription);
      return state;
    },
    pushEvent: (state, event: AppEvent) => {
      state.subscriptions.forEach((h) => {
        try {
          h(event);
        } catch (e: unknown) {
          log.exception(e);
        }
      });

      return state;
    },
  },
  (dispatch) => {
    return {
      subscribe: (handler: AppEventHandler): Unsubscribe => {
        dispatch.addSubscription(handler);
        log.debug('add AppEventHandler');

        return () => {
          log.debug('remove AppEventHandler');
          dispatch.removeSubscription(handler);
        };
      },
      pushEvent: dispatch.pushEvent,
    };
  },
);

export const withAppEventsContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithAppEventsContextProvider(props: P) {
    return (
      <AppEventsContext.Provider>
        <Content {...props} />
      </AppEventsContext.Provider>
    );
  };
};
