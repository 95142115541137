import { NoteId } from '@mero/api-sdk';
import { Body, ConfirmBox, H1, Spacer } from '@mero/components';
import * as React from 'react';

import ModalOverlay from '@mero/components/lib/components/ModalOverlay';

import { useMediaQueries } from '../../../../hooks/useMediaQueries';

export type Props = {
  readonly noteId: NoteId;
  readonly deleteNoteImages: boolean;
  readonly onDismiss: () => void;
  readonly onConfirm: (noteId: NoteId) => void;
};

/**
 * Client note removal confirmation modal
 */
const ClientConfirmNoteDeleteModal: React.FC<Props> = ({
  noteId,
  deleteNoteImages,
  onDismiss,
  onConfirm,
}: Props): React.ReactElement | null => {
  const { isDesktop } = useMediaQueries();

  const confirmNoteDeleteCallback = React.useCallback(() => {
    onConfirm(noteId);
  }, [noteId, onConfirm]);

  const leftAction = {
    text: 'Anulează',
    onPress: onDismiss,
  };

  const rightAction = {
    text: 'Confirmă ștergere',
    onPress: confirmNoteDeleteCallback,
  };

  const title = deleteNoteImages ? 'Confirmă ștergerea notiței și imaginilor' : 'Confirmă ștergerea notiței';
  const body = deleteNoteImages
    ? 'Notița și imaginile asociate vor fi șterse definitiv atât din fișa clientului cât și din programare, după caz. '
    : 'Notița va fi ștearsă definitiv atât din fișa clientului cât și din programare, după caz. Imaginile vor rămâne în galeria foto a clientului';

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <ConfirmBox
        style={{ maxWidth: isDesktop ? 468 : undefined }}
        type="error"
        icon="info"
        headerTitle="Actiune ireversibilă"
        canClose={true}
        onClose={onDismiss}
        leftAction={leftAction}
        rightAction={rightAction}
      >
        <H1>{title}</H1>
        <Spacer size="8" />
        <Body>{body}</Body>
      </ConfirmBox>
    </ModalOverlay>
  );
};

export default ClientConfirmNoteDeleteModal;
