import * as t from 'io-ts';

export interface NoteIdBrand {
  readonly NoteId: unique symbol;
}

export type NoteId = t.Branded<string, NoteIdBrand>;

const JSON: t.Type<NoteId, string> = t.brand(t.string, (_id: string): _id is NoteId => true, 'NoteId');

export const NoteId = {
  JSON,
};
