import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type MarketplaceSearchDebug = {
  readonly debugToken: string;
  readonly customWeights: {
    readonly cityNameMatchBoost: number;
    readonly areaNameMatchBoost: number;
    readonly surroundingCityNameMatchBoost: number;
    readonly nameExactMatchBoost: number;
    readonly namePrefixMatchBoost: number;
    readonly nameFuzzyMatchBoost: number;
    readonly workerExactMatchBoost: number;
    readonly workerPrefixMatchBoost: number;
    readonly workerFuzzyMatchBoost: number;
    readonly categoryNameMatchBoost: number;
    readonly serviceFuzzyMatchBoost: number;
  };
};

const JSON: t.Type<MarketplaceSearchDebug, JSONable> = t.type(
  {
    debugToken: t.string,
    customWeights: t.type(
      {
        cityNameMatchBoost: t.number,
        areaNameMatchBoost: t.number,
        surroundingCityNameMatchBoost: t.number,
        nameExactMatchBoost: t.number,
        namePrefixMatchBoost: t.number,
        nameFuzzyMatchBoost: t.number,
        workerExactMatchBoost: t.number,
        workerPrefixMatchBoost: t.number,
        workerFuzzyMatchBoost: t.number,
        categoryNameMatchBoost: t.number,
        serviceFuzzyMatchBoost: t.number,
      },
      'CustomWeight',
    ),
  },
  'PagesSearch2Debug',
);

export const MarketplaceSearchDebug = {
  JSON,
};
