import { NoteId } from '@mero/api-sdk';
import { Icon } from '@mero/components';
import * as React from 'react';
import { useCallback } from 'react';
import { Pressable, View } from 'react-native';

import FloatMenu, { Ref as FloatMenuRefu } from '../../../../../../components/FloatMenu';
import { Column, Row, Title, Line, SimpleListItem } from '../../../../../../components/shared';

import { colors } from '../../../../../../styles';
import styles from './styles';

interface ClientNotesOptionsMenuProps {
  readonly noteId: NoteId;
  readonly onEdit: () => void;
  readonly onDeleteNoteAndImages: () => void;
  readonly onDeleteNoteAndKeepImages: () => void;
  readonly notesFloatMenuRef: React.RefObject<FloatMenuRefu>;
  readonly isPinned?: boolean;
  readonly onUpdatePinnedState: (pinnedState: boolean, noteId: NoteId) => void;
}

const ClientNotesOptionsMenu: React.FC<ClientNotesOptionsMenuProps> = ({
  onEdit,
  onDeleteNoteAndImages,
  onDeleteNoteAndKeepImages,
  notesFloatMenuRef,
  isPinned,
  onUpdatePinnedState,
  noteId,
}) => {
  const handleUpdatePinnedState = useCallback(() => {
    onUpdatePinnedState(!isPinned, noteId);
  }, [isPinned, onUpdatePinnedState]);

  return (
    <Row>
      <FloatMenu
        touchableActiveOpacity={1}
        ref={notesFloatMenuRef}
        button={
          <Column style={styles.button}>
            <Icon type="options-dots" />
          </Column>
        }
      >
        <View style={styles.optionsListContainer}>
          <>
            <Pressable style={styles.optionsMenuItem} onPress={onEdit}>
              <SimpleListItem>
                <Title>Modifică notiță</Title>
              </SimpleListItem>
            </Pressable>
            <Line />
          </>
          {!isPinned && (
            <>
              <Pressable style={styles.optionsMenuItem} onPress={handleUpdatePinnedState}>
                <SimpleListItem>
                  <Title>Fixează notița</Title>
                </SimpleListItem>
              </Pressable>
              <Line />
            </>
          )}
          {isPinned && (
            <>
              <Pressable style={styles.optionsMenuItem} onPress={handleUpdatePinnedState}>
                <SimpleListItem>
                  <Title style={{ color: colors.RADICAL_RED }}>Anulează fixare notiță</Title>
                </SimpleListItem>
              </Pressable>
              <Line />
            </>
          )}
          <>
            <Pressable style={styles.optionsMenuItem} onPress={onDeleteNoteAndImages}>
              <SimpleListItem>
                <Title style={{ color: colors.RADICAL_RED }}>Șterge notiță si imagini</Title>
              </SimpleListItem>
            </Pressable>
            <Line />
          </>
          <>
            <Pressable style={styles.optionsMenuItem} onPress={onDeleteNoteAndKeepImages}>
              <SimpleListItem>
                <Title style={{ color: colors.RADICAL_RED }}>Șterge notiță si pastrează imagini</Title>
              </SimpleListItem>
            </Pressable>
            <Line />
          </>
        </View>
      </FloatMenu>
    </Row>
  );
};

export default ClientNotesOptionsMenu;
