import { Title } from '@mero/components';
import * as React from 'react';
import { Pressable, View } from 'react-native';

import Line from '@mero/components/lib/components/Line';
import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import SimpleListItem from '@mero/components/lib/components/SimpleListItem';

import { useMediaQueries } from '@/hooks/useMediaQueries';

import { styles } from './styles';

interface NoteOptionsProps {
  onDismiss: () => void;
  onEditNote: () => void;
  onRemoveNoteReference?: () => void;
}

const NoteOptions: React.FC<NoteOptionsProps> = ({ onDismiss, onEditNote, onRemoveNoteReference }) => {
  const { isPhone } = useMediaQueries();

  return (
    <ModalOverlay>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <View style={styles.optionsListContainer}>
        <SafeAreaView edges={['bottom']}>
          <Pressable style={styles.optionsMenuItem} onPress={onEditNote}>
            <SimpleListItem>
              <Title>Modifică notiță</Title>
            </SimpleListItem>
          </Pressable>
          <Line />
          <Pressable style={styles.optionsMenuItem} onPress={onRemoveNoteReference}>
            <SimpleListItem>
              <Title style={styles.optionText}>Elimină legătura notiței cu imaginea</Title>
            </SimpleListItem>
          </Pressable>
        </SafeAreaView>
      </View>
    </ModalOverlay>
  );
};

export default NoteOptions;
