import { UserAppointment } from '@mero/api-sdk/dist/calendar';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { FlatList, View } from 'react-native';

import ModalScreenContainer from '../../../components/ModalScreenContainer';
import { Body, Column, H1, MeroHeader } from '../../../components/shared';
import ClientBookingCard from '../ClientDetailsScreen/ClientDetailsLoadedScreenView/components/ClientBookingCard';
import ClientEmptyListView from '../ClientDetailsScreen/components/ClientEmptyListView';

import { CompositeNavigationProp, RouteProp } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';

import { useEscPressWeb } from '../../../hooks/useEscPressWeb';
import useGoBack from '../../../hooks/useGoBack';

import { NoteCreationContext } from '../../../contexts/NoteContext';
import { RootStackParamList, ClientNoteStackParamList } from '../../../types';
import styles from './styles';

type NoteBookingsListProps = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<RootStackParamList, 'Authorized'>,
    StackNavigationProp<ClientNoteStackParamList, 'NoteBookingsListScreen'>
  >;
  route: RouteProp<ClientNoteStackParamList, 'NoteBookingsListScreen'>;
};

const HeaderElement = () => {
  return (
    <View style={styles.header}>
      <H1>Alege programare</H1>
      <Body style={styles.subtitle}>Alege din lista programărilor clientului</Body>
    </View>
  );
};

const NoteBookingsList: React.FC<NoteBookingsListProps> = ({ route }) => {
  const [{ clientAppointments }, { getClientBookings, loadMoreClientBookings, setBooking }] =
    NoteCreationContext.useContext();

  const goBack = useGoBack();
  useEscPressWeb({
    onPress: goBack,
  });

  useEffect(() => {
    getClientBookings();
  }, []);

  const keyExtractor = (appointment: UserAppointment): string => `${appointment._id}${appointment.occurrenceIndex}`;

  const onItemPress = useCallback((booking: UserAppointment) => {
    setBooking(booking);
    goBack();
  }, []);

  return (
    <ModalScreenContainer style={styles.root}>
      <MeroHeader canGoBack onBack={goBack} title="Alege programare" />
      <FlatList
        style={styles.container}
        contentContainerStyle={styles.contentContainer}
        ListHeaderComponent={clientAppointments?.data?.length ? <HeaderElement /> : undefined}
        data={clientAppointments?.data}
        keyboardShouldPersistTaps="handled"
        keyExtractor={keyExtractor}
        onEndReached={loadMoreClientBookings}
        renderItem={({ item }) => {
          return <ClientBookingCard appointment={item} onPress={onItemPress} />;
        }}
        removeClippedSubviews
        windowSize={11}
        ListEmptyComponent={
          <Column flex={1} justifyContent="center" alignItems="center">
            <ClientEmptyListView title="Nu există programări" subtitle="Programările clientului vor fi afișate aici" />
          </Column>
        }
      />
    </ModalScreenContainer>
  );
};

export default NoteBookingsList;
