import * as E from 'fp-ts/Either';

export const isError = (e: unknown): e is Error => e instanceof Error;

export const unsafeRight = <E extends Error, T>(r: E.Either<E, T>): T => {
  if (E.isLeft(r)) {
    throw r.left;
  }

  return r.right;
};
