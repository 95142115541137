import { NoteDetails, NoteId } from '@mero/api-sdk';
import { Body } from '@mero/components';
import * as React from 'react';
import { Text, View } from 'react-native';

import { Ref as FloatMenuRef } from '../../../../../../components/FloatMenu';
import Icon from '../../../../../../components/Icon';
import { Column, FormCard, Row, StatusLabel } from '../../../../../../components/shared';
import NoteImage from '../../../../NoteDetailsScreen/components/NoteImage';

import { colors } from '../../../../../../styles';
import { getBookingDateForNote } from '../../../../../../utils/booking';
import { DEFAULT_TIMEZONE } from '../../../../../../utils/time';
import ClientNotesOptionsMenu from '../ClientNotesOptionsMenu';
import styles from './styles';

const MAX_VISIBLE_IMAGES = 4;

interface ClientNoteCardProps {
  note: NoteDetails;
  onEdit: (note: NoteDetails) => void;
  onDelete: (note: NoteDetails, deleteNoteImages: boolean) => void;
  notesFloatMenuRef: React.RefObject<FloatMenuRef>;
  isProfilePinned?: boolean;
  onUpdatePinnedState: (pinnedState: boolean, noteId: NoteId) => void;
}

const ClientNoteCard: React.FC<ClientNoteCardProps> = ({
  note,
  onEdit,
  onDelete,
  notesFloatMenuRef,
  isProfilePinned,
  onUpdatePinnedState,
}) => {
  return (
    <FormCard dropShaddow style={styles.card} borderRadius={6} paddings="none">
      <Row gap={12} flex={1}>
        {!isProfilePinned && (
          <View style={[styles.icon, note.isPinned ? styles.iconActive : styles.iconInactive]}>
            <Icon name="Document" color={note.isPinned ? '#3193F8' : colors.COMET} size={18} />
          </View>
        )}
        <Column flex={1}>
          <Row justifyContent="space-between" flex={1}>
            <Column flex={1}>
              {note.isPinned && <Text style={styles.pinnedText}>Notiță fixată</Text>}
              <Body>{note.text}</Body>
            </Column>
            <ClientNotesOptionsMenu
              noteId={note._id}
              isPinned={note.isPinned}
              onUpdatePinnedState={onUpdatePinnedState}
              onEdit={() => onEdit(note)}
              onDeleteNoteAndImages={() => onDelete(note, true)}
              onDeleteNoteAndKeepImages={() => onDelete(note, false)}
              notesFloatMenuRef={notesFloatMenuRef}
            />
          </Row>
          <Column gap={8}>
            {note.appointment && (
              <Row>
                <StatusLabel icon="Calendar" text={getBookingDateForNote(note.appointment.start, DEFAULT_TIMEZONE)} />
              </Row>
            )}

            {note.images?.length > 0 && (
              <Row gap={4} style={styles.imagesWrapper}>
                {note.images?.slice(0, MAX_VISIBLE_IMAGES).map((photo, index) => {
                  const showOverlay = note.images.length > 4 && index === 3;
                  return <NoteImage key={index} url={photo.small} showOverlay={showOverlay} />;
                })}
              </Row>
            )}
            {note.createdBy && (
              <Text style={styles.author}>{`${note.createdBy.lastname} ${
                note.createdBy.firstname
              } - ${getBookingDateForNote(note.date, DEFAULT_TIMEZONE)}`}</Text>
            )}
          </Column>
        </Column>
      </Row>
    </FormCard>
  );
};

export default ClientNoteCard;
