import { getBookingDateForNote } from '@/utils/booking';
import { DEFAULT_TIMEZONE } from '@/utils/time';
import { ClientImage } from '@mero/api-sdk';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Dimensions, ScrollView, Image, Pressable } from 'react-native';

import Icon from '../../../../components/Icon';
import ModalScreenContainer from '../../../../components/ModalScreenContainer';
import { Column, MeroHeader, Row, SafeAreaView, SelectableListItem, SmallBody } from '../../../../components/shared';
import DeleteImageConfirmModal from './components/DeleteImageConfirmModal';
import NoteOptions from './components/NoteOptions';

import { CompositeNavigationProp, RouteProp } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';

import { useEscPressWeb } from '../../../../hooks/useEscPressWeb';
import useGoBack from '../../../../hooks/useGoBack';

import { AppEventsContext } from '../../../../contexts/AppEvents';
import { ClientImageDetailsContext } from '../../../../contexts/ClientImageDetailsContext';
import { CurrentBusinessContext } from '../../../../contexts/CurrentBusiness';
import { RootStackParamList, ClientImageStackParamList } from '../../../../types';
import styles from './styles';

type ClientImageDetailsProps = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<ClientImageStackParamList, 'ClientImageDetailsScreen'>,
    StackNavigationProp<RootStackParamList, 'Authorized'>
  >;
  route: RouteProp<ClientImageStackParamList, 'ClientImageDetailsScreen'>;
};

const ClientImageDetailsScreen: React.FC<ClientImageDetailsProps> = ({ route, navigation }) => {
  const [pageState] = CurrentBusinessContext.useContext();
  const [
    { clientImages, selectedNote },
    { deleteClientImage, loadClientImages, clearNote, updateSelectedImageNote, removeClientImageNoteReference },
  ] = ClientImageDetailsContext.useContext();
  const [selectedImage, setSelectedImage] = useState<{ image: ClientImage; position: number } | undefined>(undefined);
  const [isNoteOptionsVisible, setIsNoteOptionsVisible] = useState(false);
  const [showDeleteImageConfirmModal, setShowDeleteImageConfirmModal] = useState(false);
  const { pageId, clientId } = route.params;
  const { width } = Dimensions.get('window');
  const imageWidth = (width > 768 ? 500 : width) - 48;
  const imageHeight = 436;
  const { imageId } = route.params;
  const [, { pushEvent }] = AppEventsContext.useContext();

  const goBack = useGoBack();
  useEscPressWeb({
    onPress: goBack,
  });

  useEffect(() => {
    if (pageId && clientId) {
      loadClientImages(clientId, pageId);
    }
  }, [pageId, clientId]);

  useEffect(() => {
    if (pageState.type === 'Loaded') {
      const image = clientImages.find((image) => image._id === imageId);
      if (image) {
        setSelectedImage({ image, position: clientImages.indexOf(image) });
        updateSelectedImageNote(image.note);
      }
    }
  }, [clientImages, imageId]);

  const handleNextImage = useCallback(() => {
    if (selectedImage) {
      const image = clientImages[selectedImage.position + 1];
      setSelectedImage({ image, position: selectedImage.position + 1 });
      updateSelectedImageNote(image.note);
    }
  }, [clientImages, selectedImage]);

  const handlePreviousImage = useCallback(() => {
    if (selectedImage) {
      const image = clientImages[selectedImage.position - 1];
      setSelectedImage({ image, position: selectedImage.position - 1 });
      updateSelectedImageNote(image.note);
    }
  }, [clientImages, selectedImage]);

  const handleDeleteImage = async () => {
    if (selectedImage) {
      await deleteClientImage(selectedImage.image._id);

      // If it's the last image, go back
      if (clientImages.length === 1) {
        goBack();
        return;
      }

      // If it's the last image in the list, show previous image
      if (selectedImage.position === clientImages.length - 1) {
        handlePreviousImage();

        return;
      }

      // Otherwise show next image
      handleNextImage();
    }
  };

  const goToNoteDetailsScreen = () => {
    if (selectedImage?.image._id) {
      setIsNoteOptionsVisible(false);
      navigation.navigate('ClientImageNoteScreen', {
        imageId: selectedImage.image._id,
      });
    }
  };

  const handleGoBack = () => {
    pushEvent({
      type: 'ClientImageDeleted',
    });
    goBack();
  };

  const handleRemoveNoteReference = () => {
    if (selectedImage?.image._id) {
      removeClientImageNoteReference(selectedImage.image._id);
      setIsNoteOptionsVisible(false);
    }
  };

  const title =
    selectedImage && clientImages.length > 1 ? `${selectedImage.position + 1} din ${clientImages.length}` : '';

  return (
    <ModalScreenContainer>
      <MeroHeader
        containerStyle={{ justifyContent: 'space-between' }}
        canGoBack
        onBack={handleGoBack}
        title={title}
        RightComponent={
          <Pressable style={styles.deleteButton} onPress={() => setShowDeleteImageConfirmModal(true)}>
            <SmallBody style={styles.deleteText}>Șterge</SmallBody>
          </Pressable>
        }
      />
      <ScrollView
        style={styles.listContainer}
        contentContainerStyle={{ position: 'relative', paddingBottom: 117 }}
        showsVerticalScrollIndicator={false}
      >
        <SafeAreaView edges={['bottom']}>
          <Row style={{ paddingHorizontal: 24, paddingTop: 8 }}>
            <Column style={{ flex: 1, position: 'relative' }}>
              <Image
                source={{ uri: selectedImage?.image.large }}
                style={[{ width: imageWidth, height: imageHeight }, styles.image]}
              />
              {selectedImage && selectedImage.position < clientImages.length - 1 && (
                <Pressable onPress={handleNextImage}>
                  <Column style={[styles.navigationButton, styles.nextButton]}>
                    <Icon name="Arrow" />
                  </Column>
                </Pressable>
              )}
              {selectedImage && selectedImage.position !== 0 && (
                <Pressable onPress={handlePreviousImage}>
                  <Column style={[styles.navigationButton, styles.prevButton]}>
                    <Icon name="Arrow" />
                  </Column>
                </Pressable>
              )}
            </Column>
          </Row>
          <Column style={styles.contentContainer} gap={32}>
            <Column gap={16}>
              <SelectableListItem
                onPress={goToNoteDetailsScreen}
                onClear={clearNote}
                title="Notiță"
                subtitle="Adaugă notiță"
                value={selectedNote?.text}
                onOptionPress={() => setIsNoteOptionsVisible(true)}
                labelText={
                  selectedNote?.appointment
                    ? getBookingDateForNote(selectedNote?.appointment.start, DEFAULT_TIMEZONE)
                    : undefined
                }
                labelIcon="Calendar"
              />
            </Column>
          </Column>
        </SafeAreaView>
      </ScrollView>
      {isNoteOptionsVisible && (
        <NoteOptions
          onDismiss={() => setIsNoteOptionsVisible(false)}
          onEditNote={goToNoteDetailsScreen}
          onRemoveNoteReference={handleRemoveNoteReference}
        />
      )}
      {showDeleteImageConfirmModal ? (
        <DeleteImageConfirmModal
          onDismiss={() => {
            setShowDeleteImageConfirmModal(false);
          }}
          onConfirm={() => {
            setShowDeleteImageConfirmModal(false);
            handleDeleteImage();
          }}
        />
      ) : null}
    </ModalScreenContainer>
  );
};

export default ClientImageDetailsScreen;
