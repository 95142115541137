import { StyleSheet } from 'react-native';

import { colors } from '../../../../../../styles';

const styles = StyleSheet.create({
  container: {
    height: 20,
    paddingHorizontal: 6,
    paddingVertical: 2,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontFamily: 'open-sans-semibold',
    fontSize: 10,
  },
  waitingContainer: {
    borderWidth: 1,
    borderColor: colors.YELLOW_ORANGE,
  },
});

export default styles;
