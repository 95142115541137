import { DefinedTrimedString, Email, Firstname, Lastname, StrictPhoneNumber } from '@mero/api-sdk';
import { PageId } from '@mero/api-sdk/dist/pages';
import {
  ASAP,
  In2Weeks,
  MoreThan2Weeks,
  NotDecided,
} from '@mero/api-sdk/dist/pages/onboardingLaunchEta/onboardingLaunchEta';
import { BusinessCategory, BusinessCategoryId } from '@mero/api-sdk/dist/services';
import { createModelContext } from '@mero/components';
import * as t from 'io-ts';
import * as React from 'react';
import { Platform } from 'react-native';

import { googleAnalytics } from '../../analytics/googleAnalytics.web';
import log from '../../utils/log';
import { meroApi } from '../AuthContext';

export const PlaceName = DefinedTrimedString;
export type PlaceName = t.TypeOf<typeof PlaceName>;

export const CityName = DefinedTrimedString;
export type CityName = t.TypeOf<typeof CityName>;

export const NewPlaceDetails = t.type({
  name: DefinedTrimedString,
  cityName: CityName,
  firstname: Firstname,
  lastname: Lastname,
  categoryId: BusinessCategoryId,
  phone: StrictPhoneNumber,
  email: Email.JSON,
});
export type NewPlaceDetails = t.TypeOf<typeof NewPlaceDetails>;

export const Question1 = t.type({
  q1: t.keyof({
    /*eslint-disable @typescript-eslint/naming-convention*/
    '0': null,
    '1-5': null,
    '6-15': null,
    '15-30': null,
    '>30': null,
    /*eslint-enable @typescript-eslint/naming-convention*/
  }),
});

export type Question1 = t.TypeOf<typeof Question1>;

export const Question2 = t.type({
  q2: t.keyof({
    organizare: null,
    clienti_noi: null,
    organizare_si_clienti_noi: null,
  }),
});

export type Question2 = t.TypeOf<typeof Question2>;

export const Question3 = t.type({
  q3: t.keyof({
    [ASAP]: null,
    [In2Weeks]: null,
    [MoreThan2Weeks]: null,
    [NotDecided]: null,
  }),
});

export type Question3 = t.TypeOf<typeof Question3>;

export type OnboardingContextState =
  /**
   * Initial state
   */
  | {
      readonly type: 'New';
    }
  /**
   * Loading required onboarding data
   */
  | {
      readonly type: 'Loading';
    }
  /**
   * Onboarding data loaded successfully
   */
  | {
      readonly type: 'Loaded';
      readonly businessCategories: BusinessCategory[];
    }
  /**
   * Failed to load required onboarding data
   */
  | {
      readonly type: 'LoadFailed';
      readonly error: unknown;
    }
  /**
   * New account creation in progress
   */
  | {
      readonly type: 'Saving';
      readonly businessCategories: BusinessCategory[];
    }
  /**
   * New account created
   */
  | {
      readonly type: 'Saved';
      readonly pageId: PageId;
    }
  /**
   * New account creation failed
   */
  | {
      readonly type: 'Failed';
      readonly businessCategories: BusinessCategory[];
      readonly error: unknown;
    };

const getCookie = (name: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
};

const getUtmSource = (): string | undefined => {
  if (Platform.OS !== 'web') {
    return undefined;
  }

  return getCookie('utm_source');
};

const removeCookie = (name: string): void => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

const deleteUtmSource = (): void => {
  if (Platform.OS !== 'web') {
    return;
  }

  removeCookie('utm_source');
};

const defaultState = (): OnboardingContextState => ({
  type: 'New',
});

export const OnboardingContext = createModelContext(
  defaultState(),
  {
    mutate: (s, fn: (s: OnboardingContextState) => OnboardingContextState): OnboardingContextState => fn(s),
  },
  (dispatch) => {
    const reload = (): void => {
      dispatch.mutate((state) => {
        // Only allow reload for final states
        if (
          state.type === 'New' ||
          state.type === 'Loaded' ||
          state.type === 'LoadFailed' ||
          state.type === 'Saved' ||
          state.type === 'Failed'
        ) {
          const tryReloadAsync = async () => {
            try {
              const businessCategories = await meroApi.pages.getBusinessCategories();

              dispatch.mutate((s): OnboardingContextState => {
                if (s.type === 'Loading') {
                  return {
                    type: 'Loaded',
                    businessCategories,
                  };
                } else {
                  return s;
                }
              });
            } catch (e: unknown) {
              dispatch.mutate((s) => {
                if (s.type === 'Loading') {
                  return {
                    type: 'LoadFailed',
                    error: e,
                  };
                } else {
                  // Invalid state, something happend meanwhile ...
                  return s;
                }
              });
            }
          };

          tryReloadAsync().catch(log.exception);

          return {
            type: 'Loading',
          };
        } else {
          // invalid state to reload;
          return state;
        }
      });
    };

    return {
      init: (): void => {
        dispatch.mutate((state) => {
          if (state.type === 'New') {
            reload();
          }

          return state;
        });
      },
      reload: reload,
      save: (page: NewPlaceDetails & Question1 & Question2 & Question3): void => {
        log.debug('Going to save new place details', page);
        dispatch.mutate((state) => {
          if (state.type === 'Loaded') {
            const trySaveAsync = async () => {
              try {
                const utmSource = getUtmSource();

                const pageId = await meroApi.pages.createPage({
                  categoryId: page.categoryId,
                  name: page.name,
                  firstname: page.firstname,
                  lastname: page.lastname,
                  phoneNo: page.phone,
                  email: page.email,
                  tags: [Platform.OS],
                  rawCity: page.cityName,
                  utmSource,
                  onboardingBookings: page.q1,
                  onboardingGoal: page.q2,
                  onboardingLaunchEta: page.q3,
                });

                deleteUtmSource();

                log.debug(`Onboarding: created new page with ID ${pageId}`);

                googleAnalytics.logEvent({ category: 'page_created', action: 'page_created' });

                dispatch.mutate((s) => {
                  if (s.type === 'Saving') {
                    return {
                      type: 'Saved',
                      pageId: pageId,
                    };
                  } else {
                    return s;
                  }
                });
              } catch (e) {
                log.exception(e);
                dispatch.mutate((s) => {
                  if (s.type === 'Saving') {
                    return {
                      type: 'Failed',
                      businessCategories: s.businessCategories,
                      error: e,
                    };
                  } else {
                    return s;
                  }
                });
              }
            };

            trySaveAsync().catch(log.exception);

            return {
              type: 'Saving',
              businessCategories: state.businessCategories,
            };
          } else {
            // Invalid state to save
            return state;
          }
        });
      },
    };
  },
);

const ContextInit: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  const [, { init }] = OnboardingContext.useContext();

  React.useEffect(() => {
    init();
  }, [init]);

  return <>{children}</>;
};

export const withOnboardingContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithOnboardingContextProvider(props: P) {
    return (
      <OnboardingContext.Provider>
        <ContextInit>
          <Content {...props} />
        </ContextInit>
      </OnboardingContext.Provider>
    );
  };
};
