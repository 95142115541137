import { PageId } from '../../pages/page-id';
import { OnlineSaleItem } from './onlineSaleItem/onlineSaleItem';
import { OnlineSaleSettings } from './onlineSaleSettings';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type ItemOnlineSaleSettings = {
  readonly pageId: PageId;
  readonly item: OnlineSaleItem;
  readonly settings: OnlineSaleSettings;
};

const JSON: t.Type<ItemOnlineSaleSettings, JSONable> = t.type(
  {
    pageId: PageId,
    item: OnlineSaleItem.JSON,
    settings: OnlineSaleSettings.JSON,
  },
  'ItemOnlineSaleSettings',
);

export const ItemOnlineSaleSettings = {
  JSON,
};
