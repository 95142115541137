import { ErrorObject } from './errorObject';
import { JSONable } from './jsonable';
import * as t from 'io-ts';

export type AppErrorObject<Cause> = ErrorObject & {
  readonly originalCause: Cause;
};

const json = <Cause>(cause: t.Type<Cause, JSONable>): t.Type<AppErrorObject<Cause>, JSONable> => {
  return t.intersection(
    [
      ErrorObject.JSON,
      t.type(
        {
          originalCause: cause,
        },
        '!',
      ),
    ],
    `AppErrorObject<${cause.name}>`,
  );
};

export const AppErrorObject = {
  json,
};
