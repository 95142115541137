import { StyleSheet } from 'react-native';

import { colors } from '../../../../../styles';

const styles = StyleSheet.create({
  image: {
    borderRadius: 4,
    overflow: 'hidden',
    position: 'relative',
    borderWidth: 1,
    borderColor: '#EEF0F2',
  },
  closeIconContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    borderRadius: 4,
    zIndex: 1,
    backgroundColor: `rgba(0, 0, 0, 0.4)`,
  },
  count: {
    color: colors.WHITE,
    fontFamily: 'open-sans-bold',
  },
});

export default styles;
