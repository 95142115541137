import { SelectionOption } from '../selectionOption';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type MultipleSelection = {
  readonly name: string;
  readonly required: boolean;
  readonly type: 'MultipleSelection';
  readonly options: SelectionOption[];
};

const JSON: t.Type<MultipleSelection, JSONable> = t.strict(
  {
    name: t.string,
    required: t.boolean,
    type: t.literal('MultipleSelection'),
    options: t.array(SelectionOption.JSON),
  },
  'MultipleSelection',
);

export const MultipleSelection = {
  JSON,
};
