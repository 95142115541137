import { PublicFeedbackDetails } from '../../pages/publicFeedbackDetails';
import { WorkerId } from '../../workers';
import { MarketplaceReviewsApi } from './marketplaceReviewsApi';
import { HttpClient, Option, Paged, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const marketplaceReviewsHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): MarketplaceReviewsApi => {
  const { apiBaseUrl, http } = env;

  const getPageReviewsDecoder = http.decode.response(UnknownApiError, Paged.json(t.array(PublicFeedbackDetails)));

  return {
    getPageReviews: async ({ pageId, limit, page, hasText, workerId }) => {
      const queryParams: {
        page: Option<string>;
        limit: number;
        workerId: Option<WorkerId>;
        hasText: Option<boolean>;
      } = {
        page: page,
        limit: limit,
        workerId: workerId,
        hasText: hasText,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/mp/pages/${encodeURIComponent(pageId)}/reviews`,
            params: queryParams,
          },
          getPageReviewsDecoder,
        ),
      );
    },
  };
};
