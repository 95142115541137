import * as React from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import Animated, { SlideInRight } from 'react-native-reanimated';

import { Skeleton } from '../../../components/Skeleton';
import { Column, MeroHeader, Row, Spacer } from '../../../components/shared';

import useGoBack from '../../../hooks/useGoBack';

import { colors, sizes } from '../../../styles';

const ClientDetailsLoadingScreenWebView = () => {
  const goBack = useGoBack();

  return (
    <Animated.View entering={SlideInRight.duration(250)} style={styles.container}>
      <Column style={styles.infoColumn}>
        <MeroHeader canGoBack onBack={goBack} />
        <View style={styles.contentContainer}>
          <Column alignItems="center">
            <Skeleton width={60} height={60} borderRadius={30} />
            <Spacer size={12} />
            <Skeleton width={164} height={32} borderRadius={0} />
            <Spacer size={6} />
            <Skeleton width={89} height={20} borderRadius={6} />
          </Column>
          <Row style={styles.buttonsWrapper} justifyContent="center" alignItems="flex-end" flex={1}>
            <Row flex={1} gap={8}>
              <Skeleton width={131} height={44} borderRadius={22} />
              <Skeleton width={131} height={44} borderRadius={22} />
            </Row>
          </Row>
          <Skeleton width={269} height={135} borderRadius={6} />
          <Spacer size={16} />
          <Skeleton width={269} height={126} borderRadius={6} />
        </View>
        <Row style={styles.content} alignItems="flex-end">
          <Column flex={1} gap={4}>
            <Skeleton width={30} height={15} borderRadius={6} />
            <Row justifyContent="space-between" alignItems="center" gap={8}>
              <Skeleton width={107} height={28} borderRadius={6} />
            </Row>
          </Column>
          <Row gap={12}>
            <Skeleton width={32} height={32} borderRadius={16} />
            <Skeleton width={32} height={32} borderRadius={16} />
          </Row>
        </Row>
        <Row style={styles.content} alignItems="flex-end">
          <Column flex={1} gap={2}>
            <Skeleton width={30} height={15} borderRadius={6} />
            <Row justifyContent="space-between" alignItems="center" gap={8}>
              <Skeleton width={107} height={28} borderRadius={6} />
            </Row>
          </Column>
          <Row gap={12}>
            <Skeleton width={32} height={32} borderRadius={16} />
            <Skeleton width={32} height={32} borderRadius={16} />
          </Row>
        </Row>
        <Row style={styles.content} alignItems="flex-end">
          <Column flex={1} gap={2}>
            <Skeleton width={30} height={15} borderRadius={6} />
            <Row justifyContent="space-between" alignItems="center" gap={8}>
              <Skeleton width={107} height={28} borderRadius={6} />
            </Row>
          </Column>
          <Row gap={12}>
            <Skeleton width={32} height={32} borderRadius={16} />
            <Skeleton width={32} height={32} borderRadius={16} />
          </Row>
        </Row>
      </Column>
      <Column flex={1} style={styles.menuColumn}>
        <Skeleton width={187} height={360} borderRadius={6} />
      </Column>
      <Column flex={1} style={styles.contentColumn}>
        <View style={styles.list}>
          <Spacer size="24" />
          <Skeleton width={186} height={26} />
          <Spacer size="16" />
          <Skeleton width={93} height={20} />
          <Spacer size="16" />
          <Skeleton width={362} height={173} />
          <Spacer size="16" />
          <Skeleton width={362} height={173} />
          <Spacer size="24" />
          <Skeleton width={93} height={20} />
          <Spacer size="16" />
          <Skeleton width={362} height={173} />
          <Spacer size="16" />
          <Skeleton width={362} height={173} />
          <Spacer size="16" />
          <Skeleton width={362} height={173} />
        </View>
      </Column>
    </Animated.View>
  );
};

const { height } = Dimensions.get('window');

const styles = StyleSheet.create({
  list: {
    paddingLeft: sizes[16],
    paddingRight: sizes[24],
  },
  container: {
    width: 960,
    height: '100%',
    backgroundColor: 'white',
    overflow: 'hidden',
    flexDirection: 'row',
    flex: 1,
  },
  statsWrapper: {
    padding: 12,
  },
  content: {
    marginBottom: sizes[16],
    paddingHorizontal: sizes[24],
    borderBottomWidth: 1,
    borderBottomColor: colors.ATHENS_GRAY,
    paddingBottom: 11,
  },
  buttonsWrapper: {
    paddingVertical: sizes[24],
    gap: sizes[8],
  },
  infoColumn: { borderRightWidth: 1, borderRightColor: colors.ATHENS_GRAY, width: 318, height },
  menuColumn: { paddingTop: sizes[24], width: 222, maxWidth: 222, paddingLeft: sizes[24], paddingRight: sizes[8] },
  contentColumn: { width: 400, height },
  contentContainer: { paddingHorizontal: sizes[24], paddingBottom: 48 },
});

export default ClientDetailsLoadingScreenWebView;
