import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface CustomFieldIdBrand {
  readonly CustomFieldId: unique symbol;
}

export type CustomFieldId = t.Branded<ObjectId, CustomFieldIdBrand>;

const JSON: t.Type<CustomFieldId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is CustomFieldId => true,
  'CustomFieldId',
);

export const CustomFieldId = {
  JSON,
};
