import { Any } from './any';
import { SpecificDate } from './specificDate';
import { Weekly } from './weekly';

export * from './weekly';
export * from './specificDate';
export * from './any';

export const isWeeklySchedule = (schedule: Any): schedule is Weekly => {
  return schedule.type === 'Weekly';
};

export const isSpecificDate = (schedule: Any): schedule is SpecificDate => {
  return schedule.type === 'SpecificDate';
};

export const equals = (a: Any, b: Any): boolean => {
  switch (a.type) {
    case 'SpecificDate':
      return isSpecificDate(b) && SpecificDate.equals(a, b);
    case 'Weekly':
      return isWeeklySchedule(b) && Weekly.equals(a, b);
  }
};
