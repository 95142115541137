import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type ShortAnswear = {
  readonly name: string;
  readonly required: boolean;
  readonly type: 'ShortAnswear';
};

const JSON: t.Type<ShortAnswear, JSONable> = t.strict(
  {
    name: t.string,
    required: t.boolean,
    type: t.literal('ShortAnswear'),
  },
  'ShortAnswear',
);

export const ShortAnswear = {
  JSON,
};
