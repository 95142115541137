import { JSONable } from '../common';
import * as t from 'io-ts';

export type Image = {
  readonly _id: string;
  readonly thumbnail: string;
  readonly small: string;
  readonly medium: string;
  readonly large: string;
  readonly original: string;
};

const JSON: t.Type<Image, JSONable> = t.type(
  {
    _id: t.string,
    thumbnail: t.string,
    small: t.string,
    medium: t.string,
    large: t.string,
    original: t.string,
  },
  'Image',
);

export const Image = {
  JSON,
};
