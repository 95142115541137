import { CheckoutTransactionId } from '@mero/api-sdk/dist/checkout/checkoutTransactionId';
import { ProductSale } from '@mero/api-sdk/dist/pro/productSales/productSale';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Pressable } from 'react-native';

import { Column, FormCard, Row, SmallBody, H2s, Spacer } from '../../../../../../components/shared';
import Icon from '@/components/Icon';

import { colors } from '../../../../../../styles';
import { getMeasure } from '../../../../../../utils/products';
import { scaledToString } from '../../../../../../utils/scaled';
import { nameGenerator } from '../../../../../../utils/string';
import { DefaultProductImage } from '../../../../ProductsScreen/DefaultProductImage';
import styles from './styles';

export type ClientProductCardProps = {
  product: ProductSale;
  handleProductPress: (transactionId: CheckoutTransactionId) => void;
};

const ClientProductCard: React.FC<ClientProductCardProps> = ({ product, handleProductPress }) => {
  const { t } = useTranslation('clients');
  const measure = getMeasure(product.measure);

  return (
    <FormCard dropShaddow style={styles.card} borderRadius={6} paddings="none">
      <Pressable style={styles.button} onPress={() => handleProductPress(product.transactionId)}>
        <Column>
          {product.mainImage?.thumbnail ? (
            <Image source={{ uri: product.mainImage.thumbnail }} style={styles.image} />
          ) : (
            <DefaultProductImage />
          )}
        </Column>
        <Column style={styles.container}>
          <Row>
            <Column flex={1}>
              <H2s style={styles.name}>{product.name}</H2s>
              <Spacer size={2} />
              <SmallBody>
                {scaledToString(product.quantity)} x {measure} - {scaledToString(product.total.amount)}{' '}
                {t(product.total.unit)}
              </SmallBody>
              <Spacer size={6} />
              <SmallBody style={{ color: colors.COMET }}>
                {DateTime.fromJSDate(product.createdAt).toFormat('dd MMM. yyyy').toLowerCase()},{' '}
                {nameGenerator(product.client ?? {}, t('unknownUser'))}
              </SmallBody>
              <Spacer size={8} />
              <Column style={styles.paymentLabel}>
                <SmallBody style={styles.paymentNumber}>
                  {t('paymentId', { value: product.code }).toUpperCase()}
                </SmallBody>
              </Column>
            </Column>
            <Column>
              <Icon name="Arrow" color={colors.DARK_BLUE} />
            </Column>
          </Row>
        </Column>
      </Pressable>
    </FormCard>
  );
};

export default ClientProductCard;
