import { NoteDetails } from '@mero/api-sdk';
import { Button, colors, Spacer } from '@mero/components';
import * as React from 'react';
import { FlatList } from 'react-native';

import ClientNoteCard from '../../../../components/ClientNoteCard';

import ClientEmptyListView from './ClientEmptyListView';

type Props = {
  readonly HeaderElement?: React.ReactElement;
  readonly notes: NoteDetails[];
  readonly onNoteOptionsPress: (note: NoteDetails) => void;
  readonly onAddNote: () => void;
  readonly onEndReached: () => void;
};

const ClientNotesListView: React.FC<Props> = ({
  HeaderElement,
  notes,
  onNoteOptionsPress,
  onAddNote,
  onEndReached,
}) => {
  const renderItem = React.useCallback(
    ({ item }: { item: NoteDetails }) => <ClientNoteCard note={item} onOptionsPress={onNoteOptionsPress} />,
    [onNoteOptionsPress],
  );

  const clientNotesItemKeyExtractor = (note: NoteDetails): string => note._id;

  return (
    <FlatList
      scrollEnabled={false}
      style={{ flex: 1, backgroundColor: colors.WHITE }}
      ListHeaderComponent={HeaderElement}
      data={notes}
      onEndReached={onEndReached}
      onEndReachedThreshold={0.5}
      keyboardShouldPersistTaps="handled"
      keyExtractor={clientNotesItemKeyExtractor}
      contentContainerStyle={{ paddingHorizontal: 16, gap: 16 }}
      renderItem={renderItem}
      ListEmptyComponent={
        <ClientEmptyListView title="Nu există notițe" subtitle="Notițele clientului vor fi afișate aici">
          <Spacer size="32" />
          <Button
            text="Adaugă notiță"
            onClick={onAddNote}
            size="medium"
            color={colors.DARK_BLUE}
            backgroundColor={colors.SKY_BLUE}
            containerStyle={{ flex: 1, flexWrap: 'wrap', width: 200 }}
          />
        </ClientEmptyListView>
      }
    />
  );
};

export default ClientNotesListView;
