import { colors } from '@mero/components';
import { StyleSheet } from 'react-native';

import { sizes } from '../../../../../../styles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  modalBorderBottom: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  optionsButton: { fontSize: 14, color: colors.DARK_BLUE, fontFamily: 'open-sans-semibold' },
  optionsListContainer: {
    marginTop: 0,
    flexDirection: 'column',
    backgroundColor: colors.WHITE,
    borderTopLeftRadius: sizes[12],
    borderTopRightRadius: sizes[12],
    minHeight: sizes[32],
  },
  optionsMenuItem: {
    paddingLeft: sizes[24],
    paddingRight: sizes[24],
  },
  optionsStretchContainer: {
    flex: 1,
    alignSelf: 'stretch',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent',
  },
  button: {
    borderRadius: 22,
    alignItems: 'center',
    justifyContent: 'center',
    height: 44,
    backgroundColor: colors.SKY_BLUE,
  },
});

export default styles;
