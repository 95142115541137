import React from 'react';
import { Svg, Path, G } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

import { colors } from '../../styles';

const Block = ({ color, size = 24 }: IconProps) => {
  if (size === 24) {
    return (
      <Svg width={24} height={24} viewBox="0 0 24 24">
        <G data-name="Group 6844" transform="translate(-206 -32)">
          <Path
            data-name="Subtraction 1"
            d="M7 14a7 7 0 117-7 7.008 7.008 0 01-7 7zM3.183 6.363a.636.636 0 100 1.273h7.636a.636.636 0 000-1.273z"
            transform="translate(210.999 37)"
            fill={color || '#f5365c'}
          />
          <Path data-name="Rectangle 55" transform="translate(206 32)" fill="none" d="M0 0H24V24H0z" />
        </G>
      </Svg>
    );
  }

  return (
    <Svg width={20} height={20} viewBox="0 0 20 20">
      <G data-name="Group 6844" transform="translate(-206 -32)">
        <Path
          data-name="Subtraction 1"
          d="M6 12a6 6 0 116-6 6.007 6.007 0 01-6 6zM2.728 5.455a.545.545 0 100 1.091h6.545a.545.545 0 000-1.091z"
          transform="translate(210 36)"
          fill="#f5365c"
        />
        <Path data-name="Rectangle 55" transform="translate(206 32)" fill="none" d="M0 0H20V20H0z" />
      </G>
    </Svg>
  );
};

export default Block;
