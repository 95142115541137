import { ClientHistoryRecord } from '@mero/api-sdk/dist/clients';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Text } from 'react-native';

import ClientEmptyListView from '../../../components/ClientEmptyListView';

import ClientHistoryRecordCard from '../ClientHistoryRecordCard';
import styles from './styles';

interface ClientHistoryListProps {
  readonly history: ClientHistoryRecord[];
  readonly now: Date;
}

const ClientHistoryList: React.FC<ClientHistoryListProps> = ({ history, now }) => {
  const { t } = useTranslation('clients');

  const renderItem = React.useCallback(
    ({ item }: { item: ClientHistoryRecord }) => {
      return <ClientHistoryRecordCard record={item} now={now} />;
    },
    [now],
  );

  const keyExtractor = (record: ClientHistoryRecord, index: number): string => `${record.type}-${index}`;

  const listHeader = () => {
    if (history.length === 0) {
      return null;
    }

    return <Text style={styles.contentTitle}>{t('history')}</Text>;
  };

  return (
    <FlatList
      style={styles.container}
      contentContainerStyle={styles.contentContainer}
      ListHeaderComponent={listHeader}
      data={history}
      keyboardShouldPersistTaps="handled"
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      removeClippedSubviews
      windowSize={11}
      ListEmptyComponent={
        <ClientEmptyListView title="Nu există istoric" subtitle="Istoricul clientului va fi afișat aici" />
      }
    />
  );
};

export default ClientHistoryList;
