import { ClientImage, ResponsiveImage } from '@mero/api-sdk';
import * as Device from 'expo-device';
import * as ImagePicker from 'expo-image-picker';
import { ImagePickerAsset } from 'expo-image-picker';
import { Platform } from 'react-native';
import { v4 as uuidv4 } from 'uuid';

export enum Status {
  LOADING,
  FAILED,
  TO_BIG,
  SUCCESS,
  IDLE,
}

export type ImageType = {
  _id: string;
  status: Status;
  uri: string;
};

export const convertMbToBytes = (mb: number) => mb * 1024 * 1024;

export const isResponsiveImage = (image: ResponsiveImage | ImageType): image is ResponsiveImage =>
  !image.hasOwnProperty('status');

export const isClientImage = (image: ClientImage | ImageType): image is ClientImage => !image.hasOwnProperty('status');

export const isNewImage = (image: ResponsiveImage | ImageType): image is ImageType => image.hasOwnProperty('status');

export const supportsMultiple = () => {
  if (Platform.OS === 'ios' && Device.osVersion) {
    return Device.osVersion.split('.')[0] >= '14';
  }
  return Platform.OS === 'web';
};

export const singleSelect = async () => {
  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    quality: 1,
  });

  return result.canceled ? [] : result.assets;
};

export const openCamera = async () => {
  const result = await ImagePicker.launchCameraAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    quality: 1,
  });

  return result.canceled ? [] : result.assets;
};

export const multipleSelect = async () => {
  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    quality: 1,
    allowsMultipleSelection: true,
  });

  return result.canceled ? [] : result.assets;
};

export const parseSelectedImages = (selectedImages: ImagePickerAsset[], skipUpload: boolean): ImageType[] => {
  if (selectedImages.length === 0) {
    return [];
  }

  const timestamp = new Date().getTime();

  return selectedImages.map((image) => {
    return {
      _id: Platform.OS === 'web' ? uuidv4() : `${timestamp}-${image.uri}`,
      uri: image.uri,
      status: skipUpload ? Status.IDLE : (image.fileSize ?? 0) > convertMbToBytes(20) ? Status.TO_BIG : Status.LOADING,
    };
  });
};
