import React from 'react';
import { Svg, Path, G, Circle } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

const GiftCard = ({ color, size = 32 }: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 32 32">
    <G data-name="Group 7663" transform="translate(-15244 5679)">
      <Circle cx={16} cy={16} r={16} fill="#e9ecef" data-name="Ellipse 690" transform="translate(15244 -5679)" />
      <G fill="none" data-name="Group 7657" opacity={0.939}>
        <Path d="M15249-5674h22v22h-22z" data-name="Rectangle 59" />
        <G stroke="#52577f" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10}>
          <Path d="M15265.893-5662.207v6.573h-11.786v-6.573m-.982-2.817h13.75v2.817h-13.75ZM15258.001-5665.455v9.821m4-9.821v9.821m-3.45-13.995a1.861 1.861 0 0 0-1.55-.738 2 2 0 0 0-1.87 1.27 1.942 1.942 0 0 0 .547 2.169c1.049.908 4.323 1.473 4.323 1.473a7.906 7.906 0 0 0-1.45-4.174Zm2.9 0a1.861 1.861 0 0 1 1.55-.738 2 2 0 0 1 1.869 1.27 1.942 1.942 0 0 1-.547 2.169c-1.05.908-4.322 1.473-4.322 1.473a7.906 7.906 0 0 1 1.448-4.174Z" />
        </G>
      </G>
    </G>
  </Svg>
);

export default GiftCard;
