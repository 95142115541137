import React from 'react';
import { Svg, Path, G, Circle } from 'react-native-svg';

import { IconProps } from '../../components/Icon';

const Membership = ({ color, size = 32 }: IconProps) => (
  <Svg width={size} height={size} viewBox="0 0 32 32">
    <G data-name="Group 7662" transform="translate(-15244 5722)">
      <Circle cx={16} cy={16} r={16} fill="#e9ecef" data-name="Ellipse 689" transform="translate(15244 -5722)" />
      <G fill="none">
        <Path d="M15249-5717h22v22h-22Z" data-name="Path 8275" />
        <G
          stroke="#52577f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          transform="translate(15251.75 -5712.187)"
        >
          <Path d="M13.512 0v.27a1.351 1.351 0 0 1-2.7 0V0H5.4v.27a1.351 1.351 0 0 1-2.7 0V0H0v11.891h16.215V0ZM9.729 4.324h4.324M9.729 6.486h4.324M9.729 8.648h2.162" />
          <Circle cx={1.375} cy={1.375} r={1.375} data-name="layer1" transform="translate(3.438 3.438)" />
          <Path
            d="M4.863 6.486a2.729 2.729 0 0 0-2.7 2.784v.459h5.4V9.27a2.729 2.729 0 0 0-2.7-2.784Z"
            data-name="layer1"
          />
        </G>
      </G>
    </G>
  </Svg>
);

export default Membership;
