import { StyleSheet } from 'react-native';

import { colors, sizes } from '../../../styles';

const styles = StyleSheet.create({
  container: {
    paddingVertical: sizes[12],
    paddingLeft: 14,
    paddingRight: sizes[8],
    borderWidth: 1,
    borderRadius: 6,
    borderColor: colors.GEYSER,
  },
  subtitle: {
    color: colors.COMET,
  },
  title: {
    fontFamily: 'open-sans-semibold',
  },
  value: {
    width: '90%',
  },
  label: {
    marginTop: sizes[8],
  },
});

export default styles;
