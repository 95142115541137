import { meroApi } from '@/contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '@/contexts/CurrentBusiness';
import { AuthorizedStackParamList, HomeTabsParamsList, ProsDashboardStackParamList } from '@/types';
import { dayTimeSum, DEFAULT_TIMEZONE, intervalDiff } from '@/utils/time';
import { dateStringUtils, DayTime, PageMemberPreview, SavedWorker, UserId } from '@mero/api-sdk';
import { AppointmentId } from '@mero/api-sdk/dist/calendar';
import { DateWorkingSchedule } from '@mero/api-sdk/dist/pro/workingSchedule/dateWorkingSchedule';
import { WorkerId } from '@mero/api-sdk/dist/workers';
import { Row, useShowError, H1, Line } from '@mero/components';
import { DateString, Hour, Minute } from '@mero/shared-sdk';
import { pipe } from 'fp-ts/function';
import { indexOf, sortBy } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet } from 'react-native';

import FormCard from '@mero/components/lib/components/FormCard';
import Column from '@mero/components/lib/components/Layout/Column';
import Spacer from '@mero/components/lib/components/Spacer';

import { NavigationProp, useIsFocused, useNavigation } from '@react-navigation/native';

import ActiveIntervalView, { getWeekIntervals, Interval } from '../../PageReportsScreen/ActiveIntervalView';
import WorkerSchedule from './WorkerSchedule';

export type ScheduleRecord = DateWorkingSchedule['records'][number];

export type WorkerSchedule = {
  pro: WorkerAndMember;
  schedule: Record<DateWorkingSchedule['date'], ScheduleRecord[]>;
};

export type WorkerScheduleWithTotal = WorkerSchedule & { total: DayTime };

export type WorkerAndMember = {
  worker: SavedWorker;
  member: PageMemberPreview;
};

type Props = CurrentBusinessProps;

export const DEFAULT_DAY_TIME: DayTime = { hour: 0 as Hour, minute: 0 as Minute };

const ScheduleMobile: React.FC<Props> = ({ page }) => {
  const { t } = useTranslation('pros');
  const showError = useShowError();
  const isFocused = useIsFocused();

  const navigation =
    useNavigation<NavigationProp<AuthorizedStackParamList & HomeTabsParamsList & ProsDashboardStackParamList>>();

  const lastRequest = React.useRef<symbol>();

  const [isLoading, setIsLoading] = React.useState(false);
  const [schedule, setSchedule] = React.useState<WorkerScheduleWithTotal[]>([]);

  const defaultInterval = React.useMemo(() => getWeekIntervals()[0], []);
  const [activeInterval, setActiveInterval] = React.useState<Interval>(defaultInterval);

  const startOfWeek = React.useMemo(() => activeInterval.value.start.startOf('week'), [activeInterval]);
  const weekDays = React.useMemo(
    () => Array.from({ length: 7 }, (_, i) => startOfWeek.plus({ day: i })),
    [startOfWeek],
  );

  const pros: WorkerAndMember[] = React.useMemo(() => {
    const workersByUserId: Record<UserId, SavedWorker> = page.workers.reduce(
      (acc: Record<UserId, SavedWorker>, worker) => ({ ...acc, [worker.user._id]: worker }),
      {},
    );

    const workersIds = page.workers.map((worker) => worker._id);

    const workers = page.members
      .filter((member) => workersByUserId[member.user._id])
      .map((member) => ({
        worker: workersByUserId[member.user._id],
        member,
      }))
      .filter((member) => member.worker);

    return sortBy(workers, (item) => indexOf(workersIds, item.worker._id));
  }, [page.details._id, page.members, page.workers]);

  const loadSchedule = async () => {
    setIsLoading(true);
    try {
      const request = Symbol('');
      lastRequest.current = request;
      const day = activeInterval.value.start.startOf('week');
      const interval = {
        from: DateString.fromDate(day.toJSDate(), DEFAULT_TIMEZONE),
        to: DateString.fromDate(day.plus({ days: 6 }).toJSDate(), DEFAULT_TIMEZONE),
      };

      const workersSchedule = await meroApi.pro.workingSchedule.getWorkersSchedules({
        pageId: page.details._id,
        interval,
        workerIds: undefined,
      });

      if (lastRequest.current !== request) {
        return;
      }

      const data = workersSchedule.reduce((acc, workerSchedule) => {
        const pro = pros.find((p) => p.worker._id === workerSchedule.workerId);
        if (!pro) {
          return acc;
        }

        return [
          ...acc,
          {
            pro,
            total: workerSchedule.days.reduce(
              (acc, day) =>
                dayTimeSum(
                  acc,
                  day.records.reduce(
                    (acc, record) =>
                      record.type === 'Schedule' && record.schedule.active
                        ? dayTimeSum(
                            acc,
                            record.schedule.intervals.reduce(
                              (acc, interval) => dayTimeSum(acc, intervalDiff(interval)),
                              DEFAULT_DAY_TIME,
                            ),
                          )
                        : acc,
                    DEFAULT_DAY_TIME,
                  ),
                ),
              DEFAULT_DAY_TIME,
            ),
            schedule: workerSchedule.days.reduce(
              (acc, day) => ({ ...acc, [day.date]: day.records }),
              {} as WorkerSchedule['schedule'],
            ),
          },
        ];
      }, [] as WorkerScheduleWithTotal[]);

      setSchedule(data);
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (isFocused) {
      loadSchedule();
    }
  }, [isFocused, activeInterval]);

  const onAddNewInterval = (payload: { workerId: WorkerId; day: DateString }) => {
    navigation.navigate('Schedule', { screen: 'SetSchedule', params: { ...payload, type: 'new_interval' as const } });
  };

  const onEditInterval = (payload: { workerId: WorkerId; day: DateString }) => {
    navigation.navigate('Schedule', { screen: 'SetSchedule', params: { ...payload, type: 'edit_interval' as const } });
  };

  const onAddNewSchedule = (payload: { workerId: WorkerId; day: DateString }) => {
    navigation.navigate('Schedule', { screen: 'SetSchedule', params: { ...payload, type: 'new_schedule' as const } });
  };

  const onEditSchedule = (payload: { workerId: WorkerId; day: DateString }) => {
    navigation.navigate('Schedule', { screen: 'SetSchedule', params: { ...payload, type: 'edit_schedule' as const } });
  };

  const onAddBlockTime = (payload: { workerId: WorkerId; day: DateString }) => {
    navigation.navigate('Booking', {
      screen: 'BlockedTimeCreateScreen',
      params: { date: DateString.toDateTime(payload.day, DEFAULT_TIMEZONE).toISO(), workerId: payload.workerId },
    });
  };

  const onEditBlockTime = (payload: { day: DateString; appointmentId: AppointmentId; occurrenceIndex: number }) => {
    navigation.navigate('Booking', {
      screen: 'BlockedTimeEditScreen',
      params: {
        calendarId: undefined,
        calendarEntryId: payload.appointmentId,
        occurrenceIndex: `${payload.occurrenceIndex}`,
        start: DateString.toDateTime(payload.day, DEFAULT_TIMEZONE).toISO(),
      },
    });
  };

  return (
    <>
      <ScrollView style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1 }} showsVerticalScrollIndicator={false}>
        <H1 style={{ paddingHorizontal: 8 }}>{t('schedule')}</H1>
        <Spacer size="24" />
        <Row>
          <ActiveIntervalView
            withFullCurrentMonth
            activeInterval={activeInterval}
            setActiveInterval={setActiveInterval}
            showFutureDates={true}
            type="week"
            fullWidth
            headerTitle={t('selectPeriod')}
            title={t('selectPeriod')}
            customTitle={t('selectWeek')}
          />
        </Row>
        <Spacer size="16" />
        <FormCard rounded dropShaddow paddings="none" style={{ paddingVertical: 16 }}>
          {schedule.map((workerSchedule, index) => (
            <React.Fragment key={workerSchedule.pro.worker._id}>
              <Column style={{ paddingHorizontal: 16 }}>
                <WorkerSchedule
                  workerSchedule={workerSchedule}
                  scheduledWeek={weekDays}
                  onAddNewInterval={onAddNewInterval}
                  onEditInterval={onEditInterval}
                  onAddNewSchedule={onAddNewSchedule}
                  onAddBlockTime={onAddBlockTime}
                  onEditSchedule={onEditSchedule}
                  onEditBlockTime={onEditBlockTime}
                  refresh={loadSchedule}
                />
              </Column>
              {index < schedule.length - 1 && (
                <>
                  <Spacer size="16" />
                  <Line />
                  <Spacer size="16" />
                </>
              )}
            </React.Fragment>
          ))}
        </FormCard>
        <Spacer size={96} />
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({});

export default pipe(ScheduleMobile, CurrentBusiness);
