import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type MarketplaceSearchConfig = {
  readonly filters: {
    /**
     * Allow filtering using hasPromoPrice filter
     */
    readonly hasPromoPrice: boolean;
    /**
     * Allow filter using isTabyaCertified filter
     */
    readonly isTabyaCertified: boolean;
    /**
     * Allow filtering using hasGiftcards filter
     */
    readonly hasGiftcards: boolean;
    /**
     * Allow filtering using acceptsCardPayments filter
     */
    readonly acceptsCardPayments: boolean;
  };
};

const JSON: t.Type<MarketplaceSearchConfig, JSONable> = t.type(
  {
    filters: t.type(
      {
        hasPromoPrice: t.boolean,
        isTabyaCertified: t.boolean,
        hasGiftcards: t.boolean,
        acceptsCardPayments: t.boolean,
      },
      'Filters',
    ),
  },
  'MarketplaceSearchConfig',
);

/**
 * Reasonable default filters config, to be used by client apps before server responds with real config for a query
 */
const CLIENT_DEFAULT: MarketplaceSearchConfig = {
  filters: {
    hasPromoPrice: true,
    isTabyaCertified: false,
    hasGiftcards: false,
    acceptsCardPayments: false,
  },
};

const clientDefault = (): MarketplaceSearchConfig => {
  return CLIENT_DEFAULT;
};

const hasAnyFilter = (config: MarketplaceSearchConfig): boolean => {
  return (
    config.filters.hasPromoPrice ||
    config.filters.isTabyaCertified ||
    config.filters.hasGiftcards ||
    config.filters.acceptsCardPayments
  );
};

export const MarketplaceSearchConfig = {
  JSON,
  clientDefault,
  hasAnyFilter,
};
