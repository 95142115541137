import { AppointmentId } from '../calendar/appointment-id';
import { WorkerId } from '../workers/workerId';
import { ClientHistoryRecordId } from './client-history-record-id';
import {
  ClientAppointmentCancelledHistoryType,
  ClientAppointmentCreatedHistoryType,
  ClientBlockedStatusChangedHistoryType,
  ClientFavouriteStatusChangedHistoryType,
  ClientWarnedStatusChangedHistoryType,
  ClientAppointmentModifiedHistoryType,
  AppointmentClientAddedHistoryType,
} from './client-history-type';
import { ClientId } from './client-id';
import { OptionalDefinedString, StrictPhoneNumber, UserId, optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

/**
 * ClientHistory records common fields
 */
export const ClientHistoryBase = t.type(
  {
    _id: ClientHistoryRecordId,
    clientId: ClientId,
    date: DateFromISOString,
  },
  'ClientHistoryBase',
);

export interface ClientHistoryBase extends t.TypeOf<typeof ClientHistoryBase> {}

const ByUser = t.type(
  {
    _id: UserId,
    firstname: t.string,
    lastname: t.string,
    phone: StrictPhoneNumber,
  },
  'ByUser',
);

const Worker = t.type(
  {
    _id: WorkerId.JSON,
    firstname: t.string,
    lastname: t.string,
  },
  'Worker',
);

/**
 * Client created an appointment history record
 */
export const ClientAppointmentCreatedHistoryRecord = t.intersection(
  [
    t.type({
      type: ClientAppointmentCreatedHistoryType,
      payload: t.type({
        appointmentId: AppointmentId,
        start: DateFromISOString,
        end: DateFromISOString,
        createdByClient: t.boolean,
        serviceName: OptionalDefinedString,
        byUser: optionull(ByUser),
        worker: optionull(Worker),
      }),
    }),
    ClientHistoryBase,
  ],
  'ClientAppointmentCreatedHistoryRecord',
);

export interface ClientAppointmentCreatedHistoryRecord extends t.TypeOf<typeof ClientAppointmentCreatedHistoryRecord> {}

/**
 * Client cancelled an appointment history record
 */
export const ClientAppointmentCancelledHistoryRecord = t.intersection(
  [
    t.type({
      type: ClientAppointmentCancelledHistoryType,
      payload: t.type({
        appointmentId: AppointmentId,
        start: DateFromISOString,
        end: DateFromISOString,
        cancelledByClient: t.boolean,
        reason: OptionalDefinedString,
        serviceName: OptionalDefinedString,
        byUser: optionull(ByUser),
        worker: optionull(Worker),
      }),
    }),
    ClientHistoryBase,
  ],
  'ClientAppointmentCancelledHistoryRecord',
);

export interface ClientAppointmentCancelledHistoryRecord
  extends t.TypeOf<typeof ClientAppointmentCancelledHistoryRecord> {}

/**
 * Client was blocked/unblocked history record
 */
export const ClientBlockedStatusChangedHistoryRecord = t.intersection(
  [
    t.type({
      type: ClientBlockedStatusChangedHistoryType,
      payload: t.type({
        status: t.boolean,
        byUser: optionull(ByUser),
      }),
    }),
    ClientHistoryBase,
  ],
  'ClientBlockedStatusChangedHistoryRecord',
);

export interface ClientBlockedStatusChangedHistoryRecord
  extends t.TypeOf<typeof ClientBlockedStatusChangedHistoryRecord> {}

/**
 * Client was warned/unwarned histroy record
 */
export const ClientWarnedStatusChangedHistoryRecord = t.intersection(
  [
    t.type({
      type: ClientWarnedStatusChangedHistoryType,
      payload: t.type({
        status: t.boolean,
        byUser: optionull(ByUser),
      }),
    }),
    ClientHistoryBase,
  ],
  'ClientWarnedStatusChangedHistoryRecord',
);

export interface ClientWarnedStatusChangedHistoryRecord
  extends t.TypeOf<typeof ClientWarnedStatusChangedHistoryRecord> {}

/**
 * Client added/removed to/from favourites history record
 */
export const ClientFavouriteStatusChangedHistoryRecord = t.intersection(
  [
    t.type({
      type: ClientFavouriteStatusChangedHistoryType,
      payload: t.type({
        status: t.boolean,
        byUser: optionull(ByUser),
      }),
    }),
    ClientHistoryBase,
  ],
  'ClientFavouriteStatusChangedHistoryRecord',
);

export interface ClientFavouriteStatusChangedHistoryRecord
  extends t.TypeOf<typeof ClientFavouriteStatusChangedHistoryRecord> {}

/**
 * Client's appointment was modified history record
 */
export const ClientAppointmentModifiedHistoryRecord = t.intersection(
  [
    t.type({
      type: ClientAppointmentModifiedHistoryType,
      payload: t.type(
        {
          appointmentId: AppointmentId,
          start: t.type(
            {
              from: DateFromISOString,
              to: DateFromISOString,
            },
            'Start',
          ),
          byUser: ByUser,
          worker: optionull(Worker),
          serviceName: OptionalDefinedString,
        },
        'Payload',
      ),
    }),
    ClientHistoryBase,
  ],
  'ClientAppointmentModifiedHistoryRecord',
);

export interface ClientAppointmentModifiedHistoryRecord
  extends t.TypeOf<typeof ClientAppointmentModifiedHistoryRecord> {}

/**
 * Client was added to an appointment
 */
export const AppointmentClientAddedHistoryRecord = t.intersection(
  [
    t.type({
      type: AppointmentClientAddedHistoryType,
      payload: t.type(
        {
          appointmentId: AppointmentId,
          start: DateFromISOString,
          byUser: ByUser,
        },
        'Payload',
      ),
    }),
    ClientHistoryBase,
  ],
  'AppointmentClientAddedHistoryRecord',
);

export interface AppointmentClientAddedHistoryRecord extends t.TypeOf<typeof AppointmentClientAddedHistoryRecord> {}

export const ClientHistoryRecord = t.union(
  [
    ClientAppointmentCreatedHistoryRecord,
    ClientAppointmentCancelledHistoryRecord,
    ClientBlockedStatusChangedHistoryRecord,
    ClientWarnedStatusChangedHistoryRecord,
    ClientFavouriteStatusChangedHistoryRecord,
    ClientAppointmentModifiedHistoryRecord,
    AppointmentClientAddedHistoryRecord,
  ],
  'ClientHistoryRecord',
);

export type ClientHistoryRecord =
  | ClientAppointmentCreatedHistoryRecord
  | ClientAppointmentCancelledHistoryRecord
  | ClientBlockedStatusChangedHistoryRecord
  | ClientWarnedStatusChangedHistoryRecord
  | ClientFavouriteStatusChangedHistoryRecord
  | ClientAppointmentModifiedHistoryRecord
  | AppointmentClientAddedHistoryRecord;

export const ClientHistoryRecordArray = t.array(ClientHistoryRecord);

export type ClientHistoryRecordArray = t.TypeOf<typeof ClientHistoryRecordArray>;
