import { NoteAppointment } from './noteAppointment';
import { NoteId } from './noteId';
import { JSONable, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

export type NotePreview = {
  readonly _id: NoteId;
  readonly text: tt.NonEmptyString;
  readonly appointment: Option<NoteAppointment>;
};

const JSON: t.Type<NotePreview, JSONable> = t.type({
  _id: NoteId.JSON,
  text: tt.NonEmptyString,
  appointment: Option.json(NoteAppointment.JSON),
});

export const NotePreview = {
  JSON,
};
