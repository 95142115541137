import { CalendarEntry } from './calendarEntry';
import { DateString, DayTime, JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type BulkCalendarEntry = CalendarEntry.Any & {
  /**
   * Entry start converted to calendar timezone
   */
  readonly localStart: {
    readonly dateStr: DateString;
    readonly time: DayTime;
  };
  /**
   * Entry end converted to calendar timezone
   */
  readonly localEnd: {
    readonly dateStr: DateString;
    readonly time: DayTime;
  };
};

const JSON: t.Type<BulkCalendarEntry, JSONable> = t.intersection(
  [
    CalendarEntry.Any.JSON,
    t.type({
      localStart: t.type(
        {
          dateStr: DateString.JSON,
          time: DayTime.JSON,
        },
        'LocalStart',
      ),
      localEnd: t.type(
        {
          dateStr: DateString.JSON,
          time: DayTime.JSON,
        },
        'LocalEnd',
      ),
    }),
  ],
  'BulkCalendarEntry',
);

export const BulkCalendarEntry = {
  JSON,
};
