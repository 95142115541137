import { Platform, StyleSheet } from 'react-native';

import { colors } from '../../../styles';

const styles = StyleSheet.create({
  imageContainer: {
    borderRadius: 12,
    marginBottom: 8,
    overflow: 'hidden',
    position: 'relative',
    borderWidth: 1,
    borderColor: '#EEF0F2',
    ...Platform.select({
      web: {
        width: 115,
        height: 115,
      },
    }),
  },
  appointmentOverlay: {
    position: 'absolute',
    left: 4,
    bottom: 4,
    zIndex: 1,
  },
  noteOverlay: {
    position: 'absolute',
    bottom: 4,
    zIndex: 1,
  },
  image: {
    ...Platform.select({
      web: {
        width: '100%',
        height: '100%',
      },
      ios: {
        width: '100%',
        height: '100%',
      },
      android: {
        width: '100%',
        height: '100%',
      },
    }),
  },
  overlayContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    borderRadius: 4,
    zIndex: 1,
  },
  deleteOverlay: {
    position: 'absolute',
    top: 4,
    right: 4,
    zIndex: 1,
  },
  overlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: `rgba(0, 0, 0, 0.5)`,
  },
  iconContainer: {
    width: 24,
    height: 24,
    backgroundColor: `rgba(0, 0, 0, 0.6)`,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: { color: colors.WHITE, textAlign: 'center', fontFamily: 'open-sans-semibold' },
  failedLabel: { paddingVertical: 4, backgroundColor: '#FBAC40' },
  errorLabel: { paddingVertical: 4, backgroundColor: '#FF0033' },
});

export default styles;
