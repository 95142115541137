import * as t from 'io-ts';

/**
 * Client created an appointment
 */
export const ClientAppointmentCreatedHistoryType = t.literal(1);
export type ClientAppointmentCreatedHistoryType = t.TypeOf<typeof ClientAppointmentCreatedHistoryType>;

/**
 * Client cancelled an appointment
 */
export const ClientAppointmentCancelledHistoryType = t.literal(2);
export type ClientAppointmentCancelledHistoryType = t.TypeOf<typeof ClientAppointmentCancelledHistoryType>;

/**
 * Client blocked status changed
 */
export const ClientBlockedStatusChangedHistoryType = t.literal(3);
export type ClientBlockedStatusChangedHistoryType = t.TypeOf<typeof ClientBlockedStatusChangedHistoryType>;

/**
 * Client warned status changed
 */
export const ClientWarnedStatusChangedHistoryType = t.literal(4);
export type ClientWarnedStatusChangedHistoryType = t.TypeOf<typeof ClientWarnedStatusChangedHistoryType>;

/**
 * Client favourite status changed
 */
export const ClientFavouriteStatusChangedHistoryType = t.literal(5);
export type ClientFavouriteStatusChangedHistoryType = t.TypeOf<typeof ClientFavouriteStatusChangedHistoryType>;

/**
 * Client appointment was modified
 */
export const ClientAppointmentModifiedHistoryType = t.literal(6);
export type ClientAppointmentModifiedHistoryType = t.TypeOf<typeof ClientAppointmentModifiedHistoryType>;

/**
 * Client was added to an appointment
 */
export const AppointmentClientAddedHistoryType = t.literal(7);
export type AppointmentClientAddedHistoryType = t.TypeOf<typeof AppointmentClientAddedHistoryType>;

export const ClientHistoryType = t.union(
  [
    ClientAppointmentCreatedHistoryType,
    ClientAppointmentCancelledHistoryType,
    ClientBlockedStatusChangedHistoryType,
    ClientWarnedStatusChangedHistoryType,
    ClientFavouriteStatusChangedHistoryType,
    ClientAppointmentModifiedHistoryType,
    AppointmentClientAddedHistoryType,
  ],
  'ClientHistoryType',
);

export type ClientHistoryType = t.TypeOf<typeof ClientHistoryType>;
