import { AppError } from './appError';
import { formatValidationErrors } from './io-error-reporter';
import * as t from 'io-ts';

/**
 * Class for data decoding error
 */
export class DecodeError extends AppError<undefined> {
  public readonly messages: string[];

  constructor(errors: t.Errors) {
    const messages = formatValidationErrors(errors);
    super(messages.slice(0, 5).join('\n'), undefined);
    this.messages = messages;
  }

  public toString(): string {
    return `DecodeError("${this.message}")`;
  }
}
