import { Platform, StyleSheet } from 'react-native';

import { sizes } from '../../../../../styles';

const styles = StyleSheet.create({
  container: { flex: 1, overflow: 'hidden' },
  contentContainer: {
    paddingLeft: sizes[16],
    paddingRight: sizes[16],
    ...Platform.select({
      web: { paddingTop: sizes[24] },
    }),
  },
  contentTitle: {
    fontFamily: 'merriweather-bold',
    fontSize: 20,
  },
  addNoteButton: { flex: 1, flexWrap: 'wrap', width: 200 },
});

export default styles;
