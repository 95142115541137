import { CheckoutTransactionId, CheckoutTransactionPreview } from '@mero/api-sdk';
import { Column } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Text } from 'react-native';

import ClientEmptyListView from '../../components/ClientEmptyListView';
import TransactionCard from '@/components/TransactionCard';
import { Row, SmallBody, Title } from '@/components/shared';

import { useMediaQueries } from '@/hooks/useMediaQueries';

import styles from './styles';

export type FinishedTransaction = CheckoutTransactionPreview.AnyFinished | CheckoutTransactionPreview.AnyDeleted;

interface ClientTransactionsListProps {
  readonly clientTransactions: FinishedTransaction[];
  readonly onTransactionPress: (checkoutTransactionId: CheckoutTransactionId) => void;
}

const ClientTransactionsList: React.FC<ClientTransactionsListProps> = ({ clientTransactions, onTransactionPress }) => {
  const { isPhone } = useMediaQueries();
  const { t } = useTranslation('clients');
  const renderItem = ({ item }: { item: FinishedTransaction }) => {
    return <TransactionCard transaction={item} onPress={() => onTransactionPress(item._id)} />;
  };

  const keyExtractor = (item: FinishedTransaction): string => item._id;

  const listHeader = () => {
    if (clientTransactions.length === 0) {
      return null;
    }

    return (
      <Column>
        <Row justifyContent="space-between" alignItems="center">
          <Text style={styles.contentTitle}>{t('transactions')}</Text>
        </Row>
      </Column>
    );
  };

  return (
    <FlatList
      scrollEnabled={isPhone ? false : true}
      style={styles.container}
      ListHeaderComponent={listHeader}
      contentContainerStyle={styles.contentContainer}
      data={clientTransactions}
      keyboardShouldPersistTaps="handled"
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      removeClippedSubviews
      windowSize={11}
      ListEmptyComponent={
        <ClientEmptyListView title="Nu există incasări" subtitle="Incasările clientului vor fi afișate aici" />
      }
    />
  );
};

export default ClientTransactionsList;
