import * as t from 'io-ts';

export interface ClientImageIdBrand {
  readonly ClientImageId: unique symbol;
}

export type ClientImageId = t.Branded<string, ClientImageIdBrand>;

const JSON: t.Type<ClientImageId, string> = t.brand(
  t.string,
  (_id: string): _id is ClientImageId => true,
  'ClientImageId',
);

export const ClientImageId = {
  JSON,
};
