import { colors } from '@mero/components';
import { StyleSheet } from 'react-native';

import { sizes } from '../../../../../../styles';

const styles = StyleSheet.create({
  container: {
    marginBottom: sizes[16],
    padding: 16,
  },
  name: {
    fontSize: 17,
  },
  button: {
    flexDirection: 'row',
    paddingTop: 0,
    paddingBottom: 0,
  },
  content: { flex: 1, paddingLeft: 16 },
  debtWrapper: {
    backgroundColor: colors.OUTRAGEOUS_ORANGE,
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 4,
    marginTop: 16,
  },
  debtAmount: { flex: 1, color: colors.WHITE, fontFamily: 'open-sans-semibold' },
  debtAmountValue: { color: colors.WHITE, fontFamily: 'open-sans-semibold' },
});

export default styles;
